
const UnitsData = [
    { name: "Kilogram", value: "KG" },
    { name: "Gram", value: "G" },
    { name: "Milligram", value: "MG" },
    { name: "US Pound", value: "LB" },
    { name: "Gallon", value: "GAL" },
    { name: "Liter", value: "L" },
    { name: "Milliliter", value: "ML" },
    { name: "Ounce", value: "OZ" },
    { name: "Piece", value: "PC" },
    { name: "Percentage", value: "PCT" },
    { name: "Dozen", value: "DZ" },
    { name: "Boxes", value: "BOX" },
    { name: "Bottle", value: "BTL" },
    { name: "Each", value: "EA" },
    { name: "Carton", value: "CAR" },
    { name: "Canister", value: "CAN" },
    { name: "Pack", value: "PAK" },
    { name: "Bag", value: "BAG" },
    { name: "Case", value: "CS" },
    { name: "Container", value: "CTN" },
    { name: "Meter", value: "M" },
    { name: "Millimeter", value: "MM" },
    { name: "Inch", value: "IN" },
    { name: "Square meter", value: "M2" },
    { name: "Cubic meter", value: "M3" },
    { name: "Cubic foot", value: "FT3" },
    { name: "Kiloton", value: "KT" },
    { name: "Unknown", value: "UNK" },
];

export default UnitsData;
