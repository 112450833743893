import AvenirLT from '../css/AvenirLTStd-Light.otf';
import AvenirLTB from '../css/AvenirLTStd-Black.otf';
import AvenirLTM from '../css/AvenirLTStd-Medium.otf';
const avenirlt = {
  fontFamily: 'AvenirLT',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${AvenirLT}) format('otf')`,

};
const avenirltb = {
  fontFamily: 'AvenirLTBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${AvenirLTB}) format('otf')`,

};
const avenirltm = {
  fontFamily: 'AvenirLTMedium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${AvenirLTM}) format('otf')`,

};

const typography = {
  h1: {
    // fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px',
    fontFamily: 'AvenirLT'
  },
  h2: {
    // fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px',
    fontFamily: 'AvenirLT',
    color: 'black'
  },
  h3: {
    // fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px',
    fontFamily: 'AvenirLT',
    color: 'black'
  },
  h4: {
    // fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px',
    fontFamily: 'AvenirLT',
    color: 'black'
  },
  h5: {
    // fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px',
    fontFamily: 'AvenirLT',
    color: 'black'
  },
  h6: {
    // fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
    fontFamily: 'AvenirLT',
    color: 'black'
  },
  caption: {
    fontFamily: 'AvenirLT',
    color: 'black'
  },
  overline: {
    fontWeight: 500
  },
  button: {
    textTransform: 'none'
  },

  fontFamily: [
    'AvenirLT',
    "AvenirLTBold",
    "AvenirLTMedium"
  ].join(','),

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [avenirlt, avenirltb, avenirltm],
      },
    },
  },
};
export default typography;
