import React from 'react';
import { FormControl, InputLabel, OutlinedInput, FormHelperText, Typography, makeStyles } from '@material-ui/core';
import styles from '../styles'
import {
  Grid
} from '@material-ui/core';
import SearchIcon from '../components/SVG/SearchIcon';

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      // borderWidth:0,
      borderColor: "#707272"
    },
    "&:hover $notchedOutline": {
      borderWidth: 1,
      borderColor: "#707272 !important"
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderColor: "#707272 !important"
    }
  },
  focused: {},
  notchedOutline: {}
}));


const TopSearchBar = ({ value, onChange, id, error, placeholder = "", type = "text", ...props }) => {
  const style = styles();
  const outlinedInputClasses = useOutlinedInputStyles();

  if (error) {
    props["error"] = true
  }
  return (
    <Grid data-qaid="searchbar_main_grid" container style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', position: 'relative' }}>
      <FormControl data-qaid="searchbar_formcontrol" {...props} variant="outlined" className={style.fullWidth} style={{ paddingLeftL: 30 }}>
        <OutlinedInput
          data-qaid="searchbar_outlinedinput"
          {...props}
          id={id}
          style={{ paddingLeft: 30 }}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          aria-describedby={`${id}-text`}
          classes={outlinedInputClasses}
        // height={56}
        />
        <FormHelperText data-qaid="searchbar_formhelpertext" id={`${id}-text`}>{error}</FormHelperText>
      </FormControl>

      <Grid data-qaid="searchbar_search_grid" item style={{ position: 'absolute', marginLeft: 10, left: 0 }}>
        <SearchIcon data-qaid="searchbar_searchicon" />
      </Grid>
    </Grid>


  )
}
export default TopSearchBar;