import React, { useContext, useState } from 'react';
import MyContext from '../../Provider/MyContext';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { H6, AvenirBlackH4, H2 } from '../../utils/text';
import {
    Box,
    makeStyles,
    Button
} from '@material-ui/core';
import Page from '../../components/Page';
import LoginLogo from '../../components/SVG/LoginLogo';
import LockoutIcon from '../../components/SVG/LockoutIcon';
import { ForgotPassword, ForgotPasswordSucess } from './LoginView';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F3FAFD',
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));
const LockoutPage = () => {
    const Context = useContext(MyContext);
    const classes = useStyles();
    const navigate = useNavigate();
    const [showForgotsucces, setShowForgotsucces] = useState(false)
    const [openForgotPassword, setOpenForgotPassword] = useState(false);
    const handleClickReset = () => {
        setOpenForgotPassword(true)
    }
    const forgotCallback = () => {
        setOpenForgotPassword(false)
        setShowForgotsucces(true)
    }
    const forgotSuccCallback = () => {
        setShowForgotsucces(false);
        navigate('/')
    }
    return (
        <Page
            data-qaid="loginView_page"
            className={classes.root}
            title={Context.langData.account_locked}
        >
            <Box
                data-qaid="loginView_box"
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
                alignItems="center"
            >
                <LoginLogo style={{ marginTop: '15%' }} />
                <LockoutIcon style={{ marginTop: '5%' }} />
                <H6 label={Context.langData.account_locked} style={{ marginTop: 16 }}></H6>
                <AvenirBlackH4 label={Context.langData.lockout_message} style={{ marginTop: 16, width: 360 }} />
                <Button
                    data-qaid="bcardView_icon_button"
                    aria-label="edit"
                    style={{ width: 161, height: 40, backgroundColor: '#FFB600', borderRadius: 4, marginTop: 30 }}
                    onClick={() => handleClickReset()}>
                    <AvenirBlackH4 data-qaid="bcardView_editfloor" style={{ marginLeft: 0 }} label={Context.langData.reset_password} />
                </Button>

            </Box>
            {
                openForgotPassword &&
                <ForgotPassword
                    open={openForgotPassword}
                    onClose={() => setOpenForgotPassword(false)}
                    title={Context.langData.reset_password}
                    info={Context.langData.reset_info_message}
                    callback={forgotCallback}
                />
            }
            {
                showForgotsucces &&
                <ForgotPasswordSucess
                    open={showForgotsucces}
                    onClose={() => setShowForgotsucces(false)}
                    callback={forgotSuccCallback}
                />
            }
        </Page>
    )

}
export default LockoutPage;