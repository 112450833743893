import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Paper, Grid, Typography, Divider } from '@material-ui/core';
import { H3, TBold, H6, H4 } from '../../../utils/text'
import { useSelector } from 'react-redux';
import MultiSelect from '../../../components/MultiSelect';
import clsx from 'clsx';
import RefreshButton from '../../../components/RefreshButton';
import MyContext from '../../../Provider/MyContext';
import { ConvertPrice } from '../../../utils/util';
const Sales = ({ classes, ordertypes, refresh }) => {
  const sales = useSelector(state => state.dashboard.sales)
  const [selectedOrderTypes, setSelectedorderTypes] = useState([])
  const Context = useContext(MyContext);
  const salesData = useCallback(() => {
    if (sales.data) {
      return sales.data;
    }
    else {
      return [{ "sales": 0, "type": "Subtotal" }, { "sales": 0, "type": "Discount" }, { "sales": 0, "type": "Tax" }, { "sales": 0, "type": "Delivery Charges" }, { "sales": 0, "type": "Packaging Charges" }]
    }
  }, [sales]);
  const salesList = salesData();
  const handleChangeOrderType = (value) => {
    setSelectedorderTypes(value)
    refresh(value)
  }
  return (
    <Paper data-qaid="sales_main_paper" className={clsx(classes.paper, classes.noSidePaddingsale, classes.relative)} style={{ backgroundColor: '#F3FAFD', border: '1px solid #C8E6F1' }}>
      {
        sales &&
        <Grid container data-qaid="sales_main_grid">
          <Grid data-qaid="sales_header_grid" container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Grid data-qaid="sales_header_grid" item sm={12} lg={6} md={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingLeft: 32 }}>
              <H3 data-qaid="sales_header_text" label={Context.langData.sales}></H3>
            </Grid>
            <Grid data-qaid="sales_refresh_grid" item sm={12} lg={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <RefreshButton data-qaid="sales_refresh_button" refresh={() => { refresh(selectedOrderTypes.length > 0 ? selectedOrderTypes : null) }} />
            </Grid>
          </Grid>
          <Grid data-qaid="sales_content_grid" container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: '#f2957a' }} >
            <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginLeft: 32 }}>
              <H6 data-qaid="sales_text_total" style={{ display: 'flex' }} label={`$${ConvertPrice(sales.Total)}`}  ></H6>
              <H4 data-qaid="sales_complite_label" style={{ color: "#54575A" }} label={Context.langData.completed_small} ></H4>
            </Grid>
          </Grid>
        </Grid>
      }
    </Paper >
  )
}

export default React.memo(Sales);