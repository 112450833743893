import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Button
} from '@material-ui/core';
import SingleSelect from '../../../../components/SingleSelect';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllIssues,
  fetchDepartments,
  undoIssue
} from '../../../../redux/actions';
import MyContext from '../../../../Provider/MyContext';
import ViewIssueId from './viewIssueId';
import ViewReturnStock from './viewReturnStock';
import ViewSendStock from './viewSendStock';
import { AvenirBlackH4 } from '../../../../utils/text';
import ContextMenu from '../../../../components/ContextMenu';
import _ from 'lodash';
import styles from '../../../../styles/index';
import ReturnIcon from '../../../../components/SVG/ReturnIcon';
import SimpleTable from '../../../../components/SimpleTable';
import AlertDialog from '../../../../components/Dialog';
import SnackBar from '../../../../components/SnackBar';

const Issues = () => {
  const style = styles();
  const dispatch = useDispatch();
  const [table, setTable] = useState([]);
  const kitchenName = useSelector(state => state.inventory.departments);
  const issuesData = useSelector(state => state.inventory.issues);
  const [allIndents, setAllIndents] = useState('all');
  const [pageNumber, setPageNumber] = useState(0)
  const [viewIssueId, setViewIssueId] = useState(false);
  const [row, setRow] = useState({});
  const [kitchenData, setKitchenData] = useState([]);
  const [showReturnStock, setShowReturnStock] = useState(false);
  const [showSendStock, setShowSendStock] = useState(false);
  const Context = useContext(MyContext);
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(Context.langData.undo_issue);
  const [bodyMessage, setBodyMessage] = useState(Context.langData.undo_isuue_message);
  const [snackbar, setSnackbar] = useState(null);


  useEffect(() => {
    dispatch(fetchDepartments());
  }, [])

  const ContextMenuData = [
    {
      id: 1,
      icon: ReturnIcon,
      name: Context.langData.return_stock
    }
  ]
  const callBack = (id) => {
    if (id == 1) {
      handleReturnStock()
    }

  }

  useEffect(() => {
    refreshIssuesData();
  }, [pageNumber, allIndents]);
  const refreshIssuesData = () => {
    var offsetValue = 0;
    if (pageNumber > 0) {
      offsetValue = pageNumber - 1;
    }
    dispatch(fetchAllIssues({ offset: offsetValue, limit: 20, department_id: allIndents }));
  }

  useEffect(() => {
    if (issuesData && issuesData.data && issuesData.data.length > 0) {
      if (kitchenName.length > 0) {
        var array = [...issuesData.data];
        array.map((item, index) => {
          if (item.type == 'Issue') {
            item['undo'] = <span onClick={() => { onUndoClick(item) }} style={{ textDecoration: 'underline' }}>{Context.langData.undo}</span>
          } else {
            item['undo'] = <span></span>
          }
//          item["type"] = IssueType[item["type"]]
          var kitchenObj = kitchenName.filter((e) => e.id == item.departmentId)
          if (kitchenObj.length > 0) {
            array[index] = { ...array[index], kitchenName: kitchenObj[0].name }
          } else {
            array[index] = { ...array[index], kitchenName: "-" }

          }

        })
        setTable(array)
      }
    }
    else {
      setTable([]);
    }
  }, [issuesData, kitchenName]);
  const onUndoClick = (item) => {
    setRow(item);
    setShowDialog(true);
  }
  const [columns, setColumns] = useState([
    // { field: 'IssueId', display: Context.langData.issue_id, link: true, size: '15%', sort: false },
    { field: 'kitchenName', display: Context.langData.kitchen, size: '25%', sort: false },
    { field: 'type', display: Context.langData.status, size: '15%', sort: false },
    { field: 'dateTime', display: Context.langData.date, size: '25%', sort: false },
    { field: 'id', display: Context.langData.issue_number, size: '20%', sort: false },
    { field: 'undo', display: Context.langData.undo, size: '15%' },
  ]);
  const onClickIssueId = (rowValue, event) => {
    if (event.target.onclick) {
    } else {
      setRow(rowValue)
      setViewIssueId(true);
    }
  }

  const onPageinationChange = (value) => {
    setPageNumber(value)
  }

  useEffect(() => {
    var array = [{ id: 'all', name: Context.langData.All_kitchens }];
    if (kitchenName.length > 0) {
      kitchenName.map((item) => {
        array.push(item)
      })
    }
    setKitchenData(array);
  }, [kitchenName]);
  const handleReturnStock = () => {
    setShowReturnStock(true);
  }
  const handleSendStock = () => {
    setShowSendStock(true);
  }
  const callback  = (isSuccess) => {
    var offsetValue = 0;
    if (pageNumber > 0) {
      offsetValue = pageNumber - 1;
    }
    setShowSendStock(false);
    setShowReturnStock(false);
    setViewIssueId(false);

    if (isSuccess) {
      setSnackbar({
          message: Context.langData.create_issue_succ,
          severity: "success",
      });
  } else {
      setSnackbar({
          message: Context.langData.create_issue_failed,
          severity: "error",
      });
  }

    dispatch(fetchAllIssues({ offset: offsetValue, limit: 20, department_id: allIndents }));
    dispatch(fetchDepartments());
  }
  const sortingOnClick = (value, sortValue, index) => {
    var columnData = [...columns]
    columnData[index] = { ...columnData[index], sort: !sortValue }
    setColumns(columnData)
    if(value == "dateTime"){
      var sortIssuesData = _.orderBy(table, [(d)=>{return new Date (d[value])}], [sortValue ? 'desc' : 'asc']);
    }else{
      var sortIssuesData = _.orderBy(table, [(d)=>{return typeof d[value] === 'string'? d[value].toLowerCase() : d[value] }], [sortValue ? 'desc' : 'asc']);
    }
    setTable(sortIssuesData)
  }
  const handleCloseAlert = (val) => {
    setShowDialog(false)
    if (val) {
      dispatch(undoIssue(row.id, undoResponseCallback))
    }
  }
  const undoResponseCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({ message: Context.langData.issue_undo_succ, severity: 'success' });
    } else {
      setSnackbar({ message: Context.langData.issue_undo_failed, severity: 'error' });
    }
    refreshIssuesData();
  }

  return (
    <Grid data-qaid="issues_mainContainer" container >
      <Grid data-qaid="issues_subContainer1" container style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
        <Grid data-qaid="issues_selectKitchenGrid" style={{ marginLeft: 15 }}>
          <AvenirBlackH4 data-qaid="issues_selectKitchenLabel" label={Context.langData.select_kitchen} />
          <SingleSelect
            list={kitchenData}
            valueKey="id"
            displayKey="name"
            value={allIndents}
            handleChange={(e) => setAllIndents(e.target.value)}
            border={false}
            height={56}
            FormWidth={380}
          />
        </Grid>
        <Grid item xs={12} lg={3} md={4} data-qaid="issues_returnStockGrid" style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20, flexDirection: 'row', display: 'flex' }}>
          <Button className={style.buttonClass} data-qaid="issues_sendStockButton" style={{ backgroundColor: "#FFB600", color: "white", marginRight: 10 }} variant="contained" id="cancel" onClick={handleSendStock}  >
            <AvenirBlackH4 data-qaid="issues_sendStockLabel" label={Context.langData.send_stock} style={{ marginLeft: 5 }} />
          </Button>
          <ContextMenu style={{ marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'  }} menuItems={ContextMenuData} callBack={callBack} />
          {/* <input onChange={upload} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" type="file" style={{ display: 'none' }} id="upload" /> */}
        </Grid>

      </Grid>
      <Grid data-qaid="issues_subContainer2" item lg={12} style={{ marginTop: '20px' }}>
        <SimpleTable
          columns={columns}
          rows={table}
          sortingOnClick={sortingOnClick}
          onRowClick={onClickIssueId}
          onPageChange={onPageinationChange}
          total={issuesData.count} />
      </Grid>
      {
        viewIssueId &&
        <ViewIssueId
          open={viewIssueId}
          row={row}
          onClose={() => setViewIssueId(false)}
          callback={callback}
        />
      }
      {
        showReturnStock &&
        <ViewReturnStock
          open={showReturnStock}
          callback={callback}
          onClose={() => setShowReturnStock(false)}
        />
      }
      {
        showSendStock &&
        <ViewSendStock
          open={showSendStock}
          callback={callback}
          onClose={() => setShowSendStock(false)}
        />
      }
      <AlertDialog
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleCloseAlert}
      />{
        snackbar &&
        <SnackBar
          data-qaid="taxeslist_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      }
    </Grid>
  )
}

export default Issues;