import React, { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { H4, AvenirBlackH4, AvenirBlackH3 } from '../../../../utils/text'
import PencileIcon from '../../../../components/SVG/PencileIcon'
import { ConvertPrice } from '../../../../utils/util'
import VariablePricing from './VariablePricing'
import Modifier from './Modifier';
import CirclePlus from '../../../../components/SVG/CirclePluse'
import ModifierList from './ModifierList'

const Advanced = ({
  advInfo,
  setAdvInfo,
  selectedOrderTypes,
  ordertypes,
  allModifiers,
  isEdit
}) => {
  const [showVariable, togglevariable] = useState(false)
  const [showModifierModal, toggleModifierModal] = useState(false)
  const [item, setItem] = useState(null)
  const otypes = useMemo(() => {

    return selectedOrderTypes.map(s => {
      let d = ordertypes.find(o => o.otype_id == s);

      return {
        otype_name: d?.otype_name,
        otype_id: s
      }

    })

  }, [ordertypes, selectedOrderTypes])
  const onServingChange = (data) => {
    setAdvInfo({ ...advInfo, servings: data })
    togglevariable(false)
  }
  const omModifierAdd = (modifier) => {
    let modifiers = [...advInfo.modifiers];
    let index = modifiers.findIndex(e => e.addon_cat_id == modifier.addon_cat_id);
    if (index == -1) {
      modifiers.push(modifier)
    } else {
      modifiers[index] = modifier;
    }
    setAdvInfo({ ...advInfo, modifiers: modifiers })
    toggleModifierModal(false)
  }
  const onDeleteMandatoryModifier = (index) => {
    let modifiers = [...advInfo.modifiers];
    modifiers = [...modifiers.slice(0, index), ...modifiers.slice(index + 1)]
    setAdvInfo({ ...advInfo, modifiers: modifiers })
  }
  const onEditModifier = (item) => {
    setItem(item)
    setTimeout(() => {
      toggleModifierModal(true)
    }, 200)
  }
  const onTaxChange = (val) => {
    // setTaxItem(val.target.value);
    setAdvInfo({ ...advInfo, tax_mode: val.target.value });
  }

  return (
    <Grid container style={{ padding: "0px 24px", paddingBottom: 50 }}>
      <Grid
        container
        style={{
          marginTop: 25,
          borderBottom: "1px solid #EEE",
          paddingBottom: 20,
        }}
      >
        <Grid item xs={8}>
          <AvenirBlackH3 label="Variable pricing (size & order type)" />
        </Grid>
        <Grid item xs={4}>
          <Grid
            container
            justify="flex-end"
            onClick={() => togglevariable(true)}
          >
            <PencileIcon style={{ marginRight: 5 }} />
            <AvenirBlackH4 label="Edit" style={{ color: "#004C60" }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 20 }}>
        {advInfo.servings.length > 0 && (
          <Grid container style={{ width: 430, overflow: "auto" }}>
            <div
              container
              style={{ display: "flex", flexWrap: "nowrap", marginBottom: 10 }}
            >
              <span style={{ width: 170, display: "inline-block" }}>
                <H4 label="Size" style={{ color: "#707272" }} />
              </span>
              {otypes.map((ot) => {
                return (
                  <span style={{ width: 100, display: "inline-block" }}>
                    <H4 label={ot.otype_name} style={{ color: "#707272" }} />
                  </span>
                );
              })}
            </div>
            {advInfo.servings.map((serving, indx) => {
              return (
                <div
                  container
                  style={{ display: "flex", flexWrap: "nowrap", marginTop: 15 }}
                  key={indx}
                >
                  <span style={{ width: 170, display: "inline-block" }}>
                    <H4
                      label={serving.name}
                      style={{ width: 160, wordBreak: "break-all" }}
                    />
                  </span>
                  {otypes.map((ot) => {
                    let price = serving.pricings.find(
                      (p) => p.otype_id == ot.otype_id
                    );
                    return (
                      <span style={{ width: 100, display: "inline-block" }}>
                        <H4 label={`+$${ConvertPrice(price?.price)}`} />
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </Grid>
        )}
      </Grid>
      <Grid container style={{ marginTop: 70 }}>
        {/* <Grid container style={{ marginTop: 30, borderBottom: '1px solid #EEE', paddingBottom: 20 }} >
          <Grid item xs={6}>
            <AvenirBlackH4 label="Modifiers" />

          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-end" onClick={() => toggleModifierModal(true)}>
              <CirclePlus color={"#004C60"} width={21} height={21} />
              <H4 label="Add" style={{ color: "#004C60" }} />
            </Grid>

          </Grid>
        </Grid> */}
        <ModifierList
          modifiers={advInfo.modifiers}
          isMandatory={false}
          onDeleteMandatoryModifier={onDeleteMandatoryModifier}
          onEdit={onEditModifier}
        />
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          marginTop: 20,
        }}
      >
        <span
          onClick={() => toggleModifierModal(true)}
          style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
        >
          <CirclePlus color={"#004C60"} width={21} height={21} />
          <AvenirBlackH4
            label="Add new optional modifier"
            style={{ color: "#004c60", marginLeft: 10 }}
          />
        </span>
      </Grid>
      {showVariable && (
        <VariablePricing
          show={showVariable}
          otypes={otypes}
          servings={advInfo.servings}
          onServingChange={onServingChange}
          onClose={() => togglevariable(false)}
        />
      )}
      {showModifierModal && (
        <Modifier
          show={showModifierModal}
          onSave={omModifierAdd}
          isMandatory={false}
          item={item}
          isEdit={isEdit}
          addedModifiers={allModifiers}
          onClose={() => {
            setItem(null);
            toggleModifierModal(false);
          }}
        />
      )}
    </Grid>
  );
}

export default Advanced;
