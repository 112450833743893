import React, { useState } from "react";
import { Grid, Button, InputAdornment } from "@material-ui/core";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/InputText";
import { H4, AvenirBlackH4, DotText } from "../../../../utils/text";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import ChangeImage from "./ChangeImage";
import CirclePlus from "../../../../components/SVG/CirclePluse";
import Modifier from "./Modifier";
import ModifierList from "./ModifierList";
import { ConvertPrice, validateAlphaNumeric } from "../../../../utils/util";
import {
  fetchImageUrl,
  putImageUrl,
  itemImageUpload,
} from "../../../../redux/actions";
import OnOffSwitch from "../../../../components/OnOffSwitch";
import CustomTaxMode from "./customTaxMode";

const Basic = ({
  ordertypes,
  setSelectedOrderTypes,
  selectedOrderTypes,
  setbasicInfo,
  basicInfo,
  allModifiers,
  isEdit,
  setAlreadyExistsErr,
  advInfo,
  setAdvInfo,
  taxProfileIdErr
}) => {
  const [showModifierModal, toggleModifierModal] = useState(false);
  const [editAddonItem, setAddonItem] = useState(null);
  const dispatch = useDispatch();
  const onChangeFields = (field, data) => {
    let _data = data;
    if (field == "name") {
      _data = validateAlphaNumeric(data);
    }
    setbasicInfo({ ...basicInfo, [field]: _data });
  };
  const omModifierAdd = (modifier) => {
    toggleModifierModal(false);
    const index = basicInfo.addedModifiers.findIndex(
      (e) => e.addon_cat_id == modifier.addon_cat_id
    );
    if (editAddonItem == null) {
      if (index == -1)
        onChangeFields("addedModifiers", [
          ...basicInfo.addedModifiers,
          modifier,
        ]);
    } else {
      let temp = [...basicInfo.addedModifiers];
      temp[index] = modifier;
      onChangeFields("addedModifiers", temp);
    }
    setTimeout(() => {
      setAddonItem(null);
    }, 200);
  };
  const onEditAddon = (e, id) => {
    setAddonItem(e);
    toggleModifierModal(true);
  };
  const onOrderTypeSelect = (e, id) => {
    let temp = [...selectedOrderTypes];
    let index = temp.indexOf(id);

    if (index !== -1) {
      temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
    } else {
      temp = [...temp, id];
    }
    setSelectedOrderTypes(temp);
  };
  const onImageChange = (image, file) => {
    if (file) {
      dispatch(
        fetchImageUrl(
          {
            file_name: image,
            content_type: file.type,
            image_loc: "menu",
          },
          (response) => {
            completeLogo(response, file, 1);
          }
        )
      );
    } else {
      onChangeFields("image", null);
    }
  };
  const completeLogo = (response, file, itemId) => {
    var obj = {};
    var url = response.upload_url.split("?")[0];
    obj.ContentType = file.type;
    obj.Body = file;
    dispatch(
      putImageUrl(
        response.upload_url,
        obj,
        (res) => {
          onChangeFields("image", url);
        },
        response.storage == "azure" ? true : false
      )
    );
  };
  const onDeleteMandatoryModifier = (index) => {
    console.log("INDX", index);
    let temp = [...basicInfo.addedModifiers];
    temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
    onChangeFields("addedModifiers", temp);
  };
  const handleOnBlurChange = (data) => {
    if (basicInfo.price !== "") {
      setbasicInfo({ ...basicInfo, price: ConvertPrice(data) });
    }
  };

  const toggleSwitch = (val) => { };

  return (
    <Grid
      container
      className="basic_menu_scroll"
      style={{ position: "relative" }}>
      <Grid container style={{ paddingBottom: 50 }}>
        <Grid
          container
          direction="row"
          style={{ marginTop: 30, padding: "0px 24px" }}>
          <Grid item xs={8}>
            <InputText
              onChange={(e) => onChangeFields("name", e.target.value)}
              value={basicInfo.name}
              placeholder="Item name"
              maxLength={64}
              id="item_name"
              multiline
              rows={1}
              error={setAlreadyExistsErr ? "Item name already exists" : false}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <InputText
              onChange={(e) =>
                onChangeFields("price", e.target.value.replace(/[^0-9.]/g, ""))
              }
              value={`${basicInfo.price == "" ? "" : "$" + basicInfo.price}`}
              placeholder="Price"
              inputPlaceholder="$0.00"
              maxLength={8}
              onBlur={() => {
                handleOnBlurChange(basicInfo.price);
              }}
              // startAdornment={<InputAdornment position="start">$</InputAdornment>}
              inputProps={{ step: 0.01, min: 0 }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ marginTop: 20, paddingLeft: 50 }}>
          <Grid
            data-qaid="hawkeye_body_grid"
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <H4
              data-qaid="hawkeye_label"
              label={"Active item"}
              style={{ marginTop: 15 }}
            />
            <OnOffSwitch
              data-qaid="hawkeye_onoffswith"
              onChange={(e) => onChangeFields("isActive", !basicInfo.isActive)}
              show={basicInfo.isActive}
            />
          </Grid>
          <Grid
            data-qaid="hawkeye_body_grid"
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              paddingLeft: 30,
              paddingRight: 20,
            }}>
            <H4
              data-qaid="hawkeye_label"
              label={"In stock"}
              style={{ marginTop: 15 }}
            />
            <OnOffSwitch
              data-qaid="hawkeye_onoffswith"
              onChange={(e) => onChangeFields("stock", !basicInfo.stock)}
              show={basicInfo.isActive ? basicInfo.stock : false}
            />
          </Grid>
          {/* <Grid item xs={3}>
            <H4 label="Active item" style={{ marginRight: 60 }} />
          </Grid>
          <Grid item xs={3}>
            <CustomCheckbox
              checked={basicInfo.isActive}
              onChange={e => onChangeFields('isActive', e.target.checked)} />
          </Grid> */}
        </Grid>

        {ordertypes.map((otype, indx) => {
          return (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ padding: "0px 50px" }}>
              <Grid item xs={6}>
                <DotText label={otype.name} style={{ marginRight: 60 }} />
              </Grid>
              <Grid item xs={6}>
                <CustomCheckbox
                  disabled={basicInfo.isActive ? false : true}
                  checkedColor={basicInfo.isActive ? "#004C60" : "#C3C3C3"}
                  checked={selectedOrderTypes.indexOf(otype.otype_id) !== -1}
                  onChange={(e) =>
                    onOrderTypeSelect(e.target.checked, otype.otype_id)
                  }
                />
              </Grid>
            </Grid>
          );
        })}
        <Grid
          container
          direction="row"
          style={{ marginTop: 20, padding: 20, backgroundColor: "#F3FAFD" }}>
          <Grid item xs={6}>
            <InputText
              onChange={(e) => onChangeFields("desc", e.target.value)}
              value={basicInfo.desc}
              placeholder="Description"
              height={150}
              maxLength={256}
              multiline
              inputProps={{ step: 0.01, min: 0.0 }}
              rows={6}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 10 }}>
            <AvenirBlackH4 label="Image" style={{ marginLeft: 5 }} />
            <ChangeImage image={basicInfo.image} onChange={onImageChange} />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 10 }}>
          <ModifierList
            modifiers={basicInfo.addedModifiers}
            onEdit={onEditAddon}
            onDeleteMandatoryModifier={onDeleteMandatoryModifier}
          />
        </Grid>
        <Grid
          container
          alignItems="center"
          style={{
            marginTop: 20,
            padding: 20,
          }}>
          <CustomTaxMode info={advInfo} setInfo={setAdvInfo} taxProfileIdErr={taxProfileIdErr} />
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            marginTop: 20,
          }}>
          <span
            onClick={() => toggleModifierModal(true)}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}>
            <CirclePlus color={"#004C60"} width={21} height={21} />
            <AvenirBlackH4
              label="Add new mandatory modifier"
              style={{ color: "#004c60", marginLeft: 10 }}
            />
          </span>
        </Grid>
      </Grid>
      {showModifierModal && (
        <Modifier
          show={showModifierModal}
          onSave={omModifierAdd}
          item={editAddonItem}
          isEdit={isEdit}
          addedModifiers={allModifiers}
          onClose={() => {
            setAddonItem(null);
            toggleModifierModal(false);
          }}
        />
      )}
    </Grid>
  );
};

export default Basic;
