import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { DotText } from '../utils/text';
import InfoOutlined from './SVG/InfoOutlined';

const ImportantReminder = ({ message }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <Box data-qaid="InfoIcon">
        <InfoOutlined width={24} height={24} color={'#004C60'} />
      </Box>
      <Box style={{ paddingLeft: 10, width: '100%' }}>
        {showMore ? (
          <Box
            data-qaid="message"
            style={{
              color: '#051D33',
              fontSize: 14,
              fontFamily: 'AvenirLT',
              lineHeight: 1.5,
              paddingTop: 4,
            }}>
            {message}
            <span
              onClick={() => setShowMore(!showMore)}
              style={{ cursor: 'pointer', color: '#004C60' }}>
              {showMore && ' Show Less'}
            </span>
          </Box>
        ) : (
          <Box
            style={{
              flexDirection: 'row',
              display: 'flex',
              paddingTop: 4,
            }}>
            <Box data-qaid="message" style={{ width: '83%' }}>
              <DotText label={message} />
            </Box>
            <span
              onClick={() => setShowMore(!showMore)}
              style={{
                cursor: 'pointer',
                color: '#004C60',
                marginLeft: 3,
              }}>
              {!showMore && 'Read More'}
            </span>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ImportantReminder;
