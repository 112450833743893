import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    makeStyles,
    Button, Grid, Card, CardContent, CardActions, IconButton, Typography, InputLabel, Select, MenuItem, Menu
} from '@material-ui/core';
import DataTable from '../../../../components/DataTable';
import styles from '../../../../styles/index';
import {
    fetchPrinters,
    deletePrinters,
    fetchAssignKitchenPrinter,
    fetchKitchenPrinterUnassigned,
} from '../../../../redux/actions';
import AlertDialog from '../../../../components/Dialog';
import ButtonMenu from '../../../../components/ButtonMenu';
import MyContext from '../../../../Provider/MyContext';
import { H4, H2, H3, AvenirBlackH4, H8, H5, DotText, DotTextBoldNormal, AvenirBlackH3 } from '../../../../utils/text';
import DeleteIcon from '../../../../components/SVG/Delete';
import CirclePluse from '../../../../components/SVG/PluseIcon';
import _ from 'lodash';
import SnackBar from '../../../../components/SnackBar';
import UploadIcon from '../../../../components/SVG/UploadIcon';
import NoData from '../../../../components/NoData';


import CreatePrinter from "./createPrinter";
import EditPrinter from "./editPrinter";
import CreateKitchenPrinterAssign from "./createKitchenPrinterAssign";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));
const PrintersList = () => {
    const style = styles();
    const classes = useStyles();
    const Context = useContext(MyContext);
    const [columns, setColumns] = useState(
        [
            { field: 'name', headerName: Context.langData.printer_name, size: '30%', sort: false },
            { field: 'ip_address', headerName: Context.langData.ip_address, size: '20%', sort: false },
            { field: 'assignment', headerName: Context.langData.station_assignment, size: '50%', sort: false },

        ]
    )
    const ButtonMenuData = [
        {
            id: 1,
            icon: UploadIcon,
            name: Context.langData.assign_Printer
        },
        {
            id: 2,
            icon: DeleteIcon,
            name: Context.langData.delete
        }
    ]
    const PrintersData = useSelector(state => state.settingsReducer.printers);
    console.log(PrintersData, 'PrintersDatalskfjd')
    const Kitchens = useSelector(state => state.metaReducer.printerUnassigned);
    const [selectedId, setSelectedId] = React.useState([]);
    const [btnDisable, setBtnDisable] = React.useState(true);
    const [openCreate, toggleCreateDrawer] = useState(false);
    const [openEdit, toggleEditDrawer] = useState(false);
    const [openAssign, toggleAssignDrawer] = useState(false);
    const [row, setRow] = useState({});
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [heading, setHeading] = useState(Context.langData.delete_printer);
    // const [bodyMessage, setBodyMessage] = useState(Context.langData.printer_body_delete);
    const [snackbar, setSnackbar] = useState(null);
    const [Printers, setPrinters] = useState([])
    const [data, setData] = useState([]);
    const [err, setErr] = useState(false);
    const [msg, setMsg] = useState({});


    const bodyMessage = err ? (selectedId.length > 1 ?
        'Are you sure you want to delete ' + selectedId.length + ' printers?'
        :
        Context.langData.printer_body_delete)
        :
        `Printer ${msg.printer_name} is currently assigned to Kitchen ${msg.kitchen_name}. If you delete this printer, it will be unassigned.`

    useEffect(() => {
        const filterPrinterData = _.reverse(PrintersData)
        filterPrinterData.map((item) => {
            item['is_active'] = item.is_active ? 'Active' : "Inactive"
        })

        setPrinters(filterPrinterData)
    }, [PrintersData])

    useEffect(() => {
        dispatch(fetchAssignKitchenPrinter({}, thiscallback));
    }, [])
    function thiscallback(response) {
        setData(response.data)
    }

    function getSelectedPrinters() {
        let printerArray = PrintersData.filter(e => {
            if (selectedId.includes(e.printer_id)) {
                e.selectableKitchens = []
                e.assigned_kitchen_ids = []
                if (e.assigned_kitchens) {
                    for (const k of e.assigned_kitchens) {
                        var vobj = {
                            id: k.id,
                            name: k.name,
                        };
                        e.selectableKitchens.push(vobj);
                        e.assigned_kitchen_ids.push(k.id);
                    }
                }
                if (Kitchens) {
                    for (const k of Kitchens) {
                        var vobj = {
                            id: k.kitchen_id,
                            name: k.kitchen_name,
                        };
                        e.selectableKitchens.push(vobj);
                    }
                }
                return e
            }
        })
        return printerArray
    }

    function onCheckedIds(ids) {
        setSelectedId(ids);
        console.log("ids on checked", JSON.stringify(ids));
        if (ids.length > 0) {
            setBtnDisable(false);
        } else {
            setBtnDisable(true);
        }
        const data1 = data.find(e => e.printer_id == ids)
        if (data1 == undefined) {
            setErr(true)
        } else {
            setErr(false)
            setMsg(data1)
        }
    }
    function onClickAction(rowValue) {
        setRow(rowValue)
        toggleEditDrawer(true);
        console.log("Action clicked row56756Î", JSON.stringify(rowValue));
    }
    const btnProps = {};
    if (btnDisable) {
        btnProps["disabled"] = true
    }
    function callback() {
        toggleCreateDrawer(false);
        toggleEditDrawer(false);
        toggleAssignDrawer(false);
        dispatch(fetchPrinters());
        dispatch(fetchKitchenPrinterUnassigned());
    }
    function createCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.printer_create_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.printer_create_failed, severity: 'error' });
        }
        callback();

    } function editCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.printer_edit_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.printer_edit_failed, severity: 'error' });
        }
        callback();
    }
    function deleteCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.printer_delete_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.printer_delete_failed, severity: 'error' });
        }
        callback();
    }
    function assignPrinterCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.printer_assign_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.printer_assign_failed, severity: 'error' });
        }
        callback();
    }
    const handleClose = (val) => {
        if (val) {
            console.log("error called", JSON.stringify(selectedId));
            for (const i of selectedId) {
                dispatch(deletePrinters(JSON.stringify(selectedId), deleteCallback));
            }
        }
        setShowDialog(false);
    };

    function deletePrinter(value) {
        setShowDialog(value)
    }
    const onPageinationChange = (value) => {
        console.log("on page change")
    }
    const callBack = (id) => {
        if (id == 1) {
            toggleAssignDrawer(true)
        } else if (id == 2) {
            if (selectedId.length > 0) {
                deletePrinter(true)
            }
        }
    }

    const handleDeletePrinter = () => {
        if (selectedId.length > 0) {
            deletePrinter(true)
        }
    }

    const sortingOnClick = (value, sortValue, index) => {
        var columnData = [...columns]
        columnData[index] = { ...columnData[index], sort: !sortValue }
        setColumns(columnData)
        var sortComments = _.orderBy(Printers, [value], [sortValue ? 'asc' : 'desc']);
        setPrinters(sortComments)
    }

    return (
        <div data-qaid="printerlist_div" style={{ marginTop: '2%' }} >
            <>
                {
                    Printers.length > 0 ?
                        <>

                            <Grid data-qaid="printerlist_grid" item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginRight: 20, marginBottom: 10 }} >
                                <ButtonMenu style={{ marginLeft: 10 }} menuItems={ButtonMenuData} label={Context.langData.edit_printer} callBack={callBack} btnDisable={btnDisable} />
                                <Button className={style.buttonClass} data-qaid="printerlist_create+button" style={{ backgroundColor: "#FFB600", color: "white", marginRight: 10,  }} variant="contained" id="cancel" onClick={() => toggleCreateDrawer(true)}   >
                                    <CirclePluse data-qaid="printerlist_createicon" />
                                    <AvenirBlackH4 data-qaid="printerlist_createlabel" label={Context.langData.add_printer} style={{ paddingLeft: 5 }} />
                                </Button>

                            </Grid>
                            <DataTable data-qaid="printerlist_datatable" columns={columns} rows={Printers} onCheckedIds={onCheckedIds} onClickAction={onClickAction} isOnClick={true} onPageChange={onPageinationChange} sortingOnClick={sortingOnClick} />
                        </>
                        :
                        <NoData page={"printer"} callback={() => toggleCreateDrawer(true)} />
                }
            </>
            {
                openCreate &&
                <CreatePrinter
                    data-qaid="printerlist_createprinter"
                    open={openCreate}
                    onClose={() => toggleCreateDrawer(false)}
                    printers={Printers}
                    callback={createCallback}
                />
            }
            {
                openEdit &&
                <EditPrinter
                    data-qaid="printerlist_editprinter"
                    open={openEdit}
                    row={row}
                    printers={Printers}
                    onClose={() => toggleEditDrawer(false)}
                    callback={editCallback}
                    deleteCallback={deleteCallback}
                />
            }
            {
                openAssign &&
                <CreateKitchenPrinterAssign
                    data-qaid="printerlist_assignprinter"
                    open={openAssign}
                    // printers={PrintersData}
                    printers={getSelectedPrinters()}
                    kitchens={Kitchens}
                    onClose={() => toggleAssignDrawer(false)}
                    callback={assignPrinterCallback}
                />
            }
            {
                snackbar &&
                <SnackBar
                    data-qaid="categories_snackbar"
                    open={true}
                    setOpen={() => setSnackbar(null)}
                    severity={snackbar.severity}
                    message={snackbar.message}
                />
            }

            <AlertDialog
                data-qaid="printerlist_alertdialogÎ"
                showDialog={showDialog}
                body={bodyMessage}
                heading={heading}
                IsError={false}
                showExtra={err}
                handleClose={handleClose} />
        </div>
    );
};

export default PrintersList;
