import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import MyContext from '../../../../Provider/MyContext';
import {
  fetchAllStocks,
  deleteStockBalance,
  uploadStockItems
} from '../../../../redux/actions';
import AlertDialog from './../../../../components/Dialog';
import ViewStocks from './viewStocks';
import CreateStock from './createStock';
import EditStock from './editStock';
import $ from 'jquery';
import DataTable from '../../../../components/DataTable';
import { AvenirBlackH4, DotText } from '../../../../utils/text';
import CirclePluse from '../../../../components/SVG/PluseIcon';
import TopSearchBar from '../../../../components/SearchBar';
import ContextMenu from '../../../../components/ContextMenu';
import DeleteIcon from '../../../../components/SVG/Delete';
import _ from 'lodash';
import styles from '../../../../styles/index';
import SnackBar from '../../../../components/SnackBar';
import SingleSelect from '../../../../components/SingleSelect';
import DownloadIcon from '../../../../components/SVG/DownlodeIcon';
import { downloadStockBalance } from '../../../../utils/excel/inventory';
import UploadModal from '../../../../components/UploadModal';
import UploadErrosTAble from '../../../../components/UploadErrosTable';
const useStyles = makeStyles({
  btnApply: {
    backgroundColor: '#f0585f',
    color: 'white',
    marginLeft: '10px'
  },
  btnDelete: {
    backgroundColor: '#f4f4f5',
    color: 'black',
    marginLeft: '10px'
  },
  btnClear: {
    backgroundColor: '#dcdcdc',
    color: 'black'
  },
})

const StockBalance = () => {
  const style = styles();
  const dispatch = useDispatch();
  const [table, setTable] = useState([]);
  const getVendors = useSelector((state) => state.inventory.vendors);
  const stocksData = useSelector(state => state.inventory.stocks);
  const [stocksList, setStockList] = useState([]);
  const [searchStock, setSearchStock] = useState('');
  const [selectedId, setSelectedId] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [errosData, setErrosData] = useState([]);
  const Context = useContext(MyContext);
  const [row, setRow] = useState({});
  const [rowView, setRowView] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(Context.langData.deleteStock_balance_header);
  const [bodyMessage, setBodyMessage] = useState(Context.langData.delete_body_message);
  const [viewStocks, setViewStocks] = useState(false);
  const [showCreateStock, setShowCreateStock] = useState(false);
  const [showEditStock, setShowEditStock] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [uploadTitle, setUploadTitle] = useState();
  const [stockbalance, setStockBalnace] = useState('');
  const stockDropdownArray = [
    {
      id: 0,
      name: Context.langData.all
    }, {
      id: 1,
      name: Context.langData.critical_balance
    }
  ]
  useEffect(() => {
    dispatch(fetchAllStocks());
  }, []);
  const ContextMenuData = [
    {
      id: 1,
      icon: DownloadIcon,
      name: Context.langData.download
    },
    {
      id: 2,
      icon: DeleteIcon,
      name: Context.langData.delete
    },
    // {
    //   id: 3,
    //   icon: UploadIcon,
    //   name: Context.langData.upload
    // },
  ]
  useEffect(() => {
    stocksData?.map(item => {
      if (item.currentBalance < item.criticalBalance) {
        item['c_name'] = <DotText style={{ color: '#FC4C02' }} label={item.name} />
        item['c_amount'] = <DotText style={{ color: '#FC4C02' }} label={item.currentBalance} />
        item['c_critical_balance'] = <DotText style={{ color: '#FC4C02' }} label={item.criticalBalance > 0 ? item.criticalBalance : 0} />
        item['c_units'] = <DotText style={{ color: '#FC4C02' }} label={item.unitTypeCode} />
        item['isCriticalBalance'] = true;
      } else {
        item['c_name'] = <DotText label={item.name} />
        item['c_amount'] = <DotText label={item.currentBalance} />
        item['c_critical_balance'] = <DotText label={item.criticalBalance > 0 ? item.criticalBalance : 0 } />
        item['c_units'] = <DotText label={item.unitTypeCode} />
        item['isCriticalBalance'] = false;
      }
    })
    setStockList(stocksData)
  }, [stocksData])
  const uploadCallback = (fileName, itemList, valueKey) => {
    ParseStockBalnace(itemList, valueKey);
    setUploadTitle(Context.langData.missing_stock_balance_information)
  }

  const searchVendorsData = (vendorStr) => {
    var temp = [];
    var noVendor = [];
    var duplicateVendor = [];
    if (vendorStr != "") {
      var vendors = getVendors;
      var vendor_list = vendorStr.split(",");
      for (const idx in vendor_list) {
        var v = vendors.find(x=>x["name"] == vendor_list[idx].trim())
        if (v === undefined) {
          noVendor.push(vendor_list[idx].trim())
        } else {
          if (temp.includes(v["vendor_id"])){
            duplicateVendor.push(vendor_list[idx].trim())
          }
          else {
            temp.push(v["vendor_id"])
          }
        }
      }
    }
    return { list: temp, no_vendor: noVendor, duplicate_vendor: duplicateVendor };
  }
  const valueArray = [
    { name: Context.langData.update_existing_stocks, value: 1 },
    { name: Context.langData.quick_add_stocks, value: 2 },
  ]
  const [stockColumns, setStockColumns] = useState([
    { field: 'c_name', headerName: Context.langData.stock_name, size: '45%', sort: false, sortKey: 'name' },
    { field: 'c_amount', headerName: Context.langData.balance, size: '20%', sort: false, sortKey: "currentBalance" },
    { field: 'c_critical_balance', headerName: Context.langData.critical_balance, size: '20%', sort: false, sortKey: 'criticalBalance' },
    { field: 'c_units', headerName: Context.langData.units, size: '15%', sort: false, sortKey: 'unitTypeCode' },
  ]);

  const onCheckedIds = (ids) => {
    setSelectedId(ids);
  }

  const onClickAction = (rowValue) => {
    setRow(rowValue);
    setShowEditStock(true);
  }
  const ParseStockBalnace = (json, uploadStep) => {
    var data = [];
    var errors = [];
    json?.map((item, index) => {
      var row = { replicate: 0 };
      var error = [];
      $.each(json[index], function (key, value) {
        if (key.trim() == "Stock name") {
          row["name"] = value.trim();
        }
        // else if (key.trim() == "Initial balance") {
        //   if (!isNaN(value)) {
        //     row["amount"] = parseFloat(value);
        //   }
        //   else {
        //     row["amount"] = value.trim();
        //     error.push("Initial balance is not a number");
        //   }
        // }
        else if (key.trim() == "Critical balance") {
          if (!isNaN(value)) {
            row["criticalBalance"] = parseFloat(value);
          }
          else {
            row["criticalBalance"] = value.trim();
            error.push("Critical balance is not a number");
          }
        }
        else if (key.trim() == "unitTypeCode") {
          row["unitTypeCode"] = value.trim();
        }
        else if (key.trim() == "Vendors") {
          row["vendors_names"] = value.trim();
          var obj = searchVendorsData(value.trim());
          console.log("searchVendorsDatasearchVendorsData=>", obj)
          if (obj.no_vendor.length !== 0) {
            error.push(obj.no_vendor.join(",") + " vendor(s) not found");
          }
          if (obj.duplicate_vendor.length !== 0) {
            error.push(obj.duplicate_vendor.join(",") + " vendor(s) duplicated");
          }
          row["vendor_id_list"] = obj.list;
        }
      });
      if (typeof (row.name) == "undefined" || row.name == "")
        error.push("Stock Name not entered at row " + (index + 2));
      else if (typeof (row.units) == "undefined" || row.units == "")
        error.push('Units not entered');
      else if (typeof (row.vendors_names) == "undefined" || row.vendors_names == "")
        error.push('Vendors not entered');
      else if (typeof (row.critical_balance) == "undefined")
        error.push('Critical balance not entered');
      // else if (typeof (row.amount) == "undefined")
      //   error.push('Initial balance not entered');

      if (error.length == 0) {
        if (uploadStep == 1) {
          var result = $.grep(stocksData, function (e) { return e.name.toLowerCase() == row["name"].toLowerCase() });
          if (result.length > 0) {
            row["stock_id"] = result[0].stock_id;
            data.push(row);
          }
          else {
            error.push("Stock is not exist in store");
          }
        } else {
          var result = $.grep(stocksData, function (e) { return e.name.toLowerCase() == row["name"].toLowerCase() });
          if (result.length == 0) {
            data.push(row);
          }
          else {
            error.push("Stock already exist in store");
          }
        }
      }
      if (error.length != 0) {
        errors.push({ name: row.name, errors: error.join(",") });
      }
    })
    setErrosData(errors);
    setUpdatedList(data);
    if (errors.length > 0) {
      setIsUpload(false)
      setIsUploadError(true);
    } else {
      setIsUpload(false);
      if (data.length > 0) {
        dispatch(uploadStockItems(data, stockItemsUploadCallback))
      } else {
        setSnackbar({ message: Context.langData.upload_valid_file, severity: 'error' });
      }
    }
  }
  const stockItemsUploadCallback = (isSuccess) => {
    HandleCloseDrawer();
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.stock_upload_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.stock_upload_failed,
        severity: "error",
      });
    }

  }
  const handleCloseAlert = (val) => {
    if (val) {
      var ids = []
      selectedId.forEach((id) => {
        ids.push({"id": id})
      });
      var obj = {
        "data": ids
      }
      dispatch(deleteStockBalance(obj, deleteCallback));
    }
    setShowDialog(false)
  }
  const HandleCloseDrawer = () => {
    dispatch(fetchAllStocks());
    setShowCreateStock(false);
    setShowEditStock(false);
    setShowDialog(false);
  }
  const handleSerch = () => {
    var menu_items = stocksData;
    var value = searchStock;
    if (value.trim() != "") {
      var filtered = $.grep(menu_items, function (e) { return e.name.toLowerCase().trim().indexOf(value.toLowerCase().trim()) != -1 });
      if (filtered.length != 0) {
        var desired_items = [];
        filtered.map(function (item) {
          console.log("item", item)
          desired_items.push(item);
        });
        console.log("desired_items", desired_items)
        setStockList(desired_items)
      } else {
        var desired_items = [];
        console.log("desired_items", desired_items)
        setStockList(desired_items)
      }
    } else {
      setStockList(stocksData)
    }
  }
  useEffect(() => {
    handleSerch()
  }, [searchStock])
  function deleteStockItems(value) {
    setShowDialog(value)
  }
  const handleProceedUpload = () => {
    console.log("proceed called .....==>", updatedList);
    setIsUploadError(false);
    if (updatedList.length > 0) {
      dispatch(uploadStockItems(updatedList, stockItemsUploadCallback))
    } else {
      setSnackbar({ message: Context.langData.no_stock_balances_error, severity: 'error' });
    }
  }
  const handleCancelUpload = () => {
    setIsUploadError(false);
    setUpdatedList([])
  }

  const callBack = (id) => {
    if (id == 1) {
      downloadStockBalance(stocksList)
    }
    if (id == 2) {
      handleDeleteClick()
    }
  }
  const handleDeleteClick = () => {
    if (selectedId.length > 0) {
      if (selectedId.length > 1) {
        setHeading(Context.langData.deleteStock_balance_header + Context.langData.delete_plural_text)
      } else {
        setHeading(Context.langData.deleteStock_balance_header + Context.langData.delete_single)
      }
      deleteStockItems(true)
    }

  }
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({ message: Context.langData.stock_create_succ, severity: 'success' });
    } else {
      setSnackbar({ message: Context.langData.stock_create_failed, severity: 'error' });
    }
    HandleCloseDrawer()
  }
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({ message: Context.langData.stock_edit_succ, severity: 'success' });
    } else {
      setSnackbar({ message: Context.langData.stock_edit_failed, severity: 'error' });
    }
    HandleCloseDrawer()
  }
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({ message: Context.langData.stock_delete_succ, severity: 'success' });
    } else {
      setSnackbar({ message: Context.langData.stock_delete_failed, severity: 'error' });
    }
    HandleCloseDrawer()
  }
  const sortingOnClick = (value, sortValue, index) => {
    var valueKey = stockColumns.filter(e => e.field == value);
    var columnData = [...stockColumns]
    columnData[index] = { ...columnData[index], sort: !sortValue }
    setStockColumns(columnData)
    var sortStocksList = _.orderBy(stocksList, [(d) => typeof d[valueKey[0].sortKey] == 'string' ? d[valueKey[0].sortKey].toLowerCase() : d[valueKey[0].sortKey] === null ? d[valueKey[0].sortKey] = 0 : d[valueKey[0].sortKey]], [sortValue ? 'desc' : 'asc']);
    setStockList(sortStocksList)
  }
  useEffect(() => {
    if (stockbalance == 1) {
      var filterData = stocksData?.filter(e => e.isCriticalBalance == true);
      setStockList(filterData);
    } else {
      setStockList(stocksData);
    }
  }, [stockbalance])
  return (
    <Grid data-qaid="stockBalance_mainContainer" container >
      <Grid data-qaid="stockBalance_subContainer1" container style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginLeft: 15 }}>
        <Grid data-qaid="stockBalance_stockBalanceLabelView" style={{ display: 'flex', width: '35%', flexDirection: 'column' }}>
          <AvenirBlackH4 data-qaid="stockBalance_stockBalanceLabel" label={Context.langData.stock_balance} />
          <SingleSelect
            list={stockDropdownArray}
            valueKey="id"
            displayKey="name"
            value={stockbalance}
            placeholder={Context.langData.stock_balance}
            handleChange={(e) => setStockBalnace(e.target.value)}
            minWidth={'100%'}
            border={false}
            height={56}
          />
        </Grid>
        <Grid data-qaid="stockBalance_stockItemLabelView" style={{ display: 'flex', width: '30%', flexDirection: 'column' }}>
          <AvenirBlackH4 data-qaid="stockBalance_stockItemLabel" label='Stock item' />
          <TopSearchBar
            value={searchStock}
            onChange={(e) => setSearchStock(e.target.value)}
            placeholder={Context.langData.ser_stock}
            height={56}
          />
        </Grid>
        <Grid data-qaid="stockBalance_cancelButtonView" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, width: '30%' }}>
          <Button className={style.buttonClass} data-qaid="stockBalance_cancelButton" style={{ backgroundColor: "#FFB600", color: "white", marginRight: 10 }} variant="contained" id="cancel" onClick={() => setShowCreateStock(true)}  >
            <CirclePluse data-qaid="stockBalance_createPulse" />
            <AvenirBlackH4 data-qaid="stockBalance_createStockLabel" label={Context.langData.create_stock} style={{ paddingLeft: 5 }} />
          </Button>
          <ContextMenu style={{ marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }} menuItems={ContextMenuData} callBack={callBack} />
        </Grid>

      </Grid>
      <Grid data-qaid="stockBalance_subContainer2" container style={{ width: '100%', marginTop: '20px' }}>
        {
          <Grid data-qaid="stockBalance_dataTableGrid" item lg={12}>
            <DataTable
              data-qaid="shifts_datatable"
              columns={stockColumns}
              rows={stocksList}
              onCheckedIds={onCheckedIds}
              onClickAction={onClickAction}
              style={{ width: '100%' }}
              sortingOnClick={sortingOnClick} />
          </Grid>
        }
      </Grid>
      <Grid data-qaid="stockBalance_subContainer2" container style={{ width: '100%' }}>
        {
          viewStocks &&
          <ViewStocks
            open={viewStocks}
            row={rowView}
            onClose={() => setViewStocks(false)}
          />
        }
        <AlertDialog
          showExtra={false}
          showDialog={showDialog}
          body={bodyMessage}
          heading={heading}
          IsError={false}
          handleClose={handleCloseAlert}
        />
        {
          showCreateStock &&
          <CreateStock
            open={showCreateStock}
            // row={rowView}
            onClose={() => HandleCloseDrawer(false)}
            callback={createCallback}
          />
        }
        {
          showEditStock &&
          <EditStock
            open={showEditStock}
            row={row}
            callback={editCallback}
            onClose={() => HandleCloseDrawer(false)}
          />
        }
        {
          snackbar &&
          <SnackBar
            data-qaid="taxeslist_snackbar"
            open={true}
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        }
      </Grid>
      {
        isUpload &&
        <UploadModal
          data-qaid="items_recipeupload"
          open={isUpload}
          onClose={() => setIsUpload(false)}
          callback={uploadCallback}
          valueArray={valueArray}
          dropDownName={Context.langData.stock_item_upload}
          dropDownBody={Context.langData.stock_item_upload_body}
        />
      }
      {
        isUploadError &&
        <UploadErrosTAble
          data-qaid="items_recipeupload"
          open={isUploadError}
          onClose={() => handleCancelUpload()}
          proceed={() => handleProceedUpload()}
          data={errosData}
          labelName={Context.langData.stock_name}
          title={uploadTitle}
          error={Context.langData.stock_balance_error_body}
        />
      }
    </Grid>
  )
}

export default StockBalance;