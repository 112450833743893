import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Title from "../combos/Title";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import {
  H4,
  AvenirBlackH4,
  AvenirBlackH3,
  DotText,
} from "../../../../utils/text";
import { ConvertPrice } from "../../../../utils/util";
import VariablePricing from "./VariablePricing";
import DietaryAttribute from "./Dietary";
import TaxMode from "../create/taxMode";
const Advanced = ({ advanced, setAdvanced, ordertypes }) => {
  const [editingPricing, toggleEditingPricing] = useState(false);
  const [isEditingDietary, toggleDietary] = useState(false);
  let { half_and_half, variablePrice, attributes } = advanced;
  const onHalfChange = (val, key) => {
    console.log(val, key);
    const index = half_and_half.indexOf(key);
    if (index == -1) {
      half_and_half = [...half_and_half, key];
    } else {
      half_and_half = [
        ...half_and_half.slice(0, index),
        ...half_and_half.slice(index + 1),
      ];
    }
    setAdvanced({
      variablePrice,
      attributes,
      half_and_half,
    });
  };
  console.log("halfandhalf--", half_and_half);
  const onEditPricing = () => {
    console.log("onPress");
    toggleEditingPricing(true);
  };
  const onSavePrices = (prices) => {
    setAdvanced({ ...advanced, variablePrice: prices });
    toggleEditingPricing(false);
  };
  const onSaveDietary = (values) => {
    setAdvanced({ ...advanced, attributes: values });
    toggleDietary(false);
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingRight: 24,
      }}>

      <Grid
        container
        direction="row"
        style={{ marginTop: 15, padding: "0px 4px" }}>
        <Title title={"Half & half customization"} paddingBottom={10} />
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ padding: "0px 24px" }}>
        <Grid item xs={6}>
          <H4 label={"Sauce"} style={{ marginRight: 60 }} />
        </Grid>
        <Grid item xs={6}>
          <CustomCheckbox
            checked={half_and_half.indexOf(3) !== -1}
            onChange={(e) => onHalfChange(e.target.checked, 3)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ padding: "0px 24px" }}>
        <Grid item xs={6}>
          <H4 label={"Cheese"} style={{ marginRight: 60 }} />
        </Grid>
        <Grid item xs={6}>
          <CustomCheckbox
            checked={half_and_half.indexOf(4) !== -1}
            onChange={(e) => onHalfChange(e.target.checked, 4)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ padding: "0px 24px" }}>
        <Grid item xs={6}>
          <H4 label={"Toppings"} style={{ marginRight: 60 }} />
        </Grid>
        <Grid item xs={6}>
          <CustomCheckbox
            checked={half_and_half.indexOf(5) !== -1}
            onChange={(e) => onHalfChange(e.target.checked, 5)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginTop: 30, padding: "0px 4px" }}>
        <Title
          title={"Variable pricing (order type)"}
          edit={true}
          onPress={onEditPricing}
          paddingBottom={10}
        />
        <Grid container style={{ marginLeft: 24 }}>
          <Grid container style={{ marginTop: 16 }}>
            <Grid item xs={6} md={8}>
              <H4 label="Name" style={{ color: "#707272" }} />
            </Grid>
            <Grid item xs={6} md={4}>
              <H4 label="Price" style={{ color: "#707272" }} />
            </Grid>
          </Grid>
          {editingPricing ? (
            <VariablePricing
              open={editingPricing}
              prices={variablePrice}
              onSave={onSavePrices}
              onClose={() => toggleEditingPricing(false)}
            />
          ) : (
            <Grid container>
              {variablePrice.map((vp, index) => {
                return (
                  <Grid container key={index} style={{ marginTop: 16 }}>
                    <Grid item xs={6} md={8}>
                      <DotText label={vp.otype_name} />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <H4 label={`+$${ConvertPrice(vp.price)}`} />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginTop: 30, padding: "0px 4px" }}>
        <Title
          title={"Dietary attributes"}
          edit={true}
          onPress={() => toggleDietary(true)}
          paddingBottom={10}
        />
        <Grid container style={{ marginLeft: 24 }}>
          {advanced.attributes.map((attr, index) => {
            return (
              <Grid container key={index} style={{ marginTop: 10 }}>
                <Grid item xs={12}>
                  <H4 label={attr} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {isEditingDietary && (
        <DietaryAttribute
          dietary={advanced.attributes}
          open={isEditingDietary}
          onSaveDietary={onSaveDietary}
          onClose={() => toggleDietary(false)}
        />
      )}
    </Grid>
  );
};

export default Advanced;
