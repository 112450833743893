import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Menu, MenuItem } from '@material-ui/core';
import { AvenirBlackH4 } from '../../../utils/text';
import MyContext from '../../../Provider/MyContext';
import {
  fetchItemsByCatId,
  fetchItemTypes,
  fetchAllCategories,
  deleteItemsByCatId,
} from '../../../redux/actions';
import NewDataTable from '../../../components/NewDataTable';
import CreateItem from '../items/create/CreateItem';
import CombosModal from '../items/combos';
import PizzasModal from '../items/pizzas';
import { PluseIcon } from '../../../components/SVG';
import styles from '../../../styles/index';
import BetaIcon from '../../../components/SVG/BetaIcon';
import UploadIcon from '../../../components/SVG/UploadIcon';
import DeleteIcon from '../../../components/SVG/Delete';
import ButtonMenu from '../../../components/ButtonMenu';
import SnackBar from '../../../components/SnackBar';
import AlertDialog from '../../../components/Dialog';
import ItemAssignCategories from './itemAssignCategories';
import { debounce, validateAlphaNumeric } from '../../../utils/util';
import TopSearchBar from '../../../components/SearchBar';
import SingleSelect from '../../../components/SingleSelect';
import ItemAssignAddons from './itemAssignAddons';
import { FeatureKeys } from '../../../enums';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useDrawers } from '../../../custom-hooks';

const ItemCatalogue = () => {
  const style = styles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const itemsList = useSelector((state) => state.menu.category_items);
  const categories = useSelector((state) => state.menu.categories);
  const itemTypes = useSelector((state) => state.menu.itemTypes);
  const [params, setParams] = useState({ limit: 25, offset: 0 });
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [rowData, setRowData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  const { drawers, toggleDrawer } = useDrawers({
    isAlcohol: false,
    createOpenFLag: false,
    editOpenFlag: false,
    isOpenComboModal: false,
    isOpenPizzaModal: false,
    openAssignCategories: false,
    openAssignAddons: false,
    showDialog:false
  });
  const [snackbar, setSnackbar] = useState(null);
  const [currentCatName, setCurrentCatName] = useState(null);
  const [isError, setIsError] = useState(false);
  const [showExtra, setShowExtra] = useState(false);
  const bodyMessage =
    'Deleting will remove all instances of selected item(s).This cannot be undone. \n' +
    (selectedId.length > 1
      ? 'Are you sure you want to delete ' + selectedId.length + ' items?'
      : Context.langData.item_body_delete);

  const columns = [
    {
      field: 'name',
      headerName: Context.langData.itemName,
    },
    {
      field: 'price',
      headerName: Context.langData.Price,
      isNumber: true,
    },
    {
      field: 'category',
      headerName: Context.langData.category,
    },
    {
      field: 'available',
      headerName: Context.langData.status,
      statusFlag: true,
    },
    {
      field: 'in_stock',
      headerName: Context.langData.in_stock,
    },
    {
      field: 'tax_profile',
      headerName: Context.langData.taxProfile,
    },
    {
      field: 'modifiers',
      headerName: Context.langData.modifiers,
    },
  ];
  const itemtypeName = [
    { id: 1, value: 'Individual food item', isBeta: false },
    { id: 2, value: 'Alcoholic beverage', isBeta: false },
    { id: 3, value: 'Combos', isBeta: false },
    { id: 4, value: 'Build-your-own item', isBeta: true },
  ];

  //check if build your own item is available for the restaurant which is controlled by feature flag
  const featureFlagClient = useLDClient();
  if (featureFlagClient) {
    const flagValue = featureFlagClient.variation(
      FeatureKeys.BuildYourOwnItem,
      true
    );
    if (flagValue === false) {
      //Remove build your own item from itemtypeName
      const byo = (element) => element.id === 4;
      let index = itemtypeName.findIndex(byo);
      itemtypeName.splice(index, 1);
    }
  }

  const ButtonMenuData = [
    {
      id: 1,
      icon: PluseIcon,
      name: Context.langData.add_modifier_group,
    },
    {
      id: 2,
      icon: UploadIcon,
      name: Context.langData.reassign_category,
    },
    {
      id: 3,
      icon: DeleteIcon,
      name: Context.langData.delete,
    },
  ];

  const debouncedFetchItems = useCallback(
    debounce((parameters) => {
      dispatch(fetchItemsByCatId(parameters));
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (itemsList) {
      const modifiedItems = itemsList.items?.map((item) => {
        const available = item.available ? 'Active' : 'In active';
        const inStock = item.in_stock ? 'Yes' : 'No';
        const price = item.price ? item.price : '0.00';
        const modifiers = item.modifiers
          .map((modifier) =>
            modifier.modifier_name
              ? `${modifier.modifier_name}${modifier.is_optional? '*':''}`
              : '-'
          )
          .join(',');

        return {
          ...item,
          id: item.item_id,
          available,
          in_stock: inStock,
          price,
          modifiers,
        };
      });

      setItems(modifiedItems);
    }
  }, [itemsList]);

  useEffect(() => {
    dispatch(fetchItemTypes());
    dispatch(fetchAllCategories());
    if (categories) parseCategoryList();
  }, []);

  useEffect(() => {
    handleFetchItems(params);
  }, [params, debouncedFetchItems]);

  const handleFetchItems = () => {
    let parameters = { ...params };
    if (parameters.filterBy && parseInt(parameters.filterBy) !== 0) {
      parameters = {
        ...parameters,
        filterBy: `category,${parameters.filterBy}`,
      };
    } else {
      delete parameters.filterBy;
    }
    debouncedFetchItems(parameters);
  };

  const parseCategoryList = () => {
    const categoryList = categories.map((d) => {
      return { ...d, id: d.cat_id };
    });

    setCategoryList([{ name: 'All', id: 0 }].concat(categoryList));
  };

  const findItem = useMemo(() => {
    let filtered = itemTypes?.filter((e) => e.status === 1);
    let filterIds = filtered.map((i) => i.item_type_id);
    return itemtypeName.filter((obj) => filterIds.includes(obj.id));
  }, [itemTypes]);

  const assignCategoryCallback = (isSuccess, response) => {
    if (isSuccess) {
      if (response['failed_items'].length > 0) {
        var items = '';
        response['failed_items'].forEach((it) => {
          items = items + it.name + ';';
        });

        setSnackbar({
          duration: 10000,
          message:
            Context.langData.reassign_category_success +
            response['succeed_items'].length +
            Context.langData.reassign_category_success_item +
            response['failed_items'].length +
            Context.langData.reassign_category_failed_item +
            items,
          severity: 'warn',
        });
      } else {
        setSnackbar({
          message: Context.langData.reassign_category_success,
          severity: 'success',
        });
      }
    } else {
      setSnackbar({
        message: Context.langData.reassign_category_failed,
        severity: 'error',
      });
    }
    closingDrawer();
    handleFetchItems();
  };

  const assignAddonCallback = (isSuccess, response) => {
    if (isSuccess) {
      if (response['failed_items'].length > 0) {
        var items = '';
        response['failed_items'].forEach((it) => {
          items = items + it.name + ';';
        });

        setSnackbar({
          duration: 10000,
          message:
            Context.langData.reassign_category_success +
            response['succeed_items'].length +
            Context.langData.reassign_category_success_item +
            response['failed_items'].length +
            Context.langData.reassign_category_failed_item +
            items,
          severity: 'warn',
        });
      } else {
        setSnackbar({
          message: Context.langData.reassign_category_success,
          severity: 'success',
        });
      }
    } else {
      setSnackbar({
        message: Context.langData.reassign_category_failed,
        severity: 'error',
      });
    }
    closingDrawer();
    handleFetchItems();
  };

  const createCallback = () => {
    setSnackbar({
      message: Context.langData.item_create_succ,
      severity: 'success',
    });
    closingDrawer();
    handleFetchItems();
  };
  const editCallback = () => {
    setSnackbar({
      message: Context.langData.item_edit_succ,
      severity: 'success',
    });
    closingDrawer();
    handleFetchItems();
  };

  const deleteItemCallback = () => {
    setSnackbar({
      message: Context.langData.item_delete_succ,
      severity: 'success',
    });
    closingDrawer();
    handleFetchItems();
  };
  const handleClose = (val) => {
    if (val) {
      for (const i of selectedId) {
        dispatch(
          deleteItemsByCatId(JSON.stringify(selectedId), deleteItemCallback)
        );
      }
    }
    toggleDrawer('showDialog', false)
  };
  const copyItemCallback = () => {
    setSnackbar({
      message: Context.langData.item_copy_succ,
      severity: 'success',
    });
    closingDrawer();
    handleFetchItems();
  };
  const closingDrawer = () => {
    toggleDrawer('all', false);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const onRowClick = (row, e) => {
    setRowData(row);
    toggleDrawer('editOpenFlag', true);
  };
  const omMenuItemSelect = (isAl) => {
    toggleDrawer('createOpenFLag', true);
    handleCloseMenu();
    toggleDrawer('isAlcohol', isAl);
  };
  const handleChange = (id) => {
    if (id === 1) {
      omMenuItemSelect(false);
    } else if (id === 2) {
      omMenuItemSelect(true);
    } else if (id === 3) {
      toggleDrawer('isOpenComboModal', true);
      handleCloseMenu();
    } else if (id === 4) {
      toggleDrawer('isOpenPizzaModal', true);
      handleCloseMenu();
    }
  };

  const onPageChange = (pageNumber) => {
    setParams((prev) => ({ ...prev, offset: parseInt(pageNumber) - 1 }));
  };

  const sortingOnClick = ({ field, order }) => {
    setParams((prev) => ({
      ...prev,
      offset: 0,
      sortBy: field,
      orderBy: order,
    }));
  };

  const onSearchText = (text) => {
    setParams({ limit: 25, offset: 0, searchText: text });
  };

  const onCategoryFilterChange = (filterVal) => {
    setParams({ ...params, filterBy: filterVal });
  };

  function onCheckedIds(checkedIds) {
    setSelectedId(checkedIds);
  }

  const callBack = (id) => {
    let sItems = [];
    if (id === 1) {
      selectedId.forEach((id) => {
        var item = itemsList.items.filter((e) => e.item_id === id);
        if (item.length > 0) sItems.push(item[0]);
      });
      setSelectedItems(sItems);
      toggleDrawer('openAssignAddons', true);
    } else if (id === 2) {
      var catName = '';
      selectedId.forEach((id) => {
        var item = itemsList.items.filter((e) => e.item_id === id);
        if (item.length > 0) {
          sItems.push(item[0]);
          if (catName === '') {
            catName = item[0].category;
          } else if (catName !== item[0].category) {
            catName = Context.langData.multiple;
          }
        }
      });
      setSelectedItems(sItems);
      setCurrentCatName(catName);
      toggleDrawer('openAssignCategories', true);
    } else if (id === 3) {
      if (selectedId.length > 0) {
        deleteItems(true);
      }
    }
  };

  function deleteItems(value) {
    toggleDrawer('showDialog', value)
  }

  return (
    <Grid
      data-qaid='item_catalogue_grid'
      container
      direction='row'
      style={{ padding: 30 }}>
      <Grid
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          padding: '0px 10px 10px 21px',
        }}>
        <Grid
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '30%',
            justifyContent: 'flex-start',
          }}>
          <TopSearchBar
            value={params.searchText}
            onChange={(e) => onSearchText(validateAlphaNumeric(e.target.value))}
            placeholder={Context.langData.search_item_name}
            style={{ height: 50 }}
            width={'100%'}
          />
        </Grid>

        <Grid
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '70%',
            marginBottom: 22,
            marginLeft: 20,
          }}>
          <Grid
            item
            data-qaid='item_categorySingleSelectView'
            style={{ width: '30%' }}>
            <AvenirBlackH4
              data-qaid='item_categorySingleSelectLabel'
              label={Context.langData.filter_by}
            />
            <SingleSelect
              list={categoryList}
              value={params.filterBy || 0}
              valueKey='id'
              displayKey='name'
              border={false}
              handleChange={(e) => {
                onCategoryFilterChange(e.target.value);
              }}
              height={50}
            />
          </Grid>
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <ButtonMenu
              style={{ marginLeft: 10 }}
              menuItems={ButtonMenuData}
              label={Context.langData.edit}
              callBack={callBack}
              btnDisable={selectedId?.length <= 0}
            />
            <Button
              className={style.buttonClass}
              data-qaid='categories_creategrid'
              style={{
                backgroundColor: '#FFB600',
                color: 'white',
                marginRight: 10,
              }}
              variant='contained'
              id='cancel'
              onClick={(event) => setAnchorEl(event.currentTarget)}>
              <PluseIcon
                data-qaid='categories_createpluse'
                style={{ marginRight: 5 }}
              />
              <AvenirBlackH4
                data-qaid='categories_createlabel'
                label={Context.langData.Create_new}
              />
            </Button>
          </Grid>
        </Grid>

        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl) && findItem?.length > 0}
          onClose={handleCloseMenu}
          style={{ zIndex: 999 }}>
          {findItem.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              onClick={() => handleChange(item.id)}>
              {item.value}
              <span width='20'>&nbsp;&nbsp;</span>
              {item.isBeta && (
                <BetaIcon color={'#051d33'} width={36} height={18} />
              )}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
      <NewDataTable
        data-qaid='item_catalogue_table'
        offset={params.offset}
        total={Math.ceil(itemsList.total / 25)}
        columns={columns}
        rows={items}
        onCheckedIds={onCheckedIds}
        isOnClick={true}
        onClickAction={onRowClick}
        sortingOnClick={sortingOnClick}
        onPageChange={onPageChange}
      />
      {drawers.createOpenFLag && (
        <CreateItem
          open={drawers.createOpenFLag}
          itemsList={items}
          isAlcohol={drawers.isAlcohol}
          callback={createCallback}
          onClose={() => {
            toggleDrawer('createOpenFLag', false);
          }}
          deleteItemCallback={deleteItemCallback}
        />
      )}
      {drawers.editOpenFlag && (
        <CreateItem
          open={drawers.editOpenFlag}
          id={rowData.cat_id}
          itemsList={items}
          itemId={rowData.id}
          callback={editCallback}
          onClose={() => {
            toggleDrawer('editOpenFlag', false);
          }}
          deleteItemCallback={deleteItemCallback}
          copyItemCallback={copyItemCallback}
        />
      )}
      {drawers.isOpenComboModal && (
        <CombosModal
          itemId={rowData.id}
          open={drawers.isOpenComboModal}
          callback={createCallback}
          editCallback={editCallback}
          onClose={() => closingDrawer()}
          deleteItemCallback={deleteItemCallback}
          copyItemCallback={copyItemCallback}
        />
      )}
      {drawers.isOpenPizzaModal && (
        <PizzasModal
          itemId={rowData.id}
          open={drawers.isOpenPizzaModal}
          callback={createCallback}
          editCallback={editCallback}
          onClose={() => {
            closingDrawer();
          }}
          deleteItemCallback={deleteItemCallback}
          copyItemCallback={copyItemCallback}
        />
      )}
      {drawers.openAssignCategories && (
        <ItemAssignCategories
          data-qaid='printerlist_assignprinter'
          open={drawers.openAssignCategories}
          items={selectedItems}
          currentCategory={currentCatName}
          onClose={() => toggleDrawer('openAssignCategories', false)}
          callback={assignCategoryCallback}
        />
      )}
      {drawers.openAssignAddons && (
        <ItemAssignAddons
          data-qaid='printerlist_assignAddon'
          open={drawers.openAssignAddons}
          items={selectedItems}
          onClose={() => toggleDrawer('openAssignAddons', false)}
          callback={assignAddonCallback}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <AlertDialog
        showDialog={drawers.showDialog}
        body={bodyMessage}
        heading={Context.langData.delete_item}
        IsError={isError}
        handleClose={handleClose}
        showExtra={showExtra}
      />
    </Grid>
  );
};

export default ItemCatalogue;
