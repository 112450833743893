import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Grid } from "@material-ui/core";
import VerticalTabs from "../../../components/verticalTabs";
import MyContext from "../../../Provider/MyContext";
import NoPermissionPage from "../../../components/NoPermission";
import Offers from "./Offers";
import Shifts from "./Shifts";
import Printers from "./Printers";
import Kitchens from "./KitchenCreation";
import Reservations from "./Reservations";
import DeliveryOptions from "./More";
import RememberMe from "./RememberMe";
import Reports from "./Reports";
import KitchenTickets from "./KitchenTickets";
import GuestChecks from "./GuestChecks";
import { FeatureKeys } from "../../../enums";
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const General = () => {
  const Context = useContext(MyContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  var Tabs = [
    {
      name: Context.langData.discounts,
      id: 0,
    },
    {
      name: Context.langData.shifts,
      id: 1,
    },
    {
      name: Context.langData.printers,
      id: 2,
    },
    {
      name: Context.langData.kitchen_creation,
      id: 3,
    },
    // {
    //   name: Context.langData.reservations,
    //   id: 4,
    // },
    {
      name: Context.langData.delivery_options,
      id: 4,
    },
    // {
    //   name: Context.langData.Login,
    //   id: 5,
    // },
    {
      name: Context.langData.Reports,
      id: 5,
    },
    // {
    //   name: Context.langData.guest_checks,
    //   id: 7,
    // },
  ];


  const featureFlagClient = useLDClient();
  if (featureFlagClient) {
    const flagValue = featureFlagClient.variation(FeatureKeys.KitchenTicket, false);
    if (flagValue) {
      Tabs.push({ name: Context.langData.kitchen_tickets, id: 7, })
    }
  }

  function indexCallback(value) {
    const Index = Tabs.findIndex((element) => element.id === value);
    setSelectedTabIndex(value);
  }
  const renderView = () => {
    switch (selectedTabIndex) {
      case 0:
        return (
          <>
            {isPermissionCheck("offers") ? (
              <Offers data-qaid="ordertypeList_index"></Offers>
            ) : (
              <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
            )}
          </>
        );
      case 1:
        return (
          <>
            {isPermissionCheck("shifts") ? (
              <Shifts data-qaid="ordertypeList_index"></Shifts>
            ) : (
              <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
            )}
          </>
        );
      case 2:
        return (
          <>
            {isPermissionCheck("printers") ? (
              <Printers data-qaid="ordertypeList_index"></Printers>
            ) : (
              <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
            )}
          </>
        );
      case 3:
        return (
          <>
            {isPermissionCheck("kitchen") ? (
              <Kitchens data-qaid="ordertypeList_index"></Kitchens>
            ) : (
              <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
            )}
          </>
        );
      // case 4:
      //   return (
      //     <>
      //       {isPermissionCheck("reservation_settings") ? (
      //         <Reservations data-qaid="ordertypeList_index"></Reservations>
      //       ) : (
      //         <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
      //       )}
      //     </>
      //   );
      case 4:
        return (
          <>
            {isPermissionCheck("delivery_options") ? (
              <DeliveryOptions data-qaid="ordertypeList_index"></DeliveryOptions>
            ) : (
              <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
            )}
          </>
        );
      // case 5:
      //   return (
      //     <>
      //       {isPermissionCheck("pos_login_remember_me") ? (
      //         <RememberMe data-qaid="ordertypeList_index"></RememberMe>
      //       ) : (
      //         <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
      //       )}
      //     </>
      //   );
      case 5:
        return (
          <>
            {isPermissionCheck("Reports") || true ? (
              <Reports data-qaid="reports_nopermission"></Reports>
            ) : (
              <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
            )}
          </>
        );
      // case 7:
      //   return (
      //     <>
      //       {isPermissionCheck("Kitchen_Guest_Receipt_Setting") ? (
      //         <GuestChecks data-qaid=" guest_checks_nopermission" />
      //       ) : (
      //         <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
      //       )}
      //     </>
      //   );
      case 7:
        return (
          <>
            {isPermissionCheck("Kitchen_Guest_Receipt_Setting") ? (
              <KitchenTickets data-qaid="kitchen_tickets_nopermission" />
            ) : (
              <NoPermissionPage data-qaid="ordertypeList_nopermission"></NoPermissionPage>
            )}
          </>
        );

      default:
        return null;
    }
  };
  const isPermissionCheck = (val) => {
    var isPermission = false;
    var obj = userPermissions.filter((e) => e.key === val);
    if (obj.length > 0) {
      if (obj[0].status) {
        isPermission = true;
      } else {
      }
    } else {
    }

    return isPermission;
  };
  return (
    <div
      data-qaid="ordertypes_index_div"
      style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
      <Grid
        data-qaid="menu_grid"
        container
        direction="row"
        style={{ borderTop: "1px solid #EEEEEE" }}>
        <Grid
          data-qaid="menu_tabs"
          item
          xs={12}
          lg={2}
          style={{ backgroundColor: "#FAFAFA" }}>
          <VerticalTabs
            data-qaid="menu_options_vtabs"
            tabData={Tabs}
            indexCallback={indexCallback}
            style={{ marginTop: 24 }}></VerticalTabs>
        </Grid>
        <Grid
          data-qaid="menu_options_addbutton"
          item
          xs={12}
          lg={10}
          style={{ marginTop: 10 }}>
          {renderView()}
        </Grid>
      </Grid>
    </div>
  );
};

export default General;
