import React, { useEffect, useState, useContext } from 'react';
import {
    Grid,
    Button,
    Chip,
    Snackbar,
    Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    editStock, fetchStockDetails
} from '../../../../redux/actions';
import MyContext from '../../../../Provider/MyContext';
import MuiAlert from '@material-ui/lab/Alert';
import { AvenirBlackH4, H4, H8 } from '../../../../utils/text';
import InputText from '../../../../components/InputText';
import InventoryIcon from '../../../../components/SVG/InventoryIcon';
import CircleClose from '../../../../components/SVG/CloseCircle';
import RightDrawer from '../../../../components/RightDrawer';
import styles from '../../../../styles/index';
import AutoCompliteDropDown from '../../../../components/AutoComplete';
import { ConvertPrice } from '../../../../utils/util';
import SingleSelect from '../../../../components/SingleSelect';
import UnitsData from './units';
import { validateText } from '../../../../utils/util';


const EditStock = ({ open, onClose, row, callback }) => {
    const style = styles();
    const dispatch = useDispatch();
    const getVendors = useSelector((state) => state.inventory.vendors);
    const [itemsList, setItemsList] = useState([]);
    const [name, setName] = useState('');
    const [id, setId] = useState(0);
    const [unit, setUnit] = useState('');
    const [criticalBalance, setCriticalBalance] = useState('');
    const Context = useContext(MyContext);
    const [alertData, setAlertData] = useState({ open: false, severity: "success", msg: "" })
    const [nameError, setNameError] = useState('')
    const [unitError, setUnitError] = useState('');
    const [criticalBalanceError, setCriticalBalanceError] = useState('');
    console.log("vendors", getVendors)
    const handleDelete = (index) => {
        var list = [...itemsList]
        list.splice(index, 1);
        setItemsList(list)
        console.log('delete', index)
    }
    const selectedStock = (value) => {
        console.log("select value", value)
        var itemListArray = [...itemsList];
        var item = itemListArray.filter((e) => e.name == value)
        if (item.length > 0) {
        } else {
            itemListArray.push(value);
        }
        setItemsList(itemListArray);
        // setItemName('');
        console.log("select value", value, "itemList", itemListArray)
    }
    useEffect(() => {
        console.log('row', row)
        if (row.id) {
            dispatch(fetchStockDetails(row.id, FetchStockCallback))
        }
    }, [row])
    const FetchStockCallback = (response) => {
        if (response) {
            setId(response.id);
            setName(response.name)
            var units_value = UnitsData.find(e => e.value == response.unitTypeCode);
            if (units_value) {
                setUnit(units_value.name)
            }
            else {
                setUnit(response.unitTypeCode)
            }
            setCriticalBalance(response.criticalBalance)
            var vss = []
            response.vendors.forEach((vd) => {
                vss.push(vd.name)
                });
            setItemsList(vss)
        }
    }

    const handleSave = () => {
        var itemIds = [];
        var errorFlag = 0;
        if (name) {
            setNameError('')
        } else {
            errorFlag = errorFlag + 1;
            setNameError(Context.langData.no_text_error)
        }
        if (itemsList.length > 0) {
            itemsList.map((item) => {
                var obj = getVendors.filter((e) => e.name == item);
                if (obj.length > 0) {
                    var vobj = {
                        "id" : obj[0].id,
                        "name":obj[0].name
                    }
                    itemIds.push(vobj)
                }
            })
        }
        if (errorFlag == 0) {

            if (itemIds.length > 0) {
                dispatch(editStock(id, {
                    name: name,
                    id: id,
                    categoryId: 0,
                    categoryName: "Default",
                    criticalBalance: parseInt(criticalBalance),
                    vendors: itemIds,
                }, callback))
            } else {
                setTimeout(() => {
                    let d = { open: true, severity: "error", msg: 'select atleast one vendor' }
                    setAlertData(d);
                    setTimeout(() => {
                        setAlertData({ open: false, severity: "success", msg: "" });
                    }, 3000)
                }, 2000)
            }
        } else {

        }

    }

    const NameChange = (value) => {
        setName(validateText(value))
        if (value) {
            setNameError('')
        }
    }
    const criticalBalanceChange = (value) => {
        setCriticalBalance(value.replace(/[^0-9]/g, ''))
        if (value) {
            setCriticalBalanceError('')
        }
    }

    const handleSelect = (value) => {
        selectedStock(value)
    }
    return (
        <RightDrawer data-qaid="createStock_right_drawer" open={open} padding={1} >
            <>
                <Grid data-qaid="createStock_parent_grid" style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid data-qaid="createStock_body_grid" style={{ display: 'flex', height: '85%', flexDirection: 'column', overflowX: 'auto' }}>
                        <Grid data-qaid="createStock_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createStock_closeicon" size={20} onClick={onClose} style={{ marginRight: 36, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createStock_inventory_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 36, marginBottom: 10 }}>
                            <InventoryIcon data-qaid="createStock_inventory_icon" style={{ marginRight: 0 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createStock_inventory" label={Context.langData.inventory} style={{ color: '#707272', marginLeft: 10 }} />
                        </Grid>
                        <H8 data-qaid="createStock_title" style={{ marginLeft: 36, marginBottom: 20, marginRight: 36 }} label={Context.langData.edit_stock} />
                        <Grid data-qaid="createStock_main_grid" style={{ marginLeft: 36, marginBottom: 20, marginRight: 36, width: 500, display: 'flex', flexDirection: 'column' }} >
                            <Grid data-qaid="createStock_nameLabelView" style={{ marginTop: '10px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="createStock_nameLabel" label={Context.langData.name} />
                                <InputText
                                    value={name}
                                    maxLength={64}
                                    error={nameError}
                                    onChange={(e) => { NameChange(e.target.value) }}
                                    name="name"
                                    id="name"
                                />
                            </Grid>
                            <Grid data-qaid="createStock_unitsLabelView" style={{ marginTop: '10px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="createStock_unitsLabel" label={Context.langData.units} />
                                <InputText
                                    value={unit}
                                    maxLength={64}
                                    error={unitError}
                                    disabled={true}
                                    name="unit"
                                    id="unit"
                                />
                            </Grid>
                            {/* <Grid data-qaid="createStock_unitsLabelView" style={{ marginTop: '10px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="createStock_unitsLabel" label={Context.langData.units} />
                                <SingleSelect
                                            minWidth={'100%'}
                                            data-qaid="units_singleselect"
                                            list={UnitsData}
                                            value={unit}
                                            valueKey="value"
                                            displayKey="name"
                                            placeholder="Select"
                                            border={false}
                                            height={56}
                                            disabled={true}
                                        />
                            </Grid> */}
                            <Grid data-qaid="createStock_criticalBalanceLabelView" style={{ marginTop: '10px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="createStock_criticalBalanceLabel" label={Context.langData.critical_balance} />
                                <InputText
                                    value={criticalBalance}
                                    error={criticalBalanceError}
                                    maxLength={8}
                                    onChange={(e) => { criticalBalanceChange(e.target.value) }}
                                    name="criticalBalance"
                                    id="criticalBalance"
                                    inputPlaceholder={'0'}
                                />
                            </Grid>


                            <AvenirBlackH4 data-qaid="createStock_vendorLabel" label={Context.langData.assign_vendor} style={{ marginTop: 20, paddingBottom: 10 }} />
                            <Grid item style={{ borderTop: '1px solid #EEE' }}>
                                <AutoCompliteDropDown
                                    options={getVendors}
                                    onChange={handleSelect}
                                    labelKey={'name'}
                                    key={itemsList}
                                    placeholder={Context.langData.search_vendor_name}
                                />
                            </Grid>
                            <Grid data-qaid="createStock_itemListGrid" item style={{ marginTop: '20px' }}>
                                {itemsList ?
                                    itemsList.map((itemName, index) => {
                                        return (
                                            <Chip key={itemName} label={itemName} variant="outlined" onDelete={() => handleDelete(index)} style={{ margin: '5px' }} />
                                        )
                                    }) : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="createStock_cancelButtonContainer" item style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: '#FAFAFA', width: '100%', paddingTop: '25px', alignItems: 'center', paddingBottom: '25px', borderTop: '1px solid #C3C3C3', zIndex: 9999 }}>
                            <Button className={style.buttonClass} data-qaid="createStock_cancelButtonView" style={{ backgroundColor: "#FFF", marginRight: 10, width: 103, height: 40, border: '2px solid #051D33' }} variant="contained" id="cancel" onClick={() => onClose()}  >
                                <AvenirBlackH4 data-qaid="createStock_cancelButton" label={Context.langData.cancel} />
                            </Button>
                            <Button className={style.buttonClass} data-qaid="createStock_saveButtonView" style={{ backgroundColor: "#FFB600", marginRight: 10, width: 90, height: 40 }} variant="contained" id="cancel" type='submit' onClick={() => handleSave()}>
                                <AvenirBlackH4 data-qaid="createStock_saveButtonLabel" label={Context.langData.save} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Message open={alertData.open} severity={alertData.severity} msg={alertData.msg} handleClose={() => console.log()} />
            </>
        </RightDrawer >
    )
}
const Message = ({ open, handleClose, severity, msg }) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
                <Typography variant="h5" >
                    {msg}
                </Typography>
            </Alert>
        </Snackbar>
    )
}
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default EditStock;
