import React from "react";

function DiscoverCardIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="26"
      fill="none"
      viewBox="0 0 40 26"
      {...props}
    >
      <path
        fill="#fff"
        d="M.44 1.788v22.425a.87.87 0 00.878.858H25.68c6.822 0 12.352-5.405 12.352-12.07C38.033 6.333 32.503.93 25.68.93H1.318a.87.87 0 00-.879.858z"
      ></path>
      <path
        fill="#261F20"
        d="M4.647 14.279c-.334.293-.766.422-1.451.422H2.91v-3.513h.285c.685 0 1.1.12 1.45.43.367.318.588.813.588 1.322 0 .51-.22 1.02-.587 1.339zm-1.239-3.991H1.852V15.6H3.4c.823 0 1.417-.19 1.94-.613.62-.501.986-1.258.986-2.04 0-1.567-1.198-2.659-2.918-2.659zM6.814 15.6h1.06v-5.312h-1.06V15.6zM10.466 12.326c-.636-.23-.823-.381-.823-.668 0-.335.333-.59.79-.59.318 0 .579.129.855.431l.555-.71a2.408 2.408 0 00-1.598-.589c-.961 0-1.695.653-1.695 1.522 0 .732.342 1.107 1.338 1.457.415.143.626.239.733.303a.633.633 0 01.318.55c0 .43-.35.748-.824.748-.505 0-.913-.246-1.157-.708l-.685.644c.489.701 1.075 1.012 1.882 1.012 1.102 0 1.875-.716 1.875-1.744 0-.844-.357-1.226-1.564-1.658zM12.365 12.948c0 1.56 1.255 2.772 2.87 2.772.456 0 .847-.088 1.329-.31v-1.22c-.424.415-.8.582-1.28.582-1.068 0-1.826-.757-1.826-1.832 0-1.02.782-1.824 1.777-1.824.505 0 .888.176 1.329.597v-1.219c-.466-.231-.848-.326-1.304-.326-1.607 0-2.895 1.235-2.895 2.78zM24.97 13.856l-1.45-3.568h-1.158l2.308 5.449h.57l2.348-5.45h-1.15l-1.467 3.569zM28.068 15.6h3.008v-.9h-1.948v-1.433h1.875v-.9h-1.875v-1.179h1.948v-.9h-3.008V15.6zM33.146 12.734h-.31v-1.61h.326c.66 0 1.02.27 1.02.788 0 .534-.36.822-1.036.822zm2.127-.878c0-.994-.702-1.568-1.924-1.568h-1.573V15.6h1.06v-2.134h.138l1.468 2.134h1.303l-1.711-2.237c.8-.159 1.239-.693 1.239-1.507zM35.917 10.63h-.02v-.122h.02c.058 0 .087.02.087.06 0 .041-.03.062-.087.062zm.202-.064c0-.093-.066-.144-.182-.144h-.154v.468h.115v-.181l.133.181h.14l-.158-.193c.068-.018.106-.065.106-.131z"
      ></path>
      <path
        fill="#261F20"
        d="M35.958 10.99a.334.334 0 01-.333-.335c0-.185.149-.335.333-.335a.335.335 0 010 .67zm.002-.743a.411.411 0 00-.418.408c0 .227.187.409.418.409a.412.412 0 00.413-.409.414.414 0 00-.413-.408z"
      ></path>
      <path
        fill="url(#paint0_linear_36449_620208)"
        d="M16.907 12.939c0 1.553 1.289 2.813 2.879 2.813s2.879-1.26 2.879-2.813c0-1.554-1.29-2.814-2.88-2.814-1.59 0-2.878 1.26-2.878 2.814z"
      ></path>
      <mask
        id="mask0_36449_620208"
        style={{ maskType: "luminance" }}
        width="7"
        height="6"
        x="16"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M16.91 10.125h5.756v5.627H16.91v-5.627z"></path>
      </mask>
      <g mask="url(#mask0_36449_620208)" opacity="0.65">
        <mask
          id="mask1_36449_620208"
          style={{ maskType: "luminance" }}
          width="7"
          height="6"
          x="16"
          y="10"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M22.668 12.939c0 1.554-1.29 2.813-2.88 2.813-1.59 0-2.878-1.26-2.878-2.813 0-1.554 1.289-2.814 2.879-2.814s2.878 1.26 2.878 2.814z"
          ></path>
        </mask>
        <g mask="url(#mask1_36449_620208)">
          <mask
            id="mask2_36449_620208"
            style={{ maskType: "luminance" }}
            width="46860"
            height="45795"
            x="-23442"
            y="-22861"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="url(#paint1_linear_36449_620208)"
              d="M-23441.6-22860.1h46859.3V22934h-46859.3v-45794.1z"
            ></path>
          </mask>
          <g mask="url(#mask2_36449_620208)">
            <path
              fill="url(#paint2_linear_36449_620208)"
              d="M22.662 12.935c0 1.554-1.29 2.813-2.879 2.813-1.59 0-2.879-1.26-2.879-2.813 0-1.554 1.289-2.814 2.879-2.814s2.879 1.26 2.879 2.814z"
            ></path>
          </g>
        </g>
      </g>
      <mask
        id="mask3_36449_620208"
        style={{ maskType: "luminance" }}
        width="8"
        height="7"
        x="16"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M16.858 10.078h6.43v6.544h-6.43v-6.544z"></path>
      </mask>
      <g mask="url(#mask3_36449_620208)" opacity="0.5">
        <mask
          id="mask4_36449_620208"
          style={{ maskType: "luminance" }}
          width="7"
          height="6"
          x="16"
          y="10"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M22.666 12.94c0 1.553-1.289 2.812-2.88 2.812-1.589 0-2.878-1.259-2.878-2.813 0-1.554 1.29-2.813 2.879-2.813 1.59 0 2.88 1.26 2.88 2.813z"
          ></path>
        </mask>
        <g mask="url(#mask4_36449_620208)">
          <mask
            id="mask5_36449_620208"
            style={{ maskType: "luminance" }}
            width="8"
            height="7"
            x="16"
            y="10"
            maskUnits="userSpaceOnUse"
          >
            <path fill="#fff" d="M16.86 10.078h6.43v6.544h-6.43v-6.544z"></path>
          </mask>
          <g mask="url(#mask5_36449_620208)">
            <mask
              id="mask6_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.865 10.086h5.875v5.738h-5.875v-5.738z"
              ></path>
            </mask>
            <g mask="url(#mask6_36449_620208)">
              <path
                fill="#030100"
                d="M19.801 10.086c1.621 0 2.939 1.28 2.939 2.865 0 1.584-1.318 2.872-2.939 2.872s-2.936-1.284-2.936-2.868c0-1.585 1.316-2.87 2.936-2.87z"
              ></path>
            </g>
            <mask
              id="mask7_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.871 10.093h5.875v5.737h-5.875v-5.737z"
              ></path>
            </mask>
            <g mask="url(#mask7_36449_620208)">
              <path
                fill="#060200"
                d="M19.806 10.093c1.62.002 2.94 1.277 2.94 2.861 0 1.584-1.319 2.876-2.94 2.876-1.62 0-2.936-1.284-2.935-2.868.002-1.586 1.317-2.87 2.935-2.869z"
              ></path>
            </g>
            <mask
              id="mask8_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.879 10.1h5.876v5.735H16.88v-5.734z"
              ></path>
            </mask>
            <g mask="url(#mask8_36449_620208)">
              <path
                fill="#080200"
                d="M19.813 10.101c1.62.002 2.942 1.273 2.942 2.856s-1.322 2.879-2.942 2.879c-1.621 0-2.936-1.284-2.934-2.868.002-1.585 1.316-2.869 2.934-2.867z"
              ></path>
            </g>
            <mask
              id="mask9_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.883 10.109h5.877v5.733h-5.877v-5.733z"
              ></path>
            </mask>
            <g mask="url(#mask9_36449_620208)">
              <path
                fill="#0B0300"
                d="M19.816 10.109c1.62.003 2.945 1.268 2.945 2.85 0 1.584-1.325 2.882-2.945 2.882-1.62 0-2.936-1.283-2.933-2.866.003-1.585 1.317-2.87 2.933-2.866z"
              ></path>
            </g>
            <mask
              id="mask10_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.889 10.116h5.878v5.732H16.89v-5.732z"
              ></path>
            </mask>
            <g mask="url(#mask10_36449_620208)">
              <path
                fill="#0E0400"
                d="M19.821 10.116c1.619.005 2.946 1.264 2.946 2.846 0 1.583-1.326 2.886-2.946 2.886s-2.936-1.283-2.932-2.866c.003-1.585 1.317-2.87 2.932-2.866z"
              ></path>
            </g>
            <mask
              id="mask11_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.894 10.124h5.88v5.73h-5.88v-5.73z"
              ></path>
            </mask>
            <g mask="url(#mask11_36449_620208)">
              <path
                fill="#110500"
                d="M19.825 10.125c1.619.004 2.948 1.259 2.948 2.84 0 1.584-1.329 2.89-2.948 2.89-1.62 0-2.936-1.284-2.931-2.866.004-1.585 1.318-2.869 2.931-2.864z"
              ></path>
            </g>
            <mask
              id="mask12_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.902 10.132h5.881v5.728h-5.88v-5.728z"
              ></path>
            </mask>
            <g mask="url(#mask12_36449_620208)">
              <path
                fill="#130500"
                d="M19.833 10.132c1.618.006 2.95 1.255 2.95 2.837 0 1.581-1.332 2.89-2.95 2.89-1.619 0-2.936-1.281-2.93-2.863.004-1.585 1.319-2.87 2.93-2.864z"
              ></path>
            </g>
            <mask
              id="mask13_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.908 10.139h5.882v5.726h-5.882V10.14z"
              ></path>
            </mask>
            <g mask="url(#mask13_36449_620208)">
              <path
                fill="#160600"
                d="M19.838 10.139c1.617.006 2.952 1.25 2.952 2.832 0 1.581-1.334 2.894-2.952 2.894-1.618 0-2.936-1.282-2.93-2.863.006-1.585 1.32-2.87 2.93-2.863z"
              ></path>
            </g>
            <mask
              id="mask14_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.912 10.147h5.883v5.725h-5.883v-5.725z"
              ></path>
            </mask>
            <g mask="url(#mask14_36449_620208)">
              <path
                fill="#190700"
                d="M19.841 10.147c1.617.007 2.954 1.246 2.954 2.827s-1.337 2.897-2.954 2.897c-1.618 0-2.935-1.28-2.929-2.862.007-1.586 1.32-2.87 2.93-2.862z"
              ></path>
            </g>
            <mask
              id="mask15_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.917 10.155h5.884v5.723h-5.884v-5.723z"
              ></path>
            </mask>
            <g mask="url(#mask15_36449_620208)">
              <path
                fill="#1C0800"
                d="M19.845 10.155c1.617.007 2.956 1.242 2.956 2.822s-1.34 2.9-2.956 2.9c-1.617 0-2.935-1.28-2.928-2.861.007-1.585 1.321-2.87 2.928-2.861z"
              ></path>
            </g>
            <mask
              id="mask16_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.923 10.162h5.885v5.722h-5.885v-5.722z"
              ></path>
            </mask>
            <g mask="url(#mask16_36449_620208)">
              <path
                fill="#1E0800"
                d="M19.85 10.163c1.616.008 2.958 1.238 2.958 2.818s-1.341 2.903-2.958 2.903c-1.617 0-2.935-1.28-2.927-2.86.008-1.586 1.322-2.87 2.927-2.861z"
              ></path>
            </g>
            <mask
              id="mask17_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.932 10.17h5.886v5.72h-5.886v-5.72z"
              ></path>
            </mask>
            <g mask="url(#mask17_36449_620208)">
              <path
                fill="#210900"
                d="M19.858 10.17c1.616.01 2.96 1.234 2.96 2.813 0 1.58-1.344 2.906-2.96 2.906-1.616 0-2.935-1.28-2.926-2.86.008-1.585 1.323-2.868 2.926-2.86z"
              ></path>
            </g>
            <mask
              id="mask18_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.938 10.178h5.887v5.718h-5.887v-5.718z"
              ></path>
            </mask>
            <g mask="url(#mask18_36449_620208)">
              <path
                fill="#240A00"
                d="M19.863 10.178c1.615.01 2.961 1.23 2.961 2.808 0 1.58-1.346 2.91-2.962 2.91-1.615 0-2.935-1.28-2.925-2.859.01-1.586 1.323-2.869 2.925-2.86z"
              ></path>
            </g>
            <mask
              id="mask19_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.942 10.185h5.889v5.717h-5.889v-5.717z"
              ></path>
            </mask>
            <g mask="url(#mask19_36449_620208)">
              <path
                fill="#270B00"
                d="M19.867 10.186c1.615.01 2.964 1.225 2.964 2.803 0 1.58-1.349 2.913-2.964 2.913s-2.934-1.28-2.924-2.858c.01-1.586 1.323-2.869 2.924-2.858z"
              ></path>
            </g>
            <mask
              id="mask20_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.946 10.192h5.89v5.715h-5.89v-5.715z"
              ></path>
            </mask>
            <g mask="url(#mask20_36449_620208)">
              <path
                fill="#290B00"
                d="M19.87 10.192c1.615.012 2.966 1.22 2.966 2.8 0 1.577-1.351 2.915-2.966 2.915-1.615 0-2.934-1.28-2.924-2.857.011-1.587 1.325-2.869 2.924-2.858z"
              ></path>
            </g>
            <mask
              id="mask21_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path fill="#fff" d="M16.952 10.2h5.891v5.714h-5.89V10.2z"></path>
            </mask>
            <g mask="url(#mask21_36449_620208)">
              <path
                fill="#2C0C00"
                d="M19.875 10.2c1.614.014 2.968 1.218 2.968 2.795 0 1.578-1.354 2.92-2.968 2.92-1.614 0-2.934-1.28-2.923-2.857.012-1.587 1.325-2.869 2.923-2.857z"
              ></path>
            </g>
            <mask
              id="mask22_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.96 10.208h5.892v5.711H16.96v-5.711z"
              ></path>
            </mask>
            <g mask="url(#mask22_36449_620208)">
              <path
                fill="#2F0D00"
                d="M19.882 10.208c1.613.013 2.97 1.212 2.97 2.79 0 1.577-1.356 2.92-2.97 2.92s-2.934-1.277-2.922-2.854c.012-1.587 1.326-2.869 2.922-2.856z"
              ></path>
            </g>
            <mask
              id="mask23_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.966 10.216h5.893v5.71h-5.893v-5.71z"
              ></path>
            </mask>
            <g mask="url(#mask23_36449_620208)">
              <path
                fill="#320E00"
                d="M19.887 10.216c1.614.014 2.972 1.208 2.972 2.785 0 1.577-1.358 2.925-2.972 2.925-1.613 0-2.934-1.278-2.921-2.855.014-1.586 1.327-2.868 2.921-2.855z"
              ></path>
            </g>
            <mask
              id="mask24_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.972 10.223h5.894v5.709h-5.894v-5.709z"
              ></path>
            </mask>
            <g mask="url(#mask24_36449_620208)">
              <path
                fill="#340E00"
                d="M19.892 10.224c1.613.014 2.974 1.204 2.974 2.78 0 1.577-1.36 2.928-2.974 2.928-1.613 0-2.933-1.278-2.92-2.854a2.894 2.894 0 012.92-2.854z"
              ></path>
            </g>
            <mask
              id="mask25_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.977 10.231h5.895v5.707h-5.895V10.23z"
              ></path>
            </mask>
            <g mask="url(#mask25_36449_620208)">
              <path
                fill="#370F00"
                d="M19.896 10.23c1.612.017 2.975 1.2 2.975 2.776 0 1.576-1.362 2.931-2.975 2.931-1.612 0-2.934-1.277-2.92-2.853a2.892 2.892 0 012.92-2.853z"
              ></path>
            </g>
            <mask
              id="mask26_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.983 10.239h5.897v5.705h-5.897v-5.705z"
              ></path>
            </mask>
            <g mask="url(#mask26_36449_620208)">
              <path
                fill="#3A1000"
                d="M19.902 10.24c1.612.016 2.978 1.195 2.978 2.77s-1.366 2.934-2.977 2.934c-1.613 0-2.934-1.278-2.92-2.853a2.892 2.892 0 012.92-2.852z"
              ></path>
            </g>
            <mask
              id="mask27_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.99 10.246h5.897v5.704h-5.898v-5.704z"
              ></path>
            </mask>
            <g mask="url(#mask27_36449_620208)">
              <path
                fill="#3D1100"
                d="M19.907 10.247c1.611.017 2.98 1.191 2.98 2.766s-1.368 2.937-2.98 2.937c-1.611 0-2.933-1.277-2.918-2.851a2.892 2.892 0 012.918-2.852z"
              ></path>
            </g>
            <mask
              id="mask28_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="6"
              x="16"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M16.995 10.254h5.899v5.702h-5.899v-5.702z"
              ></path>
            </mask>
            <g mask="url(#mask28_36449_620208)">
              <path
                fill="#3F1100"
                d="M19.913 10.254c1.61.018 2.981 1.188 2.981 2.762s-1.37 2.94-2.981 2.94c-1.611 0-2.934-1.276-2.918-2.85a2.891 2.891 0 012.918-2.852z"
              ></path>
            </g>
            <mask
              id="mask29_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="6"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path fill="#fff" d="M17.001 10.262h5.9v5.7H17v-5.7z"></path>
            </mask>
            <g mask="url(#mask29_36449_620208)">
              <path
                fill="#421200"
                d="M19.918 10.262c1.61.019 2.983 1.182 2.983 2.757 0 1.574-1.373 2.943-2.983 2.943-1.611 0-2.934-1.276-2.917-2.85a2.891 2.891 0 012.916-2.85z"
              ></path>
            </g>
            <mask
              id="mask30_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="6"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path fill="#fff" d="M17.008 10.269h5.9v5.698h-5.9V10.27z"></path>
            </mask>
            <g mask="url(#mask30_36449_620208)">
              <path
                fill="#451300"
                d="M19.923 10.27c1.61.019 2.986 1.177 2.986 2.751 0 1.574-1.375 2.947-2.986 2.947-1.61 0-2.933-1.276-2.915-2.85a2.89 2.89 0 012.915-2.849z"
              ></path>
            </g>
            <mask
              id="mask31_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="6"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.013 10.277h5.902v5.697h-5.902v-5.697z"
              ></path>
            </mask>
            <g mask="url(#mask31_36449_620208)">
              <path
                fill="#481400"
                d="M19.928 10.277c1.608.02 2.987 1.174 2.987 2.747s-1.378 2.95-2.987 2.95c-1.61 0-2.934-1.276-2.915-2.85a2.89 2.89 0 012.915-2.847z"
              ></path>
            </g>
            <mask
              id="mask32_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="6"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.019 10.284h5.903v5.695h-5.903v-5.695z"
              ></path>
            </mask>
            <g mask="url(#mask32_36449_620208)">
              <path
                fill="#4A1400"
                d="M19.933 10.284c1.608.022 2.989 1.17 2.989 2.743 0 1.572-1.38 2.953-2.99 2.953-1.609 0-2.932-1.276-2.913-2.848a2.89 2.89 0 012.914-2.848z"
              ></path>
            </g>
            <mask
              id="mask33_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="6"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.023 10.292h5.905v5.693h-5.905v-5.693z"
              ></path>
            </mask>
            <g mask="url(#mask33_36449_620208)">
              <path
                fill="#4D1500"
                d="M19.937 10.292c1.608.022 2.99 1.165 2.99 2.738 0 1.572-1.381 2.955-2.99 2.955-1.61 0-2.933-1.275-2.913-2.846a2.89 2.89 0 012.913-2.847z"
              ></path>
            </g>
            <mask
              id="mask34_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="6"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.032 10.3h5.906v5.692h-5.906V10.3z"
              ></path>
            </mask>
            <g mask="url(#mask34_36449_620208)">
              <path
                fill="#501600"
                d="M19.945 10.3c1.607.023 2.993 1.162 2.993 2.733 0 1.572-1.385 2.959-2.993 2.959-1.608 0-2.933-1.275-2.913-2.846a2.888 2.888 0 012.913-2.846z"
              ></path>
            </g>
            <mask
              id="mask35_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="6"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.038 10.308h5.907v5.69h-5.907v-5.69z"
              ></path>
            </mask>
            <g mask="url(#mask35_36449_620208)">
              <path
                fill="#531700"
                d="M19.95 10.308c1.607.024 2.995 1.158 2.995 2.728 0 1.572-1.388 2.963-2.996 2.963s-2.932-1.274-2.91-2.846c.02-1.587 1.334-2.867 2.91-2.845z"
              ></path>
            </g>
            <mask
              id="mask36_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.042 10.315h5.908v5.689h-5.908v-5.689z"
              ></path>
            </mask>
            <g mask="url(#mask36_36449_620208)">
              <path
                fill="#551700"
                d="M19.953 10.316c1.607.024 2.997 1.153 2.997 2.724 0 1.57-1.39 2.964-2.997 2.964s-2.932-1.273-2.91-2.844c.022-1.587 1.335-2.868 2.91-2.844z"
              ></path>
            </g>
            <mask
              id="mask37_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.047 10.322h5.909v5.687h-5.91v-5.687z"
              ></path>
            </mask>
            <g mask="url(#mask37_36449_620208)">
              <path
                fill="#581800"
                d="M19.957 10.323c1.606.025 2.998 1.148 2.998 2.719 0 1.57-1.392 2.967-2.998 2.967-1.607 0-2.933-1.273-2.91-2.843.024-1.588 1.336-2.868 2.91-2.843z"
              ></path>
            </g>
            <mask
              id="mask38_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.053 10.33h5.91v5.686h-5.91V10.33z"
              ></path>
            </mask>
            <g mask="url(#mask38_36449_620208)">
              <path
                fill="#5B1900"
                d="M19.962 10.33c1.606.027 3 1.146 3 2.715 0 1.57-1.394 2.971-3 2.971-1.607 0-2.933-1.273-2.909-2.842.024-1.588 1.336-2.868 2.909-2.843z"
              ></path>
            </g>
            <mask
              id="mask39_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.061 10.338h5.912v5.684H17.06v-5.684z"
              ></path>
            </mask>
            <g mask="url(#mask39_36449_620208)">
              <path
                fill="#5D1900"
                d="M19.97 10.338c1.605.027 3.003 1.14 3.003 2.71 0 1.569-1.397 2.973-3.003 2.973s-2.932-1.272-2.908-2.84c.025-1.588 1.337-2.868 2.908-2.843z"
              ></path>
            </g>
            <mask
              id="mask40_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.067 10.346h5.913v5.682h-5.913v-5.682z"
              ></path>
            </mask>
            <g mask="url(#mask40_36449_620208)">
              <path
                fill="#601A00"
                d="M19.974 10.346c1.605.028 3.005 1.136 3.005 2.705 0 1.569-1.4 2.977-3.005 2.977s-2.932-1.273-2.907-2.841c.026-1.587 1.337-2.867 2.907-2.84z"
              ></path>
            </g>
            <mask
              id="mask41_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.072 10.353h5.913v5.68h-5.913v-5.68z"
              ></path>
            </mask>
            <g mask="url(#mask41_36449_620208)">
              <path
                fill="#631B00"
                d="M19.98 10.354c1.604.029 3.006 1.131 3.006 2.7 0 1.569-1.401 2.98-3.006 2.98-1.606 0-2.932-1.272-2.906-2.84.025-1.588 1.338-2.867 2.905-2.84z"
              ></path>
            </g>
            <mask
              id="mask42_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.076 10.36h5.915v5.68h-5.915v-5.68z"
              ></path>
            </mask>
            <g mask="url(#mask42_36449_620208)">
              <path
                fill="#661C00"
                d="M19.982 10.361c1.604.03 3.009 1.127 3.009 2.695 0 1.569-1.405 2.984-3.01 2.984-1.604 0-2.93-1.271-2.904-2.84.027-1.587 1.339-2.867 2.905-2.839z"
              ></path>
            </g>
            <mask
              id="mask43_36449_620208"
              style={{ maskType: "luminance" }}
              width="6"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.082 10.368h5.915v5.677h-5.915v-5.677z"
              ></path>
            </mask>
            <g mask="url(#mask43_36449_620208)">
              <path
                fill="#681C00"
                d="M19.987 10.37c1.603.03 3.01 1.122 3.01 2.69 0 1.567-1.406 2.986-3.01 2.986-1.604 0-2.932-1.271-2.905-2.838.028-1.588 1.34-2.868 2.905-2.839z"
              ></path>
            </g>
            <mask
              id="mask44_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.09 10.376h5.917v5.676h-5.916v-5.676z"
              ></path>
            </mask>
            <g mask="url(#mask44_36449_620208)">
              <path
                fill="#6B1D00"
                d="M19.995 10.377c1.603.03 3.012 1.118 3.012 2.686 0 1.566-1.408 2.988-3.012 2.988s-2.932-1.27-2.904-2.837c.029-1.588 1.34-2.867 2.904-2.838z"
              ></path>
            </g>
            <mask
              id="mask45_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.096 10.384h5.918v5.674h-5.918v-5.674z"
              ></path>
            </mask>
            <g mask="url(#mask45_36449_620208)">
              <path
                fill="#6E1E00"
                d="M20 10.384c1.602.032 3.014 1.115 3.014 2.681 0 1.567-1.412 2.993-3.015 2.993-1.603 0-2.931-1.27-2.903-2.837.03-1.588 1.341-2.867 2.903-2.837z"
              ></path>
            </g>
            <mask
              id="mask46_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.102 10.392h5.918v5.672h-5.918v-5.672z"
              ></path>
            </mask>
            <g mask="url(#mask46_36449_620208)">
              <path
                fill="#711F00"
                d="M20.003 10.392c1.602.032 3.017 1.11 3.017 2.676 0 1.567-1.414 2.996-3.017 2.996-1.602 0-2.93-1.27-2.901-2.836.03-1.588 1.341-2.867 2.901-2.836z"
              ></path>
            </g>
            <mask
              id="mask47_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path fill="#fff" d="M17.106 10.4h5.92v5.67h-5.92V10.4z"></path>
            </mask>
            <g mask="url(#mask47_36449_620208)">
              <path
                fill="#731F00"
                d="M20.008 10.4c1.602.033 3.018 1.106 3.018 2.67 0 1.567-1.416 3-3.018 3-1.603 0-2.931-1.27-2.901-2.836.03-1.588 1.342-2.866 2.9-2.835z"
              ></path>
            </g>
            <mask
              id="mask48_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.111 10.407h5.921v5.67h-5.92v-5.67z"
              ></path>
            </mask>
            <g mask="url(#mask48_36449_620208)">
              <path
                fill="#762000"
                d="M20.012 10.408c1.6.034 3.02 1.101 3.02 2.667 0 1.565-1.418 3.001-3.02 3.001s-2.93-1.27-2.9-2.834c.031-1.588 1.343-2.867 2.9-2.834z"
              ></path>
            </g>
            <mask
              id="mask49_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.12 10.414h5.921v5.667H17.12v-5.667z"
              ></path>
            </mask>
            <g mask="url(#mask49_36449_620208)">
              <path
                fill="#792100"
                d="M20.02 10.415c1.6.035 3.021 1.097 3.021 2.662s-1.42 3.004-3.022 3.004c-1.6 0-2.93-1.268-2.9-2.833.033-1.588 1.344-2.867 2.9-2.833z"
              ></path>
            </g>
            <mask
              id="mask50_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.125 10.422h5.923v5.666h-5.923v-5.666z"
              ></path>
            </mask>
            <g mask="url(#mask50_36449_620208)">
              <path
                fill="#7C2200"
                d="M20.024 10.423c1.6.036 3.025 1.093 3.025 2.657 0 1.565-1.424 3.008-3.025 3.008-1.6 0-2.93-1.268-2.898-2.832.032-1.588 1.345-2.867 2.898-2.833z"
              ></path>
            </g>
            <mask
              id="mask51_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.13 10.43h5.925v5.664h-5.924V10.43z"
              ></path>
            </mask>
            <g mask="url(#mask51_36449_620208)">
              <path
                fill="#7E2200"
                d="M20.03 10.43c1.599.037 3.025 1.089 3.025 2.653s-1.425 3.01-3.026 3.01c-1.6 0-2.93-1.268-2.898-2.831.034-1.589 1.346-2.867 2.898-2.832z"
              ></path>
            </g>
            <mask
              id="mask52_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.136 10.438h5.925V16.1h-5.925v-5.662z"
              ></path>
            </mask>
            <g mask="url(#mask52_36449_620208)">
              <path
                fill="#812300"
                d="M20.033 10.438c1.599.037 3.028 1.085 3.028 2.648 0 1.564-1.428 3.014-3.028 3.014s-2.93-1.268-2.897-2.831c.035-1.588 1.346-2.867 2.897-2.83z"
              ></path>
            </g>
            <mask
              id="mask53_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.143 10.445h5.926v5.661h-5.926v-5.66z"
              ></path>
            </mask>
            <g mask="url(#mask53_36449_620208)">
              <path
                fill="#842400"
                d="M20.04 10.446c1.598.038 3.03 1.08 3.03 2.643 0 1.563-1.431 3.017-3.03 3.017-1.6 0-2.931-1.268-2.897-2.83.035-1.588 1.347-2.866 2.896-2.83z"
              ></path>
            </g>
            <mask
              id="mask54_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.148 10.453h5.928v5.659h-5.928v-5.66z"
              ></path>
            </mask>
            <g mask="url(#mask54_36449_620208)">
              <path
                fill="#872500"
                d="M20.044 10.454c1.598.038 3.033 1.076 3.033 2.638 0 1.563-1.434 3.02-3.033 3.02s-2.93-1.267-2.895-2.829c.036-1.589 1.347-2.866 2.895-2.83z"
              ></path>
            </g>
            <mask
              id="mask55_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.154 10.46h5.93v5.658h-5.93V10.46z"
              ></path>
            </mask>
            <g mask="url(#mask55_36449_620208)">
              <path
                fill="#892500"
                d="M20.05 10.461c1.597.04 3.033 1.072 3.033 2.634 0 1.562-1.435 3.023-3.034 3.023-1.598 0-2.93-1.267-2.894-2.828.037-1.59 1.348-2.867 2.894-2.829z"
              ></path>
            </g>
            <mask
              id="mask56_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.16 10.468h5.93v5.656h-5.93v-5.656z"
              ></path>
            </mask>
            <g mask="url(#mask56_36449_620208)">
              <path
                fill="#8C2600"
                d="M20.055 10.469c1.597.04 3.036 1.068 3.036 2.629s-1.438 3.026-3.036 3.026-2.93-1.266-2.894-2.828c.037-1.588 1.349-2.866 2.894-2.827z"
              ></path>
            </g>
            <mask
              id="mask57_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.168 10.476h5.931v5.654h-5.931v-5.654z"
              ></path>
            </mask>
            <g mask="url(#mask57_36449_620208)">
              <path
                fill="#8F2700"
                d="M20.061 10.476c1.597.041 3.038 1.064 3.038 2.625 0 1.56-1.44 3.029-3.038 3.029-1.597 0-2.93-1.266-2.892-2.827.038-1.59 1.348-2.866 2.892-2.827z"
              ></path>
            </g>
            <mask
              id="mask58_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.172 10.483h5.932v5.653h-5.932v-5.653z"
              ></path>
            </mask>
            <g mask="url(#mask58_36449_620208)">
              <path
                fill="#922800"
                d="M20.064 10.484c1.596.042 3.04 1.059 3.04 2.62 0 1.56-1.442 3.032-3.04 3.032-1.597 0-2.93-1.266-2.891-2.826.038-1.59 1.35-2.866 2.891-2.826z"
              ></path>
            </g>
            <mask
              id="mask59_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.178 10.491h5.933v5.651h-5.933v-5.651z"
              ></path>
            </mask>
            <g mask="url(#mask59_36449_620208)">
              <path
                fill="#942800"
                d="M20.07 10.492c1.595.042 3.041 1.054 3.041 2.615 0 1.56-1.445 3.035-3.042 3.035-1.596 0-2.93-1.265-2.89-2.825.039-1.59 1.35-2.866 2.89-2.825z"
              ></path>
            </g>
            <mask
              id="mask60_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.184 10.498h5.934v5.65h-5.934v-5.65z"
              ></path>
            </mask>
            <g mask="url(#mask60_36449_620208)">
              <path
                fill="#972900"
                d="M20.075 10.5c1.595.043 3.043 1.05 3.043 2.61 0 1.56-1.447 3.038-3.043 3.038s-2.93-1.265-2.89-2.824c.04-1.589 1.35-2.865 2.89-2.824z"
              ></path>
            </g>
            <mask
              id="mask61_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.191 10.507h5.936v5.647h-5.936v-5.647z"
              ></path>
            </mask>
            <g mask="url(#mask61_36449_620208)">
              <path
                fill="#9A2A00"
                d="M20.082 10.508c1.594.044 3.046 1.046 3.046 2.605 0 1.56-1.45 3.041-3.046 3.041-1.596 0-2.93-1.264-2.89-2.823.042-1.59 1.352-2.866 2.89-2.823z"
              ></path>
            </g>
            <mask
              id="mask62_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.197 10.513h5.937v5.647h-5.937v-5.647z"
              ></path>
            </mask>
            <g mask="url(#mask62_36449_620208)">
              <path
                fill="#9D2B00"
                d="M20.086 10.515c1.595.044 3.048 1.042 3.048 2.6 0 1.56-1.452 3.045-3.048 3.045-1.595 0-2.928-1.265-2.888-2.823.042-1.589 1.352-2.866 2.888-2.822z"
              ></path>
            </g>
            <mask
              id="mask63_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.201 10.521h5.938v5.645h-5.938v-5.645z"
              ></path>
            </mask>
            <g mask="url(#mask63_36449_620208)">
              <path
                fill="#9F2B00"
                d="M20.09 10.522c1.593.046 3.049 1.038 3.049 2.596 0 1.56-1.455 3.048-3.05 3.048-1.594 0-2.928-1.264-2.887-2.822.042-1.59 1.353-2.866 2.887-2.822z"
              ></path>
            </g>
            <mask
              id="mask64_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.207 10.529h5.94v5.643h-5.94v-5.643z"
              ></path>
            </mask>
            <g mask="url(#mask64_36449_620208)">
              <path
                fill="#A22C00"
                d="M20.095 10.53c1.593.047 3.051 1.034 3.051 2.591 0 1.558-1.457 3.05-3.051 3.05-1.595 0-2.93-1.262-2.887-2.82.043-1.59 1.354-2.866 2.887-2.821z"
              ></path>
            </g>
            <mask
              id="mask65_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.212 10.537h5.94v5.641h-5.94v-5.641z"
              ></path>
            </mask>
            <g mask="url(#mask65_36449_620208)">
              <path
                fill="#A52D00"
                d="M20.099 10.538c1.593.048 3.054 1.03 3.054 2.587 0 1.558-1.46 3.054-3.054 3.054-1.594 0-2.929-1.264-2.886-2.82.043-1.59 1.354-2.866 2.886-2.82z"
              ></path>
            </g>
            <mask
              id="mask66_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.22 10.544h5.942v5.64h-5.941v-5.64z"
              ></path>
            </mask>
            <g mask="url(#mask66_36449_620208)">
              <path
                fill="#A82E00"
                d="M20.107 10.545c1.592.048 3.055 1.025 3.055 2.582s-1.462 3.057-3.055 3.057c-1.593 0-2.929-1.263-2.885-2.82.045-1.589 1.355-2.865 2.885-2.819z"
              ></path>
            </g>
            <mask
              id="mask67_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.227 10.551h5.942v5.638h-5.942v-5.638z"
              ></path>
            </mask>
            <g mask="url(#mask67_36449_620208)">
              <path
                fill="#AA2E00"
                d="M20.111 10.553c1.592.048 3.058 1.02 3.058 2.576 0 1.557-1.465 3.06-3.058 3.06-1.593 0-2.928-1.262-2.884-2.818.045-1.59 1.356-2.865 2.884-2.819z"
              ></path>
            </g>
            <mask
              id="mask68_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.231 10.56h5.944v5.636h-5.944v-5.637z"
              ></path>
            </mask>
            <g mask="url(#mask68_36449_620208)">
              <path
                fill="#AD2F00"
                d="M20.116 10.56c1.591.05 3.06 1.016 3.06 2.573 0 1.556-1.468 3.062-3.06 3.062-1.593 0-2.928-1.262-2.883-2.817.045-1.59 1.356-2.865 2.883-2.818z"
              ></path>
            </g>
            <mask
              id="mask69_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.236 10.567h5.945v5.635h-5.945v-5.635z"
              ></path>
            </mask>
            <g mask="url(#mask69_36449_620208)">
              <path
                fill="#B03000"
                d="M20.12 10.569c1.59.05 3.061 1.011 3.061 2.568 0 1.555-1.47 3.065-3.061 3.065-1.592 0-2.928-1.262-2.883-2.817.047-1.59 1.358-2.865 2.883-2.816z"
              ></path>
            </g>
            <mask
              id="mask70_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.241 10.575h5.946v5.633h-5.946v-5.633z"
              ></path>
            </mask>
            <g mask="url(#mask70_36449_620208)">
              <path
                fill="#B33100"
                d="M20.124 10.576c1.59.052 3.063 1.008 3.063 2.563 0 1.556-1.471 3.07-3.063 3.07s-2.928-1.262-2.882-2.817c.048-1.59 1.358-2.864 2.882-2.816z"
              ></path>
            </g>
            <mask
              id="mask71_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.247 10.583h5.947v5.631h-5.947v-5.631z"
              ></path>
            </mask>
            <g mask="url(#mask71_36449_620208)">
              <path
                fill="#B53100"
                d="M20.13 10.584c1.59.053 3.064 1.004 3.064 2.559 0 1.554-1.474 3.071-3.065 3.071-1.59 0-2.928-1.26-2.88-2.814.048-1.59 1.358-2.865 2.88-2.816z"
              ></path>
            </g>
            <mask
              id="mask72_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.256 10.59h5.948v5.63h-5.948v-5.63z"
              ></path>
            </mask>
            <g mask="url(#mask72_36449_620208)">
              <path
                fill="#B83200"
                d="M20.136 10.591c1.59.053 3.068 1 3.068 2.553 0 1.555-1.478 3.076-3.068 3.076s-2.927-1.26-2.88-2.815c.05-1.59 1.36-2.864 2.88-2.814z"
              ></path>
            </g>
            <mask
              id="mask73_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.262 10.597h5.95v5.629h-5.95v-5.629z"
              ></path>
            </mask>
            <g mask="url(#mask73_36449_620208)">
              <path
                fill="#B30"
                d="M23.211 13.148c0 1.554-1.48 3.078-3.07 3.078-1.589 0-2.927-1.26-2.878-2.813.05-1.59 1.36-2.865 2.879-2.814 1.59.054 3.07.995 3.07 2.549z"
              ></path>
            </g>
            <mask
              id="mask74_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.263 10.604h5.946v5.628h-5.946v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask74_36449_620208)">
              <path
                fill="#BC3604"
                d="M20.144 10.605c1.588.053 3.066.999 3.066 2.553 0 1.554-1.476 3.074-3.066 3.074-1.59 0-2.927-1.26-2.88-2.813.05-1.59 1.36-2.864 2.88-2.814z"
                opacity="0.985"
              ></path>
            </g>
            <mask
              id="mask75_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.269 10.61h5.943v5.628H17.27V10.61z"
              ></path>
            </mask>
            <g mask="url(#mask75_36449_620208)">
              <path
                fill="#BD3908"
                d="M20.15 10.61c1.589.053 3.063 1.003 3.063 2.557 0 1.555-1.473 3.07-3.064 3.07-1.59 0-2.925-1.26-2.878-2.813.048-1.59 1.357-2.863 2.878-2.813z"
                opacity="0.971"
              ></path>
            </g>
            <mask
              id="mask76_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.27 10.615h5.942v5.628H17.27v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask76_36449_620208)">
              <path
                fill="#BE3C0B"
                d="M20.151 10.616c1.59.052 3.061 1.007 3.061 2.56 0 1.555-1.47 3.068-3.06 3.068s-2.926-1.26-2.88-2.814c.048-1.589 1.357-2.862 2.88-2.814z"
                opacity="0.956"
              ></path>
            </g>
            <mask
              id="mask77_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.276 10.62h5.939v5.629h-5.939v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask77_36449_620208)">
              <path
                fill="#BF3F0F"
                d="M20.157 10.622c1.59.05 3.058 1.01 3.058 2.564s-1.468 3.063-3.058 3.063c-1.59 0-2.924-1.26-2.878-2.814.046-1.588 1.355-2.861 2.878-2.813z"
                opacity="0.941"
              ></path>
            </g>
            <mask
              id="mask78_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.28 10.627h5.936v5.628H17.28v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask78_36449_620208)">
              <path
                fill="#C04213"
                d="M20.16 10.628c1.59.05 3.056 1.014 3.056 2.568s-1.465 3.059-3.055 3.059-2.924-1.26-2.879-2.814c.046-1.587 1.354-2.86 2.879-2.813z"
                opacity="0.926"
              ></path>
            </g>
            <mask
              id="mask79_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.284 10.633h5.933v5.628h-5.933v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask79_36449_620208)">
              <path
                fill="#C14517"
                d="M20.164 10.634c1.59.049 3.052 1.018 3.052 2.572s-1.462 3.055-3.052 3.055-2.923-1.26-2.879-2.814c.045-1.587 1.354-2.86 2.879-2.813z"
                opacity="0.912"
              ></path>
            </g>
            <mask
              id="mask80_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.29 10.639h5.929v5.628h-5.93V10.64z"
              ></path>
            </mask>
            <g mask="url(#mask80_36449_620208)">
              <path
                fill="#C2481A"
                d="M20.169 10.64c1.59.048 3.05 1.022 3.05 2.576s-1.46 3.051-3.05 3.051c-1.59 0-2.922-1.26-2.879-2.814.044-1.586 1.352-2.86 2.879-2.813z"
                opacity="0.897"
              ></path>
            </g>
            <mask
              id="mask81_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.291 10.644h5.927v5.629H17.29v-5.629z"
              ></path>
            </mask>
            <g mask="url(#mask81_36449_620208)">
              <path
                fill="#C34B1E"
                d="M20.172 10.646c1.59.047 3.046 1.025 3.046 2.58 0 1.554-1.456 3.047-3.046 3.047-1.59 0-2.922-1.26-2.879-2.814.044-1.585 1.351-2.859 2.879-2.813z"
                opacity="0.882"
              ></path>
            </g>
            <mask
              id="mask82_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.298 10.65h5.924v5.628h-5.924V10.65z"
              ></path>
            </mask>
            <g mask="url(#mask82_36449_620208)">
              <path
                fill="#C44E22"
                d="M20.177 10.651c1.59.047 3.044 1.03 3.044 2.584s-1.453 3.043-3.044 3.043c-1.59 0-2.92-1.26-2.878-2.814.043-1.585 1.35-2.858 2.878-2.813z"
                opacity="0.868"
              ></path>
            </g>
            <mask
              id="mask83_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.299 10.656h5.921v5.628h-5.921v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask83_36449_620208)">
              <path
                fill="#C55126"
                d="M20.18 10.657c1.589.047 3.04 1.035 3.04 2.588 0 1.554-1.45 3.04-3.04 3.04s-2.92-1.26-2.88-2.814c.043-1.585 1.35-2.858 2.88-2.814z"
                opacity="0.853"
              ></path>
            </g>
            <mask
              id="mask84_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.305 10.662h5.918v5.628h-5.918v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask84_36449_620208)">
              <path
                fill="#C65429"
                d="M20.184 10.663c1.59.045 3.038 1.038 3.038 2.592s-1.448 3.036-3.038 3.036c-1.59 0-2.92-1.261-2.878-2.814.041-1.585 1.347-2.857 2.878-2.814z"
                opacity="0.838"
              ></path>
            </g>
            <mask
              id="mask85_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.307 10.668h5.915v5.627h-5.915v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask85_36449_620208)">
              <path
                fill="#C7572D"
                d="M20.186 10.669c1.59.044 3.035 1.041 3.035 2.595 0 1.554-1.445 3.032-3.035 3.032s-2.919-1.26-2.878-2.814c.04-1.583 1.347-2.856 2.878-2.813z"
                opacity="0.824"
              ></path>
            </g>
            <mask
              id="mask86_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.313 10.674h5.912v5.628h-5.913v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask86_36449_620208)">
              <path
                fill="#C85A31"
                d="M20.193 10.675c1.59.043 3.033 1.045 3.033 2.6 0 1.553-1.443 3.027-3.033 3.027-1.59 0-2.918-1.26-2.879-2.814.04-1.582 1.346-2.854 2.879-2.813z"
                opacity="0.809"
              ></path>
            </g>
            <mask
              id="mask87_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.316 10.68h5.91v5.628h-5.91V10.68z"
              ></path>
            </mask>
            <g mask="url(#mask87_36449_620208)">
              <path
                fill="#C95D35"
                d="M20.196 10.68c1.59.043 3.03 1.05 3.03 2.604s-1.44 3.024-3.03 3.024c-1.59 0-2.917-1.26-2.879-2.814.04-1.583 1.345-2.854 2.88-2.813z"
                opacity="0.794"
              ></path>
            </g>
            <mask
              id="mask88_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.32 10.686h5.907v5.628H17.32v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask88_36449_620208)">
              <path
                fill="#CA6038"
                d="M20.2 10.687c1.59.042 3.027 1.053 3.027 2.607 0 1.554-1.437 3.02-3.027 3.02s-2.917-1.26-2.879-2.814c.039-1.582 1.344-2.853 2.879-2.813z"
                opacity="0.779"
              ></path>
            </g>
            <mask
              id="mask89_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.325 10.691h5.904v5.628h-5.904v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask89_36449_620208)">
              <path
                fill="#CB633C"
                d="M20.205 10.693c1.59.04 3.024 1.057 3.024 2.61 0 1.554-1.434 3.017-3.024 3.017s-2.916-1.26-2.879-2.814c.038-1.582 1.343-2.853 2.879-2.813z"
                opacity="0.765"
              ></path>
            </g>
            <mask
              id="mask90_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.328 10.697h5.902v5.628h-5.902v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask90_36449_620208)">
              <path
                fill="#CC6640"
                d="M20.208 10.698c1.59.04 3.021 1.061 3.021 2.615 0 1.554-1.431 3.012-3.021 3.012-1.59 0-2.916-1.26-2.88-2.814.038-1.58 1.343-2.851 2.88-2.813z"
                opacity="0.75"
              ></path>
            </g>
            <mask
              id="mask91_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.334 10.703h5.899v5.628h-5.899v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask91_36449_620208)">
              <path
                fill="#CD6944"
                d="M20.213 10.703c1.59.04 3.02 1.065 3.02 2.62 0 1.553-1.43 3.008-3.02 3.008s-2.914-1.261-2.878-2.814c.036-1.581 1.34-2.851 2.878-2.814z"
                opacity="0.735"
              ></path>
            </g>
            <mask
              id="mask92_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.335 10.709h5.896v5.627h-5.896V10.71z"
              ></path>
            </mask>
            <g mask="url(#mask92_36449_620208)">
              <path
                fill="#CE6C47"
                d="M20.214 10.71c1.59.038 3.016 1.068 3.016 2.622 0 1.554-1.425 3.004-3.016 3.004-1.59 0-2.913-1.26-2.878-2.813.035-1.58 1.34-2.85 2.878-2.814z"
                opacity="0.721"
              ></path>
            </g>
            <mask
              id="mask93_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.342 10.715h5.893v5.627h-5.893v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask93_36449_620208)">
              <path
                fill="#CF6F4B"
                d="M20.221 10.716c1.59.037 3.014 1.072 3.014 2.626 0 1.554-1.423 3-3.014 3-1.59 0-2.913-1.26-2.878-2.813.035-1.58 1.338-2.85 2.878-2.813z"
                opacity="0.706"
              ></path>
            </g>
            <mask
              id="mask94_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.343 10.72h5.89v5.628h-5.89V10.72z"
              ></path>
            </mask>
            <g mask="url(#mask94_36449_620208)">
              <path
                fill="#D0724F"
                d="M20.222 10.72c1.59.037 3.01 1.077 3.01 2.631 0 1.554-1.42 2.997-3.01 2.997-1.59 0-2.912-1.26-2.879-2.814.035-1.579 1.338-2.85 2.88-2.814z"
                opacity="0.691"
              ></path>
            </g>
            <mask
              id="mask95_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.349 10.726h5.887v5.628h-5.887v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask95_36449_620208)">
              <path
                fill="#D17553"
                d="M20.228 10.727c1.59.036 3.007 1.08 3.007 2.634 0 1.554-1.417 2.993-3.007 2.993s-2.912-1.26-2.879-2.814c.034-1.578 1.337-2.848 2.879-2.813z"
                opacity="0.676"
              ></path>
            </g>
            <mask
              id="mask96_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.35 10.732h5.885v5.627h-5.884v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask96_36449_620208)">
              <path
                fill="#D27856"
                d="M20.23 10.733c1.59.035 3.004 1.084 3.004 2.638 0 1.554-1.414 2.989-3.004 2.989s-2.911-1.26-2.879-2.814c.033-1.578 1.336-2.848 2.879-2.813z"
                opacity="0.662"
              ></path>
            </g>
            <mask
              id="mask97_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.356 10.738h5.882v5.628h-5.882v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask97_36449_620208)">
              <path
                fill="#D37B5A"
                d="M20.236 10.739c1.59.035 3.002 1.089 3.002 2.642 0 1.554-1.412 2.985-3.002 2.985-1.59 0-2.91-1.26-2.879-2.814.032-1.577 1.334-2.846 2.879-2.813z"
                opacity="0.647"
              ></path>
            </g>
            <mask
              id="mask98_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.361 10.744h5.879v5.628h-5.879v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask98_36449_620208)">
              <path
                fill="#D47E5E"
                d="M20.24 10.745c1.59.034 3 1.092 3 2.646 0 1.554-1.41 2.981-3 2.981s-2.909-1.26-2.878-2.814c.031-1.576 1.333-2.846 2.879-2.813z"
                opacity="0.632"
              ></path>
            </g>
            <mask
              id="mask99_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.364 10.75h5.876v5.627h-5.876V10.75z"
              ></path>
            </mask>
            <g mask="url(#mask99_36449_620208)">
              <path
                fill="#D58162"
                d="M20.244 10.75c1.589.034 2.996 1.097 2.996 2.65 0 1.554-1.406 2.978-2.996 2.978s-2.91-1.26-2.88-2.814c.031-1.576 1.333-2.845 2.88-2.813z"
                opacity="0.618"
              ></path>
            </g>
            <mask
              id="mask100_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.37 10.756h5.873v5.627H17.37v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask100_36449_620208)">
              <path
                fill="#D68465"
                d="M20.25 10.757c1.589.032 2.993 1.1 2.993 2.654 0 1.554-1.403 2.973-2.994 2.973-1.59 0-2.908-1.26-2.878-2.814.03-1.576 1.331-2.844 2.878-2.813z"
                opacity="0.603"
              ></path>
            </g>
            <mask
              id="mask101_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.371 10.762h5.87v5.627h-5.87v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask101_36449_620208)">
              <path
                fill="#D78769"
                d="M20.25 10.762c1.59.032 2.991 1.104 2.991 2.659 0 1.553-1.4 2.969-2.99 2.969-1.591 0-2.908-1.26-2.88-2.814.03-1.575 1.331-2.844 2.88-2.814z"
                opacity="0.588"
              ></path>
            </g>
            <mask
              id="mask102_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.378 10.767h5.867v5.628h-5.867v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask102_36449_620208)">
              <path
                fill="#D88A6D"
                d="M20.257 10.768c1.59.03 2.988 1.107 2.988 2.661s-1.398 2.966-2.988 2.966-2.907-1.26-2.879-2.814c.029-1.574 1.33-2.842 2.88-2.813z"
                opacity="0.574"
              ></path>
            </g>
            <mask
              id="mask103_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.379 10.773h5.865V16.4h-5.865v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask103_36449_620208)">
              <path
                fill="#D98D71"
                d="M20.258 10.774c1.59.03 2.985 1.111 2.985 2.666 0 1.553-1.395 2.96-2.985 2.96-1.59 0-2.906-1.26-2.879-2.813.027-1.574 1.329-2.842 2.879-2.813z"
                opacity="0.559"
              ></path>
            </g>
            <mask
              id="mask104_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.386 10.779h5.861v5.627h-5.861V10.78z"
              ></path>
            </mask>
            <g mask="url(#mask104_36449_620208)">
              <path
                fill="#DA9074"
                d="M20.265 10.779c1.59.03 2.982 1.116 2.982 2.67 0 1.553-1.392 2.957-2.982 2.957-1.59 0-2.905-1.26-2.879-2.814.027-1.573 1.327-2.84 2.879-2.813z"
                opacity="0.544"
              ></path>
            </g>
            <mask
              id="mask105_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.387 10.785h5.859v5.627h-5.86v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask105_36449_620208)">
              <path
                fill="#DB9378"
                d="M20.267 10.785c1.589.029 2.979 1.12 2.979 2.674 0 1.554-1.39 2.954-2.98 2.954s-2.904-1.261-2.878-2.814c.026-1.573 1.326-2.84 2.878-2.814z"
                opacity="0.529"
              ></path>
            </g>
            <mask
              id="mask106_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.393 10.79h5.856v5.628h-5.856v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask106_36449_620208)">
              <path
                fill="#DC967C"
                d="M20.272 10.791c1.59.028 2.976 1.124 2.976 2.677 0 1.554-1.386 2.95-2.976 2.95s-2.904-1.26-2.88-2.813c.026-1.573 1.326-2.84 2.88-2.814z"
                opacity="0.515"
              ></path>
            </g>
            <mask
              id="mask107_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.395 10.797h5.854v5.627h-5.854v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask107_36449_620208)">
              <path
                fill="#DD997F"
                d="M20.274 10.797c1.59.027 2.974 1.127 2.974 2.681s-1.383 2.946-2.974 2.946c-1.59 0-2.902-1.26-2.878-2.814.024-1.571 1.324-2.839 2.878-2.813z"
                opacity="0.5"
              ></path>
            </g>
            <mask
              id="mask108_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path fill="#fff" d="M17.4 10.802h5.85v5.628H17.4v-5.628z"></path>
            </mask>
            <g mask="url(#mask108_36449_620208)">
              <path
                fill="#DE9C83"
                d="M20.28 10.803c1.589.026 2.97 1.131 2.97 2.685s-1.38 2.942-2.97 2.942c-1.59 0-2.903-1.26-2.88-2.814.024-1.571 1.324-2.838 2.88-2.813z"
                opacity="0.485"
              ></path>
            </g>
            <mask
              id="mask109_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.406 10.808h5.848v5.628h-5.848v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask109_36449_620208)">
              <path
                fill="#DF9F87"
                d="M20.285 10.809c1.59.025 2.969 1.135 2.969 2.689s-1.379 2.938-2.969 2.938c-1.59 0-2.901-1.26-2.878-2.814.022-1.57 1.322-2.838 2.878-2.813z"
                opacity="0.471"
              ></path>
            </g>
            <mask
              id="mask110_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.408 10.814h5.845v5.627h-5.845v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask110_36449_620208)">
              <path
                fill="#E0A28B"
                d="M20.287 10.815c1.59.024 2.966 1.139 2.966 2.693 0 1.553-1.376 2.934-2.966 2.934-1.59 0-2.9-1.26-2.878-2.814.022-1.57 1.32-2.837 2.878-2.813z"
                opacity="0.456"
              ></path>
            </g>
            <mask
              id="mask111_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.414 10.82h5.842v5.627h-5.842V10.82z"
              ></path>
            </mask>
            <g mask="url(#mask111_36449_620208)">
              <path
                fill="#E1A58E"
                d="M20.293 10.82c1.59.023 2.963 1.143 2.963 2.696 0 1.554-1.373 2.93-2.963 2.93s-2.9-1.26-2.879-2.813c.022-1.57 1.32-2.836 2.879-2.813z"
                opacity="0.441"
              ></path>
            </g>
            <mask
              id="mask112_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.416 10.826h5.84v5.627h-5.84v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask112_36449_620208)">
              <path
                fill="#E2A892"
                d="M20.295 10.826c1.59.023 2.96 1.147 2.96 2.7 0 1.555-1.37 2.927-2.96 2.927-1.59 0-2.9-1.26-2.879-2.814a2.855 2.855 0 012.879-2.813z"
                opacity="0.426"
              ></path>
            </g>
            <mask
              id="mask113_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.422 10.831h5.836v5.628h-5.836V10.83z"
              ></path>
            </mask>
            <g mask="url(#mask113_36449_620208)">
              <path
                fill="#E3AB96"
                d="M20.3 10.832c1.59.022 2.958 1.15 2.958 2.704 0 1.554-1.367 2.923-2.957 2.923-1.59 0-2.899-1.26-2.879-2.814a2.854 2.854 0 012.879-2.813z"
                opacity="0.412"
              ></path>
            </g>
            <mask
              id="mask114_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.424 10.837h5.833v5.627h-5.833v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask114_36449_620208)">
              <path
                fill="#E4AE9A"
                d="M20.303 10.837c1.59.022 2.954 1.155 2.954 2.709s-1.364 2.918-2.954 2.918-2.898-1.26-2.879-2.814a2.855 2.855 0 012.879-2.813z"
                opacity="0.397"
              ></path>
            </g>
            <mask
              id="mask115_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.43 10.844h5.83v5.627h-5.83v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask115_36449_620208)">
              <path
                fill="#E5B19D"
                d="M20.309 10.844c1.59.02 2.951 1.159 2.951 2.712 0 1.554-1.361 2.915-2.951 2.915s-2.898-1.26-2.88-2.814a2.854 2.854 0 012.88-2.813z"
                opacity="0.382"
              ></path>
            </g>
            <mask
              id="mask116_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.432 10.85h5.828v5.627h-5.828V10.85z"
              ></path>
            </mask>
            <g mask="url(#mask116_36449_620208)">
              <path
                fill="#E6B4A1"
                d="M20.31 10.85c1.59.02 2.95 1.162 2.95 2.716 0 1.554-1.36 2.911-2.95 2.911s-2.896-1.26-2.878-2.814a2.854 2.854 0 012.879-2.813z"
                opacity="0.368"
              ></path>
            </g>
            <mask
              id="mask117_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.436 10.855h5.826v5.627h-5.826v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask117_36449_620208)">
              <path
                fill="#E7B7A5"
                d="M20.315 10.856c1.59.019 2.946 1.166 2.946 2.72 0 1.553-1.355 2.907-2.946 2.907-1.59 0-2.896-1.26-2.878-2.814a2.853 2.853 0 012.878-2.813z"
                opacity="0.353"
              ></path>
            </g>
            <mask
              id="mask118_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.44 10.86h5.823v5.628H17.44V10.86z"
              ></path>
            </mask>
            <g mask="url(#mask118_36449_620208)">
              <path
                fill="#E8BAA9"
                d="M20.319 10.861c1.59.018 2.943 1.17 2.943 2.724 0 1.554-1.353 2.903-2.943 2.903s-2.895-1.26-2.879-2.814a2.853 2.853 0 012.879-2.813z"
                opacity="0.338"
              ></path>
            </g>
            <mask
              id="mask119_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.444 10.867h5.82v5.627h-5.82v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask119_36449_620208)">
              <path
                fill="#E9BDAC"
                d="M20.323 10.867c1.59.017 2.94 1.174 2.94 2.728 0 1.554-1.35 2.9-2.94 2.9-1.59 0-2.894-1.26-2.878-2.815a2.853 2.853 0 012.878-2.813z"
                opacity="0.324"
              ></path>
            </g>
            <mask
              id="mask120_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.448 10.872h5.817V16.5h-5.817v-5.628z"
              ></path>
            </mask>
            <g mask="url(#mask120_36449_620208)">
              <path
                fill="#EAC0B0"
                d="M20.327 10.872c1.59.017 2.937 1.178 2.937 2.732 0 1.554-1.347 2.896-2.937 2.896-1.59 0-2.894-1.26-2.879-2.814a2.853 2.853 0 012.879-2.814z"
                opacity="0.309"
              ></path>
            </g>
            <mask
              id="mask121_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.452 10.878h5.814v5.627h-5.814v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask121_36449_620208)">
              <path
                fill="#EBC3B4"
                d="M20.331 10.878c1.59.016 2.935 1.182 2.935 2.736 0 1.554-1.345 2.891-2.935 2.891s-2.893-1.26-2.879-2.814a2.852 2.852 0 012.879-2.813z"
                opacity="0.294"
              ></path>
            </g>
            <mask
              id="mask122_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.458 10.884h5.811v5.627h-5.811v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask122_36449_620208)">
              <path
                fill="#ECC6B8"
                d="M20.337 10.884c1.59.015 2.932 1.186 2.932 2.74 0 1.553-1.342 2.887-2.932 2.887-1.59 0-2.892-1.26-2.879-2.814a2.852 2.852 0 012.879-2.813z"
                opacity="0.279"
              ></path>
            </g>
            <mask
              id="mask123_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.46 10.89h5.808v5.627H17.46V10.89z"
              ></path>
            </mask>
            <g mask="url(#mask123_36449_620208)">
              <path
                fill="#EDC9BB"
                d="M20.339 10.89c1.59.014 2.93 1.19 2.93 2.744 0 1.554-1.34 2.884-2.93 2.884s-2.892-1.26-2.879-2.814c.013-1.564 1.308-2.827 2.879-2.814z"
                opacity="0.265"
              ></path>
            </g>
            <mask
              id="mask124_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.466 10.896h5.805v5.627h-5.805v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask124_36449_620208)">
              <path
                fill="#EECCBF"
                d="M20.345 10.896c1.59.013 2.926 1.193 2.926 2.747 0 1.554-1.336 2.88-2.926 2.88s-2.891-1.26-2.88-2.814c.013-1.563 1.307-2.826 2.88-2.813z"
                opacity="0.25"
              ></path>
            </g>
            <mask
              id="mask125_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.468 10.902h5.802v5.627h-5.802v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask125_36449_620208)">
              <path
                fill="#EFCFC3"
                d="M20.346 10.902c1.59.013 2.924 1.198 2.924 2.751 0 1.554-1.333 2.876-2.924 2.876-1.59 0-2.89-1.26-2.878-2.814.011-1.562 1.305-2.825 2.878-2.813z"
                opacity="0.235"
              ></path>
            </g>
            <mask
              id="mask126_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.475 10.908h5.8v5.627h-5.8v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask126_36449_620208)">
              <path
                fill="#F0D2C7"
                d="M20.353 10.908c1.59.012 2.921 1.201 2.921 2.755 0 1.554-1.33 2.872-2.92 2.872-1.59 0-2.89-1.26-2.88-2.814.011-1.562 1.305-2.824 2.88-2.813z"
                opacity="0.221"
              ></path>
            </g>
            <mask
              id="mask127_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.477 10.914h5.796v5.627h-5.796v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask127_36449_620208)">
              <path
                fill="#F1D5CA"
                d="M20.355 10.914c1.59.011 2.918 1.205 2.918 2.759 0 1.554-1.328 2.868-2.918 2.868s-2.889-1.26-2.879-2.814c.01-1.561 1.304-2.824 2.88-2.813z"
                opacity="0.206"
              ></path>
            </g>
            <mask
              id="mask128_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.479 10.919h5.794v5.627h-5.794v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask128_36449_620208)">
              <path
                fill="#F2D8CE"
                d="M20.357 10.919c1.59.01 2.915 1.209 2.915 2.763 0 1.554-1.324 2.864-2.915 2.864-1.59 0-2.888-1.26-2.878-2.814.009-1.56 1.302-2.823 2.878-2.813z"
                opacity="0.191"
              ></path>
            </g>
            <mask
              id="mask129_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.484 10.925h5.792v5.627h-5.792v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask129_36449_620208)">
              <path
                fill="#F3DBD2"
                d="M20.363 10.925c1.59.01 2.912 1.213 2.912 2.766 0 1.554-1.322 2.861-2.912 2.861-1.59 0-2.887-1.26-2.879-2.814.009-1.56 1.301-2.822 2.879-2.813z"
                opacity="0.176"
              ></path>
            </g>
            <mask
              id="mask130_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.486 10.932h5.789v5.627h-5.789v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask130_36449_620208)">
              <path
                fill="#F4DED6"
                d="M20.365 10.931c1.59.009 2.91 1.217 2.91 2.771 0 1.554-1.32 2.857-2.91 2.857s-2.886-1.26-2.879-2.814c.008-1.56 1.3-2.822 2.88-2.814z"
                opacity="0.162"
              ></path>
            </g>
            <mask
              id="mask131_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.492 10.937h5.786v5.627h-5.786v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask131_36449_620208)">
              <path
                fill="#F5E1D9"
                d="M20.37 10.937c1.59.007 2.907 1.22 2.907 2.775 0 1.553-1.316 2.852-2.906 2.852s-2.886-1.26-2.879-2.814c.007-1.559 1.3-2.82 2.879-2.813z"
                opacity="0.147"
              ></path>
            </g>
            <mask
              id="mask132_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.496 10.942h5.783v5.627h-5.783v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask132_36449_620208)">
              <path
                fill="#F6E4DD"
                d="M20.375 10.942c1.59.007 2.904 1.224 2.904 2.778s-1.314 2.85-2.904 2.85c-1.59 0-2.885-1.26-2.879-2.815.007-1.558 1.299-2.82 2.88-2.813z"
                opacity="0.132"
              ></path>
            </g>
            <mask
              id="mask133_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path fill="#fff" d="M17.5 10.948h5.78v5.627H17.5v-5.627z"></path>
            </mask>
            <g mask="url(#mask133_36449_620208)">
              <path
                fill="#F7E7E1"
                d="M20.379 10.948c1.59.007 2.9 1.229 2.9 2.783 0 1.553-1.31 2.845-2.9 2.845-1.59 0-2.885-1.26-2.879-2.814.006-1.558 1.297-2.82 2.879-2.814z"
                opacity="0.118"
              ></path>
            </g>
            <mask
              id="mask134_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.504 10.954h5.777v5.627h-5.777v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask134_36449_620208)">
              <path
                fill="#F8EAE5"
                d="M20.383 10.954c1.59.006 2.899 1.233 2.899 2.786 0 1.554-1.309 2.841-2.9 2.841-1.59 0-2.883-1.26-2.878-2.813.005-1.558 1.296-2.82 2.879-2.814z"
                opacity="0.103"
              ></path>
            </g>
            <mask
              id="mask135_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.51 10.96h5.775v5.627h-5.774V10.96z"
              ></path>
            </mask>
            <g mask="url(#mask135_36449_620208)">
              <path
                fill="#F9EDE8"
                d="M20.39 10.96c1.59.005 2.895 1.236 2.895 2.79 0 1.555-1.305 2.837-2.896 2.837-1.59 0-2.883-1.26-2.878-2.814.004-1.556 1.295-2.817 2.878-2.813z"
                opacity="0.088"
              ></path>
            </g>
            <mask
              id="mask136_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.513 10.966h5.771v5.627h-5.771v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask136_36449_620208)">
              <path
                fill="#FAF0EC"
                d="M20.391 10.966c1.59.004 2.893 1.24 2.893 2.794s-1.302 2.833-2.893 2.833c-1.59 0-2.882-1.26-2.878-2.814.003-1.556 1.294-2.816 2.878-2.813z"
                opacity="0.074"
              ></path>
            </g>
            <mask
              id="mask137_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.515 10.972h5.769v5.627h-5.77v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask137_36449_620208)">
              <path
                fill="#FBF3F0"
                d="M20.393 10.972c1.59.003 2.89 1.244 2.89 2.798s-1.3 2.83-2.89 2.83-2.881-1.26-2.878-2.815c.002-1.555 1.292-2.816 2.878-2.813z"
                opacity="0.059"
              ></path>
            </g>
            <mask
              id="mask138_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.521 10.978h5.767v5.627H17.52v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask138_36449_620208)">
              <path
                fill="#FCF6F4"
                d="M20.4 10.977c1.59.002 2.888 1.248 2.888 2.802s-1.298 2.826-2.888 2.826c-1.59 0-2.88-1.26-2.878-2.815.002-1.555 1.292-2.815 2.878-2.813z"
                opacity="0.044"
              ></path>
            </g>
            <mask
              id="mask139_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.523 10.983h5.763v5.627h-5.764v-5.627z"
              ></path>
            </mask>
            <g mask="url(#mask139_36449_620208)">
              <path
                fill="#FDF9F7"
                d="M20.401 10.983c1.59.002 2.885 1.252 2.885 2.806S21.99 16.61 20.4 16.61c-1.59 0-2.88-1.26-2.878-2.814 0-1.554 1.29-2.814 2.878-2.813z"
                opacity="0.029"
              ></path>
            </g>
            <mask
              id="mask140_36449_620208"
              style={{ maskType: "luminance" }}
              width="7"
              height="7"
              x="17"
              y="10"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M17.528 10.99h5.76v5.626h-5.76V10.99z"
              ></path>
            </mask>
            <g mask="url(#mask140_36449_620208)">
              <path
                fill="#FEFCFB"
                d="M20.407 10.99c1.59 0 2.881 1.255 2.881 2.81 0 1.553-1.291 2.817-2.881 2.817-1.59 0-2.88-1.26-2.879-2.814 0-1.554 1.29-2.814 2.879-2.814z"
                opacity="0.015"
              ></path>
            </g>
          </g>
        </g>
      </g>
      <mask
        id="mask141_36449_620208"
        style={{ maskType: "luminance" }}
        width="40"
        height="26"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M1.317.5C.59.5 0 1.077 0 1.788v22.425c0 .711.59 1.287 1.317 1.287h37.366c.728 0 1.317-.576 1.317-1.287V1.788C40 1.078 39.41.5 38.683.5H1.317zM.44 24.213V1.788A.87.87 0 011.317.93H25.68C32.502.93 38.032 6.334 38.032 13c0 6.667-5.53 12.071-12.352 12.071H1.317a.869.869 0 01-.878-.858z"
        ></path>
      </mask>
      <g mask="url(#mask141_36449_620208)">
        <path
          fill="#E04E15"
          d="M11.33 44.677L-12.398 4.512 28.7-18.677l23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#E04F15"
          d="M11.824 45.513l-23.73-40.165 41.1-23.19 23.729 40.166-41.1 23.19z"
        ></path>
        <path
          fill="#E15015"
          d="M12.318 46.35l-23.73-40.165 41.1-23.19L53.417 23.16l-41.1 23.19z"
        ></path>
        <path
          fill="#E15115"
          d="M12.813 47.185L-10.916 7.021l41.099-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#E25215"
          d="M13.306 48.022L-10.423 7.857l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#E25316"
          d="M13.8 48.858L-9.929 8.693l41.1-23.19 23.728 40.165L13.8 48.858z"
        ></path>
        <path
          fill="#E35416"
          d="M14.294 49.694L-9.435 9.529l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#E35516"
          d="M14.788 50.53L-8.94 10.365l41.099-23.189 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#E35616"
          d="M15.282 51.366l-23.73-40.165 41.1-23.189L56.38 28.177l-41.1 23.19z"
        ></path>
        <path
          fill="#E45716"
          d="M15.776 52.203l-23.73-40.165 41.1-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#E45716"
          d="M16.27 53.039L-7.457 12.874l41.099-23.19L57.37 29.85l-41.1 23.19z"
        ></path>
        <path
          fill="#E55816"
          d="M16.764 53.874L-6.965 13.71l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#E55916"
          d="M17.258 54.71L-6.47 14.547l41.1-23.19 23.728 40.166L17.258 54.71z"
        ></path>
        <path
          fill="#E55A16"
          d="M17.751 55.547L-5.978 15.382l41.1-23.19L58.85 32.358l-41.099 23.19z"
        ></path>
        <path
          fill="#E65B16"
          d="M18.246 56.383L-5.482 16.218 35.617-6.97l23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#E65C17"
          d="M18.74 57.22L-4.987 17.054 36.11-6.135 59.84 34.03l-41.1 23.19z"
        ></path>
        <path
          fill="#E75D17"
          d="M19.234 58.056L-4.496 17.89l41.1-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#E75E17"
          d="M19.728 58.892L-4.001 18.727l41.099-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#E85F17"
          d="M20.221 59.728L-3.508 19.563l41.1-23.19L61.32 36.54 20.221 59.727z"
        ></path>
        <path
          fill="#E86017"
          d="M20.716 60.564L-3.013 20.399l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#E86117"
          d="M20.907 60.887l-23.73-40.165 41.1-23.189 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#E96217"
          d="M21.098 61.21L-2.631 21.046l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#E96317"
          d="M21.288 61.534L-2.44 21.369l41.099-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#E96418"
          d="M21.479 61.857L-2.25 21.692l41.099-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#E96518"
          d="M21.671 62.18L-2.058 22.015l41.1-23.19L62.77 38.992 21.671 62.18z"
        ></path>
        <path
          fill="#EA6618"
          d="M21.862 62.503L-1.868 22.34l41.1-23.19L62.96 39.314l-41.1 23.19z"
        ></path>
        <path
          fill="#EA6718"
          d="M22.053 62.827L-1.676 22.662l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#EA6818"
          d="M22.244 63.15l-23.73-40.165 41.1-23.19L63.343 39.96l-41.1 23.19z"
        ></path>
        <path
          fill="#EA6918"
          d="M22.435 63.473L-1.294 23.308 39.805.118l23.729 40.166-41.1 23.19z"
        ></path>
        <path
          fill="#EB6A18"
          d="M22.625 63.796L-1.104 23.632l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#EB6B19"
          d="M22.817 64.12L-.913 23.955l41.1-23.19L63.916 40.93l-41.1 23.19z"
        ></path>
        <path
          fill="#EB6C19"
          d="M23.007 64.443L-.722 24.278l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#EB6D19"
          d="M23.199 64.766L-.531 24.601l41.1-23.19 23.729 40.166-41.1 23.19z"
        ></path>
        <path
          fill="#EC6E19"
          d="M23.39 65.09L-.339 24.924l41.1-23.19L64.488 41.9 23.39 65.09z"
        ></path>
        <path
          fill="#EC6F19"
          d="M23.58 65.413L-.15 25.248l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#EC7019"
          d="M23.772 65.736L.043 25.57l41.099-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#EC7119"
          d="M23.962 66.06L.233 25.893l41.1-23.19L65.06 42.87 23.962 66.06z"
        ></path>
        <path
          fill="#ED7219"
          d="M24.154 66.382L.424 26.218l41.1-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#ED731A"
          d="M24.344 66.706L.615 26.54l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#ED741A"
          d="M24.535 67.029L.807 26.864l41.099-23.19L65.635 43.84l-41.1 23.19z"
        ></path>
        <path
          fill="#ED751A"
          d="M24.726 67.352L.997 27.187l41.1-23.19 23.728 40.166-41.1 23.19z"
        ></path>
        <path
          fill="#EE761A"
          d="M24.917 67.675L1.188 27.511l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#EE771A"
          d="M25.108 67.999L1.378 27.834l41.1-23.19L66.207 44.81 25.107 68z"
        ></path>
        <path
          fill="#EE781A"
          d="M25.298 68.322L1.57 28.157l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#EF791A"
          d="M25.49 68.645L1.763 28.48 42.86 5.29 66.59 45.457l-41.1 23.189z"
        ></path>
        <path
          fill="#EF7A1B"
          d="M25.681 68.968L1.952 28.803l41.1-23.189L66.78 45.779l-41.099 23.19z"
        ></path>
        <path
          fill="#EF7B1B"
          d="M25.872 69.291L2.144 29.127l41.099-23.19L66.97 46.102l-41.099 23.19z"
        ></path>
        <path
          fill="#EF7C1B"
          d="M26.063 69.615L2.334 29.45 43.433 6.26l23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F07D1B"
          d="M26.254 69.938L2.525 29.773l41.1-23.19L67.352 46.75 26.254 69.938z"
        ></path>
        <path
          fill="#F07E1B"
          d="M26.445 70.261L2.715 30.097l41.1-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F07F1B"
          d="M26.636 70.585L2.907 30.42l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F0801B"
          d="M26.826 70.908L3.098 30.743l41.099-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F1801C"
          d="M27.018 71.231L3.289 31.066l41.1-23.19 23.728 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F1811C"
          d="M27.208 71.554L3.48 31.39 44.58 8.2l23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F1821C"
          d="M27.399 71.877L3.669 31.713l41.1-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F1831C"
          d="M27.591 72.2L3.862 32.037l41.1-23.19L68.69 49.011l-41.099 23.19z"
        ></path>
        <path
          fill="#F2841C"
          d="M27.782 72.524L4.052 32.359l41.1-23.19L68.88 49.335l-41.1 23.19z"
        ></path>
        <path
          fill="#F2851C"
          d="M27.973 72.847L4.244 32.682l41.1-23.19 23.728 40.166-41.099 23.19z"
        ></path>
        <path
          fill="#F2861C"
          d="M28.163 73.17L4.435 33.006l41.099-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F2871D"
          d="M28.355 73.494L4.626 33.329l41.099-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F3881D"
          d="M28.545 73.817L4.816 33.652l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F3891D"
          d="M28.737 74.14L5.007 33.975l41.1-23.19 23.729 40.166-41.1 23.19z"
        ></path>
        <path
          fill="#F38A1D"
          d="M28.927 74.463L5.198 34.3l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F48B1D"
          d="M29.119 74.787L5.389 34.622l41.1-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F48C1D"
          d="M29.31 75.11L5.58 34.945l41.099-23.19L70.409 51.92l-41.1 23.19z"
        ></path>
        <path
          fill="#F48D1D"
          d="M29.5 75.433L5.77 35.268l41.1-23.19 23.728 40.166L29.5 75.434z"
        ></path>
        <path
          fill="#F48E1E"
          d="M29.692 75.756L5.962 35.592l41.1-23.19L70.79 52.567l-41.1 23.19z"
        ></path>
        <path
          fill="#F58F1E"
          d="M29.882 76.08L6.153 35.915l41.1-23.19L70.98 52.89 29.882 76.08z"
        ></path>
        <path
          fill="#F5901E"
          d="M30.074 76.403L6.344 36.238l41.1-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F5911E"
          d="M30.264 76.726L6.535 36.561l41.1-23.19 23.728 40.166-41.099 23.19z"
        ></path>
        <path
          fill="#F5921E"
          d="M30.455 77.05L6.727 36.884l41.099-23.19L71.554 53.86 30.455 77.05z"
        ></path>
        <path
          fill="#F6931E"
          d="M30.646 77.373L6.917 37.208l41.099-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F6941E"
          d="M30.837 77.696L7.108 37.53l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F6951E"
          d="M31.028 78.019L7.298 37.854l41.1-23.19L72.127 54.83l-41.1 23.189z"
        ></path>
        <path
          fill="#F6961F"
          d="M31.218 78.342L7.49 38.178l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F7971F"
          d="M31.41 78.665L7.682 38.501 48.78 15.31 72.51 55.476l-41.1 23.19z"
        ></path>
        <path
          fill="#F7981F"
          d="M31.6 78.989L7.873 38.824l41.1-23.19L72.7 55.8 31.6 78.99z"
        ></path>
        <path
          fill="#F7991F"
          d="M31.792 79.312L8.063 39.147l41.1-23.19L72.89 56.124 31.792 79.311z"
        ></path>
        <path
          fill="#F79A1F"
          d="M31.983 79.635L8.253 39.47l41.1-23.189 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F89B1F"
          d="M32.174 79.959L8.445 39.794l41.1-23.19L73.272 56.77l-41.099 23.19z"
        ></path>
        <path
          fill="#F89C1F"
          d="M32.365 80.282L8.635 40.117l41.1-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F89D20"
          d="M32.556 80.605L8.827 40.44l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F89E20"
          d="M32.746 80.928L9.018 40.764l41.099-23.19 23.728 40.165-41.099 23.19z"
        ></path>
        <path
          fill="#F99F20"
          d="M32.938 81.251L9.209 41.087l41.099-23.19 23.729 40.165-41.1 23.19z"
        ></path>
        <path
          fill="#F9A020"
          d="M33.128 81.575L9.4 41.41l41.1-23.19 23.728 40.165-41.099 23.19z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_36449_620208"
          x1="21.259"
          x2="19.331"
          y1="15.193"
          y2="12.103"
          gradientUnits="userSpaceOnUse"
        >
          <stop></stop>
          <stop offset="0.277"></stop>
          <stop offset="0.62"></stop>
          <stop offset="0.923"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_36449_620208"
          x1="20.935"
          x2="18.126"
          y1="15.144"
          y2="9.528"
          gradientUnits="userSpaceOnUse"
        >
          <stop></stop>
          <stop offset="0.583"></stop>
          <stop offset="0.982"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_36449_620208"
          x1="20.941"
          x2="18.133"
          y1="15.148"
          y2="9.531"
          gradientUnits="userSpaceOnUse"
        >
          <stop></stop>
          <stop offset="0.583"></stop>
          <stop offset="0.982"></stop>
          <stop offset="1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default DiscoverCardIcon;