import React, { useEffect } from 'react';
import { Select, MenuItem, FormControl, makeStyles, withStyles } from '@material-ui/core'
import DownVector from '../components/SVG/DownVector';
import { H4, H2, H3, AvenirBlackH4, DotText } from '../utils/text';
import clsx from "clsx";
import $ from 'jquery'
import colors from '../styles/colors';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: '#FFFFFF',
      borderRadius: 5,
    },
    position: 'relative'
  },
  hoverMenuItem: {
    // '&:focus': {
    //   backgroundColor: '#C8E6F1',
    // },
    '&:hover': {
      backgroundColor: '#C8E6F1',
      borderRadius: 5
    }
  },
  menuPropsStyle: props => ({
    minWidth: props.width,
    border: '1px solid #004C60',
    position: 'absolute',
    maxHeight: 200
  })
}));
const CustomExpandMore = withStyles()(
  ({ className, classes, ...rest }) => {
    return (
      <DownVector
        {...rest}
        className={clsx(className)}
        style={{ marginTop: 8, marginRight: 5 }}
      />
    );
  }
);
const SingleSelect = ({ list, handleChange, value, valueKey, displayKey, placeholder, border = false, disabled = false, minWidth, height, width, FormWidth, borderStyle = "1px solid #004C60", singleBorder = true }) => {
  const classes = useStyles({ width: 0 });
  const getpx = (val) => {
    return parseInt(val.replace("px", ""))
  }
  const getBottomPosition = (boxPosition) => {
    const windowHeight = window.innerHeight;
    if (windowHeight > (boxPosition.bottom + 207.5)) {
      return {
        position: boxPosition.bottom + 7.5,
        top: true
      }
    } else {
      return {
        position: windowHeight - (boxPosition.top) + 7.5,
        top: false
      }
    }
  }
  const onOpen = (e) => {
    console.log(e)
    setTimeout(() => {
      let els = document.getElementsByClassName(classes.menuPropsStyle)
      let cl = $(e.target).closest("." + classes.selectRoot)
      if (els.length > 0 && cl.length > 0) {
        for (var i = 0; i < els.length; i++) {
          const boxPosition = cl[0].getBoundingClientRect()
          const minWidth = boxPosition.width + 2;
          const left = boxPosition.left - 1;
          const l = getBottomPosition(boxPosition);
          console.log("menuPropsStyle", els.length, boxPosition, minWidth, left, l)
          els[i].style["min-width"] = `${minWidth}px`;
          els[i].style["max-width"] = `${minWidth}px`;
          if (l.top) {
            els[i].style["top"] = `${l.position}px`;
          } else {
            els[i].style["top"] = null;
            els[i].style["bottom"] = `${l.position}px`;
          }

          els[i].style["left"] = `${left}px`
        }

      }

    }, 50)

  }
  return (
    <FormControl
      id="innerView"
      data-qaid="singleselect_formcontrol"
      variant={border ? 'outlined' : 'filled'}
      style={{
        minWidth: minWidth,
        width: FormWidth,
        maxWidth: minWidth,
        display: 'flex'

      }}>
      <Select
        data-qaid="singleselect_select"
        value={value}
        onChange={handleChange}
        displayEmpty
        disableUnderline={true}
        style={ singleBorder ? 
          {border: borderStyle,
          backgroundColor: '#FFFFFF',
          fontSize: 14,
          fontFamily: 'AvenirLT',
          height: height,
          width: width,
          borderRadius: 4,
          alignItems: 'center'} : {backgroundColor: '#FFFFFF',
          fontSize: 14,
          fontFamily: 'AvenirLT',
          height: height,
          width: width,
          borderRadius: 4,}
        }
        classes={{ root: classes.selectRoot }}
        disabled={disabled}
        onOpen={(e) => onOpen(e)}
        IconComponent={CustomExpandMore}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
            minWidth: 496
          },
          MenuListProps: {
            disablePadding: true,
          },
          getContentAnchorEl: null,
          classes: {
            paper: classes.menuPropsStyle
          }
        }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {
          placeholder &&
          <MenuItem data-qaid="singleselect_menuitem_placeholder" value="" disabled style={{ width: 500 }}>
            <H4 label={placeholder} />
          </MenuItem>
        }
        {list && list.length > 0 ?
          list.map((item, index) => {
            return (
              <MenuItem
                data-qaid="singleselect_menuitems_label"
                value={item[valueKey]} key={item[valueKey] + "_option"}
                classes={{ root: classes.hoverMenuItem }}

                style={{ height: 56, borderBottom: '1px solid #EEEEEE' }}
              >
                <DotText label={item[displayKey]} />
              </MenuItem>
            )
          })
          :
          <>
          </>
        }
      </Select>
    </FormControl>
  )
}

export default React.memo(SingleSelect);
