import {
	Paper,
	Grid,
	createStyles,
	withStyles
} from "@material-ui/core";
import { differenceInCalendarMonths, getYear } from "date-fns";
import Month from "./Month";
import DefinedRanges from "./DefinedRanges";
import { MARKERS } from "..";
import { useMemo } from 'react';
const styles = (theme) =>
	createStyles({
		header: {
			padding: "20px 70px"
		},
		headerItem: {
			flex: 1,
			textAlign: "center"
		},
		divider: {
			borderLeft: `1px solid ${theme.palette.action.hover}`,
			marginBottom: 20
		}
	});



const Menu = props => {
	const {
		classes,
		ranges,
		dateRange,
		minDate,
		maxDate,
		firstMonth,
		setFirstMonth,
		secondMonth,
		setSecondMonth,
		setDateRange,
		helpers,
		handlers
	} = props;
	const { startDate, endDate } = dateRange;
	const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
	const commonProps = { dateRange, minDate, maxDate, helpers, handlers };
	const isMinMonth = () => {
		const diff = differenceInCalendarMonths(minDate, startDate)
		// console.log("differenceInCalendarMonths min", diff, minDate)
		return diff < 0
	}
	const isMaxMonth = () => {
		const diff = differenceInCalendarMonths(maxDate, endDate)
		// console.log("differenceInCalendarMonths max", diff, maxDate)
		return diff > 0
	}
	const yearrange = useMemo(() => {
		return [getYear(minDate), getYear(maxDate)]
	}, [minDate, maxDate])
	const onSetFirstMonth = (date) => {
		setFirstMonth(date);
		setSecondMonth(date)
	}
	console.log("year range", yearrange, minDate, maxDate)
	return (
		<Paper elevation={0} square style={{ borderBottom: '1px solid #c3c3c3' }}>
			<Grid container direction="row" wrap="nowrap">
				<Grid>
					{/* <Grid container className={classes.header} alignItems="center">
						<Grid item className={classes.headerItem}>
							<Typography variant="subtitle1">
								{startDate ? format(startDate, "mmmm dd, yyyy") : "Start Date"}
							</Typography>
						</Grid>
						<Grid item className={classes.headerItem}>
							<ArrowRightAlt color="action" />
						</Grid>
						<Grid item className={classes.headerItem}>
							<Typography variant="subtitle1">
								{endDate ? format(endDate, "mmmm dd, yyyy") : "End Date"}
							</Typography>
						</Grid>
					</Grid>
					<Divider /> */}
					<Grid container direction="row" justify="center" wrap="nowrap">
						<Month
							{...commonProps}
							value={firstMonth}
							setValue={onSetFirstMonth}
							navState={[isMinMonth(), canNavigateCloser]}
							marker={MARKERS.FIRST_MONTH}
							yearrange={yearrange}
						/>
						{/* <div className={classes.divider} /> */}
						<Month
							{...commonProps}
							value={secondMonth}
							setValue={setSecondMonth}
							yearrange={yearrange}
							navState={[canNavigateCloser, isMaxMonth()]}
							marker={MARKERS.SECOND_MONTH}
						/>
					</Grid>
				</Grid>
				<div className={classes.divider} />
				<Grid>
					<DefinedRanges
						selectedRange={dateRange}
						ranges={ranges}
						setRange={setDateRange}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles)(Menu);
