import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
    Grid,
    Button,
    makeStyles,
    withStyles,
    Typography
} from '@material-ui/core';
import CreateGroup from './createGroup';
import {
    fetchAllGroupsData,
    fetchAllStocks,
    deleteGroups
} from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '../../../../components/SVG/Delete';
import EditIcon from '../../../../components/SVG/Edit';
import EditGroup from './editGroup';
import AlertDialog from '../../../../components/Dialog';
import MyContext from '../../../../Provider/MyContext';
import CirclePluse from '../../../../components/SVG/PluseIcon';
import { AvenirBlackH4, H2, AvenirBlackH3, H3, AvenirBlackLightH3, T, DotText } from '../../../../utils/text';
import styles from '../../../../styles/index';
import MenuIcon from '../../../../components/SVG/MenuIcon';
import LineIcon from '../../../../components/SVG/Line';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DownArrow from '../../../../components/SVG/DownArrow';
import _ from 'lodash';

import SnackBar from "../../../../components/SnackBar";
const Groups = () => {
    const style = styles();
    const dispatch = useDispatch();
    const groupsData = useSelector((state) => state.inventory.groupsData);
    const stocksData = useSelector((state) => state.inventory.stocks);
    const [createGroup, setCreateGroup] = useState(false);
    const [editGroup, setEditGroup] = useState(false);
    const [table, setTable] = useState([]);
    const [row, setRow] = useState([]);
    const Context = useContext(MyContext);
    const [heading, setHeading] = useState(Context.langData.delete_groups);
    const [bodyMessage, setBodyMessage] = useState(Context.langData.delete_group_body);
    const [showDialog, setShowDialog] = useState(false);
    const [snackbar, setSnackbar] = useState(null)
    const [id, setId] = useState('');
    const [columns, setColumns] = useState(
        [
            { field: 'name', display: Context.langData.stock_name, size: 4 },
            { field: 'unitTypeCode', display: Context.langData.units, size: 4 },
            { field: 'currentBalance', display: Context.langData.balance, size: 4 },
            { field: "criticalBalance", display: Context.langData.critical_balance, size: 4 },
        ]
    )

    useEffect(() => {
        dispatch(fetchAllGroupsData());
        dispatch(fetchAllStocks());
    }, []);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        display: 'flex',
        flexDirection: 'row',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        border: isDragging ? `1px solid #C3C3C3` : '.3px solid #FAFAFA',
        marginLeft: 32,
        marginRight: 32,
        marginBottom: 16,
        borderRadius: 5,
        background: isDragging ? "#f4f4f5" : '#FAFAFA',
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        width: '100%'
    });
    const onDragEnd = (result, index) => {
        var data = [...table[index].stockObj]
        if (!result.destination) {
            return;
        }
        const items = reorder(
            data,
            result.source.index,
            result.destination.index
        );
        var tableData = [...table];
        tableData[index] = { ...tableData[index], stockObj: items };
        setTable(tableData);

    }
    useEffect(() => {
        if (stocksData?.length > 0) {
            if (groupsData?.length > 0) {
                var array = [];
                groupsData.map((item) => {
                    var obj = {};
                    var stockArray = [];
                    obj["id"] = item.id;
                    obj["name"] = item.name;
                    item.items?.map((it) => {
                        var stockObj = stocksData.filter((e) => e.id == it.id)
                        if (stockObj.length > 0) {
                            stockArray.push(stockObj[0]);
                        }
                    })
                    obj["items"] = stockArray;
                    array.push(obj);
                })
                console.log(array, 'stockdataaaa');
                setTable(array);
            }
            else {
                setTable([]);
            }
        }
    }, [groupsData, stocksData]);

    const handleCreateGroup = () => {
        setCreateGroup(true);
    }

    const handleEditGroup = (rowValue) => {
        console.log("value", rowValue)
        setRow(rowValue)
        setEditGroup(true)
    }

    const handleClose = (value) => {
        if (value) {
            dispatch(deleteGroups( id , callback));
        }
        setShowDialog(false)
    }

    function handleDeleteGroup(id) {
        setId(id)
        setShowDialog(true)
    }

    const callback = (isSuccess) => {
        setShowDialog(false)
        dispatch(fetchAllGroupsData());
        if (isSuccess) {
            setSnackbar({
              message: Context.langData.group_edit_succ,
              severity: "success",
            });
          } else {
            setSnackbar({
              message: Context.langData.group_failed,
              severity: "error",
            });
          }
    }



    const handleclose = (value) => {
        dispatch(fetchAllGroupsData());
        dispatch(fetchAllStocks());
        setCreateGroup(false);
        setEditGroup(false);
    }

    const handleSort = (name, desc, Tindex, index) => {
        var columnData = [...columns]
          columnData[index] = { ...columnData[index], desc}
          setColumns(columnData)
          var sortItemwiseSales;
          let tableItems = table[Tindex].items
          if(name == "name" || name == "unitTypeCode") {
             sortItemwiseSales = _.orderBy(tableItems, [(item) => item[name].toLowerCase()], [desc ?  'desc' : 'asc']);
          } else {
            sortItemwiseSales = _.orderBy(tableItems, [name], [desc ?  'desc' : 'asc']);
          }
           table[Tindex].items = sortItemwiseSales
          setTable(table)
      }

    return (
        <Grid data-qaid="groups_mainContainer" container >
            <Grid data-qaid="groups_subContainer1" container >
                <Grid data-qaid="groups_createGroupGrid" container style={{ justifyContent: 'flex-end', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button className={style.buttonClass} data-qaid="groups_createGroupButton" style={{ backgroundColor: "#FFB600", color: "white", marginRight: 32 }} variant="contained" id="cancel" onClick={handleCreateGroup}  >
                        <CirclePluse data-qaid="groups_createGroupPulse" />
                        <AvenirBlackH4 data-qaid="groups_createGroupLabel" label={Context.langData.create_group} style={{ paddingLeft: 5 }} />
                    </Button>

                </Grid>
                {createGroup &&
                    <CreateGroup
                        open={createGroup}
                        onClose={() => handleclose(false)}
                    />
                }
                {table.map((item, Tindex) => {
                    return (
                        <Grid data-qaid="groups_subContainer2" item xs={12} key={Tindex}>
                            <Grid item>
                                <Grid data-qaid="groups_styles1" container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10, marginTop: '10px', margin: 20, width: '96%' }}>
                                    <div style={{ maxWidth: '90%' }}>
                                        <DotText label={item.name} style={{ fontSize: 18, fontFamily: 'AvenirLT-Black' }} />
                                    </div>

                                    <Grid data-qaid="groups_styles2" item style={{ display: 'flex', flexDirection: 'row' }}>
                                        <EditIcon style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => handleEditGroup(item)} color='#004C60' />
                                        <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => { handleDeleteGroup(item.id) }} />
                                    </Grid>
                                </Grid>
                                <DragDropContext onDragEnd={(event) => { onDragEnd(event, Tindex) }}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <Grid data-qaid="editCategory_cancel_btn" item xs={12} lg={12} sm={12} md={12}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                <Grid style={getListStyle(snapshot.isDraggingOver)}>
                                                <Grid style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#F3FAFD', height: 56, justifyContent: 'center', margin: `5px 32px 10px 32px`, alignItems: 'center' }}>

                                                        {
                                                            columns.map((item, index) => {
                                                               return(
                                                                 <Grid style={{ display: 'flex', width: item.field == "name" ? "40%" : "20%" , gap: '10px' }}>
                                                                       <H3 label={item.display} style={{ marginLeft: 8 }} />
                                                                       <Grid onClick={() => {handleSort(item.field, !item.desc, Tindex, index)}}>
                                                                        <DownArrow />
                                                                       </Grid>
                                                                 </Grid>
                                                                 )
                                                            })
                                                        }
                                                        {/* <H3 label={Context.langData.stock_name} style={{ marginLeft: 20 }} /> */}
                                                    {/* <Grid style={{ display: 'flex', width: '15%' }}>
                                                        <H3 label={Context.langData.units} style={{ marginLeft: 8 }} />
                                                    </Grid>
                                                    <Grid style={{ display: 'flex', width: '20%' }}>
                                                        <H3 label={Context.langData.balance} style={{ marginLeft: 8 }} />
                                                    </Grid>
                                                    <Grid style={{ display: 'flex', width: '20%' }}>
                                                        <H3 label={Context.langData.critical_balance} style={{ marginLeft: 8 }} />
                                                    </Grid> */}
                                                </Grid>
                                                {item.items.map((item, index) => (
                                                    <Draggable key={item.name} draggableId={item.name} index={index} >
                                                        {(provided, snapshot) => (
                                                            <Grid
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <Grid item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    <Grid style={{ display: 'flex', width: '40%', maxWidth: '40%' }}>
                                                                        <MenuIcon style={{ marginLeft: 0 }} />
                                                                        <DotText label={item.name} style={{ paddingLeft: 20 }} />
                                                                    </Grid>
                                                                    <Grid style={{ display: 'flex', width: '20%', maxWidth: '20%' }}>
                                                                        <T label={item.unitTypeCode} style={{ display: 'flex', paddingLeft: 15 }} />
                                                                    </Grid>
                                                                    <Grid style={{ display: 'flex', width: '20%' }}>
                                                                        <T label={item.currentBalance} style={{ display: 'flex', paddingLeft: 15 }} />
                                                                    </Grid>
                                                                    <Grid style={{ display: 'flex', width: '20%' }}>
                                                                        <T label={item.criticalBalance} style={{ display: 'flex', paddingLeft: 30 }} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Droppable>
                                </DragDropContext >

                            </Grid>
                        </Grid>
                    )
                })
                }
                {editGroup &&
                    <EditGroup
                        open={editGroup}
                        onClose={() => handleclose(false)}
                        row={row}
                    />
                }

                <AlertDialog
                    showDialog={showDialog}
                    body={bodyMessage}
                    heading={heading}
                    IsError={false}
                    handleClose={handleClose}
                />

            </Grid>
        </Grid>
    )
}

export default Groups;