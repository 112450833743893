import * as React from "react"

const YellowCircle = (props) => (
    <svg
        width={16}
        height={17}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={8} cy={8.111} r={8} fill="#FFD877" />
    </svg>
)

export default YellowCircle
