import React, { useEffect } from 'react';
import { makeStyles, MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { H3 } from '../../utils/text';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
const muiTheme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        color: '#C3C3C3',
        height: 30,
        width: 30,
        '&$active': {
          color: '#051D33',
          height: 30,
          width: 30,
          padding: 2,
          border: '2px solid #051D33',
          borderRadius: 16,
          '& $text': {
            fill: '#FFF',
          }
        },
        '&$completed': {
          color: '#051D33',
          height: 30,
          width: 30,
          '& $text': {
            fill: '#FFF',
          }
        },
      },
      text: {
        fill: '#707272',
        fontSize: '15px',
        fontWeight: 'bold',
      }
    },

  }
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Restaurant information', 'Account settings', "Site settings"];
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  outter: {
    width: 40,
    display: "flex",
    height: 40,
    border: "2px solid #051D33",
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  inner: {
    width: 32,
    display: "flex",
    height: 32,
    backgroundColor: "#051D33",
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  nonComplete: {
    width: 40,
    display: "flex",
    height: 40,
    backgroundColor: "#c3c3c3",
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    color: '#ff0000',
  },
  completed: {
    color: "#0f0"
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#051D33',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#051D33',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);
function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed, icon } = props;
  console.log("props", props)

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {active ? <CompletedStep icon={icon} /> : <NonComplete icon={icon} isCompleted={completed} />}
    </div>
  );
}
const CompletedStep = ({ icon }) => {
  const classes = useQontoStepIconStyles();
  return (
    <div className={classes.outter}>
      <div className={classes.inner}>
        <span style={{ color: "white", fontSize: 24, fontFamily: "AvenirLT-Black", fontWeight: 900, textAlign: 'center' }}>{icon}</span>
      </div>
    </div>
  )
}
const NonComplete = ({ icon, isCompleted }) => {
  const classes = useQontoStepIconStyles();
  return (
    <div className={classes.nonComplete} style={{ backgroundColor: isCompleted ? "#051d33" : "#c3c3c3" }}>

      <span style={{ color: isCompleted ? "#FFF" : "#707272", fontSize: 24, fontFamily: "AvenirLT-Black", fontWeight: 900, textAlign: 'center' }}>{icon}</span>

    </div>
  )

}
const StepBar = ({ currentStep = 1 }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  useEffect(() => {
    setActiveStep(currentStep)
  }, [currentStep])

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} style={{ backgroundColor: 'transparent' }} connector={<QontoConnector />}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            console.log("LABELS", label)

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} className={currentStep > index ? "completed" : 'non'} StepIconComponent={QontoStepIcon}>
                  <H3 label={label} style={{ color: currentStep >= index ? "#051D33" : "#707272" }} />
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </MuiThemeProvider>
  );
}

export default StepBar;
