import React, { useState, useContext } from 'react';
import {
    Grid,
    makeStyles
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import ProvinceData from './province';
import SourceChannelData from './channelData';
import { useNavigate } from 'react-router-dom'
import { saveCustomer, fetchCustomerCheck } from '../../../redux/actions'
import TextInputComponent from '../../../components/TextInputComponent';
import TextInputMask from '../../../components/TextInputMask';
import SingleSelect from '../../../components/SingleSelect';
import moment from 'moment'
import MyContext from '../../../Provider/MyContext';
import { AvenirBlackH4, AvenirBlackH2, H5, H6 } from '../../../utils/text';
import InfoOutlinedIcon from '../../../components/SVG/InfoOutlined';
import Back from '../../../components/SVG/Back';
import styles from '../../../styles/index';
import SnackBar from '../../../components/SnackBar';
import SingleDate from '../../../components/SingleDate';
import WarningIcon from '../../../components/SVG/WarningIcon';
import { validateText } from '../../../utils/util';

const useStyles = makeStyles((theme) => ({
    newCustomerHeadingView: {
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '20px'
    },
    newCustomerText: {
        fontWeight: 'bold',
        fontSize: 24
    },
    saveBtn: {
        marginRight: '20px',
        backgroundColor: '#FFB600',
        color: 'white',
        height: '40px',
        width: '103px',
        '&:hover': {
            backgroundColor: '#FFB600',
        }
    },
    cancelBtn: {
        marginLeft: '20px',
        backgroundColor: '#707272',
        color: 'white',
        fontWeight: 'bold'
    },
    contactInfoMainView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '1%',
        marginLeft: '1%',
        marginRight: '1%'
    },
    hrStyles: {
        marginTop: '1%',
        marginLeft: ' 1%',
        marginRight: '1%',
        border: '1px solid #EEE'
    },
    checkboxMainView: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '10px'
    },
    additionalInfoCardView: {
        marginTop: '20px',
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '20px'
    },
    additionalInfoText: {
        display: 'flex',
        marginTop: '1%',
        marginLeft: '1%',
        marginRight: '1%'
    },
    root: {
    },
    title: {
        fontSize: '18px',
    },
    input: {
        color: 'black !important',
        fontSize: 14,
        backgroundColor: '#FFF',
        borderRadius: 4,
    },
    notchedOutline: {
        border: "1px solid #707272 !important",
    },
    labelRoot: {
        // fontSize:6
    },
    formControl: {
        minWidth: '100%',
        backgroundColor: '#FFF !important',
        border: '#707272',
        borderRadius: 4,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    number: {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        }
    },
}));

const NewCustomers = () => {
    const [snackbar, setSnackbar] = useState(null);
    const style = styles();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [enabled, setEnabled] = useState(false)
    const [province, setProvince] = useState("");
    const [sourceChannel, setSourceChannel] = useState("In-house");
    const Context = useContext(MyContext);
    const [emailExists, setEmailExists] = useState(false);
    const [lang, setLang] = useState(0);

    const languageType = [
        { name: "English", value: 0 },
        { name: "French", value: 1 },
    ]

    const WarningText = <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', paddingTop: 5 }}>
        <WarningIcon color='#AB1212' height={16} width={16} />
        <H5 label={'Customer email already exists'} style={{ color: '#AB1212', paddingLeft: 5 }} />
    </Grid>

    const handleCancel = () => {
        navigate('/home/profile')
    }
    const validateSchema = () => {
        const validate = Yup.object().shape({
            firstName: Yup.string().max(255).required(Context.langData.first_name_req_error),
            lastName: Yup.string().max(255).required(Context.langData.last_name_req_error),
            email: Yup.string().email(Context.langData.email_valid_error).max(255).required(Context.langData.email_required_error),
        })
        return validate;
    }

    const validateAddress = (address) => {
        var arr = ["streetAddress", "city", "postalCode"];
        var temp = [];
        arr.map(key => {
            if (address.values[key] == "" || address.values[key] == null) {
                temp.push(key)
            }
        });
        if (temp.length === arr.length || temp.length === 0) {
            return null
        }
        else {
            return "Address Incomplete";
        }
    };

    const onBlurEmail = (e) => {
        if (e.target.value) {
            dispatch(fetchCustomerCheck({ email: e.target.value }, (status, data) => {
                if (status) {
                    if (data.exist) {
                        setEmailExists(true)
                    } else {
                        setEmailExists(false)
                    }
                } else {
                    console.log("email alreay exists")
                }
            }))
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    customerId: '',
                    assignedCustomerId: '',
                    birthday: '',
                    streetAddress: '',
                    unitNumber: '',
                    city: '',
                    province: '',
                    postalCode: '',
                    notes: '',
                    sourceChannel: [SourceChannelData[0]],
                }}
                validationSchema={
                    validateSchema
                }
                onSubmit={(values) => {
                    var valid = validateAddress({ values });
                    if (valid) {
                        setSnackbar({ message: valid, severity: 'error' })
                    }
                    else {
                        dispatch(saveCustomer({
                            first_name: values.firstName,
                            last_name: values.lastName,
                            email: values.email,
                            phone_number: values.phoneNumber == null ? null : values.phoneNumber.replace(/-/g, ''),
                            enable_marketing: enabled,
                            assigned_customer_id: values.assignedCustomerId,
                            birthday: values.birthday,
                            street_number: values.streetNumber,
                            street_address: values.streetAddress,
                            unit_number: values.unitNumber,
                            city: values.city,
                            province: province == 'Select' ? "" : province,
                            postal_code: values.postalCode == null ? null : values.postalCode.replace(/ /g, ''),
                            notes: values.notes,
                            language: lang == 0 ? 'English' : 'French',
                            source_channel: sourceChannel
                        },
                            (status, data) => {
                                if (status) {
                                    navigate('/home/profile', { state: { tab: 0, createCustomer: status, message: Context.langData.customer_created_successfully, ts: moment().unix() } })
                                } else {
                                    setSnackbar({ message: data.message, severity: 'error' });
                                }
                            }
                        ));
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setFieldError,
                    setErrors,
                    touched,
                    isValid,
                    dirty,
                    values
                }) => (
                    <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                        <Grid data-qaid="newCustomers_mainContainer" container style={{ backgroundColor: '#EEE', height: '100%' }}>
                            <Grid data-qaid="newCustomers_subContainer1" container spacing={2} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#FFF', paddingTop: '25px', paddingBottom: '25px' }}>
                                <Grid data-qaid="newCustomers_newCustomerLabelView" item style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginLeft: 30 }}>
                                    <Back data-qaid="newCustomers_newCustomerBackIcon" onClick={() => handleCancel()} style={{ cursor: 'pointer' }} />
                                    <H6 data-qaid="newCustomers_newCustomerLabel" label={Context.langData.new_customer} style={{ paddingLeft: 20 }} />
                                </Grid>
                                <Grid data-qaid="newCustomers_cancelButtonContainer" item>
                                    <Button className={style.buttonClass} data-qaid="newCustomers_cancelButtonLabelView" style={{ backgroundColor: "#FFF", marginRight: 10, width: 103, height: 40, border: '2px solid #051D33' }} variant="contained" id="cancel" onClick={() => handleCancel()}  >
                                        <AvenirBlackH4 data-qaid="newCustomers_cancelButtonLabel" label={Context.langData.cancel} />
                                    </Button>

                                    <Button className={[style.buttonClass, classes.saveBtn]} data-qaid="newCustomers_saveButtonLabelView" variant="contained" id="savebtn" type='submit' disabled={!(dirty && isValid)} >
                                        <AvenirBlackH4 data-qaid="newCustomers_saveButtonLabel" label={Context.langData.save} />
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid data-qaid="newCustomers_contactInfoContainer" item lg={12} md={12} sm={12} style={{ overflow: 'scroll', marginTop: 30 }}>
                                <div data-qaid="newCustomers_contactInfoDiv" style={{ marginLeft: '20px', marginRight: '20px' }}>
                                    <Card data-qaid="newCustomers_contactInfoCard" className={classes.root}>
                                        <CardContent>
                                            <Grid data-qaid="newCustomers_contactInfoLabelView" className={classes.contactInfoMainView}>
                                                <AvenirBlackH2 data-qaid="newCustomers_contactInfoLabel" label={Context.langData.cnt_info} />
                                            </Grid>
                                            <hr className={classes.hrStyles} />
                                            <Grid data-qaid="newCustomers_firstNameContainer" container spacing={4} style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid data-qaid="newCustomers_firstNameLabelView" item xs={12} sm={6} md={6} lg={5}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_firstNameLabel" label={Context.langData.first_name} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.firstName}
                                                        onBlur={handleBlur}
                                                        onChange={(val) => setFieldValue('firstName', validateText(val.target.value))}
                                                        fullWidth
                                                        error={Boolean(touched.firstName && errors.firstName)}
                                                        helperText={touched.firstName && errors.firstName}
                                                        id="firstName"
                                                        name="firstName"
                                                        maxLength={128}
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid data-qaid="newCustomers_lastNameLabelView" item xs={12} sm={6} md={6} lg={5}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_lastNameLabel" label={Context.langData.last_name} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.lastName}
                                                        onBlur={handleBlur}
                                                        onChange={(val) => setFieldValue('lastName', validateText(val.target.value))}
                                                        fullWidth
                                                        error={Boolean(touched.lastName && errors.lastName)}
                                                        helperText={touched.lastName && errors.lastName}
                                                        id="lastName"
                                                        maxLength={128}
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid data-qaid="newCustomers_emailContainer" container spacing={4} style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid data-qaid="newCustomers_emailLabelView" item xs={12} sm={6} md={6} lg={5}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_emailLabel" label={Context.langData.email_header_star} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.email}
                                                        maxLength={64}
                                                        // onBlur={handleBlur}
                                                        onBlur={(e) => {
                                                            handleBlur(e);
                                                            onBlurEmail(e, setFieldValue)
                                                        }}
                                                        onChange={(val) => {
                                                            setFieldValue('email', val.target.value.trim().replace(/\s/g, ''));
                                                            // onBlurEmail(val, setFieldValue)
                                                        }}
                                                        fullWidth
                                                        error={Boolean(touched.email && errors.email)}
                                                        helperText={touched.email && errors.email}
                                                        id="email"
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        errorBorder={(emailExists && !errors.email) ? '1px solid #AB1212' : null}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                    {(emailExists && !errors.email) &&
                                                        <H5 label={emailExists ? WarningText : ''} style={{ color: '#AB1212', paddingTop: 5 }} />
                                                    }
                                                </Grid>
                                                <Grid data-qaid="newCustomers_phoneNumberLabelView" item xs={12} sm={6} md={4} lg={5} style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                                                    <Grid style={{ width: '60%' }}>
                                                        <AvenirBlackH4 data-qaid="newCustomers_phoneNumberLabel" label={Context.langData.phoneNumber} style={{ marginBottom: 5 }} />
                                                        <TextInputMask
                                                            id="phoneNumber"
                                                            value={values.phoneNumber}
                                                            regex="phone"
                                                            maxLength={16}
                                                            // onFocus={(val) => setFieldValue('phoneNumber', val)}
                                                            name="phoneNumber"
                                                            // helperText={touched.phoneNumber && errors.phoneNumber}
                                                            error={errors.phoneNumber}
                                                            onBlur={(error) => {
                                                                // setFieldValue('phoneNumber', value)
                                                                if (error) {
                                                                    setFieldError('phoneNumber', error)
                                                                }
                                                                else {
                                                                    let e = { ...errors };
                                                                    delete e.phoneNumber;
                                                                    setErrors({ ...e })
                                                                }
                                                            }}
                                                            isFormik={true}
                                                            // placeholder="X-XXX-XXX-XXXX"
                                                            handleChange={(val) => setFieldValue('phoneNumber', val)}
                                                        />
                                                    </Grid>
                                                    <Grid style={{ width: '35%' }}>
                                                        <AvenirBlackH4 data-qaid="newCustomers_provinceLabel" label={Context.langData.language + '*'} style={{ marginBottom: 5 }} />
                                                        <SingleSelect
                                                            list={languageType}
                                                            valueKey="value"
                                                            displayKey="name"
                                                            value={lang}
                                                            handleChange={(e) => setLang(e.target.value)}
                                                            border={false}
                                                            height={56}
                                                            FormWidth={'100%'}
                                                            borderStyle={'1px solid #707272'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <div data-qaid="newCustomers_checkboxContainer" className={classes.checkboxMainView}>
                                                <Checkbox
                                                    checked={enabled}
                                                    onChange={setEnableCheckBox}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <AvenirBlackH4 data-qaid="newCustomers_checkboxLabel" label={Context.langData.opt_marketing} />
                                            </div> */}
                                        </CardContent>
                                    </Card>
                                </div>
                                <div data-qaid="newCustomers_additionalInfoContainer" className={classes.additionalInfoCardView}>
                                    <Card data-qaid="newCustomers_additionalInfoCard" className={classes.root}>
                                        <CardContent>
                                            <AvenirBlackH2 data-qaid="newCustomers_additionalInfoLabel" label={Context.langData.additional_info} style={{ paddingLeft: 10, paddingTop: 10 }} />
                                            <hr className={classes.hrStyles} />
                                            <Grid data-qaid="newCustomers_customerIdContainer" container spacing={4} style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid data-qaid="newCustomers_sourceChannelLabelView" item xs={12} sm={3} md={3} lg={4}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_sourceChannelLabel" label={Context.langData.channel} style={{ marginBottom: 5 }} />
                                                    <SingleSelect
                                                        list={SourceChannelData}
                                                        valueKey="value"
                                                        displayKey="name"
                                                        value={sourceChannel}
                                                        handleChange={(sourceChannel) => setSourceChannel(sourceChannel.target.value)}
                                                        border={false}
                                                        height={56}
                                                        FormWidth={'100%'}
                                                    />
                                                </Grid>
                                                <Grid data-qaid="newCustomers_customerIdLabelView" item xs={12} sm={6} md={3} lg={3}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_customerIdLabel" label={Context.langData.customer_id} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        id="assignedCustomerId"
                                                        variant="outlined"
                                                        value={values.assignedCustomerId}
                                                        onChange={(val) => setFieldValue('assignedCustomerId', val.target.value.replace(/[^0-9]/g, ''))}
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        maxLength={32}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                    <Grid item style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', display: 'flex', padding: 5, paddingTop: 5 }}>
                                                        {/* <Grid style={{flexDirection:'row', alignItems:'center', display:'flex'}}>
                                                            <InfoOutlinedIcon style={{ height: 16, width:16}}/>
                                                            <H5 label={Context.langData.help} style={{paddingTop:5, paddingLeft: 5}}/>
                                                        </Grid> */}
                                                        <Grid style={{ justifyContent: 'flex-end' }}>
                                                            <H5 style={{ color: '#707272' }} label={(values.assignedCustomerId?.length == undefined ? '0' : values.assignedCustomerId?.length) + Context.langData._32_characters_count} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid data-qaid="newCustomers_birthdayLabelView" item xs={12} sm={6} md={3} lg={3}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_birthdayLabel" label={Context.langData.birthday} style={{ marginBottom: 5 }} />
                                                    <Grid item>
                                                        {/* <DOBPicker
                                                            value={values.birthday}
                                                            height={54}
                                                            width={276}
                                                            border={false}
                                                            callback={(val) => setFieldValue('birthday', val)}
                                                        /> */}
                                                        <SingleDate
                                                            value={values.birthday}
                                                            height={54}
                                                            maxDate={new Date()}
                                                            border={false}
                                                            callback={(val) => setFieldValue('birthday', val)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid data-qaid="newCustomers_streetNumberContainer" container spacing={4} style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid data-qaid="newCustomers_streetAddressLabelView" item xs={12} sm={9} md={9} lg={7}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_streetAddressLabel" label={Context.langData.street_address} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        id="streetAddress"
                                                        variant="outlined"
                                                        value={values.streetAddress}
                                                        onChange={handleChange}
                                                        maxLength={64}
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid data-qaid="newCustomers_unitNumberLabelView" item xs={12} sm={3} md={3} lg={3} >
                                                    <AvenirBlackH4 data-qaid="newCustomers_unitNumberLabel" label={Context.langData.unit_number} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        id="unitNumber"
                                                        variant="outlined"
                                                        maxLength={32}
                                                        value={values.unitNumber}
                                                        onChange={handleChange}
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid data-qaid="newCustomers_cityLabelView" container spacing={4} style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid data-qaid="newCustomers_cityLabel" item xs={12} sm={3} md={3} lg={4}>
                                                    <AvenirBlackH4 label={Context.langData.city} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        id="city"
                                                        variant="outlined"
                                                        value={values.city}
                                                        maxLength={32}
                                                        onChange={handleChange}
                                                        style={{ width: '100%', height: 186 }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid data-qaid="newCustomers_provinceLabelView" item xs={12} sm={3} md={3} lg={4}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_provinceLabel" label={Context.langData.province} style={{ marginBottom: 5 }} />
                                                    <SingleSelect
                                                        list={ProvinceData}
                                                        valueKey="value"
                                                        displayKey="name"
                                                        placeholder="Select"
                                                        value={province}
                                                        handleChange={(province) => setProvince(province.target.value)}
                                                        border={false}
                                                        height={56}
                                                        FormWidth={'100%'}
                                                    />
                                                </Grid>
                                                {
                                                    console.log("errors", errors)
                                                }
                                                <Grid data-qaid="newCustomers_postalCodeLabelView" item xs={12} sm={3} md={3} lg={2}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_postalCodeLabel" label={Context.langData.postal_code} style={{ marginBottom: 5 }} />
                                                    <TextInputMask
                                                        id="postalCode"
                                                        value={values.postalCode}
                                                        regex="postal"
                                                        name="postalCode"
                                                        isFormik={true}
                                                        error={errors.postalCode}
                                                        placeholder="X1X 1X1"
                                                        onBlur={(value) => {
                                                            if (value) {
                                                                setFieldError('postalCode', value)
                                                            } else {
                                                                let err = { ...errors }
                                                                delete err.postalCode
                                                                setErrors(err)
                                                            }

                                                        }}
                                                        handleChange={(val) => setFieldValue('postalCode', val)}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid data-qaid="newCustomers_notesLabelView" item xs={12} sm={12} md={12} lg={10}>
                                                    <AvenirBlackH4 data-qaid="newCustomers_notesLabel" label={Context.langData.note} />
                                                    <TextField
                                                        value={values.notes}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        id="notes"
                                                        multiline={true}
                                                        rows={5}
                                                        InputProps={{ disableUnderline: true }}
                                                        inputProps={{ maxLength: 500 }}
                                                        style={{ width: '100%', height: 128, backgroundColor: '#FFF', outline: 'none', fontSize: 14, borderRadius: 4, fontFamily: 'AvenirLT-Medium', color: '#051D33', padding: 10, border: '1px solid #707272' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={10} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex', padding: 10, paddingTop: 5 }}>
                                                <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                                                    <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                                    <H5 label={Context.langData.do_not_add_any_sensitive_info} style={{ paddingLeft: 5, paddingTop: 5 }} />
                                                </Grid>
                                                <Grid style={{ justifyContent: 'flex-end' }}>
                                                    <H5 style={{ color: '#707272' }} label={(values?.notes?.length == undefined ? '0' : values?.notes?.length) + Context.langData._500_characters_count} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                        {
                            snackbar &&
                            <SnackBar
                                data-qaid="shifts_snackbar"
                                open={true}
                                setOpen={() => setSnackbar(null)}
                                severity={snackbar.severity}
                                message={snackbar.message}
                            />
                        }
                    </form>
                )}
            </Formik>
        </>
    )
}

export default NewCustomers;
