import setState from './setState'
const initialState = {
    session: {},
    categories: [],
    expenses: { "opening_balance": 0, "active": 0, "expenses": [] },
};
function parseCategories(response) {
    var obj = [...response];
    obj.map(d => {
        d["id"] = d.cat_id;

    })
    return obj;
}
function pettyCashReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_PETTYCASH_SESSION": return setState(state, action.payload, 'session')
        case "SET_PETTYCASH_CATEGORIES": return setState(state, parseCategories(action.payload), 'categories')
        case "SET_PETTYCASH_EXPENSES": return setState(state, { ...action.payload }, 'expenses')
        case "CLEAR": return {...initialState}
        default: return state
    }
}

export default pettyCashReducer;