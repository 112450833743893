
import * as React from "react"

const Copy = React.memo(({ width = 24, height = 24, color = "#004C60", ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.11 4.409a.245.245 0 00-.245.245v15.673a.245.245 0 00.245.245h7.836a.735.735 0 110 1.469H2.11a1.714 1.714 0 01-1.714-1.714V4.654A1.714 1.714 0 012.11 2.94h1.959a.735.735 0 010 1.469h-1.96zM13.13 3.674c0-.405.329-.734.734-.734h1.96a1.714 1.714 0 011.714 1.714v3.428a.735.735 0 01-1.47 0V4.654a.245.245 0 00-.245-.245h-1.959a.735.735 0 01-.734-.735zM8.967 1.47a.735.735 0 100 1.469.735.735 0 000-1.47zm-2.204.734a2.204 2.204 0 114.408 0 2.204 2.204 0 01-4.408 0z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.048 3.43a.245.245 0 00-.245.244v.98a.245.245 0 00.245.245h7.837a.245.245 0 00.245-.245v-.98a.245.245 0 00-.245-.245h-2.532a.735.735 0 110-1.469h2.532a1.714 1.714 0 011.714 1.714v.98a1.714 1.714 0 01-1.714 1.714H5.048a1.714 1.714 0 01-1.714-1.714v-.98A1.714 1.714 0 015.048 1.96h2.533a.735.735 0 010 1.47H5.048zM12.885 10.776a.245.245 0 00-.245.245v11.265a.245.245 0 00.245.245H21.7a.245.245 0 00.245-.245v-9.39a.246.246 0 00-.072-.173l-1.875-1.875a.245.245 0 00-.173-.072h-6.941zm-1.212-.967a1.714 1.714 0 011.212-.502h6.94c.456 0 .892.18 1.213.502m0 0l1.875 1.875c.321.321.502.757.502 1.212v9.39A1.714 1.714 0 0121.701 24h-8.816a1.714 1.714 0 01-1.714-1.714V11.02c0-.455.18-.89.502-1.212"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.11 14.448c0-.405.328-.734.734-.734h4.898a.735.735 0 010 1.47h-4.898a.735.735 0 01-.735-.736zM14.11 17.387c0-.406.328-.735.734-.735h4.898a.735.735 0 010 1.47h-4.898a.735.735 0 01-.735-.735z"
        fill={color}
      />
    </svg>
  )
})

export default Copy
