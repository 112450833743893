import { useState } from 'react';

const useDrawers = (initialState = {}) => {
  const [drawers, setDrawers] = useState(initialState);

  const toggleDrawer = (drawer, open) => {
    if (drawer === 'all') {
      const allClosed = Object.keys(drawers).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      setDrawers(allClosed);
    }
    setDrawers((prevState) => ({ ...prevState, [drawer]: open }));
  };
  return { drawers, toggleDrawer };
};
export default useDrawers;
