import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    makeStyles,
    Button, Grid, Card, CardContent, CardActions, IconButton, Typography, InputLabel, Select, MenuItem
} from '@material-ui/core';
import DataTable from '../../../../components/DataTable';
import styles from '../../../../styles/index';
import RightDrawer from '../../../../components/RightDrawer'
import InputText from '../../../../components/InputText';
import {
    createPrinter,
} from '../../../../redux/actions';
import AlertDialog from '../../../../components/Dialog';
import SingleSelect from '../../../../components/SingleSelect';
import ContextMenu from '../../../../components/ContextMenu';
import MyContext from '../../../../Provider/MyContext';
import { H4, H2, H3, AvenirBlackH4, H8, H5, DotText, DotTextBoldNormal, AvenirBlackH3 } from '../../../../utils/text';
import CenterModal from '../../../../components/CenterModal';
import DeleteIcon from '../../../../components/SVG/Delete';
import CirclePluse from '../../../../components/SVG/PluseIcon';
import _ from 'lodash';
import $ from 'jquery';
import SnackBar from '../../../../components/SnackBar';
import SettingIcon from '../../../../components/SVG/Settings';
import CircleClose from '../../../../components/SVG/CloseCircle';
import UploadIcon from '../../../../components/SVG/UploadIcon';
import NoData from '../../../../components/NoData';
import MaskedInput from 'react-text-mask';
import ExclamationIcon from '../../../../components/SVG/ExclamationIcon';
import InfoOutlinedIcon from '../../../../components/SVG/InfoOutlined';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import { validateAlphaNumeric } from '../../../../utils/util';
import MultiSelectChip from "../../../../components/MultiSelectChip";

const CreatePrinter = ({ open, callback, onClose, printers }) => {

    const Context = useContext(MyContext);
    const Permissions = useSelector(state => state.profileReducers.permissions);
    const [name, setName] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const printerModel = [
        { name: "Star TSP100" },
        { name: "Star SP700" },
    ];
    const [pModel, setPmodel] = useState(printerModel[0].name);
    const kitchens = useSelector(state => state.metaReducer.printerUnassigned);
    const [kitchensArray, setKitchensArray] = useState([]);
    const [kitchenItemsArray, setKitchenItemsArray] = useState([]);
    const [error, setError] = useState(null);
    const [ipAddresserror, setIpAddresserror] = useState(null);
    const style = styles();
    const dispatch = useDispatch();
    const [checkError, setCheckError] = useState(false)
    const [checkIt, setCheckIt] = useState([]);
    const checkList = useMemo(() => {
        let list = Permissions?.applications.filter(e => e.application_id != 1)
        return list
    }, [Permissions])
    const handleCheck = (item) => {
        let ch = [...checkIt]
        let index = ch.indexOf(item.application_id)
        if (index !== -1) {
            ch = [...ch.slice(0, index), ...ch.slice(index + 1)]
        } else {
            ch = [...ch, item.application_id]
        }
        setCheckIt(ch)
    }
    useEffect(() => { }, [kitchensArray]);
    const save = () => {
        var errorFlag = 0;
        var kitchenIds = [];
        if (name) {
        } else {
            errorFlag = errorFlag + 1;
            setError(Context.langData.no_printer_error);
        }
        if (ipAddress) {
            const regex = /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/
            const m = regex.test(ipAddress)
            console.log(m);
            if (!m) {
                errorFlag = errorFlag + 1;
                setIpAddresserror(Context.langData.ip_address_error);
            } else {
                setIpAddresserror('');
            }
        } else {
            errorFlag = errorFlag + 1;
            setIpAddresserror(Context.langData.enter_ipaddress_error);
        }
        const elementsIndex = printers.findIndex(indexElement => indexElement.name.toUpperCase() == name.toUpperCase());
        if (elementsIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setError(name + " already exist");
        }

        const elementsipIndex = printers.findIndex(indexElement => indexElement.ip_address == ipAddress);
        console.log("IP ADRESSS", ipAddress, printers)
        if (elementsipIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setIpAddresserror(ipAddress + " already exist");
        }
        if (errorFlag == 0) {
            for (const i of kitchensArray) {
                var vobj = {
                    kitchen_id: i,
                };
                kitchenIds.push(vobj);
            }
            if (kitchenIds.length > 0) {
                dispatch(createPrinter({ ip_address: ipAddress.trim(), name: name.trim(), printer_model: pModel, kitchens: kitchenIds, }, callback));
            }
            else {
                dispatch(createPrinter({ ip_address: ipAddress.trim(), name: name.trim(), printer_model: pModel, kitchens: [], }, callback));
            }
        }
    }
    const onChange = (val) => {
        setName(validateAlphaNumeric(val))
        if (printers.indexOf(val.toLowerCase()) == -1) {
            setError("")
        }
        else {
            setError(validateAlphaNumeric(val) + " already exist");
        }
    }

    const selectedKitchen = (value) => {
        var itemListArray = [...kitchensArray];
        var item = itemListArray.filter((e) => e == value);
        if (item.length > 0) {
        } else {
            itemListArray.push(value);
        }
        setKitchensArray(itemListArray);
    };
    const handleSelect = (value) => {
        selectedKitchen(value);
    };
    const handleMultiSelect = (e) => {
        setKitchensArray(e);
        console.log("setKitchensArray");
        console.log(e);
        setKitchenItemsArray(kitchens.filter((kitchen) => e.includes(kitchen.id)));
    };
    // const onIpChange = (val) => {
    //     setIpAddress(val)
    //     if (printers.indexOf(val.toLowerCase()) == -1) {
    //         setError("")
    //     }
    //     else {
    //         setError(val + " already exist");
    //     }
    // }

    const props = {
        guide: false,
        placeholderChar: '\u2000',
        mask: value => Array(value.length).fill(/[\d.]/),
        pipe: value => {
            if (value === '.' || value.endsWith('..')) return false;

            const parts = value.split('.');
            if (
                parts.length > 4 ||
                parts.some(part => part === '00' || part < 0 || part > 255)
            ) {
                return false;
            }
            return value;
        },
    };

    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column', overflowX: 'auto', marginBottom: 20 }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.add_printer} />

                        <Grid container direction="column" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56, width: 500 }} >
                            <Grid data-qaid="createPrinter_name_grid" style={{ display: 'flex', flexDirection: 'column' }} >
                                <AvenirBlackH4 data-qaid="createPrinter_namelabel" label={Context.langData.printer_name} style={{ marginBottom: 12 }} />
                                <InputText
                                    data-qaid="createPrinter_nameinput"
                                    value={name}
                                    error={error}
                                    maxLength={64}
                                    onChange={(val) => onChange(val.target.value)}
                                    id="name"
                                    // info="name the printer after the kitchen station in which the printer is situated"
                                    inputProps={{ maxLength: 30 }}
                                />
                                <Grid item style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex', padding: 5, paddingTop: 5, paddingLeft: 0 }}>
                                    <Grid item xs={9} style={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', marginTop: 5 }}>
                                        <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                        <H5
                                            label={"Name the printer after the kitchen station in which the printer is situated"}
                                            style={{ paddingLeft: 3 }} />
                                    </Grid>
                                    <Grid item xs={3} style={{ justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: 5 }}>
                                        <H5 style={{ textAlign: 'right' }} label={(name?.length == undefined ? '0' : name?.length) + Context.langData._64_characters_count} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '55%' }}>
                                <AvenirBlackH4 data-qaid="" label={Context.langData.printer_model} style={{ marginBottom: 5 }} />
                                <SingleSelect
                                    list={printerModel}
                                    valueKey="name"
                                    displayKey="name"
                                    value={pModel}
                                    handleChange={(e) => setPmodel(e.target.value)}
                                    border={false}
                                    height={54}
                                    FormWidth={'100%'}
                                    borderStyle={'1px solid #707272'}
                                />
                            </Grid>
                            <Grid data-qaid="createPrinter_ipgrid" style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                <AvenirBlackH4 data-qaid="createPrinter_iplabel" label={Context.langData.ip_address} style={{ marginBottom: 12 }} />
                                {/* <InputText
                                    data-qaid="createPrinter_ipinput"
                                    value={ipAddress}
                                    error={ipAddresserror}
                                    onChange={(val) => onIpChange(val.target.value)}
                                    id="ip_address"
                                    inputPlaceholder="000.000.000.000"
                                /> */}
                                <MaskedInput
                                    {...props}
                                    style={{ height: 56, color: '#051D33', borderRadius: 4, paddingLeft: 10, border: ipAddresserror ? '1px solid #AB1212' : '1px solid #707272', fontSize: 16, fontFamily: 'AvenirLT' }}
                                    value={ipAddress}
                                    onChange={(val) => setIpAddress(val.target.value)}
                                />
                                {ipAddresserror &&
                                    <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginTop: 5 }}>
                                        <ExclamationIcon color="#AB1212" style={{ marginRight: 5 }} />
                                        <H5 label={ipAddresserror} style={{ color: '#AB1212 ' }} />
                                    </Grid>
                                }
                                <Grid style={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', paddingTop: 10 }}>
                                    <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                    <H5
                                        label={"Enter the IP address obtained from the receipt that was printed during initial set up of printer"}
                                        style={{ paddingLeft: 5 }} />
                                </Grid>
                            </Grid>
                            <Grid
                                data-qaid="assignPrinter_kitchenlistGrid"
                                style={{ width: "100%", marginTop: 10 }}>
                                <AvenirBlackH4
                                    data-qaid="assignPrinter_kitchenLabel"
                                    label={Context.langData.assign_Printer}
                                    style={{ marginTop: 10, paddingBottom: 10 }}
                                />
                                <MultiSelectChip
                                    list={kitchens}
                                    value="id"
                                    name="name"
                                    placeholder={Context.langData.select_kitchen}
                                    selectedValue={kitchensArray}
                                    handleChange={handleMultiSelect}
                                    border={false}
                                    height={56}
                                    width={498}

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="createPrinter_action_grid" container style={{ backgroundColor: '#FAFAFA', border: '1px solid #C3C3C3' }}>
                            <Grid data-qaid="createPrinter_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="createPrinter_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, width: 103, height: 40, border: '2px solid #051D33' }} >
                                    <AvenirBlackH4 data-qaid="createPrinter_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button className={style.buttonClass}
                                    data-qaid="createPrinter_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="createPrinter_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer >
    )
}


export default CreatePrinter;