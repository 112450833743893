import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    makeStyles,
    Button, Grid, TextField
} from '@material-ui/core';
import DataTable from '../../../../components/DataTable';
import styles from '../../../../styles/index';
import RightDrawer from '../../../../components/RightDrawer'
import InputText from '../../../../components/InputText';
import {
    fetchShifts,
    createShifts,
    EditShifts,
    deleteShifts,
} from '../../../../redux/actions';
import AlertDialog from '../../../../components/Dialog';
import moment from 'moment'
import CirclePluse from '../../../../components/SVG/PluseIcon';
import MyContext from '../../../../Provider/MyContext';
import { H4, AvenirBlackH4, H8, H5 } from '../../../../utils/text';
import DeleteIcon from '../../../../components/SVG/Delete';
import SnackBar from '../../../../components/SnackBar';
import SettingIcon from '../../../../components/SVG/Settings';
import CircleClose from '../../../../components/SVG/CloseCircle';
import _ from 'lodash';
import NoData from '../../../../components/NoData';
import TimeIcon from '../../../../components/SVG/TimeIcon';
import ExclamationIcon from '../../../../components/SVG/ExclamationIcon';
import { validateText } from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
    },
}));

const helperTextStyles = makeStyles(() => ({
    error: {
        "&.MuiFormHelperText-root.Mui-error": {
            color: '#AB1212',
            margin: 0
        }
    }
}));

const ShiftsList = () => {
    const style = styles();
    const Context = useContext(MyContext);
    const [columns, setColumns] = useState([
        { field: 'name', headerName: Context.langData.shift_name, size: '40%', sort: false },
        { field: 'start_time', headerName: Context.langData.start_time, size: '30%', sort: false },
        { field: 'end_time', headerName: Context.langData.end_tIme, size: '30%', sort: false }])
    const ShiftsData = useSelector(state => state.settingsReducer.shifts);
    const [selectedId, setSelectedId] = React.useState([]);
    const [btnDisable, setBtnDisable] = React.useState(true);
    const [openCreate, toggleCreateDrawer] = useState(false);
    const [openEdit, toggleEditDrawer] = useState(false);
    const [row, setRow] = useState({});
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [snackbar, setSnackbar] = useState(null);
    const [Shifts, setShifts] = useState([])
    const heading = selectedId.length > 1 ? Context.langData.delete_shift + 's' : Context.langData.delete_shift
    const bodyMessage = selectedId.length > 1 ? 'Are you sure you want to delete ' + selectedId.length + ' shifts?' : Context.langData.delete_shift_body

    useEffect(() => {
        if (ShiftsData.length > 0) {
            ShiftsData.map((item) => {
                item['start_time'] = moment(item.start_time, "HH:mm:ss").format("hh:mm a");
                item['end_time'] = moment(item.end_time, "HH:mm:ss").format("hh:mm a");
            })
            const filterShiftsData = _.reverse(ShiftsData);
            setShifts(filterShiftsData)
        } else {
            setShifts([])
        }

    }, [ShiftsData])
    function onCheckedIds(ids) {
        console.log(ids, 'dslkfjdl')
        setSelectedId(ids);
        if (ids.length > 0) {
            setBtnDisable(false);
        } else {
            setBtnDisable(true);
        }
    }
    function onClickAction(rowValue) {
        setRow(rowValue)
        toggleEditDrawer(true);
    }
    const btnProps = {};
    if (btnDisable) {
        btnProps["disabled"] = true
    }
    function commanCallback() {
        toggleCreateDrawer(false);
        toggleEditDrawer(false);
        dispatch(fetchShifts());
    }
    const createCallback = (isSuccess) => {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.shift_create_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.shift_create_failed, severity: 'error' });
        }
        commanCallback()
    }
    const editCallback = (isSuccess) => {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.shift_edit_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.shift_edit_failed, severity: 'error' });
        }
        commanCallback()
    }
    const deleteCallback = (isSuccess) => {
        if (isSuccess) {
            setSnackbar({ message: selectedId.length > 1 ? Context.langData.shifts_delete_succ : Context.langData.shift_delete_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: selectedId.length > 1 ? Context.langData.shifts_delete_failed : Context.langData.shift_delete_failed, severity: 'error' });
        }
        commanCallback()
    }
    const handleClose = (val) => {
        if (val) {
            console.log("error called", JSON.stringify(selectedId));
            dispatch(deleteShifts(JSON.stringify(selectedId), deleteCallback));
        }
        setShowDialog(false)
    }
    function deletePrinter(value) {
        if (selectedId.length > 0) {
            setShowDialog(value)
        }
    }
    const onPageinationChange = () => {
        console.log("on page change")
    }
    const sortingOnClick = (value, sortValue, index) => {
        var columnData = [...columns]
        columnData[index] = { ...columnData[index], sort: !sortValue }
        setColumns(columnData)
        var sortShifts = _.orderBy(Shifts, [value], [sortValue ? 'asc' : 'desc']);
        setShifts(sortShifts)
    }

    const handleCreateShift = () => {
        toggleCreateDrawer(true)
    }

    return (
        <div data-qaid="shifts_div" style={{ marginTop: '2%' }}>
            <>
                {
                    Shifts.length > 0 ?

                        <>
                            <Grid data-qaid="shifts_grid" item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginRight: 20, marginBottom: 10 }} >
                                <Button className={style.buttonClass} data-qaid="shifts_delete_button" style={{ marginRight: 10 }} variant="text" id="cancel" onClick={() => deletePrinter(true)} >
                                    <DeleteIcon data-qaid="shifts_deleteicon" width={16} height={16} style={{ marginRight: 5 }} />
                                    <AvenirBlackH4 data-qaid="shifts_delete_label" label={Context.langData.delete} />
                                </Button>
                                <Button className={style.buttonClass} data-qaid="shifts_create_button" style={{ backgroundColor: "#FFB600", color: "white" }} variant="contained" id="cancel" onClick={handleCreateShift}  >
                                    <CirclePluse data-qaid="shifts_createIcon" />
                                    <AvenirBlackH4 data-qaid="shifts_create_shift" label={Context.langData.create_shift} style={{ paddingLeft: 5 }} />
                                </Button>
                            </Grid>
                            <DataTable data-qaid="shifts_datatable" columns={columns} rows={Shifts} refresh={openCreate} onCheckedIds={onCheckedIds} onClickAction={onClickAction} onPageChange={onPageinationChange} sortingOnClick={sortingOnClick} />
                        </>
                        :
                        <NoData page="shift" callback={() => toggleCreateDrawer(true)} />
                }
            </>
            {
                openCreate &&
                <CreateShift
                    data-qaid="shifts_createShift"
                    open={openCreate}
                    onClose={() => toggleCreateDrawer(false)}
                    shifts={Shifts}
                    callback={createCallback}
                />
            }
            {
                openEdit &&
                <EditShiftDrawer
                    data-qaid="shifts_editShift"
                    open={openEdit}
                    row={row}
                    shifts={Shifts}
                    onClose={() => toggleEditDrawer(false)}
                    callback={editCallback}
                />
            }
            {
                snackbar &&
                <SnackBar
                    data-qaid="shifts_snackbar"
                    open={true}
                    setOpen={() => setSnackbar(null)}
                    severity={snackbar.severity}
                    message={snackbar.message}
                />
            }
            <AlertDialog
                data-qaid="shifts_alert"
                showDialog={showDialog}
                body={bodyMessage}
                heading={heading}
                IsError={false}
                handleClose={handleClose} />
        </div>
    );
};
const CreateShift = ({ open, callback, onClose, shifts }) => {
    const [name, setName] = useState("");
    const style = styles();
    const Context = useContext(MyContext);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [error, setError] = useState('');
    const [starttimeError, setStarttimeError] = useState(false);
    const [endTimeError, setEndimeError] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [timeValue, setTimeValue] = useState('');
    const helperTestClasses = helperTextStyles();
    const save = () => {
        var errorFlag = 0;
        if (name) {
            setError("")
        }
        else {
            errorFlag = errorFlag + 1
            setError(Context.langData.please_input_unique_name_for_shift);
        }
        const elementsIndex = shifts.findIndex(indexElement => indexElement.name.toUpperCase() == name.toUpperCase());
        if (elementsIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setError(name + " already exist");
        }
        if (endTime) {
        } else {
            errorFlag = errorFlag + 1
            setEndimeError(true);
        }
        if (startTime) {
        } else {
            errorFlag = errorFlag + 1

            setStarttimeError(true);
        }
        if (errorFlag == 0) {
            dispatch(createShifts({ end_time: endTime.trim(), start_time: startTime.trim(), name: name.trim() }, callback))
        } else {

        }

    }
    const onChange = (val) => {
        const upper = validateText(val);
        setName(upper)
        if (shifts.indexOf(upper.toLowerCase()) === -1) {
            setError("")
        }
        else {
            setError(val + " already exist");
        }
    }
    const startErrIcon = <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', paddingTop: 5 }}>
        <ExclamationIcon color='#AB1212' />
        <H5 label={Context.langData.please_enter_a_valid_start_time} style={{ marginLeft: 5, color: '#AB1212' }} />
    </Grid>

    const endErrIcon = <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', paddingTop: 5 }}>
        <ExclamationIcon color='#AB1212' />
        <H5 label={Context.langData.please_enter_a_valid_end_time} style={{ marginLeft: 5, color: '#AB1212' }} />
    </Grid>

    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Grid style={{ display: 'flex', height: '80%', flexDirection: 'column', overflowX: 'auto', marginBottom: 20 }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.create_shift} />
                        <Grid data-qaid="createShift_main_grid" container style={{ marginLeft: 56, marginRight: 56, width: 500, flexDirection: 'column' }}  >
                            <Grid data-qaid="createShift_namegrid" style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <AvenirBlackH4 data-qaid="createShift_namelabel" label={Context.langData.name} style={{ marginBottom: 12 }} />
                                <InputText
                                    data-qaid="createShift_nameip"
                                    value={name}
                                    error={error}
                                    maxLength={64}
                                    onChange={(val) => onChange(val.target.value)}
                                    id="name" />
                            </Grid>
                            <AvenirBlackH4 label={Context.langData.scheduling} style={{ paddingTop: 20 }} />
                            <Grid style={{ borderTop: '1px solid #EEE', marginTop: 10, marginBottom: 10 }}></Grid>
                            <Grid data-qaid="createShift_etimegrid" style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                <AvenirBlackH4 data-qaid="createShift_etimelabel" label={Context.langData.start_time} style={{ marginBottom: 12 }} />
                                {/* <Grid style={{flexDirection: 'row', alignItems: 'center', display: 'flex', width: '100%', justifyContent:'space-between', border: '1px solid #707272', borderRadius: 4, height: 56}}> */}
                                <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', position: 'relative' }}>
                                    <Grid style={{ position: 'absolute', paddingLeft: 10, paddingBottom: starttimeError ? 20 : 0 }}>
                                        <TimeIcon />
                                    </Grid>
                                    <TextField
                                        variant='outlined'
                                        data-qaid="createShift_starttime"
                                        id="start_time"
                                        type="time"
                                        defaultValue="00:00"
                                        error={starttimeError}
                                        helperText={starttimeError ? startErrIcon : ''}
                                        value={startTime}
                                        className={classes.textField}
                                        FormHelperTextProps={{ classes: helperTestClasses }}
                                        title=""
                                        onChange={(e) => setStartTime(e.target.value)}
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                            style: {
                                                paddingLeft: 30,
                                                color: '#051D33'
                                            }
                                        }}
                                    />
                                </Grid>
                                {/* <Grid style={{paddingRight: 10}}>
                                        <DownVector color={'#051D33'} style={{cursor:'auto'}}/>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                            <Grid data-qaid="createShift_etimegrid" style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                <AvenirBlackH4 data-qaid="createShift_etimelabel" label={Context.langData.end_tIme} style={{ marginBottom: 12 }} />
                                <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', position: 'relative' }}>
                                    <Grid style={{ position: 'absolute', paddingLeft: 10, paddingBottom: endTimeError ? 20 : 0 }}>
                                        <TimeIcon />
                                    </Grid>
                                    <TextField
                                        variant='outlined'
                                        data-qaid="createShift_endtimetext"
                                        id="end_time"
                                        type="time"
                                        defaultValue="00:00"
                                        value={endTime}
                                        title=""
                                        onChange={(e) => setEndTime(e.target.value)}
                                        className={classes.textField}
                                        error={endTimeError}
                                        FormHelperTextProps={{ classes: helperTestClasses }}
                                        helperText={endTimeError ? endErrIcon : ''}
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                            style: {
                                                paddingLeft: 30,
                                                color: '#051D33'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="createshift_action_grid" container style={{ backgroundColor: '#FAFAFA', border: '1px solid #C3C3C3' }}>
                            <Grid data-qaid="createshift_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="createshift_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, height: 40, width: 103, border: '2px solid #051D33' }} >
                                    <AvenirBlackH4 data-qaid="createshift_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="createshift_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="createshift_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer>
    )
}

const EditShiftDrawer = ({ open, row, callback, onClose, shifts }) => {
    const style = styles();
    const Context = useContext(MyContext);
    const [name, setName] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [error, setError] = useState(null);
    const [starttimeError, setStarttimeError] = useState(false);
    const [endTimeError, setEndimeError] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [id, setId] = useState("");
    const helperTestClasses = helperTextStyles();

    useEffect(() => {
        if (row) {
            setId(row.id);
            setName(row.name);
            setStartTime(moment(row.start_time, 'hh:mm a').format('HH:mm'));
            setEndTime(moment(row.end_time, 'hh:mm a').format('HH:mm'));
        }
    }, [])

    const save = () => {
        var errorFlag = 0;

        var isArray = shifts.filter((e) => e.id != id);
        var elementsIndex = isArray.findIndex((e) => e.name.toUpperCase() == name.toUpperCase());

        if (elementsIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1
            setError(name + " already exist");
        }
        if (endTime) {
        } else {
            errorFlag = errorFlag + 1
            setEndimeError(true);
        }
        if (startTime) {
        } else {
            errorFlag = errorFlag + 1

            setStarttimeError(true);
        }
        if (name) {
        } else {
            errorFlag = errorFlag + 1
            setError(Context.langData.no_text_error);
        }
        if (errorFlag == 0) {
            dispatch(EditShifts({ end_time: endTime.trim(), start_time: startTime.trim(), name: name.trim(), shift_id: id }, callback))
            setError("")
        }
        else {
            // setError(name + " already exist");
        }
    }
    const onChange = (val) => {
        const validatedText = validateText(val);
        setName(validatedText)
        if (shifts.indexOf(validatedText.toLowerCase()) === -1) {
            setError("")
        }
        else {
            setError(validatedText + " already exist");
        }
    }
    const startErrIcon = <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', paddingTop: 5 }}>
        <ExclamationIcon color='#AB1212' />
        <H5 label={Context.langData.please_enter_a_valid_start_time} style={{ marginLeft: 5, color: '#AB1212' }} />
    </Grid>

    const endErrIcon = <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', paddingTop: 5 }}>
        <ExclamationIcon color='#AB1212' />
        <H5 label={Context.langData.please_enter_a_valid_end_time} style={{ marginLeft: 5, color: '#AB1212' }} />
    </Grid>

    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Grid style={{ display: 'flex', height: '80%', overflowX: 'auto', marginBottom: 0, flexDirection: 'column' }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.edit_shift} />
                        <Grid data-qaid="createShift_main_grid" container style={{ marginLeft: 56, marginRight: 56, width: 500, flexDirection: 'column' }}  >
                            <Grid data-qaid="editShift_namegrid" style={{ display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="editShift_namelabel" label={Context.langData.name} style={{ marginBottom: 12 }} />
                                <InputText
                                    data-qaid="editShift_nameip"
                                    value={name}
                                    error={error}
                                    maxLength={64}
                                    onChange={(val) => onChange(val.target.value)}
                                    id="name" />
                            </Grid>
                            <AvenirBlackH4 label={Context.langData.scheduling} style={{ paddingTop: 20 }} />
                            <Grid style={{ borderTop: '1px solid #EEE', marginTop: 10, marginBottom: 10 }}></Grid>
                            <Grid data-qaid="editShift_etimegrid" style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                <AvenirBlackH4 data-qaid="editShift_etimelabel" label={Context.langData.start_time} style={{ marginBottom: 12 }} />
                                <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', position: 'relative' }}>
                                    <Grid style={{ position: 'absolute', paddingLeft: 10, paddingBottom: starttimeError ? 20 : 0 }}>
                                        <TimeIcon />
                                    </Grid>
                                    <TextField
                                        variant='outlined'
                                        data-qaid="editShift_starttime"
                                        id="start_time"
                                        type="time"
                                        error={starttimeError}
                                        title=""
                                        helperText={starttimeError ? startErrIcon : ''}
                                        FormHelperTextProps={{ classes: helperTestClasses }}
                                        defaultValue="00:00"
                                        value={startTime}
                                        InputProps={{ disableUnderline: true }}
                                        className={classes.textField}
                                        onChange={(e) => setStartTime(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                            style: {
                                                paddingLeft: 30,
                                                color: '#051D33'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid data-qaid="editShift_etimegrid" style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                <AvenirBlackH4 data-qaid="editShift_etimelabel" label={Context.langData.end_tIme} style={{ marginBottom: 12 }} />
                                <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', position: 'relative' }}>
                                    <Grid style={{ position: 'absolute', paddingLeft: 10, paddingBottom: endTimeError ? 20 : 0 }}>
                                        <TimeIcon />
                                    </Grid>
                                    <TextField
                                        variant='outlined'
                                        data-qaid="editShift_endtimetext"
                                        id="end_time"
                                        type="time"
                                        defaultValue="00:00"
                                        title=""
                                        value={endTime}
                                        error={endTimeError}
                                        FormHelperTextProps={{ classes: helperTestClasses }}
                                        helperText={endTimeError ? endErrIcon : ''}
                                        onChange={(e) => setEndTime(e.target.value)}
                                        className={classes.textField}
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                            style: {
                                                paddingLeft: 30,
                                                color: '#051D33'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="editShift_action_grid" container style={{ backgroundColor: '#FAFAFA', border: '1px solid #C3C3C3' }}>
                            <Grid data-qaid="editShift_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="editShift_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, border: '2px solid #051D33', height: 40, width: 103 }} >
                                    <AvenirBlackH4 data-qaid="editShift_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="editShift_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="editShift_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer >
    )
}
export default ShiftsList;
