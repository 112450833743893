import React, { useState, useContext, useEffect } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import LeftVector from '../components/SVG/LeftVector';
import SingleLeftVector from '../components/SVG/SingleLeftVector';
import SingleRightVector from '../components/SVG/SingleRightVector';
import RightVector from '../components/SVG/RightVector';
import MyContext from '../Provider/MyContext';
import IconButton from '@material-ui/core/IconButton';
const CustomPagination = ({ offset = 0, total, onChange }) => {
  const [currentPage, setCurrentPage] = useState(offset ? offset + 1 : 1);
  const Context = useContext(MyContext);
  const totalCount = total;
  useEffect(() => {
    if (currentPage !== '' && onChange) {
      onChange(currentPage);
    }
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(offset + 1);
  }, [offset]);
  const onIncreasePage = () => {
    if (total > currentPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const onDecreasePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onLastPage = () => {
    setCurrentPage(total);
  };
  const onFirstPage = () => {
    setCurrentPage(1);
  };
  const ongotoPage = (value) => {
    if (value <= total || value == '') {
      if (value >= 1 && value <= total) {
        setCurrentPage(parseInt(value));
      } else if (value == '') {
        setCurrentPage('');
      } else {
      }
    }
  };
  const btnProps = {};
  if (currentPage === 1) {
    btnProps['disabled'] = true;
  }

  return (
    <Grid data-qaid='custom_pagination_main_grid' container>
      <Grid
        data-qaid='custom_pagination_content_grid'
        item
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}>
        <IconButton
          {...btnProps}
          data-qaid='custom_pagination_leftvecto_iconbutton'
          onClick={onFirstPage}>
          <LeftVector
            data-qaid='custom_pagination_leftvector_icon'
            color={currentPage == 1 ? '#C3C3C3' : '#004C60'}
            style={{ cursor: 'pointer' }}></LeftVector>
        </IconButton>
        <IconButton
          {...btnProps}
          data-qaid='custom_pagination_single_left_iconbutton'
          onClick={onDecreasePage}>
          <SingleLeftVector
            color={currentPage == 1 ? '#C3C3C3' : '#004C60'}
            data-qaid='custom_pagination_single_left_icon'
            style={{ cursor: 'pointer', marginRight: 5 }}
          />
        </IconButton>
        <div
          data-qaid='custom_pagination_number_div'
          style={{
            width: 64,
            backgroundColor: '#FAFAFA',
            height: 56,
            borderRadius: 4,
            border: '2px solid #C3C3C3',
            outline: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <TextField
            data-qaid='custom_pagination_textinputcomponet'
            value={currentPage}
            InputProps={{
              disableUnderline: true,
            }}
            style={{ textAlign: 'center', paddingLeft: 25 }}
            onChange={(e) => {
              ongotoPage(e.target.value);
            }}
            variant='standard'
          />
        </div>
        <span
          data-qaid='custom_pagination_of span'
          style={{ paddingLeft: '15px', paddingRight: '5px' }}>
          <Typography variant='h5'>{Context.langData.of}</Typography>
        </span>
        <span
          data-qaid='custom_pagination_total_span'
          style={{ paddingRight: '5px' }}>
          <Typography variant='h5'>{total === 0 ? 1 : total}</Typography>
        </span>
        <IconButton
          data-qaid='custom_pagination_single_right_iconbutton'
          onClick={onIncreasePage}>
          <SingleRightVector
            color={currentPage === totalCount ? '#C3C3C3' : '#004C60'}
            data-qaid='custom_pagination_single_right_icon'
            style={{ cursor: 'pointer' }}
          />
        </IconButton>
        <IconButton
          data-qaid='custom_pagination_rightVector_iconbutton'
          onClick={onLastPage}>
          <RightVector
            color={currentPage === totalCount ? '#C3C3C3' : '#004C60'}
            data-qaid='custom_pagination_rightVector_icon'
            style={{ cursor: 'pointer' }}></RightVector>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CustomPagination;
