import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button, MenuItem, Menu } from '@material-ui/core';
import {
  fetchAlcoholReports,
  fetchOnlineSalesSummary,
} from '../../../redux/actions';
import _ from 'lodash';
import RightDrawer from '../../../components/RightDrawer';
import Daterange from '../../../components/DateRange';
import { AvenirBlackH4, H2, H8, H3, H4, DotText } from '../../../utils/text';
import MultiSelect from '../../../components/MultiSelect';
import MyContext from '../../../Provider/MyContext';
import CircleClose from '../../../components/SVG/CircleClose';
import DownlodeIcon from '../../../components/SVG/DownlodeIcon';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import TableWithoutCheckbox from '../../../components/TableWithoutCheckbox';
import EmployeeDropDown from '../../../components/EmployeeDropDown';
import { downloadAlcoholSales } from '../../../utils/excel';
import styles from '../../../styles/index';
import CenterModal from '../../../components/CenterModal';
import SingleSelect from '../../../components/SingleSelect';
import { ConvertPrice } from '../../../utils/util';
import { downloadPdfDocument } from '../../../components/PDFTableGenerator';

const AlcoholSales = () => {
  const dispatch = useDispatch();
  const alcoholReports = useSelector(
    (state) => state.salesReportReducer.alcoholReports
  );
  const [table1, setTable1] = useState([]);
  const Context = useContext(MyContext);
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const [date, setDate] = useState({startDate: moment().startOf('day').format("YYYY-MM-DD"), endDate:moment().endOf('day').format("YYYY-MM-DD")});
  const [ordertype, setOrderType] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [resetDate, toggleResetDate] = useState(false);
  const [row, setRow] = useState({});
  const onDateChange = (d) => {
    if (JSON.stringify(date) !== JSON.stringify(d)) {
      setDate(d);
    }
  };

  useEffect(() => {
    handleApplyDropdown();
  }, [date, ordertype, empData]);

  const handleApplyDropdown = () => {
    let obj = {
      from_date: date.startDate,
      to_date: date.endDate
    };
    if (ordertype || empData.length > 0) {
      obj = {
        ...obj,
        order_types: ordertype.join(','),
        employee_id: empData.join(','),
      };
    }
    dispatch(fetchAlcoholReports(obj));
  };
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    if (alcoholReports?.sales?.length > 0) {
      alcoholReports?.sales?.map((item) => {
        const purchaseData = moment.unix(item.purchase_time);

        item['purchase_date'] = purchaseData.format('MMMM DD, yyyy');
        item['price'] = '$' + ConvertPrice(String(item.price));
        item['delivered_by'] =
          item.delivered_by == '' ? '-' : item.delivered_by;
      });
      setTable1(alcoholReports.sales);
    } else {
      setTable1([]);
    }
  }, [alcoholReports]);

  const valueArray = [
    { name: Context.langData.csv, value: 1 },
    { name: Context.langData.xls, value: 2 },
  ];

  const [alcoholColumns, setAlcoholColumns] = useState([
    { field: 'date', display: 'Date', size: '33%', desc: true },
    {
      field: 'price',
      display: Context.langData.price,
      size: '33%',
      desc: true,
    },
    {
      field: 'order_type',
      display: Context.langData.order_types,
      size: '33%',
      desc: true,
    },
  ]);

  const onClickView = (rowValue) => {
    setRow(rowValue);
    setShowSummary(true);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const downloadExcel = () => {
    downloadAlcoholSales(table1);
    handleCloseMenu();
  };
  const downloadPDF = () => {
    const fileName = 'AlcoholSales';
    downloadPdfDocument(
      alcoholColumns,
      table1,
      Context.langData.alcohol_sales,
      date,
      fileName
    );
    handleCloseMenu();
  };

  const handleSort = (name, desc, index) => {
    var data = [...alcoholColumns];
    data[index] = { ...data[index], desc: desc };
    setAlcoholColumns(data);
    if (name == 'price') {
      var sortAlcohol = _.orderBy(
        table1,
        [(d) => parseFloat(d[name].replace(/[^0-9.-]+/g, ''))],
        [desc ? 'asc' : 'desc']
      );
    } else {
      var sortAlcohol = _.orderBy(table1, [name], [desc ? 'asc' : 'desc']);
    }
    setTable1(sortAlcohol);
  };
  const onClear = () => {
    setOrderType([]);
    setEmpData([]);
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  return (
    <Grid
      data-qaid="alcoholSales_mainContainer"
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid container>
        <Grid
          item
          lg={9}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
          }}>
          <Grid data-qaid="alcoholSales_dateGrid" item style={{}}>
            <AvenirBlackH4
              data-qaid="alcoholSales_dateLabel"
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid="alcoholSales_dateRange"
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>
          <Grid
            data-qaid="alcoholSales_orderTypeGrid"
            item
            style={{ paddingLeft: 10 }}>
            <AvenirBlackH4
              data-qaid="alcoholSales_orderTypeLabel"
              label={Context.langData.order_types}
            />
            <MultiSelect
              list={ordertypes}
              value="otype_id"
              name="otype_name"
              placeholder="All order types"
              selectedValue={ordertype}
              handleChange={(e) => setOrderType(e)}
              border={false}
              height={56}
              width={220}
            />
          </Grid>
          <EmployeeDropDown
            selectedValue={empData}
            handleChange={setEmpData}
            lg={5}
          />
        </Grid>
        <Grid
          data-qaid="alcoholSales_contextMenuGrid"
          item
          lg={3}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}>
          <Grid
            data-qaid="daywiseSales_clearGrid"
            onClick={onClear}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 20,
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <CircleClose data-qaid="daywiseSales_closeIcon"></CircleClose>
            <AvenirBlackH4
              data-qaid="daywiseSales_clearLabel"
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid="daywiseSales_downloadButtonView"
            style={{
              backgroundColor: '#FFB600',
              height: 40,
              marginRight: 0,
              width: '149px',
            }}
            variant="contained"
            id="cancel">
            <DownlodeIcon
              data-qaid="daywiseSales_downlodeIcon"
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid="daywiseSales_downloadeLabel"
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id="simple-menu-download"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      {showDownloadModal && (
        <DownloadAlcoholsale
          closeModal={() => {
            setShowDownloadModal(false);
          }}
          showModal={showDownloadModal}
          valueArray={valueArray}
          table1={table1}
        />
      )}

      <Grid
        item
        lg={12}
        data-qaid="alcoholSales_simpleTableGrid"
        style={{ marginTop: 40 }}>
        <TableWithoutCheckbox
          columns={alcoholColumns}
          rows={table1}
          onClickView={onClickView}
          handleSort={handleSort}
        />
      </Grid>
      {showSummary && (
        <SummaryModal
          openModal={showSummary}
          closeModal={() => setShowSummary(false)}
          row={row}
        />
      )}
    </Grid>
  );
};

const SummaryModal = ({ openModal, closeModal, row }) => {
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const onlineSalesSummary = useSelector(
    (state) => state.salesReportReducer.onlineSalesSummary
  );

  const dateConvert = moment.unix(onlineSalesSummary.ts);

  useEffect(() => {
    dispatch(fetchOnlineSalesSummary({ tx_id: row.tx_id }));
  }, [row]);

  return (
    <RightDrawer
      data-qaid="createcomment_centermodal"
      open={openModal}
      padding={1}>
      <Grid
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          width: 500,
        }}>
        <Grid
          data-qaid="createcategory_close_grid"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
          }}>
          <CircleClose
            data-qaid="createcategory_closeicon"
            size={20}
            onClick={closeModal}
            style={{ marginRight: 40, marginTop: 20 }}
          />
        </Grid>
        <Grid style={{ padding: 20 }}>
          <H8 label={Context.langData.sales_summary} />
          <Grid style={{ marginTop: 30 }}>
            <H3 label={Context.langData.order_information} />
            <Grid style={{ marginTop: 10, borderBottom: '1px solid #EEE' }} />
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              <Grid>
                <AvenirBlackH4 label={Context.langData.order_number} />
                <AvenirBlackH4
                  label={Context.langData.purchase_date}
                  style={{ marginTop: 5 }}
                />
                <AvenirBlackH4
                  label={Context.langData.total_price}
                  style={{ marginTop: 5 }}
                />
              </Grid>
              <Grid
                style={{
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <H4 label={onlineSalesSummary?.bill_no} />
                <H4
                  label={dateConvert.format('MMMM DD, yyyy')}
                  style={{ marginTop: 5 }}
                />
                <H4
                  label={
                    onlineSalesSummary?.total_price == null
                      ? '-'
                      : '$' + onlineSalesSummary?.total_price
                  }
                  style={{ marginTop: 5 }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid style={{ marginTop: 30 }}>
            <H3 label={Context.langData.items_ordered} />
            <Grid
              style={{
                marginTop: 10,
                borderBottom: '1px solid #EEE',
                marginBottom: 10,
              }}
            />
            {onlineSalesSummary?.items?.map((item) => {
              return (
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 5,
                  }}>
                  <Grid
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <AvenirBlackH4
                      label={
                        item?.quantity == null ? '-' : item?.quantity + ' x '
                      }
                    />
                    <DotText
                      label={item?.item_name}
                      style={{ width: '150px', paddingLeft: 6 }}
                    />
                  </Grid>
                  <Grid
                    style={{
                      alignItems: 'flex-end',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <H4 label={'$' + item?.price} />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <Grid style={{ marginTop: 30 }}>
            <H3 label={Context.langData.cnt_info} />
            <Grid style={{ marginTop: 10, borderBottom: '1px solid #EEE' }} />
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              <Grid>
                <AvenirBlackH4
                  label={Context.langData.customer_name}
                  style={{ marginTop: 5 }}
                />
                <AvenirBlackH4
                  label={Context.langData.customer_address}
                  style={{ marginTop: 5 }}
                />
                <AvenirBlackH4
                  label={Context.langData.email_header}
                  style={{ marginTop: 5 }}
                />
                <AvenirBlackH4
                  label={Context.langData.phone_number}
                  style={{ marginTop: 5 }}
                />
              </Grid>
              <Grid
                style={{
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <H4
                  label={`${onlineSalesSummary?.delivery?.first_name == null
                    ? '-'
                    : onlineSalesSummary?.delivery?.first_name
                    } ${onlineSalesSummary?.delivery?.last_name == null
                      ? '-'
                      : onlineSalesSummary?.delivery?.last_name
                    }`}
                  style={{ marginTop: 5 }}
                />
                <H4
                  label={
                    onlineSalesSummary?.delivery?.address?.address == null
                      ? '-'
                      : onlineSalesSummary?.delivery?.address?.address
                  }
                  style={{ marginTop: 5 }}
                />
                <H4
                  label={
                    onlineSalesSummary?.delivery?.email == null
                      ? '-'
                      : onlineSalesSummary?.delivery?.email
                  }
                  style={{ marginTop: 5 }}
                />
                <H4
                  label={
                    onlineSalesSummary?.delivery?.phone_number == null
                      ? '-'
                      : onlineSalesSummary?.delivery?.phone_number
                  }
                  style={{ marginTop: 5 }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: 30 }}>
            <H3 label={Context.langData.delivery_information} />
            <Grid style={{ marginTop: 10, borderBottom: '1px solid #EEE' }} />
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              <Grid>
                <AvenirBlackH4
                  label={Context.langData.delivery_address}
                  style={{ marginTop: 5 }}
                />
                <AvenirBlackH4
                  label={Context.langData.delivery_date}
                  style={{ marginTop: 5 }}
                />
                <AvenirBlackH4
                  label={Context.langData.delivery_fee}
                  style={{ marginTop: 5 }}
                />
                <AvenirBlackH4
                  label={Context.langData.delivered_by}
                  style={{ marginTop: 5 }}
                />
                <AvenirBlackH4
                  label={Context.langData.name_of_licensee}
                  style={{ marginTop: 5 }}
                />
                <AvenirBlackH4
                  label={Context.langData.licensee_address}
                  style={{ marginTop: 5 }}
                />
              </Grid>
              <Grid
                style={{
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <H4 label={'-'} style={{ marginTop: 5 }} />
                <H4 label={'-'} style={{ marginTop: 5 }} />
                <H4
                  label={
                    onlineSalesSummary?.delivery_charges == null
                      ? '-'
                      : '$' + onlineSalesSummary?.delivery_charges
                  }
                  style={{ marginTop: 5 }}
                />
                <H4 label={'-'} style={{ marginTop: 5 }} />
                <H4 label={'-'} style={{ marginTop: 5 }} />
                <H4 label={'-'} style={{ marginTop: 5 }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};
const DownloadAlcoholsale = ({
  showModal,
  closeModal,
  table1,
  fileName,
  dropDownBody,
  valueArray,
  deleteFileCallback,
  upload,
}) => {
  const [name, setName] = useState('');
  const style = styles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);

  const isDisable = name ? false : true;

  const triggerDownload = () => {
    if (name == 2) {
      downloadAlcoholSales(table1);
    } else if (name == 1) {
      let data = table1
        .map((row) => {
          return `${row.date},${row.price},${row.order_type}`;
        })
        .join('\n');
      let csvContent =
        'data:text/csv;charset=utf-8,' + 'Date,Price,Order type\n' + data;
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute(
        'download',
        `Alcoholsale${moment().format('yyyyMMDDHHmmss')}.csv`
      );
      document.body.appendChild(link); // Required for FF
      link.click();
    }
  };

  return (
    <CenterModal open={showModal} onClose={closeModal}>
      <Grid
        style={{
          width: 495,
          height: 220,
        }}>
        <Grid style={{ margin: 32 }}>
          <Grid
            style={{
              height: 120,
            }}>
            <H2
              label={Context.langData.download_options}
              style={{ marginBottom: 12 }}
            />
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 12,
              }}>
              <AvenirBlackH4
                data-qaid="createcomment_valuelabel"
                label={Context.langData.file_format}
                style={{ marginBottom: 8 }}
              />
              <SingleSelect
                id="innerView"
                data-qaid="alltrancation_ordertype_singleselect"
                list={valueArray}
                value={name}
                valueKey="value"
                displayKey="name"
                placeholder="Select option"
                handleChange={(e) => {
                  setName(e.target.value);
                }}
                border={false}
                height={56}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            height: 106,
            backgroundColor: '#FAFAFA',
            borderTop: '1px solid  #C3C3C3',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <Button
            onClick={closeModal}
            className={style.buttonClass}
            data-qaid="items_creategrid"
            style={{
              backgroundColor: '#FFFFFF',
              marginRight: 24,
              border: '2px solid #051D33',
              width: 103,
              height: 40,
            }}
            variant="outlined">
            <H4 data-qaid="items_createlabel" label={Context.langData.cancel} />
          </Button>
          <Button
            onClick={triggerDownload}
            className={style.buttonClass}
            data-qaid="items_creategrid"
            style={{
              backgroundColor: !isDisable ? '#FFB600' : '#c1c1c1',
              height: 40,
              marginRight: 32,
            }}
            disabled={isDisable}
            variant="contained">
            <DownlodeIcon
              data-qaid="daywiseSales_downlodeIcon"
              style={{ marginRight: 10 }}
            />
            <H4
              data-qaid="items_createlabel"
              label={Context.langData.download}
            />
          </Button>
        </Grid>
      </Grid>
    </CenterModal>
  );
};
export default AlcoholSales;
