import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Grid, Typography, Button, FormHelperText } from '@material-ui/core'
import InputText from '../../../components/InputText'
import SingleSelect from '../../../components/SingleSelect'
import { useSelector } from 'react-redux';
import ExclamationIcon from '../../../components/SVG/ExclamationIcon';
import { H2, AvenirBlackH4, H3 } from '../../../utils/text';
import CancelButton from '../../../components/CancelButton'
import { faClosedCaptioning } from '@fortawesome/free-solid-svg-icons';
import MyContext from '../../../Provider/MyContext';
const RenameSection = ({ onSave, onClose, data }) => {
  const ordertypes = useSelector(state => state.metaReducer.ordertypes)
  const [name, setName] = useState('')
  const [otype, setOtype] = useState('')
  const [nameError, setNameerror] = useState('');
  const Context = useContext(MyContext);
  const [ordertypeError, setOrdertypeError] = useState('');
  const [orderTypeData, setOrderTypeData] = useState([])
  var filterList = useMemo(() => {
    return ordertypes.filter(e => e.delivery_mode_id == 1 && e.order_channel_id == 1);
  }, [ordertypes])
  const save = () => {
    console.log(name, otype)
    if (name && otype) {
      onSave({ name, otype_id: otype })
    } else {
      if (!otype) {
        setOrdertypeError(Context.langData.ordertype_name_req_error)
      }
      if (!name) {
        setNameerror(Context.langData.section_name_req_error);
      }
    }

  }
  useEffect(() => {
    setName(data.name);
    setOtype(data.otype_id)
  }, [data])
  return (
    <Grid container style={{ padding: 30, borderRadius: 10, maxWidth: 400 }}>
      <Grid item xs={12}>
        <H2 label={Context.langData.rename_section} style={{ fontWeight: 800 }} />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <InputText
          error={nameError}
          maxLength={64}
          inputProps={{
            maxlength: 64,
            disableUnderline: true,
          }}
          variant="outlined"
          placeholder="Section name"
          value={name}
          onChange={(e) => { setName(e.target.value) }}

        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <SingleSelect
          list={filterList}
          handleChange={(e) => setOtype(e.target.value)}
          displayKey="otype_name"
          value={otype}
          valueKey="otype_id"
          placeholder="Select order type"
          border={false}
          minWidth={'100%'}
          height={56}
        />
        {
          ordertypeError &&
          <FormHelperText data-qaid="inputtext_formhelpertext" style={{ color: "#AB1212", display: 'flex', margin: '8px 0px 0px 0px' }}>
            <ExclamationIcon color="#AB1212" style={{ marginRight: 10 }} />
            {ordertypeError}
          </FormHelperText>
        }
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ paddingLeft: 50, marginTop: 20 }} justify="flex-end">
          <Grid item xs={6} style={{ padding: 10 }}>
            {/* <Button onClick={onClose} variant="contained" size="large" style={{ backgroundColor: '#00B6C9', width: '100%' }}>
              <Typography variant="h5">Cancel</Typography>
            </Button> */}
            <CancelButton onClick={onClose} />
          </Grid>
          <Grid item xs={6}>
            <Grid container style={{ padding: 10 }}>
              <Button onClick={save} variant="contained" size="large" style={{ border: '2px solid #ffb600', backgroundColor: '#FFB600', width: '100%', padding: '10px 0px' }}>
                <Typography variant="h5">Save</Typography>
              </Button>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RenameSection
