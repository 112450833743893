import * as React from "react"

const MailIcon = (props) => (
    <svg
        width={49}
        height={48}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 9.5A2.512 2.512 0 0 0 6 12v24c0 1.372 1.128 2.5 2.5 2.5h32c1.372 0 2.5-1.128 2.5-2.5V12c0-1.372-1.128-2.5-2.5-2.5h-32ZM3 12c0-3.028 2.472-5.5 5.5-5.5h32c3.028 0 5.5 2.472 5.5 5.5v24c0 3.028-2.472 5.5-5.5 5.5h-32A5.512 5.512 0 0 1 3 36V12Z"
            fill="#004C60"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.271 11.14a1.5 1.5 0 0 1 2.09-.369L24.5 24.17l19.14-13.398a1.5 1.5 0 1 1 1.72 2.458l-20 14a1.5 1.5 0 0 1-1.72 0l-20-14a1.5 1.5 0 0 1-.369-2.09Z"
            fill="#004C60"
        />
    </svg>
)

export default MailIcon
