import React, { useCallback, useState, useContext } from 'react';
import { Paper, Grid, Typography, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Currency from '../../../components/Currency';
import clsx from 'clsx';
import RefreshButton from '../../../components/RefreshButton';
import { H3, TBold, H4, H6 } from '../../../utils/text'
import MyContext from '../../../Provider/MyContext';
import { ConvertPrice } from '../../../utils/util';
const RunningSales = ({ classes, refresh }) => {
  const live = useSelector(state => state.dashboard.live);
  const Context = useContext(MyContext);
  const liveData = useCallback(() => {
    if (live.data) {
      return live.data;
    }
    else {
      return [{ "sales": 0, "type": "Sub Total" }, { "sales": 0, "type": "Discount" }, { "sales": 0, "type": "Tax" }]
    }
  }, [live]);
  const liveSales = liveData();
  return (
    <Paper data-qaid="runningsales_paper" className={clsx(classes.paper, classes.noSidePaddingsale, classes.relative)} style={{ backgroundColor: '#F3FAFD', border: '1px solid #C8E6F1' }}>
      {
        live &&
        <Grid data-qaid="runningsales_container_grid" container justify="center">
          <Grid data-qaid="runningsales_title_grid" container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Grid data-qaid="runningsales_title_grid2" item sm={12} lg={6} md={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 32 }}>
              <H3 data-qaid="runningsales_title" label={Context.langData.running_sales}></H3>
            </Grid>

            <Grid data-qaid="runningsales_refresh_grid" item sm={12} lg={5} md={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <RefreshButton data-qaid="runningsales_refresh_button" refresh={refresh} />
            </Grid>
          </Grid>
          <Grid data-qaid="runningsales_body_grid" container style={{ display: 'flex', justifyContent: 'flex-start', color: '#f2957a' }} >
            <Grid data-qaid="runningsales_child_body1" item sm={12} lg={12} md={12} style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginLeft: 32 }}>
              <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginRight: 32 }}>
                <H6 data-qaid="runningsales_live_text" label={`$${ConvertPrice(live.Total)}`} style={{ display: 'flex' }} ></H6>
                <H4 data-qaid="runningsales_total_value" style={{ color: "#54575A" }} label={Context.langData.in_progress} ></H4>
              </Grid>
              <Grid data-qaid="runningsales_num_grid" item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginRight: 32 }}>
                <H6 data-qaid="runningsales_num_header" label={live.num_tables || 0} style={{ display: 'flex' }} ></H6>
                <H4 data-qaid="runningsales_open_value" style={{ color: "#54575A" }} label={Context.langData.open_tables} ></H4>
              </Grid>
              <Grid data-qaid="runningsales_onlie_grid" item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <H6 data-qaid="runningsales_online" label='0' style={{ display: 'flex' }} ></H6>
                <H4 data-qaid="runningsales_online_value" style={{ color: "#54575A" }} label={Context.langData.online} ></H4>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container direction='row' justify="space-around" className={classes.subSection}>
            {
              liveSales.map((sale, index) => {
                return (
                  <Grid item key={index}>
                    <Typography variant="h6" component="div" gutterBottom>{sale.sales}</Typography>
                    <Typography variant="caption" component="div">{sale.type}</Typography>
                    {(liveSales.length - 1) !== index && <Divider orientation="vertical" flexItem />}
                  </Grid>

                )
              })
            }
          </Grid> */}
        </Grid>
      }
    </Paper >
  )
}

export default RunningSales