import React, { useState, useEffect, useContext } from 'react';
import RightDrawer from '../../../../components/RightDrawer';
import {
    Grid,
    Button,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SingleSelect from '../../../../components/SingleSelect';
import {
    fetchAllGroupsData,
    fetchDepartments,
    fetchAllStocks,
    saveIssue
} from '../../../../redux/actions';
import Delete from '../../../../components/SVG/Delete';
import moment from 'moment'
import MyContext from '../../../../Provider/MyContext';
import { AvenirBlackH4, AvenirBlackH7, AvenirBlackH3, H4, DotText } from '../../../../utils/text';
import CloseCircle from '../../../../components/SVG/CloseCircle';
import InventoryIcon from '../../../../components/SVG/InventoryIcon';
import InputText from '../../../../components/InputText';
import styles from '../../../../styles/index';
import SnackBar from '../../../../components/SnackBar';
import AutoCompliteDropDown from '../../../../components/AutoComplete';
import SingleDate from '../../../../components/SingleDate';
import { ConvertDateToUTCFormat } from '../../../../utils/util';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        "& $notchedOutline": {
            borderColor: "#707272",
        },
        "&:hover $notchedOutline": {
            borderColor: "#707272",
        },
        "&$focused $notchedOutline": {
            borderColor: "#707272",
        },
        "&.Mui-error": {
            borderColor: "#AB1212"
        },
    },
    focused: {},
    notchedOutline: {}
})
const ViewSendStock = ({ open, onClose, data, callback }) => {
    const style = styles();
    const classes = useStyles();
    const dispatch = useDispatch();
    const groupsData = useSelector(state => state.inventory.groupsData);
    const kitchenData = useSelector(state => state.inventory.departments);
    const getStocks = useSelector((state) => state.inventory.stocks);
    const [allGroups, setAllGroups] = useState([]);
    const [departmentId, setDepartmentId] = useState();
    const [itemsList, setItemsList] = useState([]);
    const [dateValue, setDateValue] = useState(moment().format("MM/DD/YYYY"))
    const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"))
    const [search, setSearch] = useState('');
    const [snackbar, setSnackbar] = useState(null);
    const Context = useContext(MyContext);
    useEffect(() => {
        dispatch(fetchAllGroupsData());
        dispatch(fetchDepartments());
        dispatch(fetchAllStocks());
    }, []);
    useEffect(() => {
        if (kitchenData && kitchenData.length > 0) {
            setDepartmentId(kitchenData[0].id)
        }
    }, [kitchenData])

    useEffect(() => {
        if (data?.length > 0 && getStocks?.length > 0) {
            let itemListArray = [];
            data.map((StockItem) => {
                var stockObj = getStocks.filter((stocke) => stocke.id == StockItem.id)
                if (stockObj.length > 0) {
                    var obj =
                    {
                        name: StockItem.name,
                        quantity: StockItem.quantity,
                        unitTypeCode: StockItem.unitTypeCode,
                        id: StockItem.id,
                        currentBalance: stockObj[0].currentBalance,
                    }
                    itemListArray.push(obj)
                }

            })
            setItemsList(itemListArray)
        }
    }, [data, getStocks])

    const selectedStock = (value) => {
        var itemListArray = [...itemsList];
        var item = itemListArray.filter((e) => e.stock_name == value)
        if (item.length > 0) {
        } else {
            if (getStocks && getStocks.length > 0) {
                var stock = getStocks.filter((e) => e.name == value);
                console.log("stock==>", stock)
                if (stock.length > 0) {
                    var obj = {
                        name: stock[0].name,
                        quantity: 0,
                        unitTypeCode: stock[0].unitTypeCode,
                        id: stock[0].id,
                        currentBalance: stock[0].currentBalance
                    }
                    itemListArray.push(obj);
                }
            }
        }
        setItemsList(itemListArray);
        console.log("select value", value, "itemList", itemListArray)
    }
    useEffect(() => {
        if (kitchenData && kitchenData.length) {
            setDepartmentId(kitchenData[0].id)
        }
    }, [kitchenData])
    const handleSave = () => {
        var items = [];
        var isError = false;
        if (itemsList && itemsList.length > 0) {
            itemsList.map((Sitem) => {
                if (Sitem.quantity) {
                    if (Sitem.quantity <= Sitem.currentBalance) {
                        var obj = {
                            name: Sitem.name,
                            quantity: Sitem.quantity || 0,
                            unitTypeCode: Sitem.unitTypeCode,
                            id: Sitem.id,
                        }
                        items.push(obj)
                    } else {
                        isError = true;
                    }
                } else {
                    isError = true;
                }
            })
        }
        const postDate = ConvertDateToUTCFormat(dateValue);
        if (!isError) {
            dispatch(saveIssue({ departmentId: departmentId, items: items, dateTime: postDate, type: "ISSUE" }, callback));
        } else {
            setSnackbar({ message: Context.langData.dispatch_error, severity: 'error' });
        }
    }
    const updateQtyValue = (index, value) => {
        var item = [...itemsList];
        item[index] = { ...item[index], quantity: parseFloat(value) || '' }
        setItemsList(item);
    }
    const removeStock = (index) => {
        var item = [...itemsList];
        item.splice(index, 1)
        setItemsList(item)
    }
    const selectCatDropDown = (value) => {
        var itemArray = []
        if (groupsData && groupsData.length > 0) {
            var catItem = groupsData.filter((e) => e.id == value)
            if (catItem.length > 0) {
                if (catItem[0].items && catItem[0].items.length > 0) {
                    catItem[0].items.map((item) => {
                        var StockItem = getStocks.filter((stocke) => stocke.id == item.id)
                        if (StockItem.length > 0) {
                            var obj =
                            {
                                name: StockItem[0].name,
                                quantity: 0,
                                unitTypeCode: StockItem[0].unitTypeCode,
                                id: StockItem[0].id,
                                currentBalance: StockItem[0].currentBalance,
                            }
                            itemArray.push(obj)
                        }
                    })
                }
            }
        }
        setAllGroups(value);
        setItemsList(itemArray);
    }
    const handleSelect = (value) => {
        selectedStock(value)
    }
    return (
        <RightDrawer open={open} padding={1}>
            <Grid data-qaid="viewsendStock_mainCointainer" container style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between', flex: 1, width: 592 }}>
                <Grid data-qaid="viewsendStock_subCointainer1" item style={{ position: 'relative', overflowY: 'scroll', height: 650 }}>
                    <Grid container style={{ dispaly: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 20, cursor: 'pointer' }} >
                        <CloseCircle onClick={onClose} />
                    </Grid>

                    <Grid data-qaid="viewsendStock_subCointainer2" item style={{ marginLeft: 30 }}>
                        <Grid data-qaid="viewsendStock_inventoryLabelView" item style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 0 }}>
                            <InventoryIcon />
                            <AvenirBlackH4 data-qaid="viewsendStock_inventoryLabel" label={Context.langData.inventory} style={{ color: '#707272', paddingLeft: 8, paddingTop: 5 }} />
                        </Grid>
                        <AvenirBlackH7 label={Context.langData.send_stock} style={{ fontWeight: 'bold' }} />
                    </Grid>

                    <Grid data-qaid="viewsendStock_subCointainer3" item style={{ margin: 30 }}>
                        <Grid data-qaid="viewsendStock_sectionTextLabelView" item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                            <Grid item lg={3}>
                                <AvenirBlackH4 data-qaid="viewsendStock_sectionTextLabel" label={Context.langData.kitchen} />
                                <Grid data-qaid="viewsendStock_setKitchenNameGrid" item style={{ marginTop: 5 }}>
                                    <SingleSelect
                                        list={kitchenData}
                                        valueKey="id"
                                        displayKey="name"
                                        placeholder={Context.langData.select}
                                        value={departmentId}
                                        handleChange={(e) => setDepartmentId(e.target.value)}
                                        border={false}
                                        height={56}
                                        FormWidth={'100%'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item lg={4}>
                                <AvenirBlackH4 data-qaid="viewsendStock_dateLabel" label={Context.langData.date} />
                                <Grid data-qaid="viewsendStock_setDateValueGrid" item style={{ marginTop: 5 }}>
                                    <SingleDate
                                        value={dateValue}
                                        height={56}
                                        border={false}
                                        callback={(e) => { setDateValue(moment(e).format('MM/DD/YYYY')) }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item lg={4}>
                                <AvenirBlackH4 data-qaid="viewsendStock_selectCategoryLabel" label={Context.langData.select_group} />
                                <Grid data-qaid="viewsendStock_selectCatDropDownGrid" item style={{ marginTop: 5 }}>
                                    <SingleSelect
                                        list={groupsData}
                                        valueKey="id"
                                        displayKey="name"
                                        placeholder={Context.langData.all_group}
                                        value={allGroups}
                                        handleChange={(e) => selectCatDropDown(e.target.value)}
                                        border={false}
                                        height={56}
                                        FormWidth={'100%'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid data-qaid="viewsendStock_topSearchBarGrid" style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', justifyContent: 'flex-end' }}>
                            <Grid item style={{ width: '100%', marginTop: '10px' }}>
                                <AutoCompliteDropDown
                                    options={getStocks}
                                    onChange={handleSelect}
                                    labelKey={'name'}
                                    key={itemsList}
                                    placeholder={Context.langData.serch_stock_items}
                                />
                            </Grid>
                        </Grid>
                        <Grid data-qaid="viewsendStock_itemsMainContainer" item style={{ paddingBottom: 100, width: '100%', paddingTop: 20 }}>
                            <Grid data-qaid="viewsendStock_itemssubContainer" style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#F3FAFD', height: 52, width: '100%' }}>
                                <Grid data-qaid="viewsendStock_itemsLabelView" style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', paddingLeft: 20, width: '45%' }}>
                                    <AvenirBlackH4 data-qaid="viewsendStock_itemsLabel" label={Context.langData.items} />
                                </Grid>
                                <Grid data-qaid="viewsendStock_storeBalanceLabelView" style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', width: '25%', paddingLeft: 5 }}>
                                    <AvenirBlackH4 data-qaid="viewsendStock_storeBalanceLabel" label={Context.langData.store_balance} />
                                </Grid>
                                <Grid data-qaid="viewsendStock_dispatchLabelView" style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', width: '30%', paddingLeft: 5 }}>
                                    <AvenirBlackH4 data-qaid="viewsendStock_dispatchLabel" label={Context.langData.dispatch_qty} />
                                </Grid>
                            </Grid>
                            {itemsList ?
                                itemsList.map((itemName, index) => {
                                    return (
                                        <Grid data-qaid="viewsendStock_itemssubContainer" key={itemName.name} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#FAFAFA', height: 52, width: '100%', marginTop: 10 }}>
                                            <Grid data-qaid="viewsendStock_itemsLabelView" style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', width: '45%' }}>
                                                <DotText label={itemName.name} style={{paddingLeft: 20}}/>
                                            </Grid>
                                            <Grid data-qaid="viewsendStock_storeBalanceLabelView" style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', width: '25%', paddingLeft: 5 }}>
                                                <AvenirBlackH4 data-qaid="viewsendStock_unitsLabel" label={`${itemName.currentBalance}  ${itemName.unitTypeCode}`} />
                                            </Grid>
                                            <Grid data-qaid="viewsendStock_dispatchLabelView" style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', width: '30%', paddingLeft: 5 }}>
                                                <TextField
                                                    style=
                                                    {
                                                        {
                                                            display: 'flex',
                                                            width: '50%',
                                                            border: '1px solid #707272',
                                                            borderRadius: 4,
                                                            color: itemName.currentBalance < itemName.quantity ? '#AB1212' : '#051D33',
                                                            paddingLeft: 6
                                                        }
                                                    }
                                                    name="amount"
                                                    id={itemName.quantity}
                                                    value={itemName.quantity || ''}
                                                    placeholder={'0'}
                                                    onChange={(e) => { updateQtyValue(index, e.target.value) }}
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                    inputProps={{ maxLength: 8 }}
                                                />
                                                <AvenirBlackH4 data-qaid="viewsendStock_deleteLabel" label={itemName.unitTypeCode} style={{ marginLeft: 8, marginRight: 8 }} />
                                                <Delete width={24} height={24} onClick={() => { removeStock(index) }} style={{ display: 'flex', paddingRight: 5, cursor: 'pointer' }} ></Delete>
                                            </Grid>
                                        </Grid>
                                    )
                                }) : null
                            }
                        </Grid>

                    </Grid>
                </Grid>
                <Grid data-qaid="viewsendStock_subCointainer4" item justify='flex-end' style={{ position: 'fixed', display: 'flex', height: '93px', backgroundColor: '#FAFAFA', alignItems: 'center', flexDirection: 'row', borderTop: '1px solid #C3C3C3', bottom: 0, width: 592 }}>
                    <Button className={style.buttonClass} data-qaid="viewsendStock_cancelButtonView" style={{ backgroundColor: "#FFF", marginRight: 10, width: 103, height: 40, marginLeft: 10, border: '2px solid #051D33' }} variant="contained" id="cancel" onClick={onClose}  >
                        <AvenirBlackH4 data-qaid="viewsendStock_cancelButtonLabel" label={Context.langData.cancel} />
                    </Button>
                    <Button className={style.buttonClass} data-qaid="viewsendStock_sendButtonView" style={{ backgroundColor: "#FFB600", marginRight: 20, width: 103, height: 40 }} variant="contained" id="cancel" type='submit' onClick={handleSave}  >
                        <AvenirBlackH4 data-qaid="viewsendStock_sendButtonLabel" label={Context.langData.send} />
                    </Button>
                </Grid>
            </Grid>
            {
                snackbar &&
                <SnackBar
                    data-qaid="shifts_snackbar"
                    open={true}
                    setOpen={() => setSnackbar(null)}
                    severity={snackbar.severity}
                    message={snackbar.message}
                />
            }

        </RightDrawer>
    )
}

export default ViewSendStock;