import setState from './setState'
const initialState = {
  sales: {},
  live: {},
  topSales: [],
  orderTypeSales: {},
  shifts: {},
  paymentSales: {},
  tracker: {},
  isDrawerOpen: true,
  setExpiry: {}
};

function dashboard(state = initialState, action) {
  switch (action.type) {
    case "SET_DASHBOARD_SALES": return setState(state, action.payload, 'sales')
    case 'SET_LIVE_SALES': return setState(state, action.payload, 'live')
    case 'SET_TOP_SALES': return setState(state, action.payload, 'topSales')
    case "SET_ORDER_TYPE_SALES": return setState(state, action.payload, 'orderTypeSales')
    case "SET_SHIFTS_SALES": return setState(state, action.payload, 'shifts')
    case "SET_PAYMENT_SALES": return setState(state, action.payload, 'paymentSales')
    case "SET_TRACKER_SALES": return setState(state, action.payload, 'tracker')
    case "TOGGLE_NAVBAR": return setState(state, action.payload, 'isDrawerOpen')
    case "SET_EXPIRY": return setState(state, action.payload, 'setExpiry')
    case "CLEAR": return {...initialState}
    default: return { ...state }
  }
}

export default dashboard;