import * as React from "react"

function RightPointingArrow(props) {
    return (
<svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.6818 16.6296C25.3848 16.9183 25.3781 17.3931 25.6669 17.6901C25.9556 17.9871 26.4304 17.9938 26.7274 17.7051L31.0131 13.5384C31.1584 13.3972 31.2403 13.2032 31.2403 13.0007C31.2403 12.7981 31.1584 12.6041 31.0131 12.4629L26.7274 8.29624C26.4304 8.0075 25.9556 8.01419 25.6669 8.31118C25.3781 8.60817 25.3848 9.08299 25.6818 9.37173L28.643 12.2507L10.4902 12.2507C10.076 12.2507 9.74023 12.5864 9.74023 13.0007C9.74023 13.4149 10.076 13.7507 10.4902 13.7507L28.643 13.7507L25.6818 16.6296Z" fill="#007191"/>
</svg>

    )
}

export default RightPointingArrow


