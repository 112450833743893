import React, { useCallback, useState, useContext } from 'react';
import { Paper, Grid, Typography, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Currency from '../../../components/Currency';
import SingleSelect from '../../../components/SingleSelect'
import clsx from 'clsx';
import RefreshButton from '../../../components/RefreshButton';
import MyContext from '../../../Provider/MyContext';
import { ConvertPrice } from '../../../utils/util';
import { H3, TBold, H4 } from '../../../utils/text'
const items = [{ name: 'Items', value: 1 }, { name: "Categories", value: 0 }]
const TopSales = ({ classes, refresh, topSalesCategory, handleChange }) => {
  const topSales = useSelector(state => state.dashboard.topSales);
  const Context = useContext(MyContext);
  return (
    <Paper data-qaid="topsales_main_paper" className={clsx(classes.paper, classes.noSidePadding, classes.relative)}>
      <Grid data-qaid="topsales_main_grid" container justify="center">
        <Grid data-qaid="topsales_header_grid" container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Grid data-qaid="topsales_item_grid" item sm={12} lg={6} md={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 20 }}>
            <H3 data-qaid="topsales_heder_text" label={Context.langData.top_5items}></H3>
          </Grid>

          <Grid data-qaid="topsales_refresh_grid" item sm={12} lg={5} md={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <RefreshButton data-qaid="topsales_refresh_icon" refresh={refresh} />
          </Grid>
        </Grid>
        <Grid data-qaid="topsales_content_grid" container justify="flex-start" className={classes.paddingHorizontal}>
          <Grid data-qaid="topsales_content_grid1" item xs={5} className={clsx(classes.pad, classes.left)}>
          </Grid>
          <Grid data-qaid="topsales_content_grid2" item xs={2} className={clsx(classes.pad, classes.right)}>
            <H4 data-qaid="topsales_content_quantity" label={Context.langData.quantity} style={{ color: '#707272' }}>
            </H4>
          </Grid>
          <Grid data-qaid="topsales_content_grid3" item xs={5} className={clsx(classes.pad, classes.right)}>
            <H4 data-qaid="topsales_content_total" label={Context.langData.total} style={{ color: '#707272', paddingRight: 5 }}>
            </H4>
          </Grid>
        </Grid>
        {
          topSales.map((sale, index) => {
            return (
              <Grid data-qaid={`topsales_topSales_grid ${index}`} container justify="flex-start" className={classes.paddingHorizontal}>
                <Grid data-qaid={`topsales_topSales_namegrid ${index}`} item xs={5} className={clsx(classes.pad, classes.left)}>
                  <H4 data-qaid={`topsales_topSales_name ${index}`} label={sale.name} style={{ color: '#000000',wordBreak:'break-all' }} />
                </Grid>
                <Grid data-qaid={`topsales_topSales_quantitygrid ${index}`} item xs={2} className={clsx(classes.pad, classes.right)}>
                  <H4 data-qaid={`topsales_topSales_quantity ${index}`} label={sale.quantity} style={{ color: '#000000' }} />
                </Grid>
                <Grid data-qaid={`topsales_topSales_amountgrid ${index}`} item xs={5} className={clsx(classes.pad, classes.right)}>
                  <H4 data-qaid={`topsales_topSales_amount ${index}`} label={`$${ConvertPrice(sale.amount)}`} style={{ color: '#000000', paddingRight: 5 }} />
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
    </Paper >
  )
}

export default React.memo(TopSales)
