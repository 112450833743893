import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchOffers } from '../../../../redux/actions';
import OffersList from './offersList';

const Offers = () => {
    const dispatch = useDispatch();
    // const Offers = useSelector(state => state.settingsReducer.offers);
    useEffect(() => {
        dispatch(fetchOffers());
    }, [])
    return (
        <div>

            <OffersList data-qaid="offers_index_div"></OffersList>

        </div>
    );
};

export default Offers;
