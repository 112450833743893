import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import theme from '../theme';
import { H3, H4, AvenirBlackH4, AvenirBlackH3, DotText } from '../utils/text';
import { ConvertPrice } from '../utils/util';
import DownArrow from './SVG/DownArrow';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  head: {
    fontWeight: 'bold',
    padding: theme.spacing(1),
    backgroundColor: '#f4f4f5',
  },
  cell: {
    padding: theme.spacing(1),
  },
});

const SalesTable = (props) => {
  const classes = useStyles();
  const getColumnDisplayName = (isNumber, value, isDOM) => {
        if (value===null || value === undefined)
        {
          return "--";
        } 
        var value = isNumber ? '$' + ConvertPrice(String(value)) :isDOM ? value: String(value);
        return value;
  }


  const handleSort = (name, desc, index) => {
    props.handleSort(name, desc, index);
  };
  return (
    <>
      <Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} style={{ wordbreak:"break-word",overFlowY:'auto', flexDirextion: 'row', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ wordbreak:"break-word", overFlowY:'auto',display: 'flex', flexDirextion: 'row', alignItems: 'center', backgroundColor: '#F3FAFD', height: 56, borderRadius: 5, paddingRight: 12, paddingLeft: 12 }}> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            borderRadius: 5,
          }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: '#F3FAFD',
              height: 56,
              paddingRight: 20,
              paddingLeft: 20,
            }}>
            {props.columns.map((column, index3) => (
              //<Grid item xs={column.size} sm={column.size} md={column.size} lg={column.size} key={index3} style={{ display: 'flex', alignItems:'center', gap:'10px', flexDirection: 'row', paddingRight: 10, paddingLeft: 10 }} >
              <Grid
                item
                xs={column.size}
                sm={column.size}
                md={column.size}
                lg={column.size}
                key={index3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  flexDirection: 'row',
                  paddingRight: 9,
                  paddingLeft: 9,
                }}>
                <AvenirBlackH3 label={column.display} />
                <Grid
                  onClick={() => {
                    handleSort(column.field, !column.desc, index3);
                  }}>
                  <DownArrow />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {props.rows?.length > 0 ? (
          props.rows.map((row, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                flexDirection: 'row',
                display: 'flex',
                backgroundColor: '#FAFAFA',
                alignItems: 'center',
                marginTop: 10,
                borderRadius: 5,
              }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: 56,
                  paddingRight: 20,
                  paddingLeft: 20,
                }}>
                {props.columns.map((colItem, index3) => (
                  <Grid
                    item
                    xs={colItem.size}
                    sm={colItem.size}
                    md={colItem.size}
                    lg={colItem.size}
                    key={index3}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      backgroundColor: '#FAFAFA',
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}>
                    <DotText
                      label={getColumnDisplayName(
                        colItem['isNumber'] || false,
                        row[colItem['field']],
                        colItem['isDOM'] || false
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default SalesTable;
