import React, { useContext, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import NoImage from '../../../../components/SVG/NoImage';

import PencilIcon from '../../../../components/SVG/PencileIcon';
import { AvenirBlackH4 } from '../../../../utils/text'
import styles from '../../../../styles/index';
import MyContext from '../../../../Provider/MyContext';
import ImageUpload from '../../../../components/ImageUpload';
const ChangeImage = ({ image, onChange }) => {
  const Context = useContext(MyContext);
  const style = styles()

  const [show, toggleImageDrawer] = useState(false)
  const imageCallback = (image, file) => {
    onChange(image, file)
    toggleImageDrawer(false)
  }
  return (
    <Grid container style={{ position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
      {
        image ?
          <img src={image} width="222" height="151" style={{ borderRadius: 5 }} />
          :
          <NoImage height="151" width='222' style={{ borderRadius: 5 }} />
      }
      <Button
        className={style.buttonClass}
        startIcon={<PencilIcon width={14} height={14} color={'#FFFFFF'} />}
        style={{
          backgroundColor: '#004C60', position: 'absolute',
          right: 12,
          top: 7,
        }}
        onClick={() => toggleImageDrawer(true)}
        variant="contained">
        <AvenirBlackH4 label={Context.langData.edit} style={{ color: '#FFFFFF' }} />
      </Button>
      {
        show &&
        <ImageUpload
          open={show}
          callback={imageCallback}
          img={image}
          onClose={() => toggleImageDrawer(false)}
        />
      }
    </Grid>
  )
}

export default ChangeImage