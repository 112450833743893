import React, { useState, useEffect, useContext } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MyContext from "../../../Provider/MyContext";
import {
  AvenirBlackH4,
  AvenirBlackH7,
  AvenirBlackH2,
  H5
} from "../../../utils/text";
import Back from "../../../components/SVG/Back";
import TextInputComponent from "../../../components/TextInputComponent";
import { addRole, fetchRoleNameCheck } from "./../../../redux/actions";
import styles from "../../../styles/index";
import SnackBar from "../../../components/SnackBar";
import moment from "moment";
import _ from 'lodash';
import CustomCheckbox from '../../../components/CustomCheckbox';
import { validateAlphaNumeric } from "../../../utils/util";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: "18px",
  },
  input: {
    color: "black !important",
    fontSize: 14,
    backgroundColor: "#FFF",
    borderRadius: 4,
    height: "24px",
  },
  notchedOutline: {
    border: "1px solid #707272 !important",
  },
  hrStyles: {
    marginTop: "1%",
    marginLeft: " 1%",
    marginRight: "1%",
    border: "1px solid #EEE",
  },
  contactInfoMainView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1%",
    marginLeft: "1%",
    marginRight: "1%",
  },

  labelRoot: {},
}));

const CreateRoleFirstStep = () => {
  const style = styles();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const Permissions = useSelector((state) => state.profileReducers.permissions);
  const RolePermissions = useSelector(
    (state) => state.profileReducers.rolePermissions
  );
  const [checkedList, setCheckedList] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const Context = useContext(MyContext);
  const [snackbar, setSnackbar] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissionItem, setPermissionItem] = useState([]);
  const [errorStatus, setErrorStatus] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const onBlurName = (e) => {
    if (e.target.value) {
      dispatch(fetchRoleNameCheck({ name: e.target.value }, (status, data) => {
        if (status) {
          if (data.exist) {
            setEmailExists(true)
          } else {
            setEmailExists(false)
          }
        } else {
          console.log("Role name alreay exists")
        }
      }))
    }
  }

  useEffect(() => {
    if (
      Permissions &&
      Permissions.permissions &&
      Permissions.permissions.length > 0
    ) {
      var arr = [];
      Permissions.applications.map((item) => {
        let obj = {};
        var filterPermission = RolePermissions.filter(
          (e) => e.application_id == item.application_id
        );
        obj["id"] = item.application_id;
        obj["name"] = item.name;
        obj["Permissions"] = filterPermission;
        obj["selectAll"] = false;
        obj["isDisable"] = true;
        obj["isError"] = false;
        arr.push(obj);
      });
      setPermissionItem(arr);
    }
  }, [Permissions]);
  function checkIt(id) {
    var permission = [...checkedList];
    var index = permission.indexOf(id);
    var checkBox = [...permissionItem];
    if (index == -1) {
      permission.push(id);
      var pIndex = checkBox.findIndex((e) => e.id == id.application_id);
      checkBox[pIndex] = { ...checkBox[pIndex], isDisable: false, isError: false };
    } else {
      permission.splice(index, 1);
      var pIndex = checkBox.findIndex((e) => e.id == id.application_id);
      checkBox[pIndex] = { ...checkBox[pIndex], isDisable: true, isError: false };
    }
    setPermissionItem(checkBox)
    setCheckedList(permission);
    enableButton(name, description);
  }

  function submit() {
    var ids = selectedPermissions;
    var flag = true;
    var permissionsArray = [...permissionItem];
    for (var j = 0; j < permissionsArray.length; j++) {
      if (!(permissionsArray[j].isDisable)) {
        var per = permissionsArray[j];
        var length = 0;
        var permission = per.Permissions;
        for (var i = 0; i < permission.length; i++) {
          var p = permission[i];
          if (ids.indexOf(parseInt(p.permission_id)) !== -1) {
            length = 1;
            break;
          }
        }
        if (length == 0) {
          flag = false;
          permissionsArray[j] = { ...permissionsArray[j], isError: true }
        } else {
        }
      }
    }
    setPermissionItem(permissionsArray);
    if (ids.length > 0) {
      if (flag) {
        dispatch(
          addRole(
            {
              name: name,
              description: description,
              permissions: ids,
            },
            onCallback
          )
        );
      } else {
      }
    } else {
      setSnackbar({ message: "Select at least one permission", severity: "error" });
    }
  }
  function onCallback(response, data, status) {
    if (response) {
      navigate("/home/profile", {
        state: {
          tab: 0,
          emp: false,
          createCustomer: false,
          message: Context.langData.role_created_successfully,
          ts: moment().unix(),
        },
      });
    } else {
      setSnackbar({ message: data.message, severity: "error" });
    }
  }
  function checkAll(id, isChecked) {
    var permission = [...selectedPermissions];
    var permissionsArray = permissionItem.filter((e) => e.id === parseInt(id));
    if (permissionsArray.length > 0) {
      const elementsIndex = permissionItem.findIndex(
        (element) => element.id == parseInt(id)
      );
      let newArray = [...permissionItem];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        selectAll: !isChecked,
        isError: false
      };
      setPermissionItem(newArray);
      if (!isChecked) {
        var selectedRestPermissions = RolePermissions.filter(
          (e) => e.application_id == parseInt(id)
        );
        selectedRestPermissions.map((item) => {
          var index = permission.indexOf(item.permission_id);
          if (index == -1) {
            permission.push(item.permission_id);
          }
        });
      } else {
        permissionsArray[0].Permissions.map((item) => {
          var index = permission.indexOf(item.permission_id);
          if (index == -1) {
          } else {
            permission.splice(index, 1);
          }
        });
      }
    } else {
      const permissionIndex = permissionItem.findIndex((e) => e.id === id);
      let permissionData = permissionItem[permissionIndex];
      permissionData.Permissions.map((item) => {
        permission.push(item.permission_id);
      });
      permissionData.selectAll = false;
      permissionItem[permissionIndex] = permissionData;
      setPermissionItem(permissionItem);
    }
    setSelectedPermissions(permission);
    enableButton(name, description);
  }

  function checkItList(item) {
    var id = item.permission_id;
    var key = item.key;
    var permissions1 = [...selectedPermissions];
    var index = permissions1.indexOf(id);
    if (index == -1) {
      permissions1.push(id);
      if (key == 'reprint_tickets' || key == 'settings') {
        var perObj = Permissions.permissions.filter(e => e.key == 'manage_tickets');
        if (perObj.length > 0) {
          var isIndex = permissions1.indexOf(perObj[0].permission_id);
          if (isIndex == -1) {
            permissions1.push(perObj[0].permission_id);
          }
        }
      }
      if (key == 'view_transaction_details' || key == 'view_hawkeye') {
        var perObj = Permissions.permissions.filter(e => e.key == 'transactions');
        if (perObj.length > 0) {
          var isIndex = permissions1.indexOf(perObj[0].permission_id);
          if (isIndex == -1) {
            permissions1.push(perObj[0].permission_id);
          }
        }
      }
      if (key == 'email_report') {
        var reports = ["sale_reports", "inventory_reports"];
        reports.map(reportItem => {
          var perObj = Permissions.permissions.filter(e => e.key == reportItem);
          if (perObj.length > 0) {
            var isIndex = permissions1.indexOf(perObj[0].permission_id);
            if (isIndex == -1) {
              permissions1.push(perObj[0].permission_id);
            }
          }
        })

      } if (key == 'update_availability' || key == 'menu_addons') {
        var perObj = Permissions.permissions.filter(e => e.key == 'menu_items');
        if (perObj.length > 0) {
          var isIndex = permissions1.indexOf(perObj[0].permission_id);
          if (isIndex == -1) {
            permissions1.push(perObj[0].permission_id);
          }
        }
      }
      permissionItem.map((item) => {
        var permissionObj = item.Permissions.filter(
          (e) => e.permission_id === id
        );
        if (permissionObj.length > 0) {
          var length = 0;
          if (
            item.Permissions.length > 0
          ) {
            item.Permissions.map((item1) => {
              var ispresent = permissions1.filter(
                (e) => e === item1.permission_id
              );
              if (ispresent.length > 0) {
                length++;
              } else {
              }
            });
          }
          let newArray = [...permissionItem];
          const elementsIndex = permissionItem.findIndex(
            (element) => element.id == item.id
          );
          if (length == item.Permissions.length) {
            newArray[elementsIndex] = {
              ...newArray[elementsIndex],
              selectAll: true,
              isError: false
            };
          } else {
            newArray[elementsIndex] = {
              ...newArray[elementsIndex],
              selectAll: false,
              isError: false
            };
          }
          setPermissionItem(newArray);
        }
      });

    } else {
      permissions1.splice(index, 1);
      console.log("keykey", key, "index,", index)
      if (key == 'manage_tickets') {
        var kdsList = ["reprint_tickets", "settings"];
        kdsList.map(item => {
          var pId = Permissions.permissions.filter(e => e.key == item);
          if (pId.length > 0) {
            var isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              permissions1.splice(isIndex, 1);
            }
          }
        })
      }
      if (key == 'transactions') {
        var kdsList = ["view_transaction_details", "view_hawkeye"];
        kdsList.map(item => {
          var pId = Permissions.permissions.filter(e => e.key == item);
          if (pId.length > 0) {
            var isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              permissions1.splice(isIndex, 1);
            }
          }
        })
      }
      if (key == 'sale_reports' || key == 'inventory_reports') {
        var isIndex = true;
        if (key == 'sale_reports') {
          var pId = Permissions.permissions.filter(e => e.key == 'inventory_reports');
          if (pId.length > 0) {
            var isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              isIndex = false;
            }
          }
        } else {
          var pId = Permissions.permissions.filter(e => e.key == 'sale_reports');
          if (pId.length > 0) {
            var isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              isIndex = false;
            }
          }
        }
        if (isIndex) {
          var kdsList = ["email_report"];
          kdsList.map(item => {
            var pId = Permissions.permissions.filter(e => e.key == item);
            if (pId.length > 0) {
              var isIndex = permissions1.indexOf(pId[0].permission_id);
              if (isIndex != -1) {
                permissions1.splice(isIndex, 1);
              }
            }
          })
        }
      }
      if (key == 'menu_items') {
        var kdsList = ["update_availability", "menu_addons"];
        kdsList.map(item => {
          var pId = Permissions.permissions.filter(e => e.key == item);
          if (pId.length > 0) {
            var isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              permissions1.splice(isIndex, 1);
            }
          }
        })
      }
      permissionItem.map((item) => {
        var permissionObj = item.Permissions.filter(
          (e) => e.permission_id === parseInt(id)
        );
        if (permissionObj.length > 0) {
          const elementsIndex = permissionItem.findIndex(
            (element) => element.id == item.id
          );
          let newArray = [...permissionItem];
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            selectAll: false,
          };
          setPermissionItem(newArray);
        }
      });
    }
    if (permissions1.length > 0) {
      setErrorStatus(false);
    } else {
    }
    setSelectedPermissions(permissions1);
    enableButton(name, description);
  }

  function enableButton(name, description, checkArray, selectedPermissions) {
    if (name && description) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }
  function changeText(value, key) {
    var Name = name;
    var Desc = description;
    if (key == "name") {
      Name = validateAlphaNumeric(value);
    } else {
      Desc = value;
    }
    setName(Name);
    setDescription(Desc);
    enableButton(Name, Desc);
  }
  const btnProps = {};
  if (btnDisable) {
    btnProps["disabled"] = true;
  }
  function backtoHome() {
    navigate("/home/profile", { state: { tab: 0, emp: false } });
  }
  // function capitalize(s) {
  //   if (typeof s !== "string") return "";
  //   var strs = s.toLowerCase().split(" ");
  //   return strs
  //     .map((ss) => {
  //       return ss.charAt(0).toUpperCase() + ss.slice(1).toLowerCase();
  //     })
  //     .join(" ");
  // }
  const handleDisable = (item, id) => {
    var isDisable = false;
    var index = RolePermissions.findIndex(
      (e) => e.key == item && e.application_id == id
    );
    if (index != -1) {
      isDisable = true;
    }
    return isDisable;
  };

  return (
    <Grid container data-qaid="createRole_mainGrid" style={{ backgroundColor: "#EEE" }}>
      <Grid
        data-qaid="createRole_topGrid"
        container
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#FFF",
          paddingTop: "25px",
          paddingBottom: "25px",
        }}
      >
        <Grid
          data-qaid="createRole_backView"
          item
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            marginLeft: 30,
          }}
        >
          <Back data-qaid="createRole_backIcon" onClick={backtoHome} style={{ cursor: "pointer" }} />
          <AvenirBlackH7
            data-qaid="createRole_backLabel"
            label={Context.langData.new_role}
            style={{ fontWeight: "bold", paddingLeft: 20 }}
          />
        </Grid>
        <Grid item data-qaid="createRole_save_cancel_Grid">
          <Button
            className={style.buttonClass}
            data-qaid="createRole_cancel_button"
            style={{
              backgroundColor: "#FFF",
              color: "white",
              marginRight: 10,
              width: 103,
              height: 40,
              border: '2px solid #051D33'
            }}
            variant="contained"
            id="cancel"
            onClick={backtoHome}
          >
            <AvenirBlackH4
              data-qaid="createRole_cancel_label"
              label={Context.langData.cancel}
            />
          </Button>
          <Button
            className={style.buttonClass}
            data-qaid="createRole_save_button"
            {...btnProps}
            style={{
              marginRight: 10,
              backgroundColor: btnDisable == true ? "#EEE" : "#FFB600",
              color: "white",
              width: 90,
              height: 40,
              marginRight: 20,
            }}
            variant="contained"
            id="next"
            onClick={submit}
          >
            <AvenirBlackH4
              data-qaid="createRole_save_label"
              label={Context.langData.save}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid
        data-qaid="createRole_Card_Grid"
        item
        lg={12}
        md={12}
        sm={12}
        style={{
          overflow: "scroll",
          // height: "550px",
          marginTop: 30,
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <Card data-qaid="createRole_Card" style={{ marginTop: 10 }}>
          <CardContent>
            <Grid data-qaid="createRole_roleLabelView" className={classes.contactInfoMainView}>
              <AvenirBlackH2 data-qaid="createRole_roleLabel" label={Context.langData.role_step_1} />
            </Grid>
            <hr data-qaid="createRole_hr_div" className={classes.hrStyles} />
            <Grid
              container
              data-qaid="createRole_name_description_Grid"
              spacing={4}
              style={{
                paddingTop: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Grid item data-qaid="createRole_name_Grid" xs={12} sm={6} md={6}>
                <AvenirBlackH4
                  data-qaid="createRole_nameLabel"
                  label={Context.langData.name_star}
                  style={{ marginBottom: 10 }}
                />
                <TextInputComponent
                  value={name}
                  // onBlur={handleBlur}
                  onChange={(e) => {
                    changeText(e.target.value, "name");
                    onBlurName(e)
                  }}
                  fullWidth
                  id="firstName"
                  name="firstName"
                  variant="outlined"
                  style={{ width: "100%" }}
                  className={classes.sample}
                  maxLength={64}
                  errorBorder={emailExists ? '1px solid red' : null}
                  InputProps={{
                    classes: {
                      root: classes.labelRoot,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input
                    },
                  }}
                />
                {emailExists &&
                  <H5 label={emailExists ? 'Role name already exists' : ''} style={{ color: '#e6473c', paddingLeft: 10, paddingTop: 5 }} />
                }
              </Grid>
              <Grid item data-qaid="createRole_description_Grid" xs={12} sm={6} md={6}>
                <AvenirBlackH4
                  data-qaid="createRole_descriptionLabel"
                  label={Context.langData.description_star}
                  style={{ marginBottom: 10 }}
                />
                <TextInputComponent
                  id="outlined-basic"
                  variant="outlined"
                  value={description}
                  style={{ width: "100%" }}
                  onChange={(e) => changeText(e.target.value, "description")}
                  className={classes.sample}
                  maxLength={300}
                  InputProps={{
                    classes: {
                      root: classes.labelRoot,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <div
              data-qaid="createRole_systemAccessDiv"
              style={{
                paddingTop: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <AvenirBlackH4 data-qaid="createRole_systemAccessLabel" label={Context.langData.system_access} />
            </div>
            <div
              data-qaid="createRole_subDiv1"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Permissions.applications.map((item, index) => {
                const convertText = validateAlphaNumeric(item.name);
                return (
                  <Grid
                    data-qaid="createRole_checkbox_grid1"
                    key={index}
                    style={{
                      marginRight: 30,
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      // paddingLeft: 15
                    }}
                  >
                    <CustomCheckbox
                      data-qaid="createRole_checkbox1"
                      id={item}
                      checked={checkedList.indexOf(item) !== -1 ? true : false}
                      onChange={() => checkIt(item)}
                      marginLeft={0}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      label={convertText}
                      labelColor={checkedList.indexOf(item) !== -1 ? "#051D33" : "#051D33"}
                    />
                  </Grid>
                );
              })}
            </div>
          </CardContent>
        </Card>

        <Card data-qaid="createRole_cardView" style={{ marginTop: 30, marginBottom: 30 }}>
          <CardContent>
            <Grid data-qaid="createRole_rolePermission_labelView" className={classes.contactInfoMainView}>
              <AvenirBlackH2 data-qaid="createRole_rolePermission_label" label={Context.langData.role_step_2} />
            </Grid>
            <hr data-qaid="createRole_hr_div" className={classes.hrStyles} />
            <Grid>
              {
                permissionItem &&
                permissionItem.map((item, index) => {
                  var list_id = "application_" + item.id;
                  var div_id = "div_" + item.id;
                  var application = checkedList.findIndex(
                    (e) => e.application_id == item.id
                  );
                  const convertText = validateAlphaNumeric(item.name);
                  return (
                    <Grid item data-qaid="createRole_subGrid" id={div_id} key={index}>
                      <AvenirBlackH4
                        data-qaid="createRole_mainHeading"
                        label={application == -1 ? convertText : convertText + "*"}
                        className="col l12 s12 m12"
                        style={{
                          marginBottom: 10,
                          marginLeft: 10,
                          marginTop: 20,
                          color: application == -1 ? "#707272" : "#051D33",
                        }}
                      />
                      <Grid
                        data-qaid="createRole_selectAllCheckbox_view"
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          // paddingLeft: 10
                        }}
                      >
                        <CustomCheckbox
                          data-qaid="createRole_selectAllCheckbox"
                          id={list_id}
                          checked={item.selectAll}
                          onChange={() => checkAll(item.id, item.selectAll)}
                          unCheckColor={application == -1 ? "#C3C3C3" : "#004C60"}
                          inputProps={{ "aria-label": "primary checkbox" }}
                          disabled={application == -1}
                          marginLeft={0}
                        />
                        <AvenirBlackH4
                          data-qaid="createRole_selectAllCheckbox_label"
                          label={Context.langData.select_deselect_all}
                          style={{
                            color: application == -1 ? "#707272" : "#051D33",
                          }}
                        />
                      </Grid>
                      <Grid
                        data-qaid="createRole_permissions_mainGrid"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          // paddingLeft: 10
                        }}
                      >
                        {item.Permissions.map((pItem, index2) => {
                          const convertText = validateAlphaNumeric(pItem.name);
                          return (
                            <Grid
                              data-qaid="createRole_Checkbox_view2"
                              container
                              key={index2}
                              style={{
                                marginRight: 50,
                                width: "20%",
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CustomCheckbox
                                data-qaid="createRole_Checkbox2"
                                id={pItem.permission_id}
                                checked={
                                  selectedPermissions.indexOf(
                                    pItem.permission_id
                                  ) !== -1
                                    ? true
                                    : false
                                }
                                marginLeft={0}
                                onChange={() =>
                                  checkItList(pItem)
                                }
                                unCheckColor={application == -1 || !handleDisable(pItem.key, pItem.application_id) ? '#C3C3C3' : '#004C60'}
                                disabled={
                                  application == -1 ||
                                  !handleDisable(
                                    pItem.key,
                                    pItem.application_id
                                  )
                                }
                                label={convertText}
                                labelColor={application == -1 || !handleDisable(pItem.key, pItem.application_id) ? "#707272" : "#051D33"}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                      {item.isError && (
                        <AvenirBlackH4
                          data-qaid="createRole_error_status"
                          label={"Select at least one permission"}
                          style={{
                            marginLeft: 10,
                            marginTop: 10,
                            color: "red",
                          }}
                        />
                      )}
                    </Grid>
                  );
                })}
            </Grid>
          </CardContent>
        </Card>
        {snackbar && (
          <SnackBar
            data-qaid="createRole_snackbar"
            open={true}
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CreateRoleFirstStep;
