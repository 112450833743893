import * as React from "react";

function ReturnIcon(props) {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.45833 0.750163C9.45833 0.451008 9.70085 0.208496 10 0.208496H14.1667C15.7469 0.208496 17.2624 0.836247 18.3798 1.95365C19.4972 3.07106 20.125 4.58658 20.125 6.16683C20.125 7.74708 19.4972 9.2626 18.3798 10.38C17.2624 11.4974 15.7469 12.1252 14.1667 12.1252H1.72687L4.96635 15.3646C5.17788 15.5762 5.17788 15.9191 4.96635 16.1307C4.75482 16.3422 4.41185 16.3422 4.20032 16.1307L0.0658915 11.9963C-0.050706 11.8971 -0.125 11.7489 -0.125 11.5835L-0.124998 11.5826V11.5819L0 11.5822L9.45833 0.750163ZM9.45833 0.750163C9.45833 1.04932 9.70085 1.29183 10 1.29183H14.1667C15.4596 1.29183 16.6996 1.80544 17.6138 2.71968C18.5281 3.63392 19.0417 4.8739 19.0417 6.16683C19.0417 7.45976 18.5281 8.69973 17.6138 9.61397C16.6996 10.5282 15.4596 11.0418 14.1667 11.0418H1.72187L9.45833 0.750163Z"
        // fill="#FFF"
        stroke="#004C60"
        // stroke-width="0.25"
        stroke-linecap="round"
        // stroke-linejoin="round"
      />
    </svg>

    // <svg
    //   width={20}
    //   height={17}
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path
    //     d="M9.458.75c0-.299.243-.542.542-.542h4.167a5.958 5.958 0 010 11.917H1.727l3.24 3.24a.542.542 0 01-.767.766L.066 11.996a.54.54 0 01-.191-.412v-.002H0L9.458.75zm0 0c0 .3.243.542.542.542h4.167a4.875 4.875 0 110 9.75H1.722L9.458.75z"
    //     fill="#004C60"
    //     stroke="#004C60"
    //     strokeWidth={0.25}
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  );
}

export default ReturnIcon;
