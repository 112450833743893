import React from 'react';
import { Grid } from '@material-ui/core';
import PlusStandardIcon from '../components/SVG/PlusStandardIcon';
import MinusIcon from '../components/SVG/MinusIcon';
import { H4, AvenirBlackH2 } from '../utils/text';

const Counter = ({ count = 1, onChange, max = 10, min = 1, label, isMandatory, onError, otherCount }) => {
  const decrement = () => {

    console.log(count, min)
    if (count > min) {
      onChange(count - 1)
    } else {
      if (isMandatory) {
        console.log(otherCount, count)
        if (label == "min")
          onError("Min value can't be less than 1 for mandatory modifiers");
        else {
          if (otherCount == count) {
            onError("Max value can't less than min value");
          }
          else
            onError("Max value should be 2 or more for multi-select");
        }

      } else {
        if (label == "min") {
          onError("Disabled min changing option for optional");
        }
        else
          onError("Max value should be 2 or more for multi-select");
      }

    }
  }
  const increment = () => {

    console.log(count, max)
    if (count < max) {
      onChange(count + 1)
    } else {
      if (label == "max") {
        onError(`Max value can't be greater than modifier count(${max})`)
      } else {
        if (isMandatory) {
          onError("Min value can't be greater than max value")
        } else {
          onError("Disabled min changing option for optional");
        }
      }
    }
  }
  return (
    <Grid container direction="row" style={{ border: '1px solid #051D33', height: 50, borderRadius: 5 }}>
      <Grid item xs={4} onClick={decrement} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
        <MinusIcon />
      </Grid>
      <Grid item xs={4} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <AvenirBlackH2 label={count} />
      </Grid>
      <Grid item xs={4} onClick={increment} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
        <PlusStandardIcon />
      </Grid>
    </Grid>
  )
}

export default Counter
