import React, { useCallback, useEffect, useState, useContext } from 'react';
import DoughnutChart from '../../../components/DoughnutChart';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import clsx from 'clsx';
import { fetchPaymentTypeSales } from '../../../redux/actions'
import { useSelector, useDispatch } from 'react-redux';
import RefreshButton from '../../../components/RefreshButton';
import { H3, TBold, AvenirBlackH4 } from '../../../utils/text'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MyContext from '../../../Provider/MyContext';
import styles from '../../../styles/index';
const PaymentChart = ({ classes, dates }) => {
  const style = styles();
  const dispatch = useDispatch();
  const paymentSales = useSelector(state => state.dashboard.paymentSales);
  const [type, setType] = useState('sales')
  const Context = useContext(MyContext);
  const [value, setValue] = React.useState(0);
  const [buttonArray, setButtonArray] = useState([Context.langData.sales, Context.langData.transactions]);
  useEffect(() => {
    if (dates)
      dispatch(fetchPaymentTypeSales(dates))
  }, [dates])
  const refresh = useCallback(() => {
    dispatch(fetchPaymentTypeSales(dates))
  }, [dates])
  console.log(paymentSales)

  const handleChange = (item) => {
    setValue(item);
    if (item == 0) {
      setType('sales');
    } else {
      setType('transactions');
    }
  }
  return (
    <Paper data-qaid="paymentchart_paper" className={clsx(classes.paper, classes.noSidePadding, classes.relative, classes.chart)}>

      <Grid data-qaid="paymentchart_content_grid" container className={clsx(classes.paddingHorizontal, classes.padding, classes.relative)} justify="space-between" style={{ marginLeft: 24, paddingLeft: 0, paddingBottom: 0 }}>
        <Grid data-qaid="paymentchart_title_grid" item sm={12} lg={8} md={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <H3 data-qaid="paymentchart_title_text" label={Context.langData.payment_type} ></H3>
        </Grid>
        <RefreshButton data-qaid="paymentchart_refresh_icon" refresh={refresh} className={classes.absolute} />

      </Grid>
      <Grid data-qaid="paymentchart_button_grid" container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 10, paddingRight: 20 }}>
        <Grid data-qaid="paymentchart_child_button_grid" item sm={12} lg={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 24 }}>
          {
            buttonArray.map((item, i) => {
              return (
                <Button className={style.buttonClass} data-qaid="paymentchart_button_button" style={{ backgroundColor: value == i ? '#051D33' : '#EEEEEE', marginRight: 1, borderRadius: 0, borderTopLeftRadius: i == 0 ? 5 : 0, borderBottomLeftRadius: i == 0 ? 5 : 0, borderTopRightRadius: buttonArray.length - 1 == i ? 5 : 0, borderBottomRightRadius: buttonArray.length - 1 == i ? 5 : 0 }} onClick={() => handleChange(i)}>
                  <AvenirBlackH4 data-qaid="paymentchart_button_label" label={item} style={{ color: value == i ? '#FFFFFF' : '#051D33', paddingLeft: 8, paddingRight: 8, paddingTop: 10, paddingBottom: 10, lineHeight: '24px' }} />
                </Button>
              )
            }
            )
          }
        </Grid>
      </Grid>

      <DoughnutChart data-qaid="paymentchart_doughnut" data={paymentSales?.data || []} valueKey={type} labelsKey="type" />
    </Paper>
  )
}

export default React.memo(PaymentChart);