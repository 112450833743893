import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchShifts } from '../../../../redux/actions';
import ShiftsList from './shiftsList';

const Printers = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchShifts());
    }, [])
    return (
        <div>
            {/* hello */}
            <ShiftsList data-qaid="shift_index" />
        </div>
    );
};

export default Printers;
