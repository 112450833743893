import * as React from "react"

function Tablet({ width = 24, height = 24, color = "red", ...props }) {
  let invert = "#fff"
  if (color == "white") {
    invert = "#000"
  }
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 48 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={48} height={48} rx={5}
        fill={color}
        stroke="#fff"
        strokeWidth={2} />
      <path
        d="M15.744 30.75h16.5M30.747 12.75h-13.5a1.5 1.5 0 00-1.5 1.5v19.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-19.5a1.5 1.5 0 00-1.5-1.5z"
        stroke={invert}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.815 56.504H8.19v.936h2.808V65h1.008v-7.56h2.808v-.936zm.202 4.212c.444-.408 1.068-.612 1.668-.612.876 0 1.392.42 1.392 1.236v.168h-.36c-1.38 0-3.54.108-3.54 1.968 0 1.092.948 1.668 2.088 1.668.852 0 1.476-.408 1.848-.984h.024c0 .24 0 .588.06.84h.9a6.237 6.237 0 01-.084-.972v-2.412c0-1.584-.696-2.376-2.352-2.376-.816 0-1.656.276-2.232.864l.588.612zm3.06 1.584v.36c-.024.912-.576 1.692-1.656 1.692-.708 0-1.236-.264-1.236-.972 0-.756.768-1.08 1.956-1.08h.936zm2.599 2.7h.936v-.996h.024c.348.636 1.152 1.14 2.16 1.14 1.572 0 2.808-1.272 2.808-2.952 0-1.704-1.188-2.952-2.952-2.952-.816 0-1.5.36-2.016.96h-.024v-4.272h-.936V65zm.936-2.808c0-1.176.78-2.088 1.992-2.088s1.992.912 1.992 2.088c0 1.176-.78 2.088-1.992 2.088s-1.992-.912-1.992-2.088zM28.144 65h.936v-9.072h-.936V65zm7.107-1.572c-.444.564-.984.852-1.788.852-1.02 0-1.764-.828-1.836-1.8h4.536v-.3c0-1.86-1.08-2.94-2.664-2.94-1.656 0-2.88 1.212-2.88 2.952 0 1.632 1.08 2.952 2.844 2.952 1.044 0 1.836-.312 2.496-1.164l-.708-.552zm-3.624-1.74c.12-.948.804-1.584 1.836-1.584s1.656.636 1.692 1.584h-3.528zm9.056-2.304h-1.656v-1.596h-.936v1.596h-1.224v.792h1.224v3.084c0 1.164.216 1.884 1.548 1.884.348 0 .768-.06 1.092-.204l-.036-.852a1.822 1.822 0 01-.828.192c-.828 0-.84-.576-.84-1.296v-2.808h1.656v-.792z"
        fill="#fff"
      />
    </svg>
  )
}

export default Tablet
