import React, { useEffect, useState, useContext } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
    makeStyles,
    Snackbar,
    Grid,
    Checkbox
} from '@material-ui/core';
import MyContext from '../../Provider/MyContext';
import Page from '../../components/Page';
import { useDispatch, useSelector } from 'react-redux'
import { login, fetchRolePermissions } from '../../redux/actions';
import MuiAlert from '@material-ui/lab/Alert';
import { H4, AvenirBlackH4, H2, H3, H5 } from '../../utils/text';
import Logo from '../../components/SVG/Logo';
import GoIcon from '../../components/SVG/Go';
import LineIcon from '../../components/SVG/LineIcon';
import CenterModal from '../../components/CenterModal';
import InputText from '../../components/InputText';
import LoginLogo from '../../components/SVG/LoginLogo';
import { activateRest } from '../../redux/actions'
import Message from '../../components/Message';
import styles from '../../styles/index';
import CheckBoxIcon from '../../components/SVG/CheckBoxIcon';
import CheckedCheckBoxIcon from '../../components/SVG/CheckBoxOn';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F3FAFD',
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}));

const UserTerms = () => {
    const style = styles();
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginDetails = useSelector(state => state.loginReducer.loginDetails);
    const [alertData, setAlertData] = useState({ open: false, severity: "success", msg: "" })
    const Context = useContext(MyContext);
    const location = useLocation();
    const loginFailedCallback = (values) => {
        if (values.accept) {
            setAlertData({ open: true, severity: "success", msg: "Successfully activated" })
            navigate('/accountVerified')
            closeMessage()
        }
        else {
            setAlertData({ open: true, severity: "error", msg: "Accept the terms and conditions" })
            closeMessage()
        }
    }
    const closeMessage = () => {
        setTimeout(() => {
            setAlertData({ open: false, severity: "success", msg: "" })
        }, 3000)
    }
    const handleBackButton = () => {
        console.log("back called", location.state.st1)
        navigate('/mfa', { state: { 'st1': location.state.st1 } })
    }
    return (
        <Page
            data-qaid="activation_page"
            className={classes.root}
        >
            <Box
                data-qaid="activation_box"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container data-qaid="activation_container" style={{ backgroundColor: '#FFFFFF', width: 600, marginTop: 56, marginBottom: 56, height: '80%' }}>
                    <Formik
                        data-qaid="activation_formik"
                        initialValues={{
                            accept: false
                        }}
                        validationSchema={Yup.object().shape({
                        })}
                        onSubmit={(values) => {
                            loginFailedCallback(values)
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                            <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                                <Grid style={{ height: '100%', marginTop: 48, marginBottom: 48, backgroundColor: '#FFFFFF' }}>
                                    <Grid data-qaid="activation_formik_main_grid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '10%' }}>
                                        <H2 label={Context.langData.terms_of_use} style={{ color: '#051D33', marginTop: 48, marginBottom: 32 }} />
                                    </Grid>
                                    <Grid style={{ display: 'flex', flexDirection: 'column', height: '70%', overflowX: 'auto', marginLeft: 48, marginRight: 48 }}>
                                        <H5
                                            label={
                                                <p>
                                                    MONERIS SOLUTIONS CORPORATION AND ITS AFFILIATES (“MONERIS”) MAINTAIN THIS APPLICATION AND ALL OF THE CONTENT, FUNCTIONALITY, SOFTWARE AND ANY OTHER INFORMATION, MATERIALS, REPORTS, FORMS, SERVICES, PRODUCTS OR SOLUTIONS MADE AVAILABLE BY MONERIS ON THE APPLICATION FROM TIME TO TIME (COLLECTIVELY, THE “APPLICATION”).<br />
                                                    <br />
                                                    BY CHECKING “I AGREE” OR ENTERING OR USING THE APPLICATION, YOU ARE AGREEING TO COMPLY AND BE BOUND BY THESE TERMS OF USE AND ANY OTHER OPERATING RULES, REGULATIONS, POLICIES AND PROCEDURES THAT MAY BE POSTED FROM TIME TO TIME WITHIN THE APPLICATION OR OTHERWISE MADE AVAILABLE BY MONERIS IN RESPECT OF THE APPLICATION (THE “TERMS”). THE TERMS GOVERN YOUR ACCESS TO AND USE OF THE APPLICATION BOTH NOW AND IN THE FUTURE. MONERIS MAY, AT ITS SOLE DISCRETION, UPDATE OR REVISE THE TERMS AT ANY TIME. ANY UPDATES OR REVISIONS OF THE TERMS WILL BE EFFECTIVE IMMEDIATELY UPON POSTING WITHIN THE APPLICATION OR OTHERWISE BEING MADE AVAILABLE TO YOU. PLEASE CHECK THE TERMS PERIODICALLY FOR UPDATES OR REVISIONS. YOUR CONTINUED ACCESS OR USE OF THE APPLICATION FOLLOWING THE POSTING OF ANY UPDATES OR REVISIONS TO THE TERMS CONSTITUTES CONFIRMATION OF YOUR ACCEPTANCE OF THE TERMS AS UPDATED OR REVISED. IF YOU DO NOT AGREE WITH THE TERMS, DO NOT USE THE APPLICATION.<br />
                                                    <br />
                                                    “YOU” (AND ALL DERIVATIONS THEREOF) MEANS YOU INDIVIDUALLY AS A USER OF THE APPLICATION, AND IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, THAT LEGAL ENTITY (YOUR “ORGANIZATION”), IN WHICH CASE YOU REPRESENT AND WARRANT THAT YOU ARE DULY AUTHORIZED TO REPRESENT AND BIND THE ORGANIZATION TO THESE TERMS. THE APPLICATION IS INTENDED TO BE USED ONLY BY MONERIS MERCHANTS OR THEIR AUTHORIZED EMPLOYEES AND PERSONNEL. IF YOU DO NOT HAVE SUCH AUTHORITY OR AUTHORIZATION, DO NOT USE THE APPLICATION.<br />
                                                    <br />
                                                    THESE TERMS RELATE SOLELY TO YOUR USE OF THE APPLICATION. THESE TERMS AND CONDITIONS DO NOT SUPERSEDE OR LIMIT, AND ARE IN ADDITION TO, YOU OR YOUR ORGANIZATION’S MERCHANT AGREEMENT AND ANY OTHER TERMS, CONDITIONS, RULES OR REQUIREMENTS IN RESPECT OF MONERIS PRODUCTS AND SERVICES, INCLUDING THOSE RELATING TO THE APPLICATION.<br />
                                                    <br />
                                                    Definitions<br />
                                                    <br />
                                                    •	“Application” has the meaning set forth in the introductory paragraphs above.<br />
                                                    <br />
                                                    •	"Claims" means any and all claims, demands, liabilities, damages, losses, suits, actions, investigations, proceedings or causes of action and any and all related costs and expenses, including without limitation reasonable legal fees and expenses.<br />
                                                    <br />
                                                    •	"Content" means all software, materials, reports, documentation, graphics, images, designs, plans, information and other content accessed by you on the Application or otherwise accessible through the Application.<br />
                                                    <br />
                                                    •	“Moneris” has the meaning set forth in the introductory paragraphs above.<br />
                                                    <br />
                                                    •	“Organization” has the meaning set forth in the introductory paragraphs above.<br />
                                                    <br />
                                                    •	"Representatives" means employees, officers, directors, affiliates and licensors of a party.<br />
                                                    <br />
                                                    •	“Terms” has the meaning set forth in the introductory paragraphs above.<br />
                                                    <br />
                                                    •	“Third Party Services” means any and all products, applications, software, services, networks, systems, or websites provided by any third party to which the Application may link or enable a connection or integration.<br />
                                                    <br />
                                                    •	"Unauthorized Use" means any abusive or fraudulent use or any use that is not consistent with these Terms or violates applicable law.<br />
                                                    <br />
                                                    •	“You” and “your” have the meaning set forth in the introductory paragraphs above.<br />
                                                    <br />
                                                    •	"We", "us", and "our" means Moneris.<br />
                                                    <br />
                                                    Credentials and Use of the Application<br />
                                                    <br />
                                                    •	You will be assigned and/or will need to set up your own ID and password, or other credentials, to access the Application (the “Credentials”). You acknowledge and agree that your Credentials can be used to access information related to your account and your use of the Application.<br />
                                                    <br /> •	You are responsible for maintaining the confidentiality of your Credentials. You agree not to provide your Credentials to anyone else and not to permit access to the Application using your Credentials.<br />
                                                    <br /> •	You are solely responsible for any use of the Application by you and by any person using your Credentials. You will ensure that in using the Application, you comply with these Terms. Any use of the Application by a third party using your Credentials will be deemed to be use of the Application by you, whether or not done with your knowledge or consent.  You will have the sole responsibility to safeguard your Credentials and to cease any unauthorized use of your account or your Credentials and you shall be liable for any loss, damage or expense arising from such use by a third party.<br />
                                                    <br /> •	You must take your own precautions to ensure that the process which you employ for accessing the Application does not expose you to the risk of viruses, malicious computer code or other forms of interference which may damage your computer system. For greater certainty, we do not accept responsibility for any interference or damage to your computer system which arises in connection with your use of the Application.<br />
                                                    <br /> Your Use of the Application<br />
                                                    <br />
                                                    <br /> •	By using the Application, you are representing to us that you have the power and authority to accept these Terms and that you understand and accept the terms, conditions and risks relating to the use of the Application.<br />

                                                    <br /> •	You will ensure that your use of the Application complies with all applicable laws and regulations (including those relating to privacy and anti-spam) and any terms, conditions, rules, policies, procedures and technical specifications as may be provided and updated by us from time to time in respect of the Application. You further acknowledge and agree that your use of the Application may be restricted or limited as a result of federal, provincial, or other applicable laws or regulations and you will act in accordance with all such restrictions and limitations.<br />

                                                    <br />
                                                    Conduct<br />
                                                    <br />  •	You agree when using the Application, that you will (i) abide by these Terms and all other procedures, codes of conduct and other notices we may provide; (ii) promptly notify us if you learn of a security breach related to the Credentials or the Application; and (iii) only use the Application for lawful purposes and for the purpose(s) for which they are intended.<br />
                                                    <br />  •	You agree not to use (or allow anyone else to use), directly or indirectly, the Application for any Unauthorized Use including without limitation:<br />
                                                    <br />•	Defaming, abusing, harassing, stalking, threatening or otherwise violating the legal rights of others;<br />
                                                    <br />•	Publishing, posting, uploading, distributing, conveying or disseminating any material or information that is profane, defamatory, infringing, obscene, salacious, abusive, libelous, unlawful, deceptive or otherwise offensive or objectionable;<br />
                                                    <br />•	Uploading, posting, reproducing or distributing any material protected by copyright, intellectual property laws, privacy or other proprietary rights unless you have obtained permission;<br />
                                                    <br />•	Restricting, inhibiting or interfering with the proper functioning of the Application;<br />
                                                    <br />•	Attempting to or assisting another to access, alter or interfere with the Application or another organization’s account;<br />
                                                    <br />•	Using the Application in a manner that harms or could harm us, our affiliates or any merchant of ours;<br />
                                                    <br />•	Using any portion of the Application as a destination linked from any unsolicited bulk messages or unsolicited commercial messages (e.g., spam);<br />
                                                    <br />•	Using any unauthorized third party software or service to access the Application;<br />
                                                    <br />•	Damaging, disabling, overburdening or impairing the Application;<br />
                                                    <br /> •	Reselling, redistributing, altering, modifying, reproducing, tampering with or rerouting the Application;<br />
                                                    <br /> •	Engaging in any conduct involving the Application that would constitute an illegal activity, criminal offence or give rise to civil liability under any local, provincial, state, federal or other law or regulation;<br />
                                                    <br /> •	Tampering with or making an unauthorized connection to the Application;<br />
                                                    <br /> •	Reverse-engineering, decompiling, disassembling or creating derivative works of the Application;<br />
                                                    <br /> •	Uploading to the Application a virus, drop dead device, lock, key, bomb, worm, Trojan-horse, trap door or other harmful, limiting destructive or debilitating feature; and<br />
                                                    <br /> •	Using the Application for anything other than the purpose(s) contemplated in these Terms.<br />
                                                    <br />
                                                    Monitoring<br />

                                                    •	 We may, in our sole discretion, monitor and keep a record of your use of the Application, your activities on the Application and any content that you post, transmit or upload to the Application, including for the purposes of (i) detecting irregular or suspicious activity by you; and (ii) ensuring compliance with these Terms. Such records and information may include Personal Information (as defined in Section 13 below) and may be made available to other Representatives of your Organization, including those with administrator credentials.<br />
                                                    •	 We may also, in our sole discretion, review, edit, refuse to post, or remove any material or information that you post, transmit or upload to the Application. We may also disclose any of your content on the Application in order to comply with any law, regulation, order, decision, ruling, rule, investigation or requirement applicable to us or with a government request.<br />
                                                    <br />Changes to the Application<br />

                                                    <br /> •	We may, in our sole discretion and without advance notice or liability, alter, modify and/or update the Application including any of the Content, the available features and/or any of the hardware or software that you require to access the Application. Such alterations, modifications and updates may include the addition or deletion of features, functionality, text, data and other content.<br />
                                                    <br /> •	Upon notice to you we may, when feasible, remotely update any Application-related software that is downloaded onto your computer, terminal or other device, including for the purposes of maintenance, patching or upgrading some of all of the Application. Any updated version of the Application furnished to you by us will be subject to all of the same Terms. You shall cooperate, as necessary (including as prompted on-screen), to perform or complete such updates.<br />
                                                    <br />
                                                    Intellectual Property and Confidentiality<br />

                                                    <br />  •	You acknowledge that the Application, the Content and all trademarks, service marks and logos contained in or accessible to you via the Application are the exclusive property of us and/or any third party credited as the provider of such materials and information. We and/or our licensors exclusively own and retain all title, ownership rights and intellectual property rights including without limitation patents, copyrights and trade secrets in and to all documentation, know how, operating processes and procedures, software and software configuration parameters and any modifications and translations thereof incorporated into or in connection with the Application and the Content. The technical procedures, processes, methods of operation and concepts which are embodied within the Application are the intellectual property of us and/or our licensors. You will not have or acquire any rights or interest in the Application, the Content or the trademarks, service marks and logos except as specifically set out herein. Any reproduction, resale or redistribution of the Application is expressly prohibited.<br />

                                                    <br /> •	You may not make any copies, uses or disclosures of the Application or the Content other than as authorized by us. You agree not to lease, sell, rent, assign, sublicense, loan or otherwise transfer to any third party any of your rights under these Terms.<br />

                                                    <br />  •	You acknowledge that any software related to the Application contains copyrighted and other proprietary and confidential information and material, and you will respect all such proprietary rights and take such precautions as may be reasonably necessary to protect private, confidential and other proprietary information and material from Unauthorized Use or disclosure.<br />

                                                    <br />
                                                    Third Party Services<br />

                                                    <br /> We are not responsible or liable for, and do not make any representations or warranties concerning, any Third Party Service, including their content, data, information or practices. The inclusion or accessibility of a Third Party Service via the Application does not imply any endorsement, recommendation or responsibility by us in relation to such Third Party Service. Your access and use of any Third Party Service shall be governed solely by the terms and conditions of the applicable third party that provides the Third Party Service to you. We are not liable for, and you irrevocably waive any claim against us in respect of, any damage or loss caused or alleged to be caused by your enablement, access or use of any Third Party Service.<br />
                                                    <br />
                                                    Default under these Terms<br />

                                                    <br />  We may immediately and without prior notice restrict, suspend or terminate your access to the Application and/or suspend or terminate your rights under these Terms, upon the occurrence of any of the following:<br />

                                                    <br />  •	If you breach or if we suspect you have breached these Terms in whole or in part, including the usage restrictions and confidentiality obligations;<br />
                                                    <br />  •	Such restriction, termination or suspension is required by law or regulation or a court or other authority;<br />
                                                    <br />  •	Your continued use of or access to the Application poses a security or privacy risk to the Application or any individuals; or<br />
                                                    <br /> •	The person or Organization that has authorized your access to the Application has requested or caused that your use and access be restricted, suspended or terminated.<br />

                                                    <br /> <br />
                                                    Disclaimer of Warranties

                                                    <br />  <br />   •	We do not warrant the accuracy, adequacy or completeness of the Application or the Content nor do we undertake to keep the Content updated. We do not accept responsibility for any loss suffered as a result of reliance by you upon the accuracy or currency of the Content or the Application.

                                                    <br /> <br />  •	You are responsible to maintain any data or information that you or your customers have uploaded, posted or transmitted to the Application. We are not responsible for the loss of any data or information you or your customers have uploaded, posted or transmitted to the Application and we have no obligation to maintain any data or information, you or your customers have uploaded, posted or transmitted to the Application.

                                                    <br /> <br /> •	The Application and the Content are accepted by you "as is" and "as available". All other representations or warranties, express or implied, including any warranties of merchantability, non-infringement, or as to correctness, quality, accuracy or reliability or as to fitness for a particular purpose and or any representations or warranties under applicable sale of goods legislation are specifically excluded and disclaimed. We do not warrant that the Application or any of the Content will meet your requirements. Neither we nor our Representatives make any representation or warranty (i) that access to the Application will be uninterrupted or error free, or (ii) that security breaches will not occur with respect to any information communicated through the Application, the Internet or any common carrier communications facility. We assume no responsibility to you or any other person for the consequences of any errors or omissions. The risk as to the quality and performance of the Application and any Content is yours.


                                                    <br /> <br />
                                                    Limitation of Liability

                                                    <br /> <br />   •	You acknowledge and agree that neither we nor our Representatives will be liable to you or any other person for any Claims that may arise directly or indirectly as a result of your or any other person's use of the Application and/or the Content including without limitation:
                                                    <br /> <br />  •	any failure, termination, suspension, delay or disruption of the Application, the Internet, or any communications network, facility or equipment;
                                                    <br /> <br />  •	any damages arising from your failed attempts to access the Application;
                                                    <br /> <br /> •	any damages arising from any failure by you to view or use the Application in, or transmit, post, upload any information or data to the Application in accordance with the Application’s documentation and/or instruction from us, or from any human, machine or software errors or faulty or erroneous input by you;
                                                    <br /> <br /> •	the integrity of any of the content that you, transmit or upload to the Application or that has been downloaded from the Application; or
                                                    <br /> <br />  •	any damages resulting from any delays and/or losses including without limitation any loss of data or information arising in connection with the Application.
                                                    <br /> <br /> •	You acknowledge that all Content is being provided to you without liability on our part or our Representatives. You agree that neither we nor our Representatives will have any liability whatsoever to you or any other person as a result of any use of the Application or the Content.

                                                    <br /> <br />   •	You expressly understand and agree that we and our Representatives make no representations or warranties whatsoever and have no legal, equitable, or other liability of any kind to you, regardless of the form of the action, whether for breach of contract, warranty, negligence, strict liability in tort or otherwise.

                                                    <br /> <br />  •	To the fullest extent permissible by applicable law, we expressly disclaim and shall have no liability whatsoever for any direct, indirect, special, incidental or consequential cost, loss, expense, damage or liability of any nature, loss of profits or revenue, loss of data, downtime costs or business opportunities arising out of or in connection with the Application or the Content including without limitation: (i) the failure of the Application to operate at any time; (ii) a third party's unauthorized access to the Application and/or communications transmitted over the network to or from the Application; and (iii) your or any third party’s use of the Application or the Content. In no event shall our aggregate liability to you exceed $100. You acknowledge that this is a fair allocation of the risks and responsibilities under these Terms and, to the extent permissible by applicable law, you waive any claim that the disclaimers of liability contained in this paragraph deprives you of an adequate remedy.

                                                    <br /> <br />
                                                    Indemnity

                                                    <br /> <br />  You agree to defend, indemnify, and hold harmless Moneris and our Representatives from and against any losses or Claims to the extent arising from or relating to (i) the use of the Application by you; (ii) the use of your Credentials or your merchant account by any other party; or (iii) failure by you (or by any person using your Credentials) to comply with these Terms.
                                                    <br /> <br />
                                                    Personal Information

                                                    <br /> <br />  Without limiting anything else herein, you acknowledge that certain information collected, used and disclosed in connection with the Application may constitute personal information under applicable law (“Personal Information”) and you agree that any such personal information is also subject to our privacy notice, as it may be amended from time to time, which can be found at the following link: https://www.moneris.com/en/Legal/Privacy-Notice (the “Privacy Notice”).

                                                    <br /> <br /> Personal Information about you that you enter into or make available via the Application may be used by Moneris to make the Application and its functionalities available to you and your Organization and its Representatives, including for the purposes of Section 5 (Monitoring) of these Terms. Such Personal Information is accessed, collected, handled and disposed of by Moneris as a service provider to your Organization, and you hereby consent to such uses, which will be subject to the terms of the Privacy Notice.

                                                    <br /> <br />  Additionally, you understand that we may use reasonably de-identified formats of such information for purposes including, but not limited to, the provision, maintenance, management and improvement of the Application, and for aggregated and statistical analysis and reporting.

                                                    <br />  <br />  With respect to any Personal Information about any third party that you enter into or make available to the Application, you acknowledge and agree that it is your responsibility to ensure that such Personal Information is collected, used and disclosed to us in compliance with all applicable laws, rules and regulations and your applicable stated privacy policies, and that you shall obtain and have all necessary consents, rights and authority to disclose such Personal Information to us to be used by us, and any of our agents, service providers or subcontractors as set forth in these Terms and/or for the purpose of providing the Application.

                                                    <br />  <br />
                                                    Feedback

                                                    <br /> <br /> You may provide feedback, suggestions, comments, improvements and ideas (collectively "Feedback") to us but you are not required to do so. We are not required to hold such feedback in confidence. We may use Feedback for any purpose without obligation of any kind. You assign all right, title, and interest in and to any Feedback that you provide to us without any obligation of confidentiality, attribution, accounting, compensation or other duty to account. To the extent a license is required under your intellectual property rights to make use of the Feedback, you grant to us and our affiliates an irrevocable, non-exclusive, perpetual, world-wide, transferable, sublicensable, royalty-free, fully paid-up license to use the Feedback in connection the business of us and our affiliates. You forever waive and agree never to assert against Moneris, its affiliates, successors, business partners and licensees and their respective officers, directors, shareholders, employees, agents and advisors any and all moral rights or other similar rights that you or your employees and subcontractors may have in the Feedback, to the extent permissible under applicable law.
                                                    <br />  <br />
                                                    Force Majeure

                                                    <br /> <br />    We will not be liable for any delay or failure to carry or make continuously available the Application including without limitation where such delay or failure is due to any cause beyond our reasonable control or the control of any of our third party providers, including without limitation, restrictions of law or regulation, labour disputes, acts of warfare or terrorism, acts of God, mechanical or electronic breakdowns, telecommunication facilities breakdowns or software bugs, errors or failures.
                                                    <br />
                                                    <br /> <br />
                                                    Governing Law

                                                    <br />  <br />  Your use of the Application and these Terms will be governed by and construed in accordance with the laws of the Province of Ontario and the laws of Canada applicable therein. You submit to the exclusive jurisdiction and venue of the courts of Ontario, located in Toronto, in order to enforce any provision of these Terms and for any action or suit arising out of the Application or your use thereof.
                                                    <br />  <br />
                                                    Severability and Waiver

                                                    <br />  <br />   Each of the provisions contained in these Terms is distinct and severable and a declaration of invalidity or unenforceability of any such provision or part thereof by a court of competent jurisdiction will not affect the validity or enforceability of any other provision in these Terms.
                                                    <br />  <br />   You agree that our conduct, delay, actions or failure to enforce any of the terms and conditions of these Terms does not waive any of our rights under these Terms or change your obligations under these Terms.
                                                    <br />  <br />  Assigning Your Rights or Obligations

                                                    <br />  <br /> You cannot assign all or part of your rights or obligations under these Terms without first getting our written approval. However, we can assign any of our rights and obligations under these Terms. These Terms bind any permitted successors and your permitted assigns.
                                                    <br />  <br /> For Residents of Quebec

                                                    <br />   <br /> It is agreed that the express wish of the parties is that these Terms and any related documents be drawn up and executed in English. Il est la volonté expresse des parties que cette convention et tous les documents s'y rattachant soient rédigés en anglais.

                                                    <br />  <br />   ® MONERIS, MONERIS & Design and Moneris Solutions & Design are registered trademarks of Moneris Solutions Corporation<br /></p>}
                                        />
                                    </Grid>
                                    <Grid style={{ height: '20%', display: 'flex', flexDirection: 'column', marginRight: 48, marginLeft: 39, marginTOp: 15, justifyContent: 'center' }}>
                                        <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                            <Checkbox
                                                data-qaid="customerDataTable_checkbox"
                                                checked={values.accept}
                                                checkedIcon={<CheckedCheckBoxIcon />}
                                                icon={<CheckBoxIcon />}
                                                id="all"
                                                onChange={() => setFieldValue("accept", !values.accept)}
                                            />
                                            <H5 label={Context.langData.checkbox_message} style={{ color: '#54575A', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                                        </Grid>
                                        <Box data-qaid="activation_formik_action_box" md={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 5, flexDirection: 'row' }}>
                                            <Button
                                                data-qaid="activation_formik_login_button"
                                                style={{ backgroundColor: '#FFFFFF', height: 40, border: '2px solid #051D33', borderRadious: 5, width: 91 }}
                                                onClick={handleBackButton}
                                                variant="outlined"
                                            >
                                                <AvenirBlackH4 data-qaid="lactivation_formik_login_label" style={{ color: '#004C60' }} label={Context.langData.back} />
                                            </Button>
                                            <Button
                                                data-qaid="activation_formik_login_button"
                                                style={{ backgroundColor: values.accept ? '#FFB600' : "#DDDDDD", marginLeft: 10, height: 40, width: 105, borderRadius: 5 }}
                                                type="submit"
                                                disabled={!values.accept}
                                                variant="contained"
                                            >
                                                <AvenirBlackH4 data-qaid="lactivation_formik_login_label" style={{ color: '#051D33' }} label={Context.langData.i_agree} />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>

            <Message data-qaid="activation_formik_message" open={alertData.open} severity={alertData.severity} msg={alertData.msg} handleClose={() => console.log()} />
        </Page >
    );
};


export default UserTerms;


