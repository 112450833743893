import moment from "moment";
import {
  getWookBook,
  downloadAs,
  setHeaderStyle,
  setNormalRowStyle,
} from "./workbook";
const DownloadKitchen = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Kitchen balance");
  ws.addRow([
    "Name",
    "Balance",
    "Units",
    "Last recived",
    "Last updated"
  ]);
  ws.getColumn(1).width = 30;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  setHeaderStyle(ws, 1);
  data.map((d, i) => {
    ws.addRow([
      d.name,
      d.currentBalance,
      d.unitTypeCode,
      d.lastReceived,
      d.lastAdjusted
    ]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(
    workbook,
    `Kitchen_${moment().format("yyyyMMDDHHmmss")}.xlsx`
  );
};

export { DownloadKitchen };
