import React, { useState, useContext } from 'react';
import {
    Grid,
  } from "@material-ui/core";  
import {AvenirBlackH4, T } from "../../../../utils/text";
import TopSearchBar from '../../../../components/SearchBar';
import MyContext from '../../../../Provider/MyContext';
import SingleSelect from '../../../../components/SingleSelect';
import InfoOutlined from '../../../../components/SVG/InfoOutlined';

const TerminalHeader = ({onChange, value}) => {

    const Context = useContext(MyContext);
    const [itemtypes, setItemTypes] = useState(2);
    const itemsDropDownList = [
        {
            id: 2,
            name: Context.langData.all
        }
    ]

    return(
        <Grid>
            <Grid container style={{flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                <Grid data-qaid="contactTracing_topSearchBarGrid" item lg={8} style={{ marginTop: 22, paddingRight: 0 }}>
                <TopSearchBar
                    value={value}
                    onChange={onChange}
                    placeholder={Context.langData.search_for_an_terminal}
                    style={{ height: 58 }}
                    width={'100%'}
                    />
            </Grid>
            <Grid data-qaid="itemSales_orderTypeGrid" item lg={4} style={{ paddingLeft: 20 }}>
                    <AvenirBlackH4 data-qaid="itemSales_orderTypeLabel" label={Context.langData.status} />
                    <SingleSelect
                        data-qaid="alltrancation_ordertype_singleselect"
                        list={itemsDropDownList}
                        value={itemtypes}
                        valueKey="id"
                        displayKey="name"
                        handleChange={(e) => { setItemTypes(e.target.value); }}
                        border={false}
                        height={56}
                        width={'100%'}
                        FormWidth='100%'
                        />
                </Grid>
            </Grid>
            <Grid
                data-qaid="terminalsList-blurb"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 15,
                    backgroundColor: '#F3FAFD',
                    height: 'auto',
                    borderRadius: 5,
                    marginTop: 13,
                }}
                >
                <Grid data-qaid="terminalsList-blurb-InfoIcon">
                    <InfoOutlined width={24} height={24} color={'#004C60'} />
                </Grid>
                <Grid style={{ paddingLeft: 10,}}>
                    <Grid style={{ flexDirection: 'row', display: 'flex', paddingTop: 4 }}>
                        <Grid data-qaid="terminalsList-blurb-message">
                        <T label={Context.langData.terminal_support_message} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TerminalHeader;