import React, { useState, useContext } from 'react';
import {
    Container,
    Grid,
    makeStyles,
} from '@material-ui/core';
import TopBar from '../layouts/DashboardLayout/TopBar';
import Page from '../components/Page';
import MyContext from '../Provider/MyContext';
import { H6 } from '../utils/text';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: '#FFFFFF',
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1)
    }
}));
const NoAccesPage = () => {
    const classes = useStyles();
    const Context = useContext(MyContext);
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    return (
        <Page
            data-qaid="no_accespage_page" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <TopBar data-qaid="no_accespage_topbar" onMobileNavOpen={() => setMobileNavOpen(true)} />
            <div data-qaid="no_accespage_div" style={{ minHeight: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', minWidth: '100%', justifyContent: 'center' }}>
                <H6 data-qaid="dashboard_nopermissions_message" label={Context.langData.access_denied_error} style={{ textAlign: 'center', width: '55%', fontWeight: 'bold' }} />
            </div>
        </Page>
    )
}
export default NoAccesPage;
