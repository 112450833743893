import React from 'react';
import { Button } from '@material-ui/core';
import PencileIcon from '../../../components/SVG/PencileIcon';
import { H4 } from '../../../utils/text';

const EditButton = ({ onClick }) => {
  return (
    <Button
      onClick={() => onClick()}
      startIcon={<PencileIcon color="#FFF" height={16} width={16} />}
      style={{ backgroundColor: '#004C60', width: 60, height: 30 }}
      variant="contained">
      <H4 style={{ color: 'white', fontSize: 12 }} label='Edit' />
    </Button>
  )
}

export default EditButton