import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getSsoRedirectUrl,
  handleSSOLogin,
  redirectExternalUrl,
  ssoCallback,
} from "../../Provider/SsoServices";
import { useDispatch, useSelector } from "react-redux";
import { fetchRolePermissions } from "../../redux/actions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button, DialogActions } from "@material-ui/core";
import {
  ReadFromSession,
  RemoveFromSession,
  SaveToSession,
} from "../../Provider/sessionStorageServices";
import { StorageKey } from "../../models/Constant";
import {  useLDClient } from 'launchdarkly-react-client-sdk';
import { string } from "mathjs";

const SsoCallback = () => {
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const code = searchParams.get("code");
  const client_info = searchParams.get("client_info");
  const session_state = searchParams.get("session_state");
  const ldClient = useLDClient();
  const loginDetails = useSelector(state => state.loginReducer.loginDetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });

  

  useEffect(() => {
    if ( loginDetails && loginDetails.restaurants.length > 0) {
      var context = {
        "kind": "restaurant",
        "key": "restaurant",
        "restId": loginDetails.restaurants[0].rest_id
      }
      if (ldClient) {
        ldClient.identify(context, null, () => {
          console.log("New context's flags available");
        });
      } 
    }
  }, [loginDetails]);

  useEffect(() => {
    // Validate auth state and current state
    dispatch({ type: "LOADING" });
    if (
      client_info &&
      code &&
      session_state &&
      state &&
      state === ReadFromSession(StorageKey.SsoState)
    ) {
      RemoveFromSession(StorageKey.SsoState);

      const payload = {
        code: code,
        state: state,
        client_info: client_info,
        session_state: session_state,
      };



      ssoCallback(payload)
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data});
            dispatch(fetchRolePermissions());
            SaveToSession(StorageKey.Is_Sso, res.data.is_sso);
            if (res.data.app_state !== "") {

              // Retrieve router state from session, and remove it from session
              let routerState = ReadFromSession(StorageKey.RouterState);
              RemoveFromSession(StorageKey.RouterState);

              // Navigate to the app state with router state
              navigate(res.data.app_state, {state: JSON.parse(routerState)});
            } else {
              navigate("/home/dashboard")
            }
          }
        })
        .catch((err) => {
          navigate("/500");
        })
        .finally(() => {
          dispatch({ type: "LOADED" });

        })
    } else {
      setAlertData({
        open: true,
        severity: "error",
        msg: "IDP Parameters Mismatch",
      });
    }
  }, [client_info, code, session_state, state]);

  const handleClose = () => {
    setAlertData({
      open: false,
      severity: "success",
      msg: "",
    });
    navigate("/");
  };

  return (
    <Dialog
      open={alertData.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">SSO</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "#AB1212" }}
        >
          {alertData.msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Go back to login</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SsoCallback;
