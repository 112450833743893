import * as React from "react"

const UpVector = ({ width = 13, height = 8, color = '#004C60', ...props }) => (
    <svg
        width={width}
        height={height}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m.295 6.295 1.41 1.41 4.59-4.58 4.59 4.58 1.41-1.41-6-6-6 6Z"
            fill={color}
        />
    </svg>
)

export default UpVector
