import { Undo } from '@material-ui/icons';
import { floor } from 'lodash';
import setState from './setState'
import moment from 'moment';
import _ from 'lodash';
const initialState = {
  savedData: [],
  workingData: [],
  isDragging: false,
  initWorkingData: [],
  history: [[]],
  floorplans: [],
  currentStep: 0,
  selectedIndex: -1,
  isFloorSelected: false,
  floorDetails: {
    created_on: 1618573518,
    description: "Its a testing ",
    device_name: "iphone_x",
    device: { sizes: [800, 400] },
    floorplan_id: -1,
    is_active: 1,
    last_modified_on: 1618638171,
    name: "Winter Plann",
    otype_id: 1,
    tables: 0,
    seats: 0,
    rotate: 0,
    floor: {
      type: 'solid',
      color: '#BCC1CC',
      orientation: 'horizontal'
    }
  },
  hasChanges: false,
  sectionsData: { sections: [] },
  sectionsList: [],
  currentSection: null

};
const sortWorkingData = (data) => {
  return _.sortBy(data, ['category'])

}
let lastUpdate = null;
const HISTORY_LENGTH = 50;
function floorplan(state = initialState, action) {
  switch (action.type) {
    case "SET_HAS_CHANGES": return { ...state, hasChanges: action.payload }
    case "SECTIONS": return { ...state, sectionsData: action.payload, sectionsList: action.list }
    case "SET_CURRENT_SECTION": return { ...state, currentSection: action.payload, hasChanges: false }
    case 'ADD_ITEM': return {
      ...state,
      ...addItem(state.workingData, action.payload, state.history, state.floorDetails),
      isDragging: false,
      hasChanges: true
    }
    case "DUPLICATE": return {
      ...state,
      ...duplicate(state, action.id, action.item),
      hasChanges: true
    }

    case 'SET_WORKINGDATA': return {
      ...state,
      workingData: sortWorkingData(action.workingData),
      initWorkingData: sortWorkingData(action.workingData),
    }
    case 'UPDATE_SIZES': return {
      ...state,
      ...updateSizes(state.workingData, action.payload, action.index, state.history),
      hasChanges: true
    }
    case 'UPDATE_POSITION': return {
      ...state,
      ...updatePosition(state.workingData, action.payload, action.index, state.history),
      hasChanges: true
    }
    case 'UPDATE_POSITION_SIZE': return {
      ...state,
      ...updatePositionAndSize(state.workingData, action.payload, action.index, state.history),
      hasChanges: true
    }
    case "UPDATE_ITEM": return {
      ...state,
      ...updateItem(state.workingData, action.payload, action.index, state.history, state.floorDetails),
      hasChanges: true
    }
    case 'SELECT_ITEM': return {
      ...state, selectedIndex: action.index, isFloorSelected: false
    }
    case 'UPDATE_LIST': return {
      ...state,
      workingData: action.payload
    }
    case 'DELETE_ITEM': return {
      ...state,
      ...deleteItem(state.workingData, action.index, state.history, state.floorDetails),
      hasChanges: true
    }
    case "DELETE_ITEM_HOT": return {
      ...state,
      ...deleteById(state.workingData, action.index, state.history, state.floorDetails, state.selectedIndex),
      hasChanges: true
    }
    case 'UNDO': return {
      ...state, ...undo(state.history, state.currentStep)
    }
    case 'REDO': return {
      ...state, ...redo(state.history, state.currentStep)
    }
    case "CURRENTSTEP": return { ...state, currentStep: 0 }
    case "ADD_CURRENTSTEP": return { ...state, currentStep: 1 }
    case 'SET_FLOORSELECTED': return { ...state, isFloorSelected: !state.isFloorSelected, selectedIndex: -1 }
    case "SET_BACKGROUND": return {
      ...state,
      floorDetails: {
        ...state.floorDetails, floor: action.payload
      },
      hasChanges: true
    }
    case "ROTATE": return {
      ...state,
      floorDetails: { ...state.floorDetails, rotate: action.payload },
      hasChanges: true
    }
    case "CLEAR_FLOORPLAN":
    case "CLEAR": return initialState
    case "GET_FLOOR_PLAN_SUCCESS": return {
      ...state,
      floorplans: action.payload
    }
    case "SET_FLOOR":
      return {
        ...state, floorDetails: { ...state.floorDetails, ...action.payload }
      }
    case "SET_HISTORY":
      return {
        ...state,
        history: action.payload
      }
    case "CANCEL": return {
      ...state,
      selectedIndex: -1,
      isFloorSelected: false
    }
    case "DRAGGING": return { ...state, isDragging: action.payload }
    default: return state
  }
}

const undo = (history, step) => {
  if (step > 0) {
    step--;
  }
  let obj = {
    currentStep: step,
    selectedIndex: -1
  }
  if (history.length > 0) {
    obj["workingData"] = history[step]
  }
  return obj

}
const ParseFloorPlanList = (Response, isActive) => {
  var array = []
  if (Response.length > 0) {
    if (isActive) {
      array = Response.items.filter((e) => e.is_active === 1);
      if (array.length > 0) {
        array.map(d => {
          d["data"] = "Last Modified:" + moment(d.last_modified_on).format("MMMM DD, YYYY");
        })
      }
    } else {
      array = Response.items.filter((e) => e.is_active === 0);
      if (array.length > 0) {
        array.map(d => {
          d["data"] = "Last Modified:" + moment(d.last_modified_on).format("MMMM DD, YYYY");
        })
      }
    }
  }
  return array;


}
const redo = (history, step) => {
  if (step < history.length - 1) {
    step++;
  }
  let obj = {
    currentStep: step,
    selectedIndex: -1
  }
  if (history.length > 0) {
    obj["workingData"] = history[step]
  }
  return obj

}
const addItem = (workingData, item, history, floorDetails) => {
  let data = [...workingData, item];
  data = _.sortBy(data, ['category'])
  let index = _.findIndex(data, (e) => e.id == item.id)
  return {
    workingData: data,
    ...addToHistory(data, history),
    floorDetails: calculateSeats(floorDetails, item),
    selectedIndex: index
  }
}
const duplicate = (state, id, item) => {
  if (id === state.selectedIndex) {
    return {
      ...addItem(state.workingData, item, state.history, state.floorDetails)
    }
  } else {
    return { ...state }
  }
}
const calculateSeats = (floorDetails, item) => {
  if (item.category == "Table")
    return {
      ...floorDetails,
      tables: floorDetails.tables + 1,
      seats: floorDetails.seats + item.seats
    }
  else {
    return floorDetails
  }
}

const addToHistory = (data, history) => {

  const newHistory = [...history, data];
  if (newHistory.length > HISTORY_LENGTH) {
    newHistory.shift();
  }
  clearTimeout(lastUpdate)
  return { history: newHistory, currentStep: newHistory.length - 1 }

}
const updateSizes = (workingData, item, index, history) => {
  const data = [
    ...workingData.slice(0, index),
    { ...workingData[index], width: item.width, height: item.height },
    ...workingData.slice(index + 1)
  ]
  return { workingData: data, ...addToHistory(data, history) }
}
const updatePositionAndSize = (workingData, item, index, history) => {
  const data = [
    ...workingData.slice(0, index),
    { ...workingData[index], width: item.width, height: item.height, x: item.x, y: item.y },
    ...workingData.slice(index + 1)
  ]
  return { workingData: data, ...addToHistory(data, history) }
}
const updateItem = (workingData, item, index, history, floorDetails) => {
  const preItem = workingData[index];
  const changeOnSeats = item.seats - preItem.seats;
  console.log(preItem, item)
  const data = [
    ...workingData.slice(0, index),
    item,
    ...workingData.slice(index + 1)
  ]
  return { workingData: data, floorDetails: { ...floorDetails, seats: floorDetails.seats + changeOnSeats } }
}
const updatePosition = (workingData, item, index, history) => {
  console.log(workingData, history)
  const data = [
    ...workingData.slice(0, index),
    { ...workingData[index], x: item.x, y: item.y },
    ...workingData.slice(index + 1)
  ]
  return { workingData: data, ...addToHistory(data, history) }
}
const deleteItem = (workingData, index, history, floorDetails) => {
  const item = workingData[index];
  const data = [...workingData.slice(0, index),
  ...workingData.slice(index + 1)];
  return {
    workingData: data,
    ...addToHistory(data, history),
    floorDetails: calculateSeats(floorDetails, item),
    selectedIndex: -1
  }

}
const deleteById = (workingData, index, history, floorDetails, selected) => {
  if (index === selected) {
    return {
      ...deleteItem(workingData, index, history, floorDetails)
    }
  }
  else {
    return {}
  }
}
export default floorplan;
