import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, Button } from '@material-ui/core';
import RightDrawer from '../../../../components/RightDrawer';
import CircleClose from '../../../../components/SVG/CircleClose';
import BackIcon from '../../../../components/SVG/Back';
import Title from '../combos/Title'
import InputText from '../../../../components/InputText';
import CirclePlus from '../../../../components/SVG/CirclePluse';
import { AvenirBlackH3, AvenirBlackH4 } from '../../../../utils/text';
import DeleteIcon from '../../../../components/SVG/Delete';
import { validateAlphaNumeric } from '../../../../utils/util';

const DietaryAttribute = ({ open, onClose, dietary, onSaveDietary }) => {
  const [name, setName] = useState('')
  const [sizesList, setSizes] = useState([]);
  useEffect(() => {
    setSizes(dietary)
  }, [dietary])
  const addSize = () => {
    if (name) {
      const index = sizesList.findIndex(e => e == name);
      if (index == -1) {
        setSizes([...sizesList, name])
      }
    }
    setName('')
  }
  const onChangeSizeFields = (value, index) => {
    setSizes([
      ...sizesList.slice(0, index),
      validateAlphaNumeric(value),
      ...sizesList.slice(index + 1)
    ])
  }
  const deleteSize = (index) => {
    setSizes([
      ...sizesList.slice(0, index),
      ...sizesList.slice(index + 1)
    ])
  }
  const onSave = () => {
    onSaveDietary(sizesList)
  }

  const handleName = (data) => {
    setName(validateAlphaNumeric(data))
  }

  return (
    <RightDrawer open={open}>
      <Grid container style={{ position: 'relative', minWidth: 500, maxWidth: 500, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', }}>
        <Grid container style={{ width: '100%', flexFlow: 'column', height: window.innerHeight - 70, overflow: 'auto', paddingBottom: 80 }}>
          <Grid container data-qaid="create_item_container" style={{ height: 60, padding: '0px 24px' }} justify="space-between" alignItems="center">

            <Grid item xs={12} >
              <Grid container justify="flex-end" alignItems="center" >
                <CircleClose data-qaid="assignkitchen_closeicon" size={20} onClick={onClose} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ paddingLeft: 20 }}>
            <BackIcon onClick={onClose} />
          </Grid>
          <Grid container>
            <Title title="Dietary attributes configure" />
          </Grid>
          <Grid container direction="row" style={{ marginTop: 30, padding: '0px 24px' }}>
            <Grid item xs={12} >
              <InputText
                onChange={e => handleName(e.target.value)}
                value={name}
                maxLength={40}
                placeholder="" />
            </Grid>
            <Grid container direction="row" justify='flex-start' style={{ marginTop: 15, width: 'auto', cursor: 'pointer' }} onClick={addSize}>
              <CirclePlus color={"#004C60"} />
              <AvenirBlackH3 label="Add" style={{ marginLeft: 5, color: "#004C60" }} />
            </Grid>
          </Grid>
          <Grid container>
            <Title title="Edit existing dietary attribute" />
          </Grid>
          <Grid container style={{ paddingLeft: 20 }}>
            {
              sizesList.map((s, index) => {
                return (
                  <Grid container direction="row" key={index} justify="space-between" style={{ marginTop: 15 }}>
                    <Grid item xs={10}>
                      <InputText
                        onChange={e => onChangeSizeFields(e.target.value, index)}
                        value={s}
                        maxLength={40}
                        placeholder={`Dietary attribute ${index + 1}`} />
                    </Grid>

                    <Grid item xs={1}>
                      <Grid container alignItems='center' justify='center' style={{ height: '100%', paddingTop: 15 }}>
                        <DeleteIcon onClick={() => deleteSize(index)} />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
        <Grid container style={{ position: 'absolute', zIndex: 999, backgroundColor: "white", height: 60, backgroundColor: "white", borderTop: '1px solid #EEE', justifyContent: 'flex-end', bottom: 0, left: 0, alignItems: 'center', zIndex: 999 }}>
          <Grid item xs={12} md={6}>
            <Grid container justify="flex-end">

              <Button style={{ border: '2px solid #707272' }} onClick={onClose} >
                <AvenirBlackH4 label="Cancel" />
              </Button>
              <Button

                variant="contained"
                style={{ backgroundColor: '#FFB600', marginLeft: 20, marginRight: 20 }} onClick={onSave}>
                <AvenirBlackH4 label="Save" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  )
}

export default DietaryAttribute;