import React, { useContext, useCallback } from 'react';
import { useNavigate, useLocation, generatePath } from 'react-router';
import {
  Grid,
  Container,
  Box,
  Button,
  Divider,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  makeStyles,
} from '@material-ui/core';
import MonerisPOSLogo from '../../components/SVG/MonerisPOSLogo';
import Page from '../../components/Page';
import MyContext from '../../Provider/MyContext';
import { H4, TBold, T } from '../../utils/text';
import urls from '../../urls';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F3FAFD',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const SsoLogout = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const Context = useContext(MyContext);
  const navigate = useNavigate();

  const handleLoginRedirect = useCallback(() => {
    // Redirect to login
    if (state) {
      navigate(`${urls.sso.loginRedirect}${state.rest_id}`);
    } else {
      navigate(urls.home);
    }
  }, [state]);

  return (
    <Page
      data-qaid="loginView_page"
      className={classes.root}
      title={Context.langData.Login}
    >
      <Box
        data-qaid="loginView_box"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container data-qaid="loginView_container" maxWidth="md">
          <Grid
            data-qaid="loginView_formik_main_grid"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 49,
            }}
          >
            <MonerisPOSLogo />
          </Grid>
          <Grid container justifyContent="center">
            <TBold
              label="You are logged out"
              style={{ fontSize: 18, fontWeight: 800 }}
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 32 }}
          >
            <Grid
              container
              style={{
                backgroundColor: '#FFB600',
                width: 200,
                height: 40,
                borderRadius: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleLoginRedirect}
            >
              <H4 label="Log in with Moneris SSO" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export default SsoLogout;
