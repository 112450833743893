import * as React from "react";

function CirclePluse({ width = 18, height = 18, color = "#051D33", ...props }) {
  return (
    // <svg
    //     width={width}
    //     height={height}
    //     viewBox="0 0 16 16"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //     {...props}
    // >
    //     <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M1.125 8a6.875 6.875 0 1113.75 0 6.875 6.875 0 01-13.75 0zM8 .208a7.792 7.792 0 100 15.584A7.792 7.792 0 008 .208zM8.458 4a.458.458 0 10-.916 0v3.542H4a.458.458 0 100 .916h3.542V12a.458.458 0 00.916 0V8.458H12a.458.458 0 100-.916H8.458V4z"
    //         fill={color}
    //         stroke={color}
    //         strokeWidth={0.25}
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //     />
    // </svg>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 1.69336C6.20101 1.69336 1.5 6.39437 1.5 12.1934C1.5 17.9923 6.20101 22.6934 12 22.6934C17.799 22.6934 22.5 17.9923 22.5 12.1934C22.5 6.39437 17.799 1.69336 12 1.69336ZM0.5 12.1934C0.5 5.84208 5.64873 0.693359 12 0.693359C18.3513 0.693359 23.5 5.84208 23.5 12.1934C23.5 18.5446 18.3513 23.6934 12 23.6934C5.64873 23.6934 0.5 18.5446 0.5 12.1934ZM12 5.69336C12.2761 5.69336 12.5 5.91722 12.5 6.19336V11.6934H18C18.2761 11.6934 18.5 11.9172 18.5 12.1934C18.5 12.4695 18.2761 12.6934 18 12.6934H12.5V18.1934C12.5 18.4695 12.2761 18.6934 12 18.6934C11.7239 18.6934 11.5 18.4695 11.5 18.1934V12.6934H6C5.72386 12.6934 5.5 12.4695 5.5 12.1934C5.5 11.9172 5.72386 11.6934 6 11.6934H11.5V6.19336C11.5 5.91722 11.7239 5.69336 12 5.69336Z"
        fill={color}
      />
    </svg>
  );
}

export default CirclePluse;
