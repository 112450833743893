import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPettyCashCategories } from '../../../redux/actions';

import CategoriesList from './categoriesList';

const Categories = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPettyCashCategories());
    }, [])
    return (
        <div data-qaid="Categories_index_div">
            <CategoriesList data-qaid="Categories_index_categoriesList">
            </CategoriesList>
        </div>
    );
};

export default Categories;
