import React from 'react';
import { FormControl, InputLabel, OutlinedInput, FormHelperText, Typography, makeStyles } from '@material-ui/core';
import styles from '../../styles'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '../../components/SVG/InfoOutlined';
import {AvenirBlackH4, H5} from '../../utils/text';
import ExclamationIcon from '../../components/SVG/ExclamationIcon';

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      // borderWidth:0,
      borderColor: "#707272"
    },
    "&:hover $notchedOutline": {
      borderWidth:1,
      borderColor: "#707272 !important"
    },
    "&$focused $notchedOutline": {
      borderWidth:1,
      borderColor: "#707272 !important"
    }
  },
  focused: {},
  notchedOutline: {}
}));

const Input = ({
  value,
  onChange,
  id,
  error,
  placeholder = "",
  type = "text",
  info = "",
  paddingLeft = 10,
  paddingRight = 10,
  hasRegex = false,
  readOnly = false,
  marginTop=10,
  maxLength,
  inputPlaceholder,
  ...props }) => {

  const style = styles();
  const outlinedInputClasses = useOutlinedInputStyles();

  if (error) {
    props["error"] = true
  }
  const onChangevalue = (val) => {
    if (hasRegex) {
      var regex = new RegExp("^[a-zA-Z0-9]+$");
      if (regex.test(val)) {
        onChange(val)
      }
    }
    else {
      onChange(val)
    }

  }
  return (
    <FormControl {...props} variant="outlined" size="small" style={{ marginTop: marginTop, paddingLeft: paddingLeft, paddingRight: paddingRight }} className={style.fullWidth}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <AvenirBlackH4 style={{ paddingBottom: 5 }} label={placeholder} />
        {
          info &&
          <Tooltip data-qaid="downlode_csv_tooltip" title={info} >
            <IconButton>
              <InfoOutlined width={12} height={12} />
            </IconButton>
          </Tooltip>
        }

      </div>
      <OutlinedInput
        {...props}
        id={id}
        type={type}
        value={value}
        style={props.rows ? {} : { height: 56, backgroundColor: readOnly ? '#FAFAFA' : '#FFF', color: readOnly ? '#54575A' : '#051D33', border: readOnly ? '1px solid #C3C3C3' : null }}
        onChange={(e) => onChangevalue(e.target.value)}
        aria-describedby={`${id}-text`}
        classes={outlinedInputClasses}
        placeholder={inputPlaceholder}
        inputProps={
					{ readOnly: readOnly, 
            maxLength: maxLength 
          }
				}
      />
      {error &&
      <FormHelperText id={`${id}-text`} style={{marginLeft:0}}>
        <div style={{flexDirection:'row', display: 'flex', alignItems: 'center'}}>
          <ExclamationIcon color="#AB1212" style={{ marginRight: 10 }} />
          <H5 label={error} style={{color:"#AB1212"}}/>
        </div>
      </FormHelperText>
      }
    </FormControl>

  )
}
export default Input;
