import * as React from "react"

const EditIcon = React.memo(({ width = 24, height = 24, color = "#051d33", ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.977 1.922L2.927 16.974l-1.256 5.355 5.356-1.257 15.05-15.048a1.445 1.445 0 00.001-2.041l-2.06-2.06a1.443 1.443 0 00-2.04 0zm2.748-.707a2.443 2.443 0 00-3.455 0L2.12 16.368a.5.5 0 00-.134.239L.513 22.886a.5.5 0 00.601.6l6.28-1.473a.5.5 0 00.24-.133L22.784 6.73c.953-.954.953-2.5 0-3.454l-2.06-2.06z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.583 1.902a.5.5 0 01.708 0l4.81 4.81a.5.5 0 01-.707.706l-4.81-4.809a.5.5 0 010-.707zM15.211 3.274a.5.5 0 01.707.002l4.798 4.822a.5.5 0 01-.71.705L15.21 3.981a.5.5 0 01.002-.707zM2.363 16.122a.5.5 0 01.708 0l4.807 4.807a.5.5 0 11-.707.707L2.363 16.83a.5.5 0 010-.707z"
        fill={color}
      />
    </svg>
  )
})

export default EditIcon
