import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, makeStyles, InputAdornment } from '@material-ui/core'
import { H4, H6, AvenirBlackH3, AvenirBlackH4 } from '../../../../utils/text'
import CircleClose from "../../../../components/SVG/CircleClose";
import MenuBookIcon from '../../../../components/SVG/Menu'
import RightDrawer from "../../../../components/RightDrawer";
import MyContext from '../../../../Provider/MyContext';
import InputText from '../../../../components/InputText';
import { ConvertPrice } from "../../../../utils/util";
const useStyles = makeStyles({
  inputText: {
    fontSize: 14,
    color: '#051D33',
    fontFamily: 'AvenirLT',
  },
  h: {
    height: "100%"
  }
})
const VariablePricing = ({ prices, open, onClose, onSave }) => {
  const classes = useStyles();
  const Context = useContext(MyContext);
  const [newPrices, setPrices] = useState([])
  useEffect(() => {
    setPrices(prices)
  }, [prices])
  const setPrice = (value, index) => {
    let np = [...newPrices];
    np[index].price = value;
    setPrices(np)
  }
  const savePrices = () => {
    onSave(newPrices)
  }
  return (
    <RightDrawer open={open} onClose={onClose}>
      <Grid container style={{ position: 'relative', minWidth: 500, maxWidth: 500, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <Grid container style={{ width: '100%' }}>
          <Grid container data-qaid="create_item_container" style={{ height: 60, padding: '0px 24px' }} justify="space-between" alignItems="center">
            <Grid item xs={6} className={classes.h}>
              <Grid container justify="flex-start" alignItems="center" className={classes.h}>
                <MenuBookIcon data-qaid="assignkitchen_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                <H4 data-qaid="assignkitchen_settings" label={Context.langData.menu} style={{ color: '#707272' }} />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.h}>
              <Grid container justify="flex-end" alignItems="center" className={classes.h}>
                <CircleClose data-qaid="assignkitchen_closeicon" size={20} onClick={onClose} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20, padding: '0px 24px' }}>
            <H6 label="Variable pricing (order type)" />
          </Grid>

          <Grid container style={{ margin: '50px 24px 0px', borderBottom: '1px solid #EEE', height: 40, marginTop: 60 }}>
            <AvenirBlackH3 label="Configure order types" />
          </Grid>
          <Grid container style={{ height: window.innerHeight - 280, overflowY: 'auto', paddingBottom: 100, flexFlow: 'column', paddingRight: 50 }}>
            {
              prices.map((price, index) => {
                return (
                  <Grid container alignItems='center' key={index} style={{ margin: '10px 24px 0px' }}>
                    <Grid item xs={6}>
                      <H4 label={price.otype_name} />
                    </Grid>
                    <Grid item xs={6}>
                      <InputText
                        value={`${price.price == '' ? '' : '$' + price.price}`}
                        inputPlaceholder="$0.00"
                        maxLength={9}
                        onChange={e => setPrice(e.target.value.replace("$", "").replace(/[^0-9\.?]/g, "") || "", index)}
                        onBlur={e => setPrice(ConvertPrice(price.price), index)}
                      />
                    </Grid>
                  </Grid>
                )
              })
            }

          </Grid>
        </Grid>
        <Grid container style={{ backgroundColor: 'white', position: 'absolute', height: 60, borderTop: '1px solid #EEE', justifyContent: 'flex-end', bottom: 0, left: 0, alignItems: 'center' }}>
          <Grid item xs={12} md={6} style={{ marginRight: 20 }}>
            <Grid container justify="flex-end" >
              <Button style={{ border: '2px solid #707272', marginRight: 10 }} onClick={onClose}>
                <AvenirBlackH4 label="Cancel" />
              </Button>
              <Button variant="contained"
                style={{ backgroundColor: '#FFB600' }} onClick={savePrices}>
                <AvenirBlackH4 label="Save" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  )
}
export default VariablePricing