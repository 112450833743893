import * as React from 'react';
import { TextField, Grid, makeStyles, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from './SVG/SearchIcon';
const useAutoCompliteStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 6,
    height: '60px',
    "& $notchedOutline": {
      borderColor: "#707272",
    },
    "&:hover $notchedOutline": {
      borderColor: "#707272",
    },
    "&$focused $notchedOutline": {
      borderColor: "#004C60",
    },
    "&.Mui-error": {
      borderColor: "#AB1212"
    },
  },
  focused: {},
  notchedOutline: {

  },
  option: {
    borderBottom: "1px solid #EEEEEE",
    "&:last-child": {
      borderBottom: "1px solid #FFF"
    },
    height: 40,
    "&:hover": {
      backgroundColor: "#C8E6F1 !important"
    }
  }

}));
const AutoComplete = ({ key, options, onChange, labelKey, placeholder = null, onInputChange, inputValue }) => {
  const autoCompliteStyles = useAutoCompliteStyles();
  let c = { key: key };
  if (typeof inputValue !== "undefined") {
    c["inputValue"] = inputValue;
  }
  return (
    <Autocomplete
      style={{ height: 60 }}
      freeSolo
      key={key}
      {...c}
      disableClearable
      PaperComponent={({ children }) => (
        <Paper style={{ border: "1px solid #004C60" }}>{children}</Paper>
      )}
      onInputChange={(event, newInputValue) => {
        if (onInputChange) {
          onInputChange(newInputValue);
        }
      }}
      classes={{
        option: autoCompliteStyles.option
      }}
      options={options?.map((option) => option[labelKey])}
      onChange={(event, value) => onChange(value)}
      renderInput={(params) => (
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 60 }}>
          <TextField
            style={{ height: '60px', border: '1px solid #707272', borderRadius: 4, display: 'flex', alignItems: 'center' }}
            {...params}
            placeholder={placeholder}
            InputProps={{
              classes: {
                root: autoCompliteStyles.root,
                focused: autoCompliteStyles.focused,
                notchedOutline: autoCompliteStyles.notchedOutline,
              },
              ...params.InputProps,
              type: 'search',
              minHeight: '60px',
              paddingLeft: '10px',
              disableUnderline: true,
              endAdornment: (
                <SearchIcon style={{ cursor: 'pointer', marginRight: 8, display: 'flex' }} />
              ),

            }}
          />
        </Grid>
      )}
    />

  );
}

export default React.memo(AutoComplete);
