import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  TextField,
  makeStyles,
  Select,
  Hidden,
  Button,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import TopSearchBar from '../../../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import AlertDialog from './../../../components/Dialog';
import { useDispatch } from 'react-redux';
import {
  deleteEmployees,
  fetchEmployees,
  fetchAllEmployees,
} from '../../../redux/actions';
import styles from '../../../styles/index';
import MyContext from '../../../Provider/MyContext';
import { AvenirBlackH7, AvenirBlackH4, H6 } from '../../../utils/text';
import CirclePluse from '../../../components/SVG/PluseIcon';
import ContextMenu from '../../../components/ContextMenu';
import CustomerDataTable from '../../../components/CustomerDataTable';
import CloseIcon from '../../../components/SVG/CloseCircle';
import DownloadIcon from '../../../components/SVG/DownlodeIcon';
import DeleteIcon from '../../../components/SVG/Delete';
import SingleSelect from '../../../components/SingleSelect';
import SnackBar from '../../../components/SnackBar';
import { downloadEmployees } from '../../../utils/excel';
import ProvinceData from '../customers/province';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));
const Employee = () => {
  const style = styles();
  const EmployeesList = useSelector(
    (state) => state.profileReducers.employeesList
  );
  const Roles = useSelector((state) => state.profileReducers.roles);
  const GetRoles = useSelector((state) => state.profileReducers.getRoles);
  const [isError, setIsError] = useState(false);
  const [heading, setHeading] = useState('');
  const [bodyMessage, setBodyMessage] = useState('');
  const [ids, setIds] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useDispatch();
  const limit = useSelector((state) => state.profileReducers.limit);
  const [pagenumber, setPagenumber] = useState(0);
  const [serchText, setSerchText] = useState('');
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [role, setRole] = useState('All');
  const [rolesDataa, setRolesDataa] = useState([]);
  const [statusValue, setStatusValue] = useState('All');
  const [table, setTable] = useState([]);
  const Context = useContext(MyContext);
  const [snackbar, setSnackbar] = useState(null);
  const [showExtra, setShowExtra] = useState(false);
  const [NoSearchBody, setNoSearchBody] = useState('');

  const [columns, setColumns] = useState([
    {
      id: 'name',
      field: 'full_name',
      headerName: 'Name',
      sort: true,
      size: 3,
      desc: true,
    },
    {
      id: 'email',
      field: 'email',
      headerName: 'Email',
      sort: true,
      size: 3,
      desc: true,
    },
    {
      id: 'username',
      field: 'username',
      headerName: 'Username',
      sort: true,
      size: 2,
      desc: true,
    },
    {
      id: 'role',
      field: 'role_link',
      headerName: 'Role',
      sort: true,
      link: true,
      size: 2,
      desc: true,
    },
    {
      id: 'status',
      field: 'status',
      headerName: 'Status',
      sort: true,
      size: 2,
      desc: true,
    },
  ]);
  useEffect(() => {
    if (EmployeesList && EmployeesList.data && EmployeesList.data.length > 0) {
      EmployeesList.data.map((item, index) => {
        item['role_link'] = (
          <span
            onClick={() => {
              handleEditRole(item);
            }}
            style={{ textDecoration: 'underline' }}>
            {item.name}
          </span>
        );
      });
      setTable(EmployeesList.data);
    } else {
      setTable([]);
      if (serchText) {
        setNoSearchBody(Context.langData.that_have_that_name);
      } else if (role && statusValue == 'All') {
        setNoSearchBody(Context.langData.with_that_role);
      } else if (statusValue) {
        setNoSearchBody(Context.langData.with_that_status);
      }
    }
  }, [EmployeesList]);

  const handleEditRole = (item) => {
    navigate('/home/profile/employees/editRole', {
      state: { id: item.role_id, tab: 2, from: 'employee' },
    });
  };

  useEffect(() => {
    const arr = [];
    if (GetRoles?.roles?.length > 0) {
      GetRoles.roles.map((item) => {
        const filterData = GetRoles.roles.filter((e) => e.name == item.name);
        arr.push(filterData);
      });
    }
    setRolesDataa(arr);
  }, []);

  const ContextMenuData = [
    {
      id: 1,
      icon: DownloadIcon,
      name: Context.langData.download,
    },
    {
      id: 2,
      icon: DeleteIcon,
      name: Context.langData.delete,
    },
  ];
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    var offsetValue = 0;
    var filterBy = [];
    if (pagenumber > 0) {
      offsetValue = pagenumber - 1;
    }
    var queryString = { limit: limit, offset: offsetValue };

    if (serchText) {
      queryString['search_text'] = serchText;
    }
    if (orderBy) {
      queryString['sortBy'] = orderBy;
      queryString['orderBy'] = order;
    }
    if (role !== '' && role !== 'All') {
      var roles = [...Roles.roles];
      var selectedRole = roles.filter((e) => e.name == role);
      if (selectedRole.length > 0) {
        filterBy.push('role,' + selectedRole[0].role_id);
      }
    } else {
    }
    if (statusValue !== '' && statusValue !== 'All') {
      var s = statusValue == 'Active' ? 1 : 0;
      filterBy.push('status,' + s);
    } else {
    }
    if (filterBy && filterBy.length > 0) {
      queryString['filterBy'] = filterBy;
    }
    dispatch(fetchEmployees(queryString));
  }, [pagenumber, serchText, orderBy, order, role, statusValue]);
  // useEffect(() => {
  //   var offsetValue = 0;
  //   if (pagenumber > 0) {
  //     offsetValue = pagenumber - 1;
  //   }
  //   var queryString = { limit: limit, offset: offsetValue };

  //   if (serchText) {
  //     queryString["search_text"] = serchText
  //   }
  //   if (orderBy) {
  //     queryString["sortBy"] = orderBy
  //     queryString["orderBy"] = order;
  //   }
  //   dispatch(fetchEmployees(queryString));
  // }, [])
  function onDeleteRoles(ids) {
    var errorDeleteObj = [];
    var errorFlag = false;
    ids.map((rowId) => {
      var rowObj = EmployeesList.data.filter((element) => element.id === rowId);
      if (rowObj[0].is_super_admin == 1) {
        var obj = rowObj[0];
        obj['super_admin'] = true;
        errorDeleteObj.push(obj);
      }
    });
    if (errorDeleteObj.length > 0) {
      errorFlag = true;
    } else {
    }
    if (ids.length > 0) {
      setIds(ids);
      setIsError(errorDeleteObj);
      setIsError(errorFlag);
      setShowDialog(true);
      // setHeading("Delete employee?")
      if (errorFlag) {
        var string = ``;
        errorDeleteObj.map((item) => {
          string = `` + string + ` The Super Admin role cannot be deleted.\n`;
          setHeading('Error');
          setShowExtra(false);
        });
        setBodyMessage(string);
      } else {
        if (ids.length > 1) {
          setBodyMessage(
            'Are you sure you want to delete these ' +
              ids.length +
              ' employees?'
          );
          setHeading('Delete employees?');
          setShowExtra(true);
        } else {
          setBodyMessage('Are you sure you want to delete this employee?');
          setHeading('Delete employee?');
          setShowExtra(true);
        }
      }
      setShowDialog(true);
    }
  }

  function onColumnClick(rowValue, event) {
    if (event.target.onclick) {
    } else {
      navigate('/home/profile/employees/editEmployee', {
        state: { id: JSON.stringify(rowValue) },
      });
    }
  }

  function callback(isSuccess) {
    dispatch(fetchEmployees());
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.employee_deleted_successfully,
        severity: 'success',
      });
    } else {
      setSnackbar({
        message: Context.langData.employee_delete_failed,
        severity: 'error',
      });
    }
  }
  function parseEmployess(obj) {
    if (typeof obj !== 'undefined') {
      if (obj && obj.length > 0) {
        obj.map((d) => {
          d['id'] = d.employee_id;
          d['username'] = d.username || '-';
          d['email'] = d.email || '-';
          d['status'] = d.status == 1 ? 'Active' : 'Inactive';
          var provinceName = ProvinceData.filter((e) => e.value == d.province);
          console.log('provinceName==>', provinceName);
          if (provinceName.length > 0) {
            d['province'] = provinceName[0].name;
          }
        });
      }
      return obj;
    } else {
      return [];
    }
  }
  const callBack = (id) => {
    if (id == 1) {
      var offsetValue = 0;
      var filterBy = [];
      if (pagenumber > 0) {
        offsetValue = pagenumber - 1;
      }
      var queryString = { limit: EmployeesList.total, offset: offsetValue };

      if (serchText) {
        queryString['search_text'] = serchText;
      }
      if (orderBy) {
        queryString['sortBy'] = orderBy;
        queryString['orderBy'] = order;
      }
      if (role !== '' && role !== 'All') {
        var roles = [...Roles.roles];
        var selectedRole = roles.filter((e) => e.name == role);
        if (selectedRole.length > 0) {
          filterBy.push('role,' + selectedRole[0].role_id);
        }
      } else {
      }
      if (statusValue !== '' && statusValue !== 'All') {
        var s = statusValue == 'Active' ? 1 : 0;
        filterBy.push('status,' + s);
      } else {
      }
      if (filterBy && filterBy.length > 0) {
        queryString['filterBy'] = filterBy;
      }
      dispatch(
        fetchAllEmployees(queryString, (response) => {
          let v = parseEmployess(response);
          downloadEmployees(v);
        })
      );
    } else if (id == 2) {
      onDeleteRoles(ids);
    }
    console.log('id', id);
  };

  const handleDeleteEmployee = () => {
    onDeleteRoles(ids);
  };

  const handleClose = (val) => {
    if (val) {
      dispatch(deleteEmployees({ delete_employee_id: ids }, callback));
    }
    setShowDialog(false);
  };
  const totalNumber = (total) => {
    var totalPages = Math.floor(total / 25) + (total % 25 !== 0 ? 1 : 0);
    return totalPages;
  };
  const onChangeText = (value) => {
    // store.dispatch({ type: 'LOADED' })
    setSerchText(value);
  };
  const onPageinationChange = (value) => {
    setPagenumber(value);
  };
  const onCheckedIds = (checkedIds) => {
    setIds(checkedIds);
  };
  const handleSort = (name, desc, index) => {
    var data = [...columns];
    data[index] = { ...data[index], desc: desc };
    setColumns(data);
    setOrder(desc ? 'asc' : 'desc');
    if (name == 'full_name') {
      name = 'name';
    }
    if (name == 'role_link') {
      name = 'role';
    }
    setOrderBy(name);
  };
  return (
    <Grid
      container
      data-qaid="employee_mainContainer"
      style={{
        padding: 20,
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
      }}>
      {(EmployeesList.data && EmployeesList.data.length > 0) ||
      serchText ||
      role ? (
        <>
          <Grid
            container
            data-qaid="employee_subContainer1"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Grid
              item
              data-qaid="employee_topSearchView"
              lg={5}
              style={{ marginTop: 25 }}>
              <TopSearchBar
                value={serchText}
                onChange={(val) => onChangeText(val.target.value)}
                name="serchText"
                id="serchText"
                placeholder={Context.langData.search_employee}
                style={{ width: '100%', height: 56 }}
              />
            </Grid>
            <Grid
              item
              data-qaid="employee_roleSingleSelectView"
              lg={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 10,
              }}>
              <AvenirBlackH4
                data-qaid="employee_roleSingleSelectLabel"
                label={Context.langData.new_role}
                style={{ marginBottom: 5 }}
              />
              <SingleSelect
                list={[{ full_name: 'All', role_id: 0 }].concat(Roles.roles)}
                value={role}
                valueKey="full_name"
                displayKey="full_name"
                border={false}
                handleChange={(val) => {
                  setRole(val.target.value);
                }}
                FormWidth={'100%'}
                height={56}
              />
            </Grid>
            <Grid
              item
              data-qaid="employee_statusValueView"
              lg={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 10,
              }}>
              <AvenirBlackH4
                data-qaid="employee_statusLabel"
                label={Context.langData.status}
                style={{ marginBottom: 5 }}
              />
              <SingleSelect
                list={[
                  { name: 'All' },
                  { name: 'Active' },
                  { name: 'Inactive' },
                ]}
                value={statusValue}
                valueKey="name"
                displayKey="name"
                border={false}
                handleChange={(val) => {
                  setStatusValue(val.target.value);
                }}
                FormWidth={'100%'}
                height={56}
              />
            </Grid>
            <Grid
              item
              data-qaid="employee_deleteView"
              lg={3}
              xs={12}
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: 30,
                paddingRight: 10,
              }}>
              {/* <Button
                data-qaid="employee_deleteButton"
                variant="text"
                id="cancel"
                onClick={() => handleDeleteEmployee()}
              >
                <DeleteIcon
                  data-qaid="employee_deleteIcon"
                  width={20}
                  height={20}
                />
                <AvenirBlackH4
                  data-qaid="employee_deleteLabel"
                  label={Context.langData.delete}
                  style={{ paddingLeft: 5 }}
                />
              </Button> */}
              <Button
                className={style.buttonClass}
                data-qaid="employee_createEmployeeView"
                style={{
                  backgroundColor: '#FFB600',
                  color: 'white',
                  height: 40,
                  width: 190,
                  marginLeft: 10,
                }}
                variant="contained"
                id="cancel"
                onClick={() =>
                  navigate('/home/profile/employees/createEmployee')
                }>
                <CirclePluse data-qaid="employee_createPlus" />
                <AvenirBlackH4
                  data-qaid="employee_createLabel"
                  label={Context.langData.create_employee}
                  style={{ paddingLeft: 5 }}
                />
              </Button>
              <ContextMenu
                menuItems={ContextMenuData}
                callBack={callBack}
                style={{ paddingLeft: 10 }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            data-qaid="employee_dateTableMainView"
            style={{ marginTop: 20 }}>
            {EmployeesList &&
            EmployeesList.data &&
            EmployeesList.data.length > 0 ? (
              <Grid item data-qaid="employee_dateTableView" lg={12}>
                <CustomerDataTable
                  columns={columns}
                  rows={table}
                  onClickAction={onColumnClick}
                  onPageChange={onPageinationChange}
                  style={{ width: '100%' }}
                  handleSort={handleSort}
                  onCheckedIds={onCheckedIds}
                  total={totalNumber(EmployeesList.total)}
                />
              </Grid>
            ) : (
              <Grid
                data-qaid="employee_no_records_employee_View"
                item
                lg={12}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  marginTop: '10%',
                  flexDirection: 'column',
                }}>
                <H6
                  data-qaid="employee_no_records_employee_label"
                  label={
                    Context.langData.no_records_employee +
                    NoSearchBody +
                    Context.langData.please_try_again
                  }
                  style={{
                    textAlign: 'center',
                    width: '55%',
                    fontWeight: 'bold',
                  }}
                />
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Grid
          data-qaid="employee_no_employee_records_View"
          item
          lg={12}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: '10%',
            flexDirection: 'column',
          }}>
          <H6
            data-qaid="employee_no_employee_records_label"
            label={Context.langData.no_employee_records}
            style={{ textAlign: 'center', width: '70%', fontWeight: 'bold' }}
          />
          <Grid
            item
            data-qaid="employee_createEmployeeView"
            style={{ marginTop: 30 }}>
            <Button
              data-qaid="employee_createEmployeeButton"
              className={style.buttonClass}
              style={{ backgroundColor: '#FFB600', color: 'white', height: 40 }}
              variant="contained"
              id="cancel"
              onClick={() =>
                navigate('/home/profile/employees/createEmployee')
              }>
              <CirclePluse
                data-qaid="employee_createPlus"
                style={{ marginRight: 5 }}
              />
              <AvenirBlackH4
                data-qaid="employee_createLabel"
                label={Context.langData.create_employee}
              />
            </Button>
          </Grid>
        </Grid>
      )}
      {snackbar && (
        <SnackBar
          data-qaid="shifts_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <AlertDialog
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={isError}
        handleClose={handleClose}
        showExtra={showExtra}
      />
    </Grid>
  );
};

export default Employee;
