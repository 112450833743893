import * as React from "react"

function NoImage({ height = 197, width = 171, ...props }) {
    return (
        <svg
            width={width}
            height={height}

            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x={0.5} y={0.5} width={width} height={height} rx={4.5} fill="#FAFAFA" />
            <g clipPath="url(#prefix__clip0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M59.167 71c.644 0 1.166.522 1.166 1.167v11.666a5.833 5.833 0 004.67 5.716V72.167a1.167 1.167 0 012.333 0v17.381A5.834 5.834 0 0072 83.833V72.167a1.167 1.167 0 112.333 0v11.666a8.167 8.167 0 01-6.997 8.083v33.917a1.167 1.167 0 11-2.334 0V91.917A8.167 8.167 0 0158 83.833V72.167c0-.645.522-1.167 1.167-1.167zm45.5 1.167c0-.645.522-1.167 1.166-1.167.895 0 1.592.546 2.081 1.108.509.584.968 1.381 1.386 2.305.841 1.857 1.623 4.47 2.299 7.599 1.355 6.272 2.322 14.81 2.401 23.978v.01a2.336 2.336 0 01-2.333 2.333H107v17.5a1.166 1.166 0 11-2.333 0V72.167zM107 106h4.667c-.079-9.029-1.033-17.403-2.349-23.495-.66-3.055-1.401-5.488-2.144-7.13-.059-.13-.117-.253-.174-.37V106zM86.002 94.333c-7.732 0-14 6.268-14 14s6.268 14 14 14 14-6.268 14-14-6.268-14-14-14zm-16.333 14c0-9.02 7.313-16.333 16.333-16.333 9.021 0 16.334 7.313 16.334 16.333 0 9.021-7.313 16.334-16.334 16.334-9.02 0-16.333-7.313-16.333-16.334z"
                    fill="#C3C3C3"
                />
            </g>
            <rect x={0.5} y={0.5} width={width} height={height} rx={4.5} stroke="#EEE" />
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill="#fff" transform="translate(58 71)" d="M0 0h56v56H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoImage
