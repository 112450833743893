import React, { useState, useCallback, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid,
} from '@material-ui/core';
import VerticalTabs from '../../../components/verticalTabs';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import MyContext from '../../../Provider/MyContext';
import ContactTracing from './ContactTracing';

const More = () => {
    const Context = useContext(MyContext);
    const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
    const [selectedIndexHeading, setSelectedIndexHeading] = useState(0);
    const Tabs = [
        { name: Context.langData.contact_tracing, id: 0 },
    ]
    const callback = (value) => {
        switch (value) {
            case 0:
                setSelectIndexTabChange(0);
                setSelectedIndexHeading(0);
                break;
            default:
                break;
        }
    }
    return (
        <Grid data-qaid="more_mainContainer" container style={{ padding: 20 }}>
            <Grid data-qaid="more_subContainer1" item xs={12} lg={2} style={{ backgroundColor: '#FAFAFA' }}>
                <VerticalTabs data-qaid="more_verticalTabs" tabData={Tabs} indexCallback={callback} />
            </Grid>
            <Grid data-qaid="more_subContainer2" item xs={12} lg={10} style={{ paddingLeft: '10px' }}>
                {
                    selectIndexTabChange == 0 &&
                    <ContactTracing />
                }
            </Grid>
        </Grid>

    )
}

export default More;