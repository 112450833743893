import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Container,
    makeStyles
} from '@material-ui/core';
import { fetchConfigPayments, fetchPayments } from '../../../redux/actions';
import PaymentList from './PaymentList';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const PaymentMethods = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPayments());
        dispatch(fetchConfigPayments());
    }, [])
    return (
        <div>
            <PaymentList data-qaid="payment_index_paymentlist"></PaymentList>
        </div>
    );
};

export default PaymentMethods;
