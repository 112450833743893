import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import logger from "redux-logger";

const persistConfig = {
  key: "root",
  storage: storageSession,
  // blacklist: ['floorplan']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  applyMiddleware(thunkMiddleware, logger)
);
const StoreConfig = {
  persistor: persistStore(store),
  store,
};
export default StoreConfig;
