import React, { useContext } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from '@react-pdf/renderer';
import { ConvertPrice } from '../utils/util';
import MyContext from '../Provider/MyContext';
import moment from 'moment';

const styles = StyleSheet.create({
  titleArea: { marginLeft: 20, marginBottom: 10, marginTop: 10 },
  title: {
    color: 'rgb(5, 29, 51)',
    fontSize: '18px',
    fontWeight: 1000,
  },
  subTitle: {
    color: 'rgb(5, 29, 51)',
    fontSize: '14px',
    fontWeight: 1000,
  },
  date: {
    fontSize: '12px',
    paddingTop: '5px',
    fontWeight: 500,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    borderRadius: '5px',
  },
  header: {
    flexDirection: 'row',
    marginBottom: 10,
    textAlign: 'left',
    borderBottomWidth: 1,
    borderBottomColor: '#F3FAFD',
    borderBottomStyle: 'solid',
    backgroundColor: '#F3FAFD',
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: 5,
  },
  eodHeader: {
    flexDirection: 'row',
    marginBottom: 10,
    textAlign: 'right',
    paddingTop: 5,
    paddingRight: 10,
    paddingLeft: 1,
    borderRadius: 5,
  },
  row: {
    flexDirection: 'row',
    textAlign: 'left',
    backgroundColor: '#FAFAFA',
    paddingRight: 10,
    paddingLeft: 10,
  },
  headerCell: {
    flex: 1,
    fontSize: 12,
    padding: 1,
    overflowWrap: 'break-word',
    fontWeight: 'bold',
    paddingLeft: '3px',
    paddingRight: '3px',
  },
  cell: {
    flex: 1,
    padding: 1,
    overflowWrap: 'break-word',
    lineHeight: 1.5,
    fontSize: 10,
    paddingLeft: '3px',
    paddingRight: '3px',
  },
  alignRightCell: {
    flex: 1,
    fontSize: 10,
    padding: 1,
    textAlign: 'right',
  },
  cellTotal: {
    flex: 1,
    padding: 1,
    overflowWrap: 'break-word',
    lineHeight: 1.5,
    fontSize: 12,
    paddingLeft: '3px',
    paddingRight: '3px',
    fontWeight: 'bold',
  },
  alignRightCellTotal: {
    flex: 1,
    fontSize: 12,
    padding: 1,
    textAlign: 'right',
    fontWeight: 'bold',
  },
  cellTotal: {
    flex: 1,
    padding: 1,
    overflowWrap: 'break-word',
    lineHeight: 1.5,
    fontSize: 12,
    paddingLeft: '3px',
    paddingRight: '3px',
    fontWeight: 'bold',
  },
  alignRightCellTotal: {
    flex: 1,
    fontSize: 12,
    padding: 1,
    textAlign: 'right',
    fontWeight: 'bold',
  },
});

const MyDocument = ({
  data,
  title,
  date,
  headers = [],
  reportCase = null,
  salesSummary = [],
  subTitle = null,
  pettyCashSummary = [],
  purchasesSummary = [],
  kitchenSummary,
}) => {
  const getColumnDisplayName = (isNumber, value, isDOM) => {
    if (value === null || value === undefined) {
      return '--';
    }
    var value = isNumber
      ? '$' + ConvertPrice(String(value))
      : isDOM
      ? value
      : String(value);
    return value;
  };

  const getInventoryColumnDisplayName = (isNumber, value) => {
    var value = isNumber ? '$' + ConvertPrice(String(value)) : String(value);
    return value;
  };
  const convertToCanadianDate = (unixTimeStamp) => {
    const date = new Date(unixTimeStamp * 1000);
    return date.toLocaleDateString('en-CA');
  };

  const Context = useContext(MyContext);

  switch (reportCase) {
    case 'Sales Summary':
      return (
        <Document>
          <Page size="A4" style={styles.page} orientation="portrait">
            <View style={styles.titleArea}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.date}>{`${convertToCanadianDate(
                date.start_date
              )} to ${convertToCanadianDate(date.end_date)}`}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '50%',
                borderRadius: '5px',
                backgroundColor: '#F3FAFD',
                marginLeft: 20,
                padding: 5,
              }}>
              {salesSummary.data?.map((item, index) => {
                return (
                  <View
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}>
                    <View
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                      <Text style={styles.date}>{item.type}</Text>
                      <Text style={styles.date}>
                        ${ConvertPrice(item.sales)}
                      </Text>
                    </View>
                  </View>
                );
              })}
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontSize: '12px',
                    paddingTop: '5px',
                    fontWeight: 'bold',
                  }}>
                  Grand Total
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    paddingTop: '5px',
                    fontWeight: 'bold',
                  }}>
                  ${ConvertPrice(salesSummary.Total)}
                </Text>
              </View>
            </View>
            {data?.map((table, index) => {
              return (
                <View style={styles.section} key={index}>
                  <View style={styles.header}>
                    {table.headers?.map((header, i) => (
                      <Text key={i} style={styles.headerCell}>
                        {header.display}
                      </Text>
                    ))}
                  </View>

                  {table.data?.map((item, rowIndex) => (
                    <View key={rowIndex} style={styles.row}>
                      {table.headers?.map((header, cellIndex) => (
                        <Text key={cellIndex} style={styles.cell}>
                          {getColumnDisplayName(
                            header['isNumber'] || false,
                            item[header['field']],
                            header['isDOM'] || false
                          )}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>
              );
            })}
          </Page>
        </Document>
      );

    case 'Petty Cash':
      return (
        <Document>
          <Page size="A4" style={styles.page} orientation="portrait">
            <View style={styles.titleArea}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.date}>{`${convertToCanadianDate(
                date.start_date
              )} to ${convertToCanadianDate(date.end_date)}`}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '50%',
                borderRadius: '5px',
                backgroundColor: '#F3FAFD',
                marginLeft: 20,
                padding: 5,
              }}>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text style={styles.date}>Opening balance</Text>
                  <Text style={styles.date}>
                    ${ConvertPrice(pettyCashSummary.opening_balance)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text style={styles.date}>Credit balance</Text>
                  <Text style={styles.date}>
                    ${ConvertPrice(pettyCashSummary.credit_amount)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text style={styles.date}>Debit balance</Text>
                  <Text style={styles.date}>
                    ${ConvertPrice(pettyCashSummary.debit_amount)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text style={styles.date}>Closing balance</Text>
                  <Text style={styles.date}>
                    ${ConvertPrice(pettyCashSummary.closing_balance)}
                  </Text>
                </View>
              </View>
            </View>
            {data?.map((table, index) => {
              return (
                <View style={styles.section} key={index}>
                  <View style={styles.header}>
                    {table.headers?.map((header, index) => (
                      <Text key={index} style={styles.headerCell}>
                        {header.display}
                      </Text>
                    ))}
                  </View>

                  {table.data?.map((item, rowIndex) => (
                    <View key={rowIndex} style={styles.row}>
                      {table.headers?.map((header, cellIndex) => (
                        <Text key={cellIndex} style={styles.cell}>
                          {getColumnDisplayName(
                            header['isNumber'] || false,
                            item[header['field']],
                            header['isDOM'] || false
                          )}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>
              );
            })}
            {data?.map((table, index) => {
              return (
                <View style={styles.section} key={index}>
                  <View style={styles.header}>
                    {table.headers?.map((header, i) => (
                      <Text key={i} style={styles.headerCell}>
                        {header.display}
                      </Text>
                    ))}
                  </View>

                  {table.data?.map((item, rowIndex) => (
                    <View key={rowIndex} style={styles.row}>
                      {table.headers?.map((header, cellIndex) => (
                        <Text key={cellIndex} style={styles.cell}>
                          {getColumnDisplayName(
                            header['isNumber'] || false,
                            item[header['field']],
                            header['isDOM'] || false
                          )}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>
              );
            })}
          </Page>
        </Document>
      );

    case 'Purchases Inventory':
      return (
        <Document>
          <Page size="A4" style={styles.page} orientation="portrait">
            <View style={styles.titleArea}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.date}>{`${convertToCanadianDate(
                date.start_date
              )} to ${convertToCanadianDate(date.end_date)}`}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '50%',
                borderRadius: '5px',
                backgroundColor: '#F3FAFD',
                marginLeft: 20,
                padding: 5,
              }}>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text style={styles.date}>Total purchases</Text>
                  <Text style={styles.date}>
                    ${ConvertPrice(purchasesSummary.total_amount)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text style={styles.date}>Paid</Text>
                  <Text style={styles.date}>
                    ${ConvertPrice(purchasesSummary.amount_paid)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text style={styles.date}>Total balance</Text>
                  <Text style={styles.date}>
                    ${ConvertPrice(purchasesSummary.balance)}
                  </Text>
                </View>
              </View>
            </View>

            {data?.map((table, index) => {
              return (
                <View style={styles.section} key={index}>
                  <View style={styles.header}>
                    {table.headers?.map((header, i) => (
                      <Text key={i} style={styles.headerCell}>
                        {header.display}
                      </Text>
                    ))}
                  </View>

                  {table.data?.map((item, rowIndex) => (
                    <View key={rowIndex} style={styles.row}>
                      {table.headers?.map((header, cellIndex) => (
                        <Text key={cellIndex} style={styles.cell}>
                          {getColumnDisplayName(
                            header['isNumber'] || false,
                            item[header['field']],
                            header['isDOM'] || false
                          )}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>
              );
            })}
          </Page>
        </Document>
      );
    case 'Item Sales':
      return (
        <Document>
          <Page size="A4" style={styles.page} orientation="portrait">
            <View style={styles.titleArea}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.date}>{`${convertToCanadianDate(
                date.start_date
              )} to ${convertToCanadianDate(date.end_date)}`}</Text>
            </View>
            {data?.map((table, index) => {
              return (
                <View style={styles.section} key={index}>
                  <Text
                    style={{
                      color: 'rgb(5, 29, 51)',
                      fontSize: '16px',
                      fontWeight: 1000,
                      paddingBottom: 5,
                      paddingLeft: 3,
                    }}>
                    {table.menu_cat}
                  </Text>
                  <View style={styles.header}>
                    {headers?.map((header, index) => (
                      <Text key={index} style={styles.headerCell}>
                        {header.display}
                      </Text>
                    ))}
                  </View>

                  {table.items?.map((item, rowIndex) => (
                    <View key={rowIndex} style={styles.row}>
                      {headers?.map((header, cellIndex) => (
                        <Text key={cellIndex} style={styles.cell}>
                          {getColumnDisplayName(
                            header['isNumber'] || false,
                            item[header['field']],
                            header['isDOM'] || false
                          )}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>
              );
            })}
          </Page>
        </Document>
      );
    case 'Modifier Sales':
      return (
        <Document>
          <Page size="A4" style={styles.page} orientation="portrait">
            <View style={styles.titleArea}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.date}>{`${convertToCanadianDate(
                date.start_date
              )} to ${convertToCanadianDate(date.end_date)}`}</Text>
            </View>
            {data?.map((table, index) => {
              return (
                <View style={styles.section} key={index}>
                  <Text
                    style={{
                      color: 'rgb(5, 29, 51)',
                      fontSize: '16px',
                      fontWeight: 1000,
                      paddingBottom: 5,
                      paddingLeft: 3,
                    }}>
                    {table.addon_cat}
                  </Text>
                  <View style={styles.header}>
                    {headers?.map((header, index) => (
                      <Text key={index} style={styles.headerCell}>
                        {header.display}
                      </Text>
                    ))}
                  </View>

                  {table.addons?.map((item, rowIndex) => (
                    <View key={rowIndex} style={styles.row}>
                      {headers?.map((header, cellIndex) => (
                        <Text key={cellIndex} style={styles.cell}>
                          {getColumnDisplayName(
                            header['isNumber'] || false,
                            item[header['field']],
                            header['isDOM'] || false
                          )}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>
              );
            })}
          </Page>
        </Document>
      );
    case 'Kitchen Inventory':
      return (
        <Document>
          <Page size="A4" style={styles.page} orientation="portrait">
            <View style={styles.titleArea}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.date}>{`${moment(date.from_date)
                .utc()
                .format('YYYY-MM-DD')} to ${moment(date.to_date)
                .utc()
                .format('YYYY-MM-DD')}`}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '50%',
                borderRadius: '5px',
                backgroundColor: '#F3FAFD',
                marginLeft: 20,
                padding: 5,
              }}>
              {salesSummary.data?.map((item, index) => {
                return (
                  <View
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}>
                    <View
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                      <Text style={styles.date}>{item.type}</Text>
                      <Text style={styles.date}>
                        ${ConvertPrice(item.sales)}
                      </Text>
                    </View>
                  </View>
                );
              })}
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}>
                <Text style={styles.date}>Total</Text>
                <Text style={styles.date}>${ConvertPrice(kitchenSummary)}</Text>
              </View>
            </View>

            {data?.map((table, index) => {
              return (
                <View style={styles.section} key={index}>
                  <Text
                    style={{
                      color: 'rgb(5, 29, 51)',
                      fontSize: '16px',
                      fontWeight: 1000,
                      paddingBottom: 5,
                      paddingLeft: 3,
                    }}>
                    {table.kitchenName}
                  </Text>
                  <View style={styles.header}>
                    {headers?.map((header, index) => (
                      <Text key={index} style={styles.headerCell}>
                        {header.display}
                      </Text>
                    ))}
                  </View>

                  {table.data?.map((item, rowIndex) => (
                    <View key={rowIndex} style={styles.row}>
                      {headers?.map((header, cellIndex) => (
                        <Text key={cellIndex} style={styles.cell}>
                          {getInventoryColumnDisplayName(
                            header['isNumber'] || false,
                            item[header['field']]
                          )}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>
              );
            })}
          </Page>
        </Document>
      );
    case 'End of Day':
      return (
        <Document>
          <Page size="A4" style={styles.page} orientation="portrait">
            <View style={styles.titleArea}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.subTitle}>{subTitle}</Text>
            </View>
            {data.map((item, index) => {
              var hasQuantity = false;
              // has to have something in the header, so it will show the header anyway. Otherwise, there is big gap between some of the sections. Don't know why
              var headers = [''];
              if (
                item?.sectionData.some((obj) => obj.hasOwnProperty('quantity'))
              ) {
                headers = ['', 'Quantity', 'Amount'];
                hasQuantity = true;
              }

              return (
                <View style={styles.section} key={index}>
                  <Text
                    style={{
                      color: 'rgb(5, 29, 51)',
                      fontSize: '14px',
                      fontWeight: 1000,
                      paddingBottom: 5,
                      paddingLeft: 1,
                    }}>
                    {item.sectionName}
                  </Text>
                  <hr
                    style={{
                      width: '100%',
                      border: 'none',
                      borderTop: '1px solid #bababa',
                      marginBottom: '5px',
                    }}
                  />
                  {headers.length > 0 && (
                    <View style={styles.eodHeader}>
                      {headers.map((header, index) => (
                        <Text key={index} style={styles.headerCell}>
                          {header}
                        </Text>
                      ))}
                    </View>
                  )}
                  {item.sectionData?.map((subItem, i) => {
                    return (
                      <View
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}>
                        {subItem.name.includes('Total') && (
                          <hr
                            style={{
                              width: '100%',
                              border: 'none',
                              borderTop: '1px solid #EEEEEE',
                              marginTop: '10px',
                            }}
                          />
                        )}
                        {subItem.name.includes('Total') ? (
                          <View
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                            }}>
                            <Text style={styles.cellTotal}>{subItem.name}</Text>
                            <Text style={styles.alignRightCellTotal}>
                              {hasQuantity && subItem.quantity}
                            </Text>
                            <Text style={styles.alignRightCellTotal}>
                              ${(subItem.value.amount / 100.0).toFixed(2)}
                            </Text>
                          </View>
                        ) : (
                          <View
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                            }}>
                            <Text style={styles.cell}>{subItem.name}</Text>
                            <Text style={styles.alignRightCell}>
                              {hasQuantity && subItem.quantity}
                            </Text>
                            <Text style={styles.alignRightCell}>
                              ${(subItem.value.amount / 100.0).toFixed(2)}
                            </Text>
                          </View>
                        )}
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </Page>
        </Document>
      );
    case 'Consolidated Inventory':
      return (
        <Document>
          <Page
            size="A4"
            style={styles.page}
            orientation={headers?.length > 6 ? 'landscape' : 'portrait'}>
            <View
              style={{
                marginLeft: 20,
                marginBottom: 10,
                marginTop: 10,
              }}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.date}>
                {moment({ month: date.month - 1, year: date.year }).format(
                  'MMMM YYYY'
                )}
              </Text>
            </View>
            <View style={styles.section}>
              <View style={styles.header}>
                {headers?.map((header, index) => (
                  <Text key={index} style={styles.headerCell}>
                    {header.display}
                  </Text>
                ))}
              </View>

              {data?.map((item, rowIndex) => (
                <View key={rowIndex} style={styles.row}>
                  {headers?.map((header, cellIndex) => (
                    <Text key={cellIndex} style={styles.cell}>
                      {getColumnDisplayName(
                        header['isNumber'] || false,
                        item[header['field']],
                        header['isDOM'] || false
                      )}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
          </Page>
        </Document>
      );
    case 'Vendor Inventory':
      return (
        <Document>
          <Page size="A4" style={styles.page} orientation="portrait">
            <View style={styles.titleArea}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.date}>{`${moment(date.from_date)
                .utc()
                .format('YYYY-MM-DD')} to ${moment(date.to_date)
                .utc()
                .format('YYYY-MM-DD')}`}</Text>
            </View>

            {data?.map((table, index) => {
              return (
                <>
                  <Text
                    style={{
                      color: 'rgb(5, 29, 51)',
                      fontSize: '16px',
                      fontWeight: 1000,
                      paddingBottom: 5,
                      marginLeft: 20,
                    }}>
                    {table.vendorName}
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '50%',
                      borderRadius: '5px',
                      backgroundColor: '#F3FAFD',
                      marginLeft: 20,
                      padding: 5,
                    }}>
                    <View
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}>
                      <View
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}>
                        <Text style={styles.date}>Balance amount</Text>
                        <Text style={styles.date}>
                          ${ConvertPrice(table.balance_amount)}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                      <Text style={styles.date}>Paid amount</Text>
                      <Text style={styles.date}>
                        ${ConvertPrice(table.amount_paid)}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                      <Text style={styles.date}>Total purchases</Text>
                      <Text style={styles.date}>
                        ${ConvertPrice(table.total_amount)}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                      <Text
                        style={{
                          fontSize: '12px',
                          paddingTop: '5px',
                          fontWeight: 'bold',
                        }}>
                        Total (without tax)
                      </Text>
                      <Text
                        style={{
                          fontSize: '12px',
                          paddingTop: '5px',
                          fontWeight: 'bold',
                        }}>
                        ${ConvertPrice(table.net_amount)}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.section} key={index}>
                    <View style={styles.header}>
                      {headers?.map((header, index) => (
                        <Text key={index} style={styles.headerCell}>
                          {header.display}
                        </Text>
                      ))}
                    </View>

                    {table.stocks?.map((item, rowIndex) => (
                      <View key={rowIndex} style={styles.row}>
                        {headers?.map((header, cellIndex) => (
                          <Text key={cellIndex} style={styles.cell}>
                            {getColumnDisplayName(
                              header['isNumber'] || false,
                              item[header['field']],
                              header['isDOM'] || false
                            )}
                          </Text>
                        ))}
                      </View>
                    ))}
                  </View>
                </>
              );
            })}
          </Page>
        </Document>
      );

    default:
      return (
        <Document>
          <Page
            size="A4"
            style={styles.page}
            orientation={headers?.length > 6 ? 'landscape' : 'portrait'}>
            <View
              style={{
                marginLeft: 20,
                marginBottom: 10,
                marginTop: 10,
              }}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.date}>{`${convertToCanadianDate(
                date.start_date
              )} to ${convertToCanadianDate(date.end_date)}`}</Text>
            </View>
            <View style={styles.section}>
              <View style={styles.header}>
                {headers?.map((header, index) => (
                  <Text key={index} style={styles.headerCell}>
                    {header.display}
                  </Text>
                ))}
              </View>

              {data?.map((item, rowIndex) => (
                <View key={rowIndex} style={styles.row}>
                  {headers?.map((header, cellIndex) => (
                    <Text key={cellIndex} style={styles.cell}>
                      {getColumnDisplayName(
                        header['isNumber'] || false,
                        item[header['field']],
                        header['isDOM'] || false
                      )}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
          </Page>
        </Document>
      );
  }
};

const downloadPdfDocument = async (headers, data, title, date, fileName) => {
  const blob = await pdf(
    <MyDocument headers={headers} data={data} title={title} date={date} />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const downloadSalesSummaryPdfDocument = async (
  data,
  title,
  date,
  fileName,
  salesSummary
) => {
  const blob = await pdf(
    <MyDocument
      data={data}
      title={title}
      date={date}
      reportCase="Sales Summary"
      salesSummary={salesSummary}
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const downloadPettyCashPdfDocument = async (
  data,
  title,
  date,
  fileName,
  pettyCashSummary
) => {
  const blob = await pdf(
    <MyDocument
      data={data}
      title={title}
      date={date}
      reportCase="Petty Cash"
      pettyCashSummary={pettyCashSummary}
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
const downloadPurchasesPdfDocument = async (
  data,
  title,
  date,
  fileName,
  purchasesSummary
) => {
  const blob = await pdf(
    <MyDocument
      data={data}
      title={title}
      date={date}
      reportCase="Purchases Inventory"
      purchasesSummary={purchasesSummary}
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
const downloadItemSalesPdfDocument = async (
  headers,
  data,
  title,
  date,
  fileName
) => {
  const blob = await pdf(
    <MyDocument
      headers={headers}
      data={data}
      title={title}
      date={date}
      reportCase="Item Sales"
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const downloadEodPdfDocument = async (title, subTitle, data, fileName) => {
  const blob = await pdf(
    <MyDocument
      data={data}
      title={title}
      subTitle={subTitle}
      reportCase="End of Day"
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const downloadModifierSalesPdfDocument = async (
  headers,
  data,
  title,
  date,
  fileName
) => {
  const blob = await pdf(
    <MyDocument
      headers={headers}
      data={data}
      title={title}
      date={date}
      reportCase="Modifier Sales"
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
const downloadKitchenInventoryPdfDocument = async (
  headers,
  data,
  title,
  date,
  kitchenSummary,
  fileName
) => {
  const blob = await pdf(
    <MyDocument
      headers={headers}
      data={data}
      title={title}
      date={date}
      reportCase="Kitchen Inventory"
      kitchenSummary={kitchenSummary}
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const downloadConsolidatedPdfDocument = async (
  headers,
  data,
  title,
  date,
  fileName
) => {
  const blob = await pdf(
    <MyDocument
      headers={headers}
      data={data}
      title={title}
      date={date}
      reportCase="Consolidated Inventory"
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const downloadVendorPdfDocument = async (
  headers,
  data,
  title,
  date,
  fileName
) => {
  const blob = await pdf(
    <MyDocument
      headers={headers}
      data={data}
      title={title}
      date={date}
      reportCase="Vendor Inventory"
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export {
  downloadSalesSummaryPdfDocument,
  downloadPettyCashPdfDocument,
  downloadPdfDocument,
  downloadItemSalesPdfDocument,
  downloadModifierSalesPdfDocument,
  downloadKitchenInventoryPdfDocument,
  downloadEodPdfDocument,
  downloadConsolidatedPdfDocument,
  downloadVendorPdfDocument,
  downloadPurchasesPdfDocument,
};
