const initialState = {
  listEodReports: [],
  listEodShiftReports: [],
  specificEodReport: {},
  specificEodShiftReport: {},
};

const eodReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LIST_EOD_REPORTS':
      return {
        ...state,
        listEodReports: action.payload,
      };
    case 'GET_LIST_EOD_SHIFT_REPORTS':
      return {
        ...state,
        listEodShiftReports: action.payload,
      };
    case 'GET_SPECIFIC_EOD_REPORT':
      return {
        ...state,
        specificEodReport: action.payload,
      };
    case 'GET_SPECIFIC_EOD_SHIFT_REPORTS':
      return {
        ...state,
        specificEodShiftReport: action.payload,
      };
    default:
      return state;
  }
};

export default eodReportsReducer;
