import instance from './axiosInstance'
import urls from './urls';

const fetchSalesSummary = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.salesSummary, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_SALES_SUMMARY_SUCCESS', payload: response.data });
      }
    });
  };
}
const fetchpaymentMethods = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.paymentMethods, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_PAYMENT_METHODS_SUCCESS', payload: response.data });
      }
    });
  };
}

const fetchorderType = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.orderType, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_ORDERTYPE_SUCCESS', payload: response.data });
      }
    });
  };
}

const fetchshiftReport = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.shiftReport, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_SHIFT_REPORT_SUCCESS', payload: response.data });
      }
    });
  };
}

const fetchtaxType = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.taxType, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_TAX_TYPE_SUCCESS', payload: response.data });
      }
    });
  };
}

const fetchPettyCashReports = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.pettyCashReport, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_PETTYCASH_REPORTS', payload: response.data });
      }
    });
  };
}
const fetchdaywiseSales = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.daywiseSales, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_DAYWISE_SALES_SUCCESS', payload: response.data });
      }
    });
  };
}

const fetchitemSales = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.itemSales, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_ITEM_SALES_SUCCESS', payload: response.data });
      }
    });
  };
}

const fetchmenuSales = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.itemSales, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_MENU_SALES_SUCCESS', payload: response.data });
      }
    });
  };
}


const fetchaddonDetails = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.addonDetails, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_ADDON_DETAILS_SUCCESS', payload: response.data });
      }
    });
  };
}

const fetchwaiterDetails = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.waiters, { params }).then((response) => {
      console.log(response, 'waiter response');
      if (response.status === 200) {
        dispatch({ type: 'GET_WAITER_DETAILS_SUCCESS', payload: response.data });
      }
    });
  };
}



const fetchcashierDetails = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.cashier, { params }).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: 'GET_CASHIER_DETAILS_SUCCESS', payload: response.data });
      }
    });
  };
}

const fetchgroupDetails = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.groups, { params }).then((response) => {
      if (response.status === 200) {
        dispatch({ type: 'GET_GROUP_DETAILS_SUCCESS', payload: response.data });
      }
    }).catch(err=>{
      dispatch({
        type: "GET_GROUP_DETAILS_SUCCESS",
        payload: { category_grouping :[]},
      });
    })
  }
}

const DeleteGroupDetails = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.salesReports.groups, params).then((response) => {
      const { data } = response;
      callback(true, data)
      dispatch({ type: '' })

    }).catch(err => {
      const { data } = err.response;
      callback(false, data)
      dispatch({ type: '' })
    })
  }
}

const fetchupdateGroup = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.updateGroup, { params }).then((response) => {
      if (response.status === 200) {
        dispatch({ type: 'GET_UPDATE_GROUP_SUCCESS', payload: response.data });
      }
    })
  }
}

const fetchGroupMenuList = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.updateGroup, { params }).then((response) => {
      if (response.status === 200) {
        dispatch({ type: 'GET_GROUP_LIST', payload: response.data });
      }
    })
  }
}
const fetchEmployeeSalesReport = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.employeeReports, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_EMPLOYEE_SALES', payload: response.data });
      }
    });
  };
}
const fetchAlcoholReports = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.alcoholReports, {params}).then((response) => {
      if(response.status === 200) {
        dispatch({ type: 'GET_ALCOHOL_REPORTS', payload: response.data });
      }
    })
  }
}
const fetchOnlineSalesSummary = (params) => {
  return(dispatch) => {
    instance
      .get(urls.transactions.transactionDetails, { params })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "GET_ONLINE_SALES_SUMMARY",
            payload: response.data,
          });
        }
      });
  }
}
const createGroup = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.salesReports.groups, params).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback();
        // dispatch({ type: '' })
      }
    });
  };
}
const fetchModifierSales = (params) => {
  return (dispatch) => {
    instance.get(urls.salesReports.addonDetails, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        dispatch({ type: 'GET_MODIFIER_SALES', payload: response.data });
      }
    });
  };
}
export {
  fetchSalesSummary,
  fetchpaymentMethods,
  fetchorderType,
  fetchshiftReport,
  fetchtaxType,
  fetchdaywiseSales,
  fetchitemSales,
  fetchmenuSales,
  fetchaddonDetails,
  fetchwaiterDetails,
  fetchcashierDetails,
  fetchgroupDetails,
  fetchupdateGroup,
  DeleteGroupDetails,
  fetchGroupMenuList,
  fetchPettyCashReports,
  fetchEmployeeSalesReport,
  fetchAlcoholReports,
  fetchOnlineSalesSummary,
  createGroup,
  fetchModifierSales,
}