import instance from './axiosInstance';
import urls from './urls';
import axios from 'axios';
import { constructUrl } from './urls';
import qs from 'qs';

const fetchAllCategories = () => {
  return (dispatch) => {
    instance.get(urls.menu.categories).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_CATEGORIES', payload: response.data });
    });
  };
};
const fetchAllAddons = () => {
  return (dispatch) => {
    instance.get(urls.menu.menu_addons).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_ADDONS', payload: response.data });
    });
  };
};
const fetchItemsByCatId = (params) => (dispatch) => {
  const queryString = constructUrl(params);
  instance.get(`${urls.menu.itemsByCatId}?${queryString}`).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: 'SET_CAT_ITEMS', payload: response.data });
  });
};
const fetchAllItems = () => {
  return (dispatch) => {
    instance.get(urls.menu.items).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_ITEMS', payload: response.data });
    });
  };
};
const getPizzaConfig = () => {
  return (dispatch) => {
    instance.get(urls.menu.pizzaConfig, {}).then((response) => {
      // if (response.status === 200 || response.status === 304)
      dispatch({ type: 'PIZZA_CONFIG', payload: response.data });
    });
  };
};
const createItemsByCatId = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.menu.itemsByCatId, params)
      .then((response) => {
        console.log(response);
        fetchItemsByCatId({ category_id: params.cat_id });
        if (response.status === 200) {
          callback(true);
          // dispatch({ type: '' })
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};
const createQuickItemsByCatId = (params, callback) => {
  return (dispatch) => {
    instance.put(urls.menu.itemQuickAdd, params).then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback();
        // dispatch({ type: '' })
      }
    });
  };
};
const editItemsByCatId = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.menu.itemsByCatId, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          // dispatch({ type: '' })
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};
const copyItemById = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.menu.copy, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          // dispatch({ type: '' })
        }
      })
      .catch((err) => {
        console.log('copy', err);
        callback(false);
      });
  };
};
const deleteItemsByCatId = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.menu.itemsByCatId, { data: cred })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          if (callback) {
            callback(true);
          }
          // dispatch({ type: '' })
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};
const fetchComments = () => {
  return (dispatch) => {
    instance.get(urls.menu.comments).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_COMMENTS', payload: response.data });
    });
  };
};
const createComment = (params, callback) => {
  return (dispatch) => {
    instance.put(urls.menu.comments, params).then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback();
        // dispatch({ type: '' })
      }
    });
  };
};
const editComment = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.menu.comments, params).then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback();
        // dispatch({ type: '' })
      }
    });
  };
};
const deleteComments = (cred, callback) => {
  return (dispatch) => {
    instance.delete(urls.menu.comments, { data: cred }).then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback();
      }
    });
  };
};

const createCategory = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.menu.categories, params)
      .then((response) => {
        callback(true, response);
      })
      .catch((err) => {
        callback(false, err);
      });
  };
};
const editCategories = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.menu.categories, params)
      .then((response) => {
        callback(true, response);
      })
      .catch((err) => {
        callback(false, err);
      });
  };
};
const deleteCategories = (cred, callback) => {
  return (dispatch) => {
    instance.delete(urls.menu.categories, { data: cred }).then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback();
      }
    });
  };
};

const fetchAddonCategories = () => {
  return (dispatch) => {
    instance.get(urls.menu.addons).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_ADDONS_CATEGORIES', payload: response.data });
    });
  };
};
const createAddonCategories = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.menu.addons, params)
      .then((response) => {
        callback(true);
      })
      .catch((err) => {
        callback(false);
      });
  };
};

const assginAddonItems = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.menu.assgin, params).then((response) => {
      dispatch({ type: '' });
      callback(response);
    });
  };
};
const fetchAssginAddonItems = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.menu.assgin, { params }).then((response) => {
      if (response.status === 200 || response.status === 304)
        if (callback) {
          callback(response.data);
        }
      dispatch({ type: '' });
    });
  };
};
const editAddonCategories = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.menu.addons, params)
      .then((response) => {
        callback(true, response);
      })
      .catch((err) => {
        callback(false, err);
      });
  };
};
const deleteAddonCategories = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.menu.addons, { data: cred })
      .then((response) => {
        callback(true, response.data);
      })
      .catch((err) => {
        callback(false, err);
      });
  };
};
const fetchAddonItem = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.menu.addons, { params }).then((response) => {
      if (response.status === 200 || response.status === 304)
        callback(response.data);
      // dispatch({ type: '' })
    });
  };
};
const createAddonItem = (params, callback) => {
  return (dispatch) => {
    instance.put(urls.menu.addonItem, params).then((response) => {
      // dispatch({ type: '' })
      callback(response);
    });
  };
};
const editAddonItem = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.menu.addonItem, params).then((response) => {
      // dispatch({ type: '' })
      callback(response);
    });
  };
};
const deleteAddonItem = (cred, callback) => {
  return (dispatch) => {
    instance.delete(urls.menu.addonItem, { data: cred }).then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback();
      }
    });
  };
};
const fetchItemInfoById = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.menu.getItemById, { params }).then((response) => {
      if (response.status === 200 || response.status === 304)
        if (callback) {
          callback(response.data);
        }
    });
  };
};
const fetchImageUrl = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.menu.presigned_url, { params }).then((response) => {
      console.log(response);
      if (response.status === 200) {
        if (callback) {
          callback(response.data);
        }
      }
    });
  };
};
const putImageUrl = (url, cred, callback, isAzure) => {
  return (dispatch, getState) => {
    axios
      .put(url, cred.Body, {
        headers: isAzure
          ? {
              'content-type': cred.ContentType,
              oauth_token: getState().loginReducer.loginDetails.oauth_token,
              'x-ms-blob-type': 'BlockBlob',
            }
          : {
              'content-type': cred.ContentType,
              oauth_token: getState().loginReducer.loginDetails.oauth_token,
            },
      })
      .then((response) => {
        console.log(response);
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          callback();
        }
      })
      .catch((err) => {
        const { data } = err.response;
        callback(false, data);
        dispatch({ type: '' });
      });
  };
};
const itemImageUpload = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.menu.image_upload, params).then((response) => {
      callback(response);
    });
  };
};
const fetchItemTypes = () => {
  return (dispatch) => {
    instance.get(urls.menu.itemtypes).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_ITEM_TYPES', payload: response.data });
    });
  };
};

const putItemTypes = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.menu.itemtypes, params)
      .then((response) => {
        callback(true);
      })
      .catch((err) => {
        callback(false);
      });
  };
};
const fetchDietaryAttributes = (params) => {
  return (dispatch) => {
    instance.get(urls.menu.dietary_attribute).then((response) => {
      if (response.status === 200) {
        dispatch({ type: 'GET_DIETARY_ATTRIBUTES', payload: response.data });
      }
    });
  };
};
const createDietaryAttribute = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.menu.dietary_attribute, params)
      .then((response) => {
        callback(true);
      })
      .catch((err) => {
        callback(false);
      });
  };
};
const editDietaryAttribute = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.menu.dietary_attribute, params)
      .then((response) => {
        callback(true);
      })
      .catch((err) => {
        callback(false);
      });
  };
};
const deleteDietaryAttribute = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.menu.dietary_attribute, { data: cred })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};

const AssignCategoryToItems = (params, callback) => {
  return (dispatch) => {
    // var response = {
    //   succeed_items: [{id:123, name:"test"}],
    //   failed_items: [{id:1234, name:"test1"}, {id:1235, name:"test2"}]
    // }
    // callback(true, response);
    instance
      .post(urls.menu.bulkAssignCategories, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200 || response.status === 207) {
          callback(true, response.data);
          // dispatch({ type: '' })
        }
      })
      .catch((err) => {
        callback(false, null);
      });
  };
};

const AssignAddonToItems = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.menu.bulkAssignAddon, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200 || response.status === 207) {
          callback(true, response.data);
          // dispatch({ type: '' })
        }
      })
      .catch((err) => {
        callback(false, null);
      });
  };
};

export {
  fetchAllCategories,
  fetchAllAddons,
  fetchItemsByCatId,
  createItemsByCatId,
  editItemsByCatId,
  deleteItemsByCatId,
  fetchComments,
  createComment,
  editComment,
  deleteComments,
  createCategory,
  editCategories,
  deleteCategories,
  fetchAddonCategories,
  createAddonCategories,
  editAddonCategories,
  deleteAddonCategories,
  fetchAddonItem,
  createAddonItem,
  editAddonItem,
  deleteAddonItem,
  fetchItemInfoById,
  createQuickItemsByCatId,
  assginAddonItems,
  fetchAssginAddonItems,
  fetchImageUrl,
  putImageUrl,
  itemImageUpload,
  copyItemById,
  getPizzaConfig,
  fetchItemTypes,
  putItemTypes,
  fetchDietaryAttributes,
  createDietaryAttribute,
  editDietaryAttribute,
  deleteDietaryAttribute,
  AssignCategoryToItems,
  AssignAddonToItems,
  fetchAllItems,
};
