import moment from 'moment';
import {
  getWookBook,
  downloadAs,
  setHeaderStyle,
  setNormalRowStyle,
} from './workbook';
import { ConvertPrice, ConvertCurrencyPriceToDollar } from '../util';
const downloadConsolidateReport = (data) => {
  console.log(data);
  const workbook = getWookBook();
  const ws = workbook.addWorksheet('Consolidated report');
  ws.addRow([
    'Stock name',
    'Units',
    'Opening balance',
    'Closing balance',
    'Issued',
    'Purchased',
    'Consumption',
    'Disposal',
  ]);
  setHeaderStyle(ws, 1);
  ws.getColumn(1).width = 50;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 20;
  ws.getColumn(7).width = 20;
  ws.getColumn(8).width = 20;
  ws.getColumn(9).width = 20;
  ws.getColumn(10).width = 20;
  ws.getColumn(11).width = 20;
  data.map((d, i) => {
    ws.addRow([
      d.itemName,
      d.itemUnitTypeCode,
      d.openingBalance,
      d.closingBalance,
      d.issuedQuantity,
      d.purchasedQuantity,
      d.consumedQuantity,
      d.disposedQuantity,
    ]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(
    workbook,
    `consolidated_report_${moment().format('yyyyMMDDHHmmss')}.xlsx`
  );
};
const downloadConsumptionReport = (data) => {
  console.log(data);
  const workbook = getWookBook();
  const ws = workbook.addWorksheet('Consumption report');
  ws.addRow([
    'Stock item',
    'units',
    'Theoritical',
    'Actual',
    'Difference',
    'Variation',
    'Disposed',
  ]);

  setHeaderStyle(ws, 1);
  ws.getColumn(1).width = 50;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 20;
  ws.getColumn(7).width = 20;
  data.map((d, i) => {
    ws.addRow([
      d.itemName,
      d.itemUnitTypeCode,
      d.theoreticalConsumption,
      d.actualConsumption,
      d.difference,
      d.variation,
      d.disposedQuantity,
    ]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(
    workbook,
    `consumption_report_${moment().format('yyyyMMDDHHmmss')}.xlsx`
  );
};
const downloadKitchenReport = (data) => {
  console.log(data);
  const workbook = getWookBook();
  if (!data.kitchens_data || data.kitchens_data.length === 0) {
    const ws = workbook.addWorksheet("no data");
    ws.addRow([
      'Stock item',
      'Issued quantity',
      'units',
      'Avg price',
      'Amount',
    ]);
    setHeaderStyle(ws, 1);
  } else {
    data.kitchens_data.map((kitchen) => {
      const ws = workbook.addWorksheet(kitchen.kitchenName);
      ws.addRow([
        'Stock item',
        'Issued quantity',
        'units',
        'Avg price',
        'Amount',
      ]);
      setHeaderStyle(ws, 1);
      ws.getColumn(1).width = 50;
      ws.getColumn(2).width = 20;
      ws.getColumn(3).width = 20;
      ws.getColumn(4).width = 20;
      ws.getColumn(5).width = 20;
      kitchen.data.map((stock, i) => {
        ws.addRow([
          stock.itemName,
          stock.issuedQuantity,
          stock.itemUnitTypeCode,
          stock.averagePriceValue,
          stock.amountValue,
        ]);
        setNormalRowStyle(ws, i + 2);
      });
    });
  }
  downloadAs(
    workbook,
    `inventory_kitchen_report_${moment().format('yyyyMMDDHHmmss')}.xlsx`
  );
};

const downloadVendorsReport = (data) => {
  console.log(data);
  const workbook = getWookBook();
  if (!data || data.length === 0) {
    const ws = workbook.addWorksheet("no data");
    ws.addRow([
      'Balance amount',
      'Paid amount',
      'Total purchases',
      'Total(without tax)',
    ]);
    setHeaderStyle(ws, 1);
    ws.addRow([]);
    ws.addRow([
      'Stock item',
      'Received qty',
      'Amount',
    ]);
    setHeaderStyle(ws, 3);

  } else {
    data.map((vendor) => {
      const ws = workbook.addWorksheet(vendor.vendor_name);
      ws.addRow([
        'Balance amount',
        'Paid amount',
        'Total purchases',
        'Total(without tax)',
      ]);
      setHeaderStyle(ws, 1);

      ws.addRow([vendor.balance_amount, vendor.amount_paid, vendor.total_amount, vendor.net_amount])

      ws.addRow([
        'Stock item',
        'Received qty',
        'Amount',
      ]);
      setHeaderStyle(ws, 3);
      ws.getColumn(1).width = 50;
      ws.getColumn(2).width = 25;
      ws.getColumn(3).width = 25;
      vendor.data.map((d, i) => {
        const amount = ConvertCurrencyPriceToDollar(d.fulfilledAmount);
        const received_qty =
          d.fulfilledQuantity.toString() + ' ' + d.itemUnitTypeCode;
        ws.addRow([
          d.itemName,
          received_qty,
          amount,
        ]);
        setNormalRowStyle(ws, i + 4);
      });
    });
  }
  downloadAs(
    workbook,
    `inventory_vendors_report_${moment().format('yyyyMMDDHHmmss')}.xlsx`
  );
};

const downloadPurchasesReport = (table, purchasesSummary) => {
  const workbook = getWookBook()
  buildTotalPurchaseSummarySheet(purchasesSummary, workbook)
  buildPurchaseSheet(table, workbook)

  downloadAs(
    workbook,
    `inventory_purchases_report_${moment().format('yyyyMMDDHHmmss')}.xlsx`
  );
}

const buildTotalPurchaseSummarySheet = (purchasesSummary, workbook) => {
  const ws = workbook.addWorksheet("Purchase summary");
  ws.addRow([
    'Total purchases',
    'Paid',
    'Total balance',
  ]);
  setHeaderStyle(ws, 1);
  ws.addRow([purchasesSummary.total_amount, purchasesSummary.amount_paid, purchasesSummary.balance])

}
const buildPurchaseSheet = (data, workbook) => {
  const ws = workbook.addWorksheet('Purchases');
  ws.addRow([
    'Purchase order ID',
    'Bill no',
    'Date received',
    'Amount',
    'Vendor',
    'Payment',
    'Balance',
  ]);
  setHeaderStyle(ws, 1);
  ws.getColumn(1).width = 30;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 20;
  ws.getColumn(7).width = 20;
  data.map((d, i) => {
    ws.addRow([
      d.po_id,
      d.bill_no,
      d.date,
      d.total_amount,
      d.vendor_name,
      d.amount_paid,
      d.balance_amount,
    ]);
    setNormalRowStyle(ws, i + 2);
  });

};

export {
  downloadConsolidateReport,
  downloadConsumptionReport,
  downloadKitchenReport,
  downloadVendorsReport,
  downloadPurchasesReport,
};
