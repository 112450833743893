import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import OrderTypesList from './ordertypesList';
import {
  fetchOrderTypes,
  fetchPaymentTypes,
  fetchTaxes,
  fetchOrderTypeDelivery,
  fetchDietaryAttributes,
} from '../../../redux/actions';
import VerticalTabs from '../../../components/verticalTabs';
import MyContext from '../../../Provider/MyContext';
import NoPermissionPage from '../../../components/NoPermission';
import AddonsItems from './addonItems';
import ItemCatalogue from './itemCatalogue';
import DietaryAttribute from './dietaryAttributes';

const OrderTypes = () => {
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState(
    Context.langData.order_types
  );
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const Tabs = [
    {
      name: Context.langData.item_catalogue,
      id: 0,
    },
    {
      name: Context.langData.order_types,
      id: 1,
    },
    {
      name: Context.langData.modifiers,
      id: 2,
    },
    {
      name: Context.langData.dietary_attributes,
      id: 3,
    },
  ];
  useEffect(() => {
    dispatch(fetchOrderTypes());
    dispatch(fetchPaymentTypes());
    dispatch(fetchTaxes());
    dispatch(fetchOrderTypeDelivery());
    dispatch(fetchDietaryAttributes());
  }, []);
  function indexCallback(value) {
    const Index = Tabs.findIndex((element) => element.id == value);
    setSelectedTitle(Tabs[Index].name);
    setSelectedTab(value);
  }
  const renderView = () => {
    return (
      <>
        {selectedTab === 0 && (
          <>
            {isPermissionCheck('order_types') ? (
              <ItemCatalogue />
            ) : (
              <NoPermissionPage data-qaid='orderTypeList_noPermission'></NoPermissionPage>
            )}
          </>
        )}
        {selectedTab === 1 && (
          <>
            {isPermissionCheck('order_types') ? (
              <OrderTypesList data-qaid='orderTypeList_index'></OrderTypesList>
            ) : (
              <NoPermissionPage data-qaid='orderTypeList_noPermission'></NoPermissionPage>
            )}
          </>
        )}
        {selectedTab === 2 && (
          <>
            {isPermissionCheck('menu_addons') ? (
              <AddonsItems data-qaid='AddonsItems_index'></AddonsItems>
            ) : (
              <NoPermissionPage data-qaid='AddonsItems_noPermission'></NoPermissionPage>
            )}
          </>
        )}
        {selectedTab === 3 && (
          <DietaryAttribute data-qaid='DietaryAttribute_index'></DietaryAttribute>
        )}
      </>
    );
  };
  const isPermissionCheck = (val) => {
    var isPermission = false;
    var obj = userPermissions.filter((e) => e.key == val);
    if (obj.length > 0) {
      if (obj[0].status) {
        isPermission = true;
      }
    }
    return isPermission;
  };
  return (
    <div
      data-qaid='order_types_index_div'
      style={{ borderTop: '1px solid #EEEEEE' }}>
      <Grid
        data-qaid='menu_grid'
        container
        direction='row'
        style={{ margin: 20 }}>
        <Grid
          data-qaid='menu_tabs'
          item
          xs={12}
          lg={2}
          style={{ backgroundColor: '#FAFAFA' }}>
          <VerticalTabs
            data-qaid='menu_options_vtabs'
            tabData={Tabs}
            indexCallback={indexCallback}
            style={{ marginTop: 24 }}></VerticalTabs>
        </Grid>

        <Grid data-qaid='menu_options_add_button' item xs={12} lg={10}>
          {renderView()}
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderTypes;
