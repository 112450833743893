import React, { useState, useEffect, useContext } from 'react';
import RightDrawer from '../../../../components/RightDrawer';
import {
    Grid,
    Button,
    TextField,
    Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SingleSelect from '../../../../components/SingleSelect';
import {
    fetchAllGroupsData,
    fetchDepartments,
    fetchAllStocks,
    saveIssue,
    fetchAllKitchenStocks
} from '../../../../redux/actions';
import AutoCompliteDropDown from '../../../../components/AutoComplete';
import Delete from '../../../../components/SVG/Delete';
import moment from 'moment'
import MyContext from '../../../../Provider/MyContext';
import { AvenirBlackH4, AvenirBlackH7, AvenirBlackH3, H4, DotText } from '../../../../utils/text';
import CloseCircle from '../../../../components/SVG/CloseCircle';
import InventoryIcon from '../../../../components/SVG/InventoryIcon';
import SearchIcon from '../../../../components/SVG/SearchIcon';
import TopSearchBar from '../../../../components/SearchBar';
import InputText from '../../../../components/InputText';
import styles from '../../../../styles/index';
import SingleDate from '../../../../components/SingleDate';
import SnackBar from '../../../../components/SnackBar';

const ViewReturnStock = ({ open, onClose, data, callback }) => {
    const style = styles();
    const dispatch = useDispatch();
    const groupsData = useSelector(state => state.inventory.groupsData);
    const kitchenData = useSelector(state => state.inventory.departments);
    const getStocks = useSelector((state) => state.inventory.stocks);
    const kitchenStocks = useSelector((state) => state.inventory.kitchenStocks);
    const [allGroups, setAllGroups] = useState([]);
    const [departmentId, setDepartmentId] = useState();
    const [itemsList, setItemsList] = useState([]);
    const [dateValue, setDateValue] = useState(moment().format("MM/DD/YYYY"))
    const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"))
    const [search, setSearch] = useState('');
    const Context = useContext(MyContext);
    const [snackbar, setSnackbar] = useState(null);
    useEffect(() => {
        dispatch(fetchAllGroupsData());
        dispatch(fetchDepartments());
        dispatch(fetchAllStocks());
    }, []);
    useEffect(() => {
        if (kitchenData && kitchenData.length > 0) {
            setDepartmentId(kitchenData[0].id)
        }
    }, [kitchenData]);
    useEffect(() => {
        if (departmentId) {
            dispatch(fetchAllKitchenStocks( departmentId ));
        }
    }, [departmentId])
    useEffect(() => {
        if (data?.length > 0 && getStocks?.length > 0) {
            let itemListArray = [];
            data.map((StockItem) => {
                var stockObj = kitchenStocks.stocks?.filter((stocke) => stocke.id == StockItem.id)
                if (stockObj.length > 0) {
                    var obj =
                    {
                        name: StockItem.name,
                        quantity: StockItem.quantity,
                        unitTypeCode: StockItem.unitTypeCode,
                        id: StockItem.id
                    }
                    if (kitchenStocks.stocks?.length > 0) {
                        var item = kitchenStocks.stocks.filter(e => e.id == stockObj[0].id);
                        if (item.length > 0) {
                            obj['currentBalance'] = item[0].currentBalance;
                        } else {
                            obj['currentBalance'] = 0;
                        }
                    }
                    else {
                        obj['currentBalance'] = 0;
                    }
                    itemListArray.push(obj)
                }
            })
            setItemsList(itemListArray)
        }
    }, [data, kitchenStocks])
    const selectedStock = (value) => {
        var itemListArray = [...itemsList];
        var item = itemListArray.filter((e) => e.name == value)
        if (item.length > 0) {
        } else {
            if (getStocks && getStocks.length > 0) {
                var stock = getStocks.filter((e) => e.name == value)
                if (stock.length > 0) {
                    var obj = {
                        name: stock[0].name,
                        quantity: 0,
                        unitTypeCode: stock[0].unitTypeCode,
                        id: stock[0].id
                    }
                    if (kitchenStocks.stocks?.length > 0) {
                        var item = kitchenStocks.stocks.filter(e => e.id == stock[0].id);
                        if (item.length > 0) {
                            obj['currentBalance'] = item[0].currentBalance;
                        } else {
                            obj['currentBalance'] = 0;
                        }
                    }
                    else {
                        obj['currentBalance'] = 0;
                    }
                    itemListArray.push(obj);
                }
            }
        }
        setItemsList(itemListArray);
    }
    const handleSave = () => {
        var items = [];
        var iserror = false;
        if (itemsList && itemsList.length > 0) {
            itemsList.map((Sitem) => {
                if (Sitem.quantity) {
                    if (Sitem.quantity <= Sitem.currentBalance) {
                        var obj = {
                            name: Sitem.name,
                            quantity: Sitem.quantity || 0,
                            unitTypeCode: Sitem.unitTypeCode,
                            id: Sitem.id,

                        }
                        items.push(obj)
                    } else {
                        iserror = true
                    }
                } else {
                    iserror = true
                }
            })
        }
        const postDate = moment(dateValue).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        if (!iserror) {
            dispatch(saveIssue({ departmentId: departmentId, items: items, date: postDate, type: "RETURN" }, callback));
        } else {
            setSnackbar({ message: Context.langData.dispatch_error, severity: 'error' });
        }
    }
    const updateQtyValue = (index, value) => {
        var item = [...itemsList];
        item[index] = { ...item[index], quantity: value ? parseFloat(value) : '' }
        setItemsList(item);
    }
    const removeStock = (index) => {
        var item = [...itemsList];
        item.splice(index, 1)
        setItemsList(item)
    }
    const selectCatDropDown = (value) => {
        var itemArray = []
        if (groupsData && groupsData.length > 0) {
            var catItem = groupsData.filter((e) => e.id == value)
            if (catItem.length > 0) {
                if (catItem[0].items && catItem[0].items.length > 0) {
                    catItem[0].items.map((item) => {
                         var StockItem = getStocks.filter((stocke) => stocke.id == item.id)
                        if (StockItem.length > 0) {
                            var obj =
                            {
                                name: StockItem[0].name,
                                quantity: 0,
                                unitTypeCode: StockItem[0].unitTypeCode,
                                id: StockItem[0].id,
                                currentBalance: StockItem[0].currentBalance,
                            }
                            if (kitchenStocks.stocks.length > 0) {
                                var it = kitchenStocks.stocks.filter(e => e.id == item.id);
                                if (it.length > 0) {
                                    obj['currentBalance'] = it[0].currentBalance;
                                } else {
                                    obj['currentBalance'] = 0;
                                }
                            }
                            else {
                                obj['currentBalance'] = 0;
                            }
                            itemArray.push(obj)
                        }
                    })
                }
            }
        }
        setAllGroups(value);
        setItemsList(itemArray);
    }
    const handleSelect = (value) => {
        selectedStock(value)
    }
    return (
        <>
            <RightDrawer open={open} padding={1}>
                <Grid data-qaid="viewReturnStock_mainContainer" container style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between', flex: 1, width: 592 }}>
                    <Grid data-qaid="viewReturnStock_subContainer1" item style={{ position: 'relative', overflowY: 'scroll', height: 650 }}>
                        <Grid data-qaid="viewReturnStock_onCloseGrid" container style={{ dispaly: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 20, cursor: 'pointer' }} >
                            <CloseCircle onClick={onClose} />
                        </Grid>
                        <Grid data-qaid="viewReturnStock_inventoryGrid" item style={{ margin: 30 }}>
                            <Grid data-qaid="viewReturnStock_inventoryLabelView" item style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 0 }}>
                                <InventoryIcon />
                                <AvenirBlackH4 data-qaid="viewReturnStock_inventoryLabel" label={Context.langData.inventory} style={{ color: '#707272', paddingLeft: 10, paddingTop: 5 }} />
                            </Grid>
                            <AvenirBlackH7 data-qaid="viewReturnStock_returnIssueLabel" label={Context.langData.return_stock} style={{ fontWeight: 'bold' }} />
                        </Grid>

                        <Grid data-qaid="viewReturnStock_kitchenGrid" item style={{ margin: 30 }}>
                            <Grid data-qaid="viewReturnStock_sectionTextLabelView" item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                                <Grid item lg={3}>
                                    <AvenirBlackH4 data-qaid="viewReturnStock_sectionTextLabel" label={Context.langData.kitchen} />
                                    <Grid data-qaid="viewReturnStock_setKitchenNameGrid" item style={{ marginTop: 5 }}>
                                        <SingleSelect
                                            list={kitchenData}
                                            valueKey="id"
                                            displayKey="name"
                                            placeholder={Context.langData.select}
                                            value={departmentId}
                                            handleChange={(e) => setDepartmentId(e.target.value)}
                                            border={false}
                                            height={56}
                                            FormWidth={'100%'}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item lg={4}>
                                    <AvenirBlackH4 data-qaid="viewReturnStock_setDateValueLabel" label={Context.langData.date} />
                                    <Grid item style={{ marginTop: 5 }}>
                                        <SingleDate
                                            value={dateValue}
                                            height={56}
                                            border={false}
                                            callback={(e) => { setDateValue(moment(e).format('MM/DD/YYYY')) }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item lg={4}>
                                    <AvenirBlackH4 data-qaid="viewReturnStock_selectCatDropDownLabel" label={Context.langData.select_group} />
                                    <Grid item style={{ marginTop: 5 }}>
                                        <SingleSelect
                                            list={groupsData}
                                            valueKey="id"
                                            displayKey="name"
                                            placeholder={Context.langData.all_group}
                                            value={allGroups}
                                            handleChange={(e) => selectCatDropDown(e.target.value)}
                                            border={false}
                                            height={56}
                                            FormWidth={'100%'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ width: '100%', marginTop: '10px' }}>
                                <AutoCompliteDropDown
                                    options={getStocks}
                                    onChange={handleSelect}
                                    labelKey={'name'}
                                    key={itemsList}
                                    placeholder={Context.langData.serch_stock_items}
                                />
                            </Grid>
                            <Grid data-qaid="viewReturnStock_itemsMainContainer" item style={{ paddingBottom: 100, width: '100%', paddingTop: 20 }}>
                                <Grid data-qaid="viewReturnStock_itemssubContainer" style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#F3FAFD', height: 52, width: '100%' }}>
                                    <Grid data-qaid="viewReturnStock_itemsLabelView" style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', paddingLeft: 20, width: '45%' }}>
                                        <AvenirBlackH4 data-qaid="viewReturnStock_itemsLabel" label={Context.langData.items} />
                                    </Grid>
                                    <Grid data-qaid="viewReturnStock_storeBalanceLabelView" style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', width: '25%', paddingLeft: 5 }}>
                                        <AvenirBlackH4 data-qaid="viewReturnStock_storeBalanceLabel" label={Context.langData.kitchen_balance} />
                                    </Grid>
                                    <Grid data-qaid="viewReturnStock_dispatchLabelView" style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', width: '30%', paddingLeft: 5 }}>
                                        <AvenirBlackH4 data-qaid="viewReturnStock_dispatchLabel" label={Context.langData.dispatch_qty} />
                                    </Grid>
                                </Grid>
                                {itemsList ?
                                    itemsList.map((itemName, index) => {
                                        return (
                                            <Grid data-qaid="viewReturnStock_stockNameContainer" key={itemName.name} item style={{ display: 'flex', flexDirection: 'row', marginTop: 10, width: '100%', backgroundColor: '#FAFAFA', minHeight: 50 }}>
                                                <Grid data-qaid="viewReturnStock_stockNameGrid" style={{ display: 'flex', width: '45%', justifyContent: 'flex-start', alignItems: 'center'  }}>
                                                    <DotText label={itemName.name} style={{paddingLeft: 20}}/>
                                                </Grid>
                                                <Grid data-qaid="viewReturnStock_updateQuantityGrid" style={{ display: 'flex', flexDirection: 'row', width: '25%', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 5 }}>
                                                    <AvenirBlackH4 data-qaid="viewReturnStock_unitsLabel" label={`${itemName.currentBalance} ${itemName.unitTypeCode}`} />
                                                </Grid>
                                                <Grid data-qaid="viewReturnStock_updateUnitPriceGrid" style={{ display: 'flex', flexDirection: 'row', width: '30%', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 5 }}>
                                                    <InputText
                                                        style=
                                                        {
                                                            {
                                                                display: 'flex',
                                                                maxHeight: 38,
                                                                color: itemName.currentBalance < itemName.quantity ? '#AB1212' : '#051D33',
                                                                width: '50%',
                                                                marginRight: 10

                                                            }
                                                        }
                                                        name="quantity"
                                                        height={38}
                                                        maxLength={8}
                                                        id={itemName.quantity}
                                                        value={itemName.quantity}
                                                        onChange={(e) => { updateQtyValue(index, e.target.value) }}
                                                    />
                                                    <AvenirBlackH4 data-qaid="viewReturnStock_deleteLabel" label={itemName.units} style={{ marginRight: 10 }} />
                                                    <Delete width={24} height={24} onClick={() => { removeStock(index) }} style={{ display: 'flex', paddingRight: 5, cursor: 'pointer' }} ></Delete>

                                                </Grid>
                                            </Grid>
                                        )
                                    }) : null
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid data-qaid="viewReturnStock_subContainer2" item justify='flex-end' style={{ position: 'fixed', display: 'flex', height: '93px', backgroundColor: '#FAFAFA', alignItems: 'center', flexDirection: 'row', borderTop: '1px solid #C3C3C3', bottom: 0, width: 592 }}>
                        <Button className={style.buttonClass} data-qaid="viewReturnStock_cancelButton" style={{ backgroundColor: "#FFF", marginRight: 10, width: 103, height: 40, marginLeft: 10, border: '2px solid #051D33' }} variant="contained" id="cancel" onClick={onClose}  >
                            <AvenirBlackH4 data-qaid="viewReturnStock_cancelLabel" label={Context.langData.cancel} />
                        </Button>
                        <Button className={style.buttonClass} data-qaid="viewReturnStock_sendButton" style={{ backgroundColor: "#FFB600", marginRight: 20, width: 90, height: 40 }} variant="contained" id="cancel" type='submit' onClick={handleSave}  >
                            <AvenirBlackH4 data-qaid="viewReturnStock_sendLabel" label={Context.langData.send} />
                        </Button>
                    </Grid>
                </Grid>
                {
                    snackbar &&
                    <SnackBar
                        data-qaid="shifts_snackbar"
                        open={true}
                        setOpen={() => setSnackbar(null)}
                        severity={snackbar.severity}
                        message={snackbar.message}
                    />
                }
            </RightDrawer >

        </>
    )
}

export default ViewReturnStock;