import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NoPermissionPage from '../../components/NoPermission';
import MyContext from '../../Provider/MyContext';
import { AvenirBlackH4, H6 } from '../../utils/text';
import Items from './items';
import OrderTypes from './OrderTypes';
import { fetchAllItems } from '../../redux/actions';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Menu = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const [buttonArray, setButtonArray] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const menulist = [
    { key: 'menu_items', title: Context.langData.categories, id: 0 },
    { key: 'order_types', title: Context.langData.settings, id: 1 },
  ];

  useEffect(() => {
    if (state && state.tab) {
      setSelectedIndex(state.tab);
    }
  }, [state]);
  useEffect(() => {
    dispatch(fetchAllItems())
  }, [])
  const isItemHasPermission = useMemo(() => {
    var buttonName = [];
    var buttonCount = 0;
    menulist.map((childItem) => {
      if (childItem.id == 1) {
        var menuOptions = ['order_types', 'menu_addons'];
        var isPermission = 0;
        menuOptions.map((menuOptionItem) => {
          var obj = userPermissions.filter((e) => e.key == menuOptionItem);
          if (obj.length > 0) {
            if (obj[0].status) {
              isPermission = isPermission + 1;
            }
          }
        });
        if (isPermission != 0) {
          buttonCount = buttonCount + 1;
          childObj = { ...childItem, isPermission: true };
        } else {
          childObj = { ...childItem, isPermission: false };
        }
        buttonName.push(childObj);
      } else {
        var obj = userPermissions.filter((e) => e.key == childItem.key);
        if (obj.length > 0) {
          var childObj = {};
          if (obj[0].status) {
            buttonCount = buttonCount + 1;
            childObj = { ...childItem, isPermission: true };
          } else {
            childObj = { ...childItem, isPermission: false };
          }
          buttonName.push(childObj);
        } else {
          var childObj = { ...childItem, isPermission: false };
          buttonName.push(childObj);
        }
      }
    });
    if (buttonCount == 0) {
      setButtonArray([]);
    } else {
      setButtonArray(buttonName);
    }
    var result = false;
    if (buttonName.length > 0) {
      result = buttonName[selectedIndex].isPermission;
    } else {
      result = false;
    }
    return result;
  }, [userPermissions]);
  const renderSelectedView = () => {
    if (selectedIndex == 0) {
      if (isItemHasPermission) {
        return <Items data-qaid='menu_ietms' />;
      } else {
        return <NoPermissionPage data-qaid='menu_ietms_nopermissions' />;
      }
    } else if (selectedIndex == 1) {
      if (isItemHasPermission) {
        return <OrderTypes data-qaid='menu_ietms' />;
      } else {
        return <NoPermissionPage data-qaid='menu_ietms_nopermissions' />;
      }
    }
  };
  const handleSelectItem = (item) => {
    setSelectedIndex(item);
  };
  return (
    <Grid
      data-qaid='settings_index_grid'
      style={{
        backgroundColor: '#FFFFFF',
        minHeight: '100%',
        width: '100%',
        overflow: 'hidden',
      }}>
      <Grid
        data-qaid='settings_index_contentgrid'
        style={{ height: '20%', marginBottom: selectedIndex == 0 ? 28 : 18 }}>
        <Grid
          data-qaid='settings_index_titlegrid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: 36 }}>
          <H6
            data-qaid='settings_index_title_label'
            label={Context.langData.menu}
            style={{
              color: '#051D33',
              paddingTop: 36,
              fontWeigth: '800',
            }}></H6>
        </Grid>
        <Grid
          data-qaid='settings_index_bodygrid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: 36, marginTop: 36, flexDirextion: 'row' }}>
          {buttonArray.map((item, i) => {
            return (
              <Button
                key={i}
                data-qaid='settings_button'
                style={{
                  backgroundColor:
                    selectedIndex == item.id ? '#051D33' : '#EEEEEE',
                  marginRight: 1,
                  borderRadius: 0,
                  borderTopLeftRadius: i == 0 ? 4 : 0,
                  borderBottomLeftRadius: i == 0 ? 4 : 0,
                  borderTopRightRadius: buttonArray.length - 1 == i ? 4 : 0,
                  borderBottomRightRadius: buttonArray.length - 1 == i ? 4 : 0,
                }}
                onClick={() => handleSelectItem(item.id)}>
                <AvenirBlackH4
                  data-qaid='settings_button_label'
                  label={item.title}
                  style={{
                    color: selectedIndex == item.id ? '#FFFFFF' : '#051D33',
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 10,
                    paddingBottom: 10,
                    lineHeight: '24px',
                  }}
                />
              </Button>
            );
          })}
        </Grid>
      </Grid>
      <Grid data-qaid='settings_body_view' style={{ height: '80%' }}>
        {renderSelectedView()}
      </Grid>
    </Grid>
  );
};

export default Menu;
