import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux'
import moment from 'moment'
import { T } from '../../../utils/text'
const RestInfo = () => {
  const { info } = useSelector(state => state.builder)
  console.log()
  const timings = useMemo(() => {
    const times = info?.operating_hours;
    if (times) {
      const today = moment().format("ddd").toLowerCase();
      console.log(today, times)
      const todayTimes = times.filter(t => t.id == today);
      console.log(todayTimes)
      if (todayTimes.length > 0) {
        const t = todayTimes[0]
        const time = moment(t.openTime, "HH:mm").format("hh:mm a") + " - " + moment(t.closeTime, "HH:mm").format("hh:mm a")
        return time
      }
    }
    return "11:00 am - 10:00 pm";
  }, [info])
  return (
    <div style={{ backgroundColor: '#FAFAFA', height: 40, width: '100%', alignItems: 'center' }}>
      <Grid container alignItems="center" style={{ height: 40 }}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center" justify="center">

            <T style={{ color: '#1C7D26' }} label={`Open: ${timings}`} />

          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center" justify="center">
            <Grid item style={{ borderRight: '1px solid #C3C3C3', paddingRight: 20 }}>

              <T style={{ color: '#1C7D26' }} label={info.contact.address} />
            </Grid>

            <Grid item style={{ paddingLeft: 20 }}>

              <T style={{ color: '#1C7D26' }} label={info.contact.phone} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default RestInfo
