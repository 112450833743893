import React, { useEffect, useState, useContext } from 'react';
import {
    Grid,
    Button,
    makeStyles
} from '@material-ui/core';
import SettingsIcon from '../../components/SVG/Settings';
import CreateOutlinedIcon from '../../components/SVG/Edit';
import OnOffSwitch from '../../components/OnOffSwitch';
import FileCopyOutlinedIcon from '../../components/SVG/Copy';
import DeleteOutlineOutlinedIcon from '../../components/SVG/Delete';
import TextInputComponent from '../../components/TextInputComponent';
import { TextField } from "@material-ui/core";
import InputText from '../../components/InputText'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment';
import { H6, H2, AvenirBlackH4, H4, DotTextBold } from '../../utils/text';
import Message from '../../components/Message'
import { useNavigate } from 'react-router-dom'
import { editFloorPlan, deleteFloorPlan, copyFloorplan } from '../../redux/actions'
import MyContext from '../../Provider/MyContext';
import { deviceDimensions } from './builder/items'
import CircleCloseIcon from '../../components/SVG/CircleClose';
import SingleSelect from '../../components/SingleSelect';
const useStyles = makeStyles({
    mainView: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex',
        flex: 1,
        minWidth: 500,
        position: 'relative'
    },
    settingsView: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
        marginLeft: 56,
        marginRight: 32
    },
    settingsText: {
        paddingLeft: '10px',
        color: '#808285',
        fontSize: '14px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    floorplanView: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '10px',
        marginLeft: 56,
        marginRight: 32,
        width: 500

    },
    floorplanEditText: {
        alignItems: 'center',
        paddingTop: '10px',
        marginLeft: 56,
        marginRight: 32
    },

    floorplanText: {
        fontSize: '32px',
        display: 'flex',
        color: 'black',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    floorplanTextInput: {
        fontSize: '32px',
        color: 'black',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },

    tableSeatsView: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '10px',
        marginLeft: 56,
        marginRight: 32
    },
    tablesButtonText: {
        color: 'black',
        fontFamily: 'AvenirLT',
        fontWeight: 400,
        fontSize: '12px'
    },
    seatsButtonText: {
        color: 'black',
        fontFamily: 'AvenirLT',
        fontWeight: 400,
        fontSize: '12px'
    },
    saveButton: {
        backgroundColor: '#373737',
        color: 'white',
        height: 40,
        borderRadius: 5
        // marginLeft: '10px'
    },
    cancelButton: {
        color: 'black',
        height: 40,
        borderRadius: 5
        // marginLeft: '10px'
    },
    saveButtonText: {
        color: 'white',
        fontFamily: 'AvenirLT',
        fontWeight: 400,
        fontSize: '14px'
    },
    cancelButtonText: {
        color: 'black',
        fontFamily: 'AvenirLT',
        fontWeight: 400,
        fontSize: '14px'
    },

    discriptionView: {
        // backgroundColor: '#FAFAFA',
        backgroundColor: '#FAFAFA',
        marginTop: '20px',
        marginLeft: 56,
        marginRight: 32
    },
    discriptionText: {
        color: 'black',
        fontSize: '18px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    iconStyles: {
        color: 'black',
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        marginLeft: '10px'
    },
    iconStyles1: {
        paddingLeft: '5px',
        height: '20px',
        width: '20px',
        color: '#808285',
        cursor: 'pointer'
    },
    descriptionText: {
        color: 'black',
        fontSize: '14px',
        paddingTop: '5px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    showordertypeMainView: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '15px',
    },
    showordertypeText: {
        color: '#808285',
        fontSize: '14px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    showordertypeDropdown: {
        paddingLeft: '5px',
        height: '20px',
        width: '20px',
        color: '#808285'
    },
    dineinText: {
        color: 'black',
        fontSize: '14px',
        paddingTop: '5px',
        paddingBottom: '20px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    settingsMainview: {
        color: '#808285'
    },
    createdonText: {
        color: 'black',
        fontSize: '14px',
        paddingTop: '15px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    dateStyles: {
        color: 'black',
        fontSize: '14px',
        paddingTop: '5px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    editDescriptionStyles: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '15px',
    },
    descriptionText: {
        color: '#808285',
        fontSize: '14px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    textAreaStyles: {
        borderColor: '#c3c3c3',
        backgroundColor: '#FFFFFF',
        marginTop: '5px',

    },
    ordertypeMainView: {
        display: 'flex',
        flexDirection: 'column'
    },
    editorderStyles: {
        display: 'flex',
        flexDirection: 'column'
    },
    editOrderText: {
        color: '#808285',
        fontSize: '14px',
        fontFamily: 'AvenirLT',
        fontWeight: 400,
        paddingTop: '15px'
    },
    dropdownStyles: {
        height: '10px',
        // marginTop: '10px'
    },
    settings2: {
        color: 'black',
        fontSize: '18px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    ActiveFloorplanText: {
        color: 'black',
        fontSize: '14px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    editfloorplanIcon: {
        color: '#FFFFFF',
        height: '15px',
        width: '15px'
    },
    copyText: {
        marginRight: '20px',
        fontSize: '14px',
        color: 'black',
        cursor: 'pointer',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    deleteText: {
        fontSize: '14px',
        color: 'black',
        cursor: 'pointer',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    descriptionText2: {
        color: '#707272',
        fontSize: '14px',
        fontFamily: 'AvenirLT',
        fontWeight: 400
    },
    bottomView: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: 20,
    }
})
const DrawerSettings = ({ item, onClose, copyCallback, deleteCallback }) => {
    const navigate = useNavigate()
    const classes = useStyles();
    const dispatch = useDispatch();
    const orderTypes = useSelector(state => state.metaReducer.ordertypes)
    const [toggleButton, setToggleButton] = useState(false);
    const Context = useContext(MyContext);
    const default_value = Context.langData.fall_florrplan;
    const [inputText, setInputText] = useState(default_value);
    const [toggleEditText, setToggleEditText] = useState(false);
    const [selectedOrderType, setSelectedOrderType] = useState('');
    const [editDescription, setEditDescription] = useState(false);
    const [textArea, setTextArea] = useState('');
    const [ordertypeName, setOrderTypeName] = useState('');
    const [editOrderType, setOrderType] = useState(false);
    const [data, setData] = useState({})
    const floorplanDetails = useSelector(state => state.floorplan.floorplans);
    const [msg, setMsg] = useState({ open: false, msg: "", severity: "success" })
    const [showDevices, setShowDevices] = useState(false)
    const [device, setDevice] = useState({})
    useEffect(() => {
        setData(item)
        setInputText(item.name);
        setTextArea(item.description);
        setSelectedOrderType(item.otype_id);
        setToggleButton(item.is_active == 1)
        const otype = _.find(orderTypes, (o) => o.otype_id == item.otype_id)
        // const selectedDevice = deviceDimensions.find(e => e.id == item.device_name)
        // if (selectedDevice) {
        //     setDevice(selectedDevice)
        // }
        omDeviceSelect(item.device_name)
        // console.log(selectedDevice)
        if (otype)
            setOrderTypeName(otype.otype_name)
    }, [item])
    const omDeviceSelect = (val) => {
        const selectedDevice = deviceDimensions.find(e => e.id == val)
        console.log(selectedDevice)
        if (selectedDevice) {
            setDevice(selectedDevice)
        }
    }
    const onDevCancel = () => {
        omDeviceSelect(item.device_name)
        setShowDevices(false)
    }
    const onDevSave = () => {
        dispatch(editFloorPlan({ ...data, device_name: device.id, number_of_sections: 0 }))
        setData({ ...data, device_name: device.id })
        setShowDevices(false)
    }
    const btnProps = {};
    if (!inputText) {
        btnProps["disabled"] = true
    }
    const onSaveName = () => {
        let flag = true;
        const floorName = inputText
        if (floorName !== '') {
            if (floorplanDetails.length > 0) {
                floorplanDetails.map((textinputName) => {
                    if (textinputName.floorplan_id != item.floorplan_id) {
                        if (textinputName.name == floorName) {
                            flag = false;
                        }
                    }
                })
            }
            if (flag) {
                setToggleEditText(false)
                dispatch(editFloorPlan({ ...data, name: floorName, number_of_sections: 0 }))
                setData({ ...data, name: floorName })
                floorNameChangeSuccess()
            }
            else {
                floorNameAlreadyExists()
            }
        }

    }

    const floorNameChangeSuccess = () => {
        showMessage(Context.langData.floor_plan_succ)
        setTimeout(() => {
            onClose()
        }, 3000)
    }

    const floorNameAlreadyExists = () => {
        showMessage(Context.langData.floor_name_already_exit, 'warning')
        setTimeout(() => {
        }, 3000);
    }
    const onCancelName = () => {
        console.log("oncalncel", data.name)
        setToggleEditText(false)
        setInputText(data.name)
    }
    const showMessage = (message, severity = "success") => {
        setMsg({ open: true, msg: message, severity })
        setTimeout(() => {
            setMsg({ open: false, msg: "", severity: "success" })
        }, 2000);

    }
    const onDescSave = () => {
        setEditDescription(false)
        dispatch(editFloorPlan({ ...data, description: textArea, number_of_sections: 0 }))
        setData({ ...data, description: textArea })
        showMessage(Context.langData.description_change_succ)
    }
    const onDescCancel = () => {
        setEditDescription(false)
        setTextArea(data.description)

    }
    const onToggleOnOff = () => {
        dispatch(editFloorPlan({ ...data, is_active: toggleButton ? 0 : 1, number_of_sections: 0 }, editFloorPlanCallBack))

    }
    const editFloorPlanCallBack = (isSuccess, msg) => {
        console.log("toggleStatus", msg)
        if (isSuccess) {
            setMsg({ open: true, msg: Context.langData.floorplan_active_sucess, severity: "success" })
            setData({ ...data, is_active: toggleButton ? 0 : 1 })
            setToggleButton(!toggleButton)
        } else {
            setMsg({ open: true, msg: msg.status_code == 400 ? msg.message : Context.langData.floorplan_active_fail, severity: "error" })
            setData({ ...data, is_active: 0 })
            setToggleButton(item.is_active == 1)
        }
        setTimeout(() => {
            setMsg({ open: false, msg: "", severity: "success" })
        }, 2000);

    }
    const onCopy = () => {
        const { floorplan_id } = data;
        dispatch(copyFloorplan({ floorplan_id }, copyCallback))
    }

    const onDelete = () => {
        const { floorplan_id } = data;
        dispatch(deleteFloorPlan({ floorplan_id: item.floorplan_id }, deleteCallback));

    }
    const showMainText = () => {
        if (toggleEditText == false) {
            return (
                <div className={classes.floorplanView}>
                    <DotTextBold label={data.name} style={{ width: '90%', fontSize: 24 }} />
                    <CreateOutlinedIcon style={{ marginLeft: 16 }} width={24} height={24} color={'#007191'} onClick={() => { setToggleEditText(true) }} />
                </div>
            )
        }
        else {
            return null;
        }
    }

    const showDescriptionBox = () => {
        if (editDescription == false) {
            return (
                <div style={{ maxWidth: 380 }}>
                    <div style={{ display: 'flex', alignItems: 'center', paddingTop: '15px' }}>
                        <H4 style={{ color: '#707272' }} label={Context.langData.description} />
                        <CreateOutlinedIcon style={{ marginLeft: 9.48 }} width={15.33} height={15.33} color={'#007191'} onClick={() => { setEditDescription(true) }} />
                    </div>
                    <H4 className={classes.dineinText} label={data.description} style={{ wordBreak: 'break-all' }} />
                </div>
            )
        }
    }

    const onHandleChange = (txtData) => {
        setInputText(txtData.target.value)
        // return txtData;

    }
    const goToBuilder = () => {
        navigate('/floorplan/create', { state: item })
    }

    console.log("item", item)

    return (
        <Grid container className={classes.mainView}>
            <Grid item style={{ flexDirection: 'column', overflow: 'auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 32, marginTop: 20 }}>
                    <div></div>
                    <CircleCloseIcon style={{ float: 'right', cursor: 'pointer' }} onClick={onClose} />
                </div>
                <div className={classes.settingsView}>
                    <SettingsIcon color={"#707272"} height={15} width={15} />
                    <H4 className={classes.settingsText} label={Context.langData.settings} />
                </div>
                {showMainText()}

                {
                    toggleEditText &&
                    <div className={classes.floorplanEditText}>
                        <TextInputComponent
                            value={inputText}
                            maxLength={64}
                            onChange={onHandleChange}
                            capitalize={false}
                            InputProps={{
                                maxlength: 1,
                                classes: {
                                    input: classes.floorplanTextInput,
                                },
                            }}
                        />
                        <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                            <Button variant="contained" size="large" className={classes.cancelButton} style={{ marginLeft: 10, border: '2px solid #051D33', backgroundColor: '#FFF', height: 40, width: 103 }} onClick={onCancelName}>
                                <AvenirBlackH4 label={Context.langData.cancel} />
                            </Button>
                            <Button {...btnProps} variant="contained" size="large" className={classes.saveButton} style={{ backgroundColor: inputText ? '#FFB600' : '#C3C3C3', marginLeft: 10, height: 40, width: 90 }} onClick={onSaveName}>
                                <AvenirBlackH4 label={Context.langData.save} />
                            </Button>

                        </div>
                    </div>
                }

                <div className={classes.tableSeatsView}>
                    <Button variant="outlined" size="small" disabled ><H4 className={classes.tablesButtonText} label={Context.langData.tables + (item.table_count ? item.table_count : 0)} /></Button>
                    <Button variant="outlined" size="small" disabled style={{ marginLeft: '10px' }}><H4 className={classes.seatsButtonText} label={Context.langData.seats + (item.seat_count ? item.seat_count : 0)} /></Button>
                </div>

                <Grid item style={{ backgroundColor: editDescription || '#F3FAFD', marginTop: '20px', paddingTop: 20, paddingBottom: 20, paddingLeft: 56, paddingRight: 56 }}>
                    <H2 className={classes.discriptionText} label={Context.langData.description} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <H4 style={{ color: '#707272', paddingTop: 10 }} label={Context.langData.created_on} />
                        <H4 className={classes.dateStyles} label={moment(data.created_on * 1000).format('MMMM DD, YYYY')} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {showDescriptionBox()}
                        {
                            editDescription &&
                            <div className={classes.editDescriptionStyles}>
                                <H4 className={classes.descriptionText} label='Description' />
                                <TextField
                                    inputProps={{
                                        maxlength: 300,
                                        disableUnderline: true,
                                    }}
                                    variant="outlined"
                                    placeholder={Context.langData.brief_desc}
                                    multiline
                                    rows={4}
                                    value={textArea}
                                    onChange={(e) => { setTextArea(e.target.value) }}
                                    className={classes.textAreaStyles}
                                />
                                <div style={{ marginTop: '10px' }}>
                                    <Button variant="contained" size="small" className={classes.cancelButton} style={{ border: '2px solid #051D33', backgroundColor: '#FFF', height: 40, width: 103 }} onClick={onDescCancel}>
                                        <AvenirBlackH4 label={Context.langData.cancel} />
                                    </Button>
                                    <Button variant="contained" size="small" className={classes.saveButton} style={{ backgroundColor: '#FFB600', marginLeft: 10, height: 40, width: 90 }} onClick={onDescSave}>
                                        <AvenirBlackH4 label={Context.langData.save} />
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 0 }}>
                                <H4 className={classes.descriptionText2} label='Number of Sections' />
                            </div>
                            <span className={classes.dineinText}>{data.number_of_sections == null ? 0 : data.number_of_sections}</span>
                        </div>
                    </div>
                    <Grid item xs={12}>
                        {
                            !showDevices ?
                                <Grid>
                                    <div style={{ display: 'flex', alignItems: 'center', paddingTop: '15px' }}>
                                        <H4 style={{ color: '#707272' }} label='Selected device' />
                                        <CreateOutlinedIcon style={{ marginLeft: 9.48 }} width={15.33} height={15.33} color={'#007191'} onClick={() => { setShowDevices(true) }} />
                                    </div>
                                    <H4 className={classes.dineinText} label={device.device} />
                                </Grid>
                                :
                                <div className={classes.editDescriptionStyles}>
                                    <H4 className={classes.descriptionText} label={'Select device'} />
                                    <SingleSelect
                                        list={deviceDimensions}
                                        handleChange={(e) => { omDeviceSelect(e.target.value) }}
                                        value={device.id}
                                        valueKey="id"
                                        displayKey="device"
                                        border={false}
                                        height={56}
                                    // className={classes.dropdownStyles}
                                    />
                                    <div style={{ marginTop: '10px' }}>
                                        <Button variant="contained" size="small" className={classes.cancelButton} style={{ border: '2px solid #051D33', height: 40, width: 103, backgroundColor: '#FFF' }} onClick={onDevCancel}>
                                            <AvenirBlackH4 label={Context.langData.cancel} />
                                        </Button>
                                        <Button variant="contained" size="small" className={classes.saveButton} style={{ backgroundColor: '#FFB600', marginLeft: 10, height: 40, width: 90 }} onClick={onDevSave}>
                                            <AvenirBlackH4 label={Context.langData.save} />
                                        </Button>
                                    </div>
                                </div>
                        }

                    </Grid>
                </Grid>

                <Grid item style={{ marginTop: '20px', marginLeft: 56, marginRight: 32 }}>
                    <H2 className={classes.settings2} label={Context.langData.settings} />
                    <div style={{ paddingTop: '20px', display: 'flex', alignItems: 'center' }}>
                        <H4 className={classes.ActiveFloorplanText} style={{ color: toggleButton ? "black" : '#808285' }} label={Context.langData.active_floor} />
                        <OnOffSwitch onChange={onToggleOnOff} show={toggleButton} style={{ marginLeft: '10px' }} />
                    </div>
                </Grid>
            </Grid>

            <Grid container style={{ backgroundColor: '#FAFAFA', paddingBottom: 15, position: 'sticky', bottom: 0 }} >
                <hr style={{ width: '100%', border: '1px solid #EEEEEE' }} />
                <Grid container className={classes.bottomView}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: 32 }}>
                        <Button style={{
                            color: '#000000', fontSize: '14px', fontFamily: 'AvenirLT', fontWeight: 400
                        }} onClick={onDelete} startIcon={<DeleteOutlineOutlinedIcon width={24} height={24} />}>
                            <AvenirBlackH4 label={Context.langData.delete} />
                        </Button>
                        <Button style={{
                            color: '#000000', fontSize: '14px', fontFamily: 'AvenirLT', fontWeight: 400, marginRight: 20, marginLeft: 20
                        }}
                            startIcon={<FileCopyOutlinedIcon width={24} height={24} />} onClick={onCopy}>
                            <AvenirBlackH4 label={Context.langData.copy} />
                        </Button>

                        <Button variant="text" size="small" style={{ backgroundColor: '#FFB600', height: 40, borderRadius: 5, width: 175 }} onClick={goToBuilder}>
                            <CreateOutlinedIcon width={16} height={16} color={'#051D33'} style={{ marginRight: 8 }} />
                            <AvenirBlackH4 label={Context.langData.edit_floor_plan} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Message open={msg.open} severity={msg.severity} msg={msg.msg} handleClose={() => console.log()} />
        </Grid>
    )
}

export default DrawerSettings;
