import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AvenirBlackH4, H4, DotText } from '../utils/text';
import MyContext from '../Provider/MyContext';
import { Grid } from '@material-ui/core';
import CircleIcon from './SVG/Circle'
import { ConvertPrice } from '../utils/util';

const oldcolors = [
  "#051D33",
  "#00B6C9",
  "#FC44C0",
  "#FFB600",
  "#1DA897",
  "#EEEEEE"
];
const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const DoughnutChart = ({ data, labelsKey, valueKey }) => {
  ChartJS.register(ArcElement, Tooltip);
  // console.log("datadatadatadata=====>>>>data", data, "labelsKeylabelsKeylabelsKey===>", labelsKey, "valueKeyvalueKeyvalueKeyvalueKey===>", valueKey)
  const Context = useContext(MyContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [labels, setLabels] = useState([]);
  const [val, setVal] = useState([]);
  const [legend, setLegend] = useState({
    position: "right",
    align: "middle"
  })
  const colors = useMemo(() => {
    return data.map((d, i) => {
      if (oldcolors.length > i) {
        return oldcolors[i]
      } else {
        return getRandomColor()
      }
    })
  }, [data])
  useEffect(() => {
    const leg = {
      position: matches ? 'bottom' : "right",
      align: "middle"
    }
    setLegend(leg)
  }, [matches])
  useEffect(() => {
    const label = data.map(d => d[labelsKey]);
    // + " (" + ConvertPrice(d[valueKey]) + ")")
    let values = [];
    if (valueKey == 'transactions') {
      values = data.map(d => d[valueKey]);
    } else {
      values = data.map(d => ConvertPrice(d[valueKey]).replace(",", ""));
    }

    setLabels(label);
    setVal(values)
  }, [data, valueKey])
  const GraphData = {
    labels: labels,
    maintainAspectRatio: false,
    responsive: false,
    datasets: [{
      data: val,
      backgroundColor: colors,
      hoverBackgroundColor: colors
    }]
  };
  const options = {
    legend: {
      display: false,
      position: "right",
      align: "middle"
    },
    responsive: true,
    elements: {
      arc: {
        borderWidth: 0
      }
    },

    tooltips: {
      callbacks: {
        label: function (context, data) {
          let index = context?.index;
          let label = "";
          if (index > -1) {
            label += data.labels[index];
            let val = data.datasets[0].data[index];
            if (valueKey == 'transactions') {
              val = parseInt(val)
            } else {
              val = `$${ConvertPrice(val)}`
            }
            label += ": " + val
          }

          return label;
        }
      }
    }

  };
  const labelValue = (index) => {
    var value = 0;
    if (GraphData.datasets[0].data[index]) {
      value = GraphData.datasets[0].data[index]
    }
    if (valueKey == 'transactions') {
      return parseInt(value)
    } else {
      return `$${ConvertPrice(value)}`
    }

  }
  const labelColor = (index) => {
    var value = '#FFFFFF';
    if (GraphData.datasets[0].backgroundColor[index]) {
      value = GraphData.datasets[0].backgroundColor[index]
    }
    return value

  }
  return (
    <>
      {
        val.length > 0 ?
          <Grid data-qaid="doughnutChart_main_grid" container >
            <Grid data-qaid="doughnutChart_content_grid" className="chartBox" container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>
              <Grid data-qaid="doughnutChart_data_grid" item sm={6} lg={6} md={6} style={{ display: 'flex' }}>
                <Grid data-qaid="doughnutChart_grapData_grid" className="chartBoxLabels" style={{ flexDirection: 'column', width: '100%', marginTop: 24, height: 160, overflowX: 'auto' }} >
                  {GraphData.labels.length > 0 ?
                    GraphData.labels.map((item, index) => {
                      return (
                        <>
                          <Grid key={index} data-qaid="doughnutChart_data_label_grid" item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 17, paddingLeft: 20 }}>
                            <Grid data-qaid="doughnutChart_data_left_grid" item sm={2} lg={1} md={1} >
                              <CircleIcon data-qaid="doughnutChart_circle_icon" color={labelColor(index)} style={{ marginRight: 10 }} />
                            </Grid>
                            <Grid data-qaid="doughnutChart_center_grid" item sm={10} lg={6} md={6} style={{ display: 'flex', flexDirection: 'row' }}>
                              {/* <CircleIcon data-qaid="doughnutChart_circle_icon" color={labelColor(index)} style={{ marginRight: 10 }} /> */}
                              <DotText data-qaid="doughnutChart_litem_abel" label={item} />
                            </Grid>
                            <Grid data-qaid="doughnutChart_right_grid" item sm={12} lg={5} md={5}>
                              <AvenirBlackH4 data-qaid="doughnutChart_value_label" label={labelValue(index)} />
                            </Grid>
                          </Grid>
                        </>
                      )
                    })
                    :
                    <> </>
                  }
                </Grid>
              </Grid>
              <Grid data-qaid="doughnutChart_doughnutchart_grid" item sm={6} lg={6} md={6} style={{ display: 'block', marginTop: 20, height: 166 }}>
                <Doughnut
                  style={{ width: 200, height: 200 }}
                  data-qaid="doughnutChart_doughnutchart" data={GraphData} options={options} />
              </Grid>
            </Grid>

          </Grid>
          :
          <div data-qaid="doughnutChart_nodata_div">
            <AvenirBlackH4 data-qaid="doughnutChart_nodata_label" label={Context.langData.no_data} />
          </div>
      }

    </>
  )
}
export default DoughnutChart
