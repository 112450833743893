import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {

    Button, Grid
} from '@material-ui/core';
import styles from '../../../../styles/index';
import RightDrawer from '../../../../components/RightDrawer'
import {
    fetchPrinters,
    fetchAssignKitchenPrinter,
    createAssignKitchen
} from '../../../../redux/actions';
import SingleSelect from '../../../../components/SingleSelect';
import MyContext from '../../../../Provider/MyContext';
import { H4, H2, H3, AvenirBlackH4, H8, H5, DotText, DotTextBoldNormal, AvenirBlackH3 } from '../../../../utils/text';
import _ from 'lodash';
import SettingIcon from '../../../../components/SVG/Settings';
import CircleClose from '../../../../components/SVG/CloseCircle';
import MultiSelectChip from "../../../../components/MultiSelectChip";

const CreateKitchenPrinterAssign = ({ open, callback, onClose, printers, kitchens }) => {
    const style = styles();
    const Context = useContext(MyContext);
    console.log("PRINTERS", printers)
    const [unassignedKitchensArray, setUnassignedKitchensArray] = useState(kitchens);
    const [selectedPrinters, setSelectedPrinters] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
        let selectedP = JSON.parse(JSON.stringify(printers));
        setSelectedPrinters(selectedP)
     }, [printers]);
    
    const updateSelectedPrinters = (printer_id, selected_kitchens) => {
        //get unassigned kitchen
        var unassignedKitchens = unassignedKitchensArray.filter((e)=>{
            if (!selected_kitchens.includes(e.kitchen_id)) {
                return e
            }
        })
        selectedPrinters.filter(e => {
            if (e.printer_id === printer_id ) {
               for (const k of e.selectableKitchens){
                    //kitchen is not selected and is not in unassigned kitchen array, add to array
                    if (!selected_kitchens.includes(k.id) &&
                        unassignedKitchens.filter((e)=> { if (e.kitchen_id === k.id) {return e}}).length === 0) {
                        var vobj = {
                            kitchen_id: k.id,
                            kitchen_name: k.name,
                        };
                        unassignedKitchens.push(vobj)
                    } 
               }
            }
        })
        setUnassignedKitchensArray(unassignedKitchens)
        //Update assigned kitchen ids and selectable kitchen list for each printer
        let printerArray = selectedPrinters.filter(e => {
            if (e.printer_id === printer_id ) {
               e.assigned_kitchen_ids = []
               e.assigned_kitchens = []
               for (const k of selected_kitchens){
                    e.assigned_kitchen_ids.push(k)
                    const selected_k = e.selectableKitchens.filter((e) => { if (e.id === k) {return e}})
                    var vobj = {
                        id: selected_k[0].id,
                        name: selected_k[0].name,
                    };
                    e.assigned_kitchens.push(vobj)
               }
            } else {
                e.selectableKitchens = []
                if (e.assigned_kitchens) {
                    for (const k of e.assigned_kitchens) {
                        var vobj = {
                            id: k.id,
                            name: k.name,
                        };
                        e.selectableKitchens.push(vobj);
                    }
                }
                if (unassignedKitchens) {
                    for (const k of unassignedKitchens) {
                        var vobj = {
                            id: k.kitchen_id,
                            name: k.kitchen_name,
                        };
                        e.selectableKitchens.push(vobj);
                    }
                }
            }
            e.selectableKitchens = e.selectableKitchens.sort((a,b) => a.name.localeCompare(b.name))
            return e
        })
        return printerArray
    }

    useEffect(() => { }, [selectedPrinters]);
    
    const handleMultiSelect = (printer_id, e) => {
        console.log("edit multi select")
        console.log(e)
        console.log(printer_id)
        const ps = updateSelectedPrinters(printer_id, e)
        setSelectedPrinters(ps)
    };
    
    const save = () => {
        if (selectedPrinters.length > 0) {
            var array = [];
            selectedPrinters.map(p => {
                if (!p.assigned_kitchens || p.assigned_kitchens.length == 0){
                    var obj = {}
                    obj.printer_id = p.printer_id;
                    obj.kitchen_id = null;
                    array.push(obj)
                } else {
                    p.assigned_kitchens.map(k=>{
                        var obj = {}
                        obj.printer_id = p.printer_id;
                        obj.kitchen_id = k.id;
                        array.push(obj)
                    })
                }
            })
            if (array.length > 0) {
                console.log(array)
                dispatch(createAssignKitchen(array, callback));
            } else {
            }
        } else {
        }
        dispatch(fetchPrinters());
    }
    
    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid container style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid item style={{ display: 'flex', height: '85%', flexDirection: 'column' }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.assign_printer} />
                        <Grid style={{ overflowX: 'auto', display: 'flex', flexDirection: 'column', marginLeft: 56, marginRight: 56, width: 500, marginBottom: 15 }}>
                            {
                                selectedPrinters && selectedPrinters.length > 0 ?
                                selectedPrinters.map((item) => (
                                        <Grid container style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
                                            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <DotTextBoldNormal label={item.name} style={{ marginRight: 20, alignItems: 'center', width: '50%', marginBottom: 5 }} />
                                                <Grid
                                                    data-qaid="assignPrinter_kitchenlistGrid"
                                                    style={{ width: "100%", marginTop: 10 }}>
                                                    <MultiSelectChip
                                                        list={item.selectableKitchens}
                                                        value="id"
                                                        name="name"
                                                        placeholder={Context.langData.select_kitchen}
                                                        selectedValue={item.assigned_kitchen_ids}
                                                        handleChange={(val) => {
                                                            handleMultiSelect(item.printer_id, val);
                                                          }}
                                                        border={false}
                                                        height={56}
                                                        width={498}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                    :
                                    <div>
                                        <AvenirBlackH4 label={Context.langData.no_printers} />
                                    </div>
                            }
                        </Grid>
                    </Grid>
                    <Grid item style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="assignPrinter_action_grid" container style={{ backgroundColor: '#FAFAFA', borderTop: '1px solid #C3C3C3' }}>
                            <Grid data-qaid="assignPrinter_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="assignPrinter_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, height: 40, width: 103, border: '2px solid #051D33' }} >
                                    <AvenirBlackH4 data-qaid="assignPrinter_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="assignPrinter_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="assignPrinter_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer>
    )
}


export default CreateKitchenPrinterAssign;