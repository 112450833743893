import React, { useContext } from 'react';
import RightDrawer from '../../../../components/RightDrawer';
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core';
import MyContext from '../../../../Provider/MyContext';
import styles from '../../../../styles/index';
const ViewStocks = ({ open, onClose }) => {
    const style = styles();
    const Context = useContext(MyContext);

    return (
        <RightDrawer open={open}>
            <Grid data-qaid="viewStocks_mainContainer" container>
                <Grid data-qaid="viewStocks_subContainer1" container>
                    <span data-qaid="viewStocks_vendorsContainer" style={{ fontSize: '24px' }}>
                        <Typography variant="h3" >{Context.langData.vendors}</Typography></span>
                </Grid>
                <Grid data-qaid="viewStocks_subContainer2" container style={{ marginTop: '50px', marginLeft: '50px' }}>
                    <Button className={style.buttonClass} size="large" variant="outlined" style={{ color: 'black', cursor: 'pointer' }} onClick={onClose}> <Typography variant="h5" >{Context.langData.close}</Typography></Button>
                </Grid>
            </Grid>
        </RightDrawer>
    )
}

export default ViewStocks;