import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Footer from '../../components/Footer'
import useMfa from '../MainLayout/useMfa'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      // paddingLeft: 200
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingBottom: 41
  }
}));

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(true);
  const classes = useStyles(isMobileNavOpen);
  const mfa = useMfa()
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Footer from="dashboard" />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />

          </div>
        </div>
      </div>
      {/* <div style={{height: 50, width:'100%', backgroundColor:'red', position: 'fixed', bottom: 0, zIndex: 100}}><p>laksjd</p></div> */}
    </div>
  );
};


export default DashboardLayout;
