import React, { useState, useEffect, useCallback, useContext } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MyContext from '../Provider/MyContext';
import CalenderIcon from './SVG/CalenderIcon';
import DownVector from './SVG/DownVector';
import {
  makeStyles,
  Button, Grid,
  Popover
} from '@material-ui/core';
import moment from 'moment';
import { H4, H2, H3, AvenirBlackH4, H6, H5, AvenirBlackH3 } from '../utils/text';
import Calendar from '../mui-drp/Calendar'
const SingleDate = ({
  callback = null,
  disabled = false,
  height = 56,
  width = '100%',
  value = null,
  maxDate, minDate }) => {
  const Context = useContext(MyContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [startDate, setStartDate] = useState();
  const [dateValue, setDateValue] = useState(moment().format("MMM DD, YYYY"));
  useEffect(() => {
    console.log("date value", value)
    if (value) {
      const v = moment(value, "MM/DD/YYYY").format("MMM DD, YYYY")
      console.log("date....", v)
      setDateValue(v)
    } else {
      setDateValue(Context.langData.select_a_date)
    }
  }, [value])
  const fillWithZero = (val) => {
    return val > 9 ? val + "" : "0" + val;
  }
  const onDateChange = (date) => {
    const m = date.getMonth() + 1;
    const dt = date.getDate();
    const d = fillWithZero(m) + "/" + fillWithZero(dt) + "/" + date.getFullYear();
    console.log("date", date, d)
    callback(d)
    // setStartDate(e);
    handleClose()
  }
  // useEffect(() => {
  //     console.log("datevalue", new Date(value), value, "valuevaluevaluevaluevalue")
  //     if (value) {
  //         setStartDate(new Date(value))
  //     }else{
  //       setStartDate(new Date())
  //     }
  // }, [value])
  // useEffect(() => {
  //     var datevalue = startDate.toString().substring(4, 16);
  //     setDateValue(moment(startDate).format('MMM DD, YYYY'));
  //     var date = moment(new Date(startDate.toString().substr(0, 16)));
  //     console.log("datedatedate", "startDate", startDate, moment(startDate).format("MM/DD/YYYY"))
  //     callback(moment(date).format("MM/DD/YYYY"))
  // }, [startDate]);
  const handleClick = (event) => {
    if (!disabled)
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const borderColor = open ? "#004C60" : "#707272"
  const calEl = document.getElementById("calendar")
  let popWidth = 290;
  if (calEl) {
    popWidth = document.getElementById("calendar").getBoundingClientRect().width - 4
    if (popWidth < 200) {
      popWidth = 200
    }
  }
  console.log("// OPTIMIZE: ", value)
  return (
    <>
      <Grid data-qaid="datePicker_button"
        aria-describedby={"single_cal"}
        onClick={handleClick}
        id="calendar"
        variant="contained" style={{
          backgroundColor: '#FFFFFF', width: '100%', height: height, margin: 0, border: `1px solid ${borderColor}`, cursor: 'pointer', alignItems: 'center', borderRadius: 4, paddingLeft: 10, display: 'flex'
        }} >
        <Grid data- qaid="datePicker_button_grid" item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
          <Grid item data-qaid="datePicker_calender_icon_grid" style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
            <CalenderIcon data-qaid="datePicker_calender_icon" />
            <H4 data-qaid="datePicker_value_label" label={dateValue} style={{ paddingLeft: 10 }} />
          </Grid>

        </Grid>
      </Grid>
      <Popover
        data-qaid="dateRange_popover"
        id={"single_cal"}
        open={open}

        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >
        <div style={{ border: '1px solid #004C60', borderRadius: 5, padding: 2, paddingBottom: 0 }}>
          <Calendar
            width={popWidth}
            selecteddate={value ? new Date(value) : new Date()}
            maxDate={maxDate}
            minDate={minDate}
            onSetDate={onDateChange}
          />
        </div>
      </Popover>
    </>
  )
}

export default SingleDate
