import * as React from "react"

const InfoOutlined = ({ width = 24, height = 24, color = "#000", ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5zM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.068a.75.75 0 01.75.75v8.727a.75.75 0 01-1.5 0V9.818a.75.75 0 01.75-.75z"
        fill={color}
      />
      <path d="M12 4.91a.75.75 0 100 1.5.75.75 0 000-1.5z" fill="#000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.306 4.62a1.25 1.25 0 111.389 2.079 1.25 1.25 0 01-1.39-2.08zm.694.79a.25.25 0 100 .5.25.25 0 000-.5z"
        fill={color}
      />
    </svg>
  )
}

export default InfoOutlined
