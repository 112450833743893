import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import SimpleTable from '../../../components/SimpleTable';
import MyContext from '../../../Provider/MyContext';
import Daterange from '../../../components/DateRange';
import moment from 'moment';
import {
  fetchContactTracing,
  fetchEmployees,
  fetchTransactionDetails,
} from '../../../redux/actions';
import DownlodeIcon from '../../../components/SVG/DownlodeIcon';
import {
  AvenirBlackH7,
  AvenirBlackH2,
  H2,
  H3,
  H4,
  AvenirBlackH4,
  H5,
} from '../../../utils/text';
import RefreshIcon from '../../../components/SVG/RefreshIcon';
import TopSearchBar from '../../../components/SearchBar';
import SingleSelect from '../../../components/SingleSelect';
import RightDrawer from '../../../components/RightDrawer';
import CircleClose from '../../../components/SVG/CloseCircle';
import MonariesLogo from '../../../components/SVG/MonariesTextLogo';
import { ConvertPrice } from '../../../utils/util';
import styles from '../../../styles/index';
import { downloadContactTracing } from '../../../utils/excel';
import useDebounce from '../../../components/memos/useDebounce';
import { setPhoneNumber } from '../../../utils/util';

const ContactTracing = () => {
  const Context = useContext(MyContext);
  const { debounce } = useDebounce();
  const [date, setDate] = useState({
    start_ts: moment().startOf('day').unix(),
    end_ts: moment().endOf('day').unix(),
  });
  const dispatch = useDispatch();
  const [selectedEmployeeName, setSelectedEmployeeName] = useState('all');
  const [data, setData] = useState([]);
  const employeesList = useSelector(
    (state) => state.profileReducers.employeesList
  );
  const contactTracingData = useSelector(
    (state) => state.inventoryReportReducer.contactTracing
  );
  const [pagenumber, setPagenumber] = useState(0);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [serchText, setSerchText] = useState('');
  const [employeesData, setEmployeesData] = useState([]);
  const [resetDate, setResetDate] = useState(moment().unix());
  const [showBillDetails, setShowBillDetails] = useState(false);
  const [row, setRow] = useState({});

  useEffect(() => {
    dispatch(fetchEmployees());
  }, []);
  useEffect(() => {
    var data = employeesList?.data || [];
    data.unshift({ employee_id: 'all', full_name: 'All' });
    setEmployeesData(data);
  }, [employeesList]);
  const refreshFilter = () => {
    setSerchText('');
    setSelectedEmployeeName('all');
    setDate({
      start_ts: moment().startOf('day').unix(),
      end_ts: moment().endOf('day').unix(),
    });
    setResetDate(moment().unix());
    restartFilters();
  };
  useEffect(() => {
    if (contactTracingData?.contact_tracing?.length > 0) {
      contactTracingData?.contact_tracing.map((item) => {
        item['dateValue'] = moment.unix(item.ts).format('DD MMM, YYYY');
        item['timeValue'] = moment.unix(item.ts).format('hh:mm a');
        item['userName'] = item.name != ' ' ? item.name : '-';
        item['phone'] = setPhoneNumber(item['phone']);
      });
      setData(contactTracingData.contact_tracing);
    } else {
      setData([]);
    }
  }, [contactTracingData]);
  var timeout = null;
  useEffect(() => {
    var offsetValue = 0;
    if (pagenumber > 0) {
      offsetValue = pagenumber;
    }
    var queryString = { limit: 25, offset: offsetValue };
    if (serchText) {
      queryString['search_text'] = serchText;
    }
    if (orderBy) {
      queryString['sort_by'] = orderBy;
      queryString['order_by'] = order;
    }
    if (date) {
      queryString['start_date'] = date.start_ts;
      queryString['end_date'] = date.end_ts;
    }
    if (selectedEmployeeName && selectedEmployeeName != 0) {
      queryString['employee_id'] = selectedEmployeeName;
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function () {
      dispatch(fetchContactTracing(queryString));
    }, 500);
    // debounce(()=>dispatch(fetchContactTracing(queryString)))
  }, [serchText, date, selectedEmployeeName, pagenumber, order, orderBy]);

  // useEffect(() => {
  //     var offsetValue = 0;
  //     if (pagenumber > 0) {
  //         offsetValue = pagenumber - 1;
  //     }
  //     var queryString = { limit: 25, offset: offsetValue };
  //     if (serchText) {
  //         queryString["search_text"] = serchText;
  //     }
  //     if (orderBy) {
  //         queryString["sort_by"] = orderBy
  //         queryString["order_by"] = order;
  //     }
  //     if (date) {
  //         queryString["start_date"] = date.start_ts
  //         queryString["end_date"] = date.end_ts;
  //     }
  //     if (timeout) {
  //       clearTimeout(timeout);
  //     }
  //     timeout = setTimeout(function() {
  //       dispatch(fetchContactTracing(queryString))
  //     }, 2000);
  // debounce(()=>dispatch(fetchContactTracing(queryString)))
  // }, [pagenumber, orderBy, order]);

  const restartFilters = () => {
    dispatch(
      fetchContactTracing({
        limit: 25,
        offset: 0,
        start_date: date.start_ts,
        end_date: date.end_ts,
      })
    );
  };
  const onDateChange = (d) => {
    if (
      JSON.stringify(date) !==
      JSON.stringify({ start_ts: d.start_date, end_ts: d.end_date })
    ) {
      setDate({ start_ts: d.start_date, end_ts: d.end_date });
    }
  };

  const [columns, setColumns] = useState([
    {
      field: 'userName',
      display: Context.langData.name,
      size: '20%',
      sort: false,
    },
    {
      field: 'dateValue',
      display: Context.langData.date,
      size: '15%',
      sort: false,
    },
    {
      field: 'timeValue',
      display: Context.langData.time,
      size: '15%',
      sort: false,
    },
    {
      field: 'email',
      display: Context.langData.email_header,
      size: '20%',
      sort: false,
    },
    {
      field: 'phone',
      display: Context.langData.phone,
      size: '15%',
      sort: false,
    },
    {
      field: 'server',
      display: Context.langData.employee,
      size: '15%',
      sort: false,
    },
  ]);

  const sortingOnClick = (name, desc, index) => {
    console.log(name, desc, index, '_____________');
    var data = [...columns];
    data[index] = { ...data[index], sort: !desc };
    setColumns(data);
    setOrder(desc ? 'desc' : 'asc');
    if (name == 'userName') {
      name = 'name';
    }
    if (name == 'timeValue') {
      name = 'time';
    }
    if (name == 'dateValue') {
      name = 'date';
    }
    console.log('name', name, 'desc', desc, 'index', index);
    console.log('queryString1212 setOrderBy', name);
    setOrderBy(name);
  };
  const onPageChange = (value) => {
    setPagenumber(value);
  };
  const onRowClick = (values) => {
    setRow(values);
    setShowBillDetails(true);
  };

  function parseVisitors(visitors) {
    if (typeof visitors !== 'undefined') {
      var obj = {
        total: visitors.length,
        data: visitors,
      };
      obj?.data?.map((item) => {
        item['dateValue'] = moment.unix(item.ts).format('DD MMM, YYYY');
        item['timeValue'] = moment.unix(item.ts).format('hh:mm a');
        item['phone'] = setPhoneNumber(item['phone']);
      });
      console.log(obj.data, 'objobjobjobjobj');
      return obj.data;
    } else {
      return [];
    }
  }

  const download = () => {
    var queryString = { export: true };
    if (serchText) {
      queryString['search_text'] = serchText;
    }
    if (orderBy) {
      queryString['sort_by'] = orderBy;
      queryString['order_by'] = order;
    }
    if (date) {
      queryString['start_date'] = date.start_ts;
      queryString['end_date'] = date.end_ts;
    }
    if (selectedEmployeeName && selectedEmployeeName != 0) {
      queryString['employee_id'] = selectedEmployeeName;
    }
    dispatch(
      fetchContactTracing(queryString, (response) => {
        const parsedData = parseVisitors(response.contact_tracing);
        downloadContactTracing(parsedData);
      })
    );
  };
  return (
    <Grid
      data-qaid="contactTracing_mainContainer"
      container
      style={{ paddingLeft: 20, paddingRight: 0 }}>
      <Grid
        data-qaid="contactTracing_subContainer"
        container
        style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <Grid
          data-qaid="contactTracing_topSearchBarGrid"
          item
          lg={4}
          style={{ marginTop: 25, paddingRight: 10 }}>
          <TopSearchBar
            value={serchText}
            onChange={(e) => setSerchText(e.target.value)}
            placeholder={Context.langData.search_by_customer_name}
            style={{ height: 58 }}
          />
        </Grid>
        <Grid
          data-qaid="contactTracing_dateContainer"
          item
          lg={4}
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Grid data-qaid="contactTracing_dateContainer">
            <AvenirBlackH4
              data-qaid="contactTracing_dateLabel"
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid="contactTracing_dateRange"
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>
          <Grid
            data-qaid="contactTracing_servicesLabelView"
            style={{ marginLeft: 10 }}>
            <AvenirBlackH4
              data-qaid="contactTracing_servicesLabel"
              label={Context.langData.employee}
            />
            <SingleSelect
              list={employeesData}
              valueKey="employee_id"
              displayKey="full_name"
              value={selectedEmployeeName}
              handleChange={(e) => setSelectedEmployeeName(e.target.value)}
              border={false}
              height={56}
              width={100}
            />
          </Grid>
        </Grid>
        <Grid
          data-qaid="contactTracing_resetFiltersLabelView"
          item
          lg={2}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'flex-end',
            marginLeft: 0,
          }}
          onClick={() => refreshFilter()}>
          <RefreshIcon />
          <AvenirBlackH4
            data-qaid="contactTracing_resetFiltersLabel"
            label={Context.langData.reset_filters}
            style={{ marginLeft: 0, marginRight: 10, color: '#004C60' }}
          />
        </Grid>
        <Grid item lg={2}>
          <Button
            onClick={download}
            data-qaid="salesSummary_downlodeButtonGrid"
            style={{
              backgroundColor: '#FFB600',
              height: 40,
              marginRight: 0,
              width: '149px',
            }}
            variant="contained"
            id="cancel">
            <DownlodeIcon
              data-qaid="salesSummary_downlodeIcon"
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid="salesSummary_downlodelabel"
              label={Context.langData.download}
            />
          </Button>
        </Grid>
      </Grid>

      <Grid
        data-qaid="contactTracing_simpleTableView"
        item
        style={{ marginTop: 50, width: '100%' }}
        lg={12}>
        <SimpleTable
          data-qaid="contactTracing_simpleTable"
          columns={columns}
          rows={data}
          total={contactTracingData.total}
          sortingOnClick={sortingOnClick}
          onPageChange={onPageChange}
          isOnClick={true}
          onRowClick={onRowClick}
        />
      </Grid>

      {showBillDetails && (
        <BillDetails
          data-qaid="morelist_createmore"
          open={showBillDetails}
          row={row}
          onClose={() => setShowBillDetails(false)}
        />
      )}
    </Grid>
  );
};

const BillDetails = ({ open, callback, onClose, row }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [labelValue, setLabelValue] = useState('');
  const [id, setId] = useState('');
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [guestIndex, setGuestIndex] = useState(0);
  const [guestDropDownData, setGuestDropDownData] = useState([]);
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  useEffect(() => {
    if (row?.tx_id) {
      dispatch(fetchTransactionDetails({ tx_id: row.tx_id }, fetchCallback));
    }
  }, [row]);
  const fetchCallback = (response) => {
    if (response) {
      var array = [];
      if (response.split_mode == 'all' || !response.split_mode) {
        var obj = { ...response, seat_index: 0 };
        array.push(obj);
        setGuestDropDownData([{ id: 0, values: '0' }]);
      } else {
        if (response.guests?.length > 0) {
          response.guests.map((guestItem, guestIndex) => {
            var itemObj = {};
            if (response.items.length > 0) {
              var itemArray = [];
              var total = 0;
              var total_tax = 0;
              var total_discount = 0;
              var final_price = 0;
              response.items.map((item, itemIndex) => {
                if (item.splits.length > 0) {
                  var guestItemsData = item.splits.filter(
                    (e) => e.guest_id == guestItem.guest_id
                  );
                  if (guestItemsData.length > 0) {
                    var guestObj = {
                      ...item,
                      price: guestItemsData[0].split_price,
                      quantity: guestItemsData[0].split_quantity,
                    };
                    total += guestItemsData[0].split_price;
                    total_tax += guestItemsData[0].split_tax;
                    total_discount += guestItemsData[0].split_discount;
                    final_price += guestItemsData[0].split_final_price;

                    itemArray.push(guestObj);
                  }
                }
              });
              itemObj = {
                ...response,
                items: itemArray,
                total_price: total,
                total_tax: total_tax,
                total_discount: total_discount,
                final_price: final_price,
                seat_index: guestItem.seat_index,
              };
              array.push(itemObj);
            }
          });
        }
      }
      setData(array);
      if (array.length > 0) {
        var GuestArray = [];
        array.map((gItem, GIndex) => {
          GuestArray.push({ id: GIndex, value: gItem.seat_index });
        });
        setGuestDropDownData(GuestArray);
      }
    }
  };
  const save = () => {};

  const onNameChange = (val) => {
    setName(val);
  };
  const onValueChange = (val) => {
    setLabelValue(val);
  };
  return (
    <RightDrawer data-qaid="createcomment_centermodal" open={open} padding={1}>
      <>
        <Grid
          style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
          <Grid
            style={{
              display: 'flex',
              height: '85%',
              flexDirection: 'column',
              overflowX: 'auto',
            }}>
            <Grid
              data-qaid="createcategory_close_grid"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                cursor: 'pointer',
              }}>
              <CircleClose
                data-qaid="createcategory_closeicon"
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>

            <Grid
              data-qaid="createmore_main_grid"
              container
              direction="column"
              style={{
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                width: 500,
              }}>
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}>
                <MonariesLogo
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
                <H2
                  label={selectedRestaurant?.name}
                  style={{ marginTop: 20 }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: 16,
                  }}>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '50%',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}>
                    <H5
                      label={moment
                        .unix(data[guestIndex]?.ts)
                        .format('MM/DD/YYYY hh:mm a')}
                    />
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '50%',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    {guestDropDownData.length > 1 ? (
                      <>
                        <SingleSelect
                          data-qaid="transaction_bill_details_singleselect"
                          list={guestDropDownData}
                          // value={id}
                          handleChange={(e) => {
                            setGuestIndex(e.target.value);
                          }}
                          value={guestIndex}
                          valueKey="id"
                          displayKey="value"
                        />
                      </>
                    ) : (
                      <>
                        <H5 label={Context.langData.all_seats} />
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: 5,
                  }}>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '50%',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}>
                    <H5 label={`${row?.server || '-'}`} />
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '50%',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    <H5
                      label={`${data[guestIndex]?.table?.table_name || '-'}`}
                    />
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: 5,
                  }}>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '50%',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}>
                    <H5
                      label={`${Context.langData.order} #${data[guestIndex]?.tx_id}`}
                    />
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '50%',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    <H5
                      label={`${Context.langData.total_guest}${guestDropDownData?.length}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  borderBottom: '1px solid #000000',
                  marginTop: 16,
                }}
              />
              {data?.length > guestIndex &&
              data[guestIndex]?.items &&
              data[guestIndex]?.items.length > 0 ? (
                data[guestIndex].items.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      style={{ display: 'flex', flexDirection: 'column' }}>
                      <Grid
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          marginTop: 16,
                        }}>
                        <Grid
                          style={{
                            display: 'flex',
                            width: '20%',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}>
                          <H4 label={item.quantity} />
                        </Grid>{' '}
                        <Grid
                          style={{
                            display: 'flex',
                            width: '60%',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}>
                          <H4 label={item.item_name} />
                        </Grid>
                        <Grid
                          style={{
                            display: 'flex',
                            width: '20%',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}>
                          <H4 label={`$${ConvertPrice(item.price)}`} />
                        </Grid>
                      </Grid>
                      {item.addons?.length > 0 &&
                        item.addons.map((addonItem, AddonIndex) => {
                          return (
                            <Grid
                              key={AddonIndex}
                              style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'row',
                                marginTop: 16,
                              }}>
                              <Grid
                                style={{
                                  display: 'flex',
                                  width: '20%',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}></Grid>
                              <Grid
                                style={{
                                  display: 'flex',
                                  width: '60%',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}>
                                <H4 label={addonItem.name} />
                              </Grid>{' '}
                              <Grid
                                style={{
                                  display: 'flex',
                                  width: '20%',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                }}>
                                {/* <H4 label={`$(+${ConvertPrice(addonItem.price)})`} /> */}
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  );
                })
              ) : (
                <></>
              )}
              <Grid
                style={{
                  display: 'flex',
                  borderBottom: '1px solid #000000',
                  marginTop: 16,
                }}
              />
              <Grid
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  marginTop: 16,
                }}>
                <Grid
                  style={{
                    display: 'flex',
                    width: '80%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <H4 label={Context.langData.subtotal} />
                </Grid>{' '}
                <Grid
                  style={{
                    display: 'flex',
                    width: '20%',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                  <H4
                    label={`$ ${ConvertPrice(data[guestIndex]?.total_price)}`}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  marginTop: 16,
                }}>
                <Grid
                  style={{
                    display: 'flex',
                    width: '80%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <H4 label={Context.langData.discount} />
                </Grid>{' '}
                <Grid
                  style={{
                    display: 'flex',
                    width: '20%',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                  <H4
                    label={`$ ${ConvertPrice(
                      data[guestIndex]?.total_discount
                    )}`}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  marginTop: 16,
                }}>
                <Grid
                  style={{
                    display: 'flex',
                    width: '80%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <H4 label={Context.langData.tax} />
                </Grid>{' '}
                <Grid
                  style={{
                    display: 'flex',
                    width: '20%',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                  <H4
                    label={`$ ${ConvertPrice(data[guestIndex]?.total_tax)}`}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  marginTop: 16,
                }}>
                <Grid
                  style={{
                    display: 'flex',
                    width: '80%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <H4 label={'Tip'} />
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    width: '20%',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                  <H4
                    label={`$ ${ConvertPrice(
                      data[guestIndex]?.tip_amount != null
                        ? data[guestIndex]?.tip_amount
                        : '0'
                    )}`}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  marginTop: 16,
                }}>
                <Grid
                  style={{
                    display: 'flex',
                    width: '80%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <AvenirBlackH4 label={Context.langData.grand_total} />
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    width: '20%',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                  <AvenirBlackH4
                    label={`$ ${ConvertPrice(
                      data[guestIndex]?.final_price +
                        data[guestIndex]?.tip_amount
                    )}`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              height: '15%',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}>
            <Grid
              data-qaid="createmore_action_grid"
              container
              style={{ backgroundColor: '#FAFAFA' }}>
              <Grid
                data-qaid="createmore_cancel_btn"
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid="createmore_cancel_button"
                  onClick={onClose}
                  variant="outlined"
                  style={{
                    backgroundColor: '#FFF',
                    marginRight: 16,
                    border: '2px solid #051D33',
                    height: 40,
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid="createmore_cancel_text"
                    label={Context.langData.cancel}
                    style={{ color: '#004C60' }}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid="createmore_save_btn"
                  variant="contained"
                  style={{
                    backgroundColor: '#FFB600',
                    marginRight: 32,
                    height: 40,
                  }}
                  onClick={save}>
                  <AvenirBlackH4
                    data-qaid="createmore_save_text"
                    label={Context.langData.print_bill}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

export default ContactTracing;
