import React from "react";
import { Grid } from "@material-ui/core";
import TaxProfilesList from "./taxProfilesList";


const TaxProfiles = () => {
  return (
    <Grid>
      <TaxProfilesList data-qaid="tax-taxProfilelist"></TaxProfilesList>
    </Grid>
  );
};

export default TaxProfiles;
