import React from 'react';
import { H4 } from '../../../utils/text';
import { Grid } from '@material-ui/core';
import BuilderIcon from '../../../components/SVG/BuilderIcon'
const WebSiteTitle = () => {
  return (
    <Grid container direction="row">
      <BuilderIcon color="#707272" />
      <H4 label="Website builder" style={{ color: "#707272", marginLeft: 5 }} />
    </Grid>
  )
}

export default WebSiteTitle;