import React, { useState, useContext, useMemo } from "react";
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { AvenirBlackH3, H4 } from "../../../../utils/text";
import MyContext from "../../../../Provider/MyContext";
import InfoOutlined from "../../../../components/SVG/InfoOutlined";


const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "#EEE",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  radio: {
    "&$checked": {
      color: "#004C60",
    },
    fontSize: 14,
    "&.MuiFormControlLabel-label.MuiTypography-body1": {
      fontSize: 14,
    },
  },
  checked: {
    color: "#707272",
  },
  root: {
    fontSize: 14,
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  label: {
    fontSize: 14,
  },
  mainDiv: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "2%",
  },
}));
const taxList = [
  { id: "standard", name: "Standard tax" },
  { id: "tax-free", name: "Tax-free" },
  { id: "tax-inclusive", name: "Tax inclusive" },
];

const TaxMode = ({ value, onChange, from }) => {
  const classes = useStyles();
  const Context = useContext(MyContext);
  const showText = useMemo(() => { 
    let text = ""
    if(value === "standard" || value == undefined) {
      text = Context.langData.standard_tax_description
    }else if(value === "tax-free"){
      text = Context.langData.tax_free_description
    }else if(value === "tax-inclusive"){
      text = Context.langData.tax_inclusive_description
    }
    return text 
  })
  return (
    <Grid container className="taxMode ">
      <Grid
        container
        style={{
          marginTop: 30,
          borderBottom: "1px solid #EEE",
          paddingBottom: from === 'combo' ? 0 : 20,
        }}
      >
        <Grid item xs={8}>
          <AvenirBlackH3 label="Tax" />
        </Grid>
      </Grid>
      <RadioGroup
        data-qaid="tax-list"
        row
        aria-label="position"
        name="position"
        value={value ?? "standard"}
        onChange={onChange}
        style={{
          width: "100%",
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
        classes={{
          root: classes.radio,
          label: classes.label,
        }}
      >
        {taxList.length > 0 ? (
          taxList.map((taxType, index) => {
            return (
              <FormControlLabel
                data-qaid="tax-list"
                value={taxType.id}
                control={
                  <Radio
                    key={index}
                    classes={{
                      root: classes.radio,
                      label: classes.label,
                    }}
                  />
                }
                label={taxType.name}
              />
            );
          })
        ) : (
          <Grid></Grid>
        )}
      </RadioGroup>
      <Grid style={{ display: 'flex', flexDirection: 'row', padding: 15, backgroundColor: "#F3FAFD", width: '100%', height: 'auto', alignItems: 'flex-start', borderRadius: 5, marginTop: 24, marginBottom: 32 }}>
        <InfoOutlined width={24}  height={24} color={"#004C60"}/>
        <Grid style={{width: "90%", marginLeft: 10 }}>
          <H4 label={showText} style={{ lineHeight: '24px' }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(TaxMode);
