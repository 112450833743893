import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchKitchenName } from '../../../../redux/actions';
import KitchenList from './kitchensList';

const KitchenCreation = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchKitchenName());
    }, [])
    return (
        <div>
            <KitchenList data-qaid="kitxhens_kitchenList">
            </KitchenList>
        </div>
    );
};

export default KitchenCreation;
