import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import moment from "moment-timezone";
import _ from "lodash";
import {
  H2,
  AvenirBlackH4,
  H5,
  AvenirBlackLightH2,
  H4,
  T,
  H1,
} from "../../../utils/text";
import RightDrawer from "../../../components/RightDrawer";
import CircleClose from "../../../components/SVG/CloseCircle";
import RightArrow from "../../../components/SVG/RightArrow";
import SingleLeftVector from "../../../components/SVG/SingleLeftVector";
import MonariesLogo from "../../../components/SVG/MonariesTextLogo";
import { convertNormalCase, ConvertPrice } from "../../../utils/util";
import styles from "../../../styles/index";
import Cash from "../../../components/SVG/Cash";
import MasterCardIcon from "../../../components/SVG/masterCardIcon";
import GiftCard from "../../../components/SVG/GiftCard";
import DebitCard from "../../../components/SVG/DebitCard";
import { fetchTransactionDetails } from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import SnackBar from "../../../components/SnackBar";
import { fraction, add } from "mathjs";
import VisaIcon from "../../../components/SVG/visaIcon";
import InteracCardIcon from "../../../components/SVG/InteracCardIcon";
import DefaultCreditIcon from "../../../components/SVG/DefaultCreditIcon";
import DiscoverCardIcon from "../../../components/SVG/DiscoverCardIcon";
import AmexCardIcon from "../../../components/SVG/AmexCardIcon";
import ReactToPrint from "react-to-print";

const BillDetails = ({
  open,
  callback,
  onClose,
  row,
  isEnableRefund = false,
}) => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [guestIndex, setGuestIndex] = useState(0);
  const [guestDropDownData, setGuestDropDownData] = useState([]);
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const [snackbar, setSnackbar] = useState(null);
  const [responseData, setResponseData] = useState({});
  const printJobRef = useRef();
  useEffect(() => {
    if (row?.tx_id) {
      dispatch(fetchTransactionDetails({ tx_id: row.tx_id }, fetchCallback));
    }
  }, [row]);
  const fetchCallback = (response) => {
    if (response) {
      //Calculate price of combo item to exclude extra charge from selected combo item
      //Calculate price of item with addon to exclude extra charge from the addon
      response.items.map(function (orderItem, item_idx) {
          orderItem.quantity_faction = orderItem.quantity
          orderItem.combo.map(function (comboItem, combo_item_idx) {
            if (comboItem.price > 0) {
              orderItem.price -= comboItem.price 
              orderItem.total_discount -= comboItem.total_discount
              orderItem.final_price -= comboItem.final_price
              orderItem.total_price -= comboItem.total_price
              orderItem.total_tax -= comboItem.total_tax 
            }
            comboItem.addons.map(function (comboAddonItem, combo_addon_item_idx) {
              if (comboAddonItem.price > 0) {
                comboItem.price -= comboAddonItem.price
                comboItem.final_price -= comboAddonItem.price * comboItem.quantity * comboAddonItem.quantity
                comboItem.total_price -= comboAddonItem.price * comboItem.quantity * comboAddonItem.quantity
              }
            })
          })
          orderItem.addons.map(function (addonItem, addon_item_idx) {
            if (addonItem.price > 0) {
              orderItem.price -= addonItem.price * orderItem.quantity * addonItem.quantity
              orderItem.final_price -= addonItem.price * orderItem.quantity * addonItem.quantity
              orderItem.total_price -= addonItem.price * orderItem.quantity * addonItem.quantity
            }
          })
      })
      setResponseData(response);
      // channel_order_no to be displayed: for kiosk it is kiosk number; for ueat: external_order_id; for ueat intergarted parter: marketplace_order_number
      var channel_order_no =
        response.order_channel_name === "Kiosk"
          ? response.kiosk
          : response.order_channel_name === "Third Party" &&
            response.partner_id == response.integrated_partner_id
          ? response.external_order_id
          : response.marketplace_order_number;

      var array = [];
      if (response.split_mode === "all" || !response.split_mode) {
        var guestRefund = null;
        if (response.refund?.length > 0) {
          response.refund.map((refundObj) => {
            refundObj.refund_payment_details.map((paymentItem) => {
              var arrayList = response.payments.filter(
                (e) =>
                  e.order_payment_detail_id ==
                  paymentItem.order_payment_detail_id
              );
              if (arrayList.length > 0) {
                paymentItem["ptype"] = arrayList[0].ptype;
                paymentItem["ptype_id"] = arrayList[0].ptype_id;
                paymentItem["masked_number"] = arrayList[0].masked_number;
                paymentItem["payment_card_type_code"] =
                  arrayList[0].payment_card_type_code;
              }
            });
          });
          guestRefund = response.refund;
        }
        var guestArray = response.guests;
        if (guestArray.length > 0 && response.payments.length > 0) {
          guestArray[0].order_payment_id =
            response.payments[0].order_payment_id;
        }
        var obj = {
          ...response,
          seat_index: 0,
          refund: guestRefund,
          guests: guestArray,
          paymentList: response.payments,
          channel_order_no: channel_order_no,
        };
        array.push(obj);
        setGuestDropDownData([{ id: 0, value: "0" }]);
      } else if (response.split_mode === "even") {
        let total_guests = response.guests.length;
        response.guests.map((guestItem) => {
          var obj = {
            ...response,
            total_price: response.total_price / total_guests,
            total_tax: response.total_tax / total_guests,
            total_discount: response.total_discount / total_guests,
            final_price: response.final_price / total_guests,
            delivery_charges: response.delivery_charges / total_guests,
            packaging_charges: response.packaging_charges / total_guests,
            tip_amount: response.tip_amount / total_guests,
            sub_total: response.sub_total / total_guests,
          };
          let itemsArray = [];
          if (response.items.length > 0) {
            response.items.map((item) => {
              let quantity_faction = item.quantity;
              if (item.quantity % total_guests === 0) {
                quantity_faction = item.quantity / total_guests;
              } else {
                let frc = fraction(item.quantity);
                quantity_faction = `${frc.n}/${total_guests}`;
              }
              let comboArray = [];
              item.combo.map((comboItem) => {
                let quantity_faction = comboItem.quantity;
                if (comboItem.quantity % total_guests === 0) {
                  quantity_faction = comboItem.quantity / total_guests;
                } else {
                  let frc = fraction(comboItem.quantity);
                  quantity_faction = `${frc.n}/${total_guests}`;
                }
                let comboItemObj = {
                  ...comboItem,
                  price: comboItem.price,
                  total_tax: comboItem.total_tax / total_guests,
                  total_discount: comboItem.total_discount / total_guests,
                  final_price: comboItem.final_price / total_guests,
                  total_price: comboItem.total_price / total_guests,
                  quantity: comboItem.quantity / total_guests,
                  quantity_faction: quantity_faction,
                };
                comboArray.push(comboItemObj);
              });
              let itemObj = {
                ...item,
                price: item.price / total_guests,
                total_tax: item.total_tax / total_guests,
                total_discount: item.total_discount / total_guests,
                final_price: item.final_price / total_guests,
                total_price: item.total_price / total_guests,
                quantity: item.quantity / total_guests,
                quantity_faction: quantity_faction,
                combo: comboArray,
              };
              itemsArray.push(itemObj);
            });
          }
          obj["items"] = itemsArray;
          var guestRefund = null;
          var paymentList = [];
          if (response.refund?.length > 0) {
            var guestrefundArray = response.refund.filter(
              (e) => e.order_payment_id == guestItem.order_payment_id
            );
            if (guestrefundArray?.length > 0) {
              guestrefundArray.map((refundObj) => {
                refundObj?.refund_payment_details?.map((paymentItem) => {
                  var arrayList = response.payments.filter(
                    (e) =>
                      e.order_payment_detail_id ==
                      paymentItem.order_payment_detail_id
                  );
                  if (arrayList.length > 0) {
                    paymentItem["ptype"] = arrayList[0].ptype;
                    paymentItem["ptype_id"] = arrayList[0].ptype_id;
                    paymentItem["masked_number"] = arrayList[0].masked_number;
                    paymentItem["payment_card_type_code"] =
                      arrayList[0].payment_card_type_code;
                  }
                });
              });
              guestRefund = guestrefundArray;
            }
          }
          if (response.payments?.length > 0) {
            paymentList = response.payments.filter(
              (e) => e.order_payment_id == guestItem.order_payment_id
            );
          }

          obj = {
            ...obj,
            refund: guestRefund,
            paymentList: paymentList,
            seat_index: guestItem.seat_index,
            order_payment_id: guestItem.order_payment_id,
            channel_order_no: channel_order_no,
          };
          array.push(obj);
        });
      } else {
        if (response.guests?.length > 0) {
          let guestsByPay = [];
          if (response.payments?.length > 0) {
            response.payments.map((pay) => {
              guestsByPay = response.guests
                .filter((e) => e.order_payment_id == pay.order_payment_id)
                .map((e) => e.guest_id);
            });
          }
          response.guests.map((guestItem, guestIndex) => {
            var itemObj = {};
            if (response.items.length > 0) {
              var itemArray = [];
              var total = 0;
              var total_tax = 0;
              var total_discount = 0;
              var final_price = 0;
              response.items?.map((item) => {
                if (item.splits.length > 0) {
                  var guestItemsData = item.splits.filter(
                    (e) => e.guest_id == guestItem.guest_id
                  );
                  if (guestItemsData.length > 0) {
                    var guestObj = {
                      ...item,
                      total_price: guestItemsData[0].split_price,
                      quantity: guestItemsData[0].split_quantity,
                      total_tax: guestItemsData[0].split_tax,
                      final_price: guestItemsData[0].split_final_price,
                    };
                    total += guestItemsData[0].split_price;
                    total_tax += guestItemsData[0].split_tax;
                    total_discount += guestItemsData[0].split_discount;
                    final_price += guestItemsData[0].split_final_price;
                    itemArray.push(guestObj);
                  }
                }
              });
              var guestRefund = null;
              var paymentList = [];
              if (response.refund?.length > 0) {
                var guestrefundArray = response.refund.filter(
                  (e) => e.order_payment_id == guestItem.order_payment_id
                );
                if (guestrefundArray?.length > 0) {
                  guestrefundArray.map((refundObj) => {
                    refundObj?.refund_payment_details?.map((paymentItem) => {
                      var arrayList = response.payments.filter(
                        (e) =>
                          e.order_payment_detail_id ==
                          paymentItem.order_payment_detail_id
                      );
                      if (arrayList.length > 0) {
                        paymentItem["ptype"] = arrayList[0].ptype;
                        paymentItem["ptype_id"] = arrayList[0].ptype_id;
                        paymentItem["masked_number"] =
                          arrayList[0].masked_number;
                        paymentItem["payment_card_type_code"] =
                          arrayList[0].payment_card_type_code;
                      }
                    });
                  });
                  guestRefund = guestrefundArray;
                }
              }
              if (response.payments?.length > 0) {
                paymentList = response.payments.filter(
                  (e) => e.order_payment_id == guestItem.order_payment_id
                );
              }
              itemObj = {
                ...response,
                items: itemArray,
                total_price: total,
                total_tax: total_tax,
                total_discount: total_discount,
                final_price: final_price,
                seat_index: guestItem.seat_index,
                refund: guestRefund,
                paymentList: paymentList,
                order_payment_id: guestItem.order_payment_id,
              };
              if (itemObj.items.length > 0) array.push(itemObj);
            }
          });
        }
      }

      let group = _.groupBy(array, function (b) {
        return b.order_payment_id;
      });

      let temp = [];
      try {
        Object.entries(group).map(([, gItem]) => {
          if (gItem.length == 1) {
            temp.push(gItem[0]);
          } else {
            let obj = {
              ...gItem[0],
            };
            gItem.map((groupItem, index) => {
              if (index > 0) {
                obj = {
                  ...obj,
                  total_price: obj.total_price + groupItem.total_price,
                  tip_amount: obj.tip_amount + groupItem.tip_amount,
                  total_tax: obj.total_tax + groupItem.total_tax,
                  total_discount: obj.total_discount + groupItem.total_discount,
                  final_price: obj.final_price + groupItem.final_price,
                  delivery_charges:
                    obj.delivery_charges + groupItem.delivery_charges,
                  packaging_charges:
                    obj.packaging_charges + groupItem.packaging_charges,
                };
                let items = [];
                groupItem.items.map((itm) => {
                  let f = obj.items.find(
                    (e) => e.order_item_id == itm.order_item_id
                  );
                  let itemObj = { ...itm };
                  if (f) {
                    let frc = add(
                      fraction(itemObj.quantity),
                      fraction(f.quantity)
                    );
                    itemObj = {
                      ...itemObj,
                      price: itemObj.price + f.price,
                      total_tax: itemObj.total_tax + f.total_tax,
                      total_discount: itemObj.total_discount + f.total_discount,
                      final_price: itemObj.final_price + f.final_price,
                      total_price: itemObj.total_price + f.total_price,
                      quantity: frc.n + "/" + frc.d,
                    };
                  }
                  items.push(itemObj);
                });
                obj["items"] = items;
              }
            });
            temp.push(obj);
          }
        });
      } catch (e) {
        console.error(e);
      }
      setData(temp);
      if (temp.length > 0) {
        let guestArray = temp.map((gItem, GIndex) => {
          return {
            id: GIndex,
            value: gItem.seat_index,
          };
        });
        setGuestDropDownData(guestArray);
      }
    }
  };
  const save = () => {};

  const getSeatIndexValue = (value) => {
    var seatName = "";
    if (guestDropDownData.length > 0) {
      var filterList = guestDropDownData.filter((e) => e.id == value);
      if (filterList.length > 0) {
        seatName = filterList[0].value;
      }
    }
    return seatName;
  };
  const decreaseSeatIndex = () => {
    if (guestIndex != 0) {
      setGuestIndex(guestIndex - 1);
    }
  };
  const increaseSeatIndex = () => {
    if (guestIndex != guestDropDownData.length - 1) {
      setGuestIndex(guestIndex + 1);
    }
  };
  const handlerefundClick = () => {
    if (data.length > guestIndex) {
      var isFullRedound = false;
      var obj = {};
      obj["tx_id"] = data[guestIndex].tx_id;
      data[guestIndex].items?.map((i) => {
        var itemPrice =
          parseFloat(
            (i.final_price - (i.refunded_amount || 0)) * i.total_price
          ) / parseFloat(i.total_price + (i.total_tax || 0));
        i["itemId"] = i.order_item_id;
        i["itemName"] =
          i.is_refunded == 0
            ? i.item_name + " - $" + ConvertPrice(i.total_price)
            : ConvertPrice(i.final_price - (i.refunded_amount || 0)) <= 0
            ? i.item_name + " - $" + ConvertPrice(i.total_price)
            : i.item_name + " - $" + ConvertPrice(itemPrice);
        i["isrefunded"] = i.is_refunded == 1 ? true : false;
        i["isChecked"] = false;
        i["isAmount"] = true;
        i["refunded_amount"] = i.refunded_amount;
        i["amount"] = 0;
        i["percentage"] = 0;
        i["quantity"] = i.quantity;
        i["total_price"] = i.total_price;
        i["total_tax"] = i.total_tax;
        i["final_price"] = i.final_price;
        i["isError"] = false;
      });
      obj["items"] = data[guestIndex].items;
      obj["payments"] = data[guestIndex].payments;
      obj["final_price"] = data[guestIndex].final_price;
      obj["total_price"] = data[guestIndex].total_price;
      obj["total_tax"] = data[guestIndex].total_tax;
      obj["refund"] = data[guestIndex].refund;
      obj["is_gift_card"] = 0;
      obj["gift_comment"] = null;
      obj["order_payment_id"] =
        data[guestIndex].guests[guestIndex]?.order_payment_id;
      dispatch({ type: "SET_TRANSACTION_REFUND", payload: obj });
      if (data[guestIndex].refund) {
        if (data[guestIndex].refund?.refund_type == "FullRefund") {
          isFullRedound = true;
        }
        if (
          data[guestIndex].final_price <=
          (data[guestIndex].refund?.reduce((a, b) => a + b.refund_amount, 0) ||
            0)
        ) {
          isFullRedound = true;
        }
      } else {
      }
      if (isFullRedound) {
        setSnackbar({
          message: Context.langData.fully_refunded,
          severity: "error",
        });
      } else {
        callback();
      }
    }
  };

  const creditCardFilter = (item) => {
    switch (item.payment_card_type_code?.toLowerCase()) {
      case "mastercard":
        return <MasterCardIcon style={{ marginRight: 10 }} />;
      case "visa":
        return <VisaIcon style={{ marginRight: 10 }} />;
      case "discover":
        return <DiscoverCardIcon style={{ marginRight: 10 }} />;
      case "amex":
        return <AmexCardIcon style={{ marginRight: 10 }} />;
      case "interac":
        return <InteracCardIcon style={{ marginRight: 10 }} />;
      default:
        return <DefaultCreditIcon style={{ marginRight: 10 }} />;
    }
  };
  const paymentTypeView = (item) => {
    switch (item.ptype.toLowerCase()) {
      case "cash":
        return (
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <Cash style={{ marginRight: 10 }} />
            <T label={`${item.ptype}`} />
          </Grid>
        );
      case "credit":
        return (
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              {creditCardFilter(item)}
              <T
                label={`${item.ptype}(****${
                  item.masked_number?.length > 4
                    ? item.masked_number.substring(
                        item.masked_number.length - 4,
                        item.masked_number.length
                      )
                    : item.masked_number
                    ? item.masked_number
                    : "0000"
                })`}
                style={{
                  paddingTop:
                    item.payment_card_type_code?.toLowerCase() == "visa"
                      ? "3px"
                      : 0,
                }}
              />
            </Grid>
          </Grid>
        );
      case "gift":
        return (
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <GiftCard style={{ marginRight: 10 }} />
            <T
              label={`${item.ptype}(****${
                item.masked_number ? item.masked_number : "0000"
              })`}
            />
          </Grid>
        );
      case "debit":
        return (
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <DebitCard style={{ marginRight: 10 }} />
            <T
              label={`${item.ptype}(****${
                item.masked_number?.length > 4
                  ? item.masked_number.substring(
                      item.masked_number.length - 4,
                      item.masked_number.length
                    )
                  : item.masked_number
                  ? item.masked_number
                  : "0000"
              })`}
            />
          </Grid>
        );
      default:
        return (
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <Cash style={{ marginRight: 10 }} />
            <T label={`${item.ptype}`} />
          </Grid>
        );
    }
  };
  const isDisableRefund = useMemo(() => {
    if (row.void == 0) {
      if (data.length > 0) {
        let payments = data[guestIndex].paymentList;
        if (payments.length > 0) {
          const payment_medium = payments[0].payment_medium;
          if (payment_medium == "MonerisCheckout") {
            return false;
          }
        }
      }
    }
    return true;
  }, [data, guestIndex, row]);
  const voidedValue = data[0]?.void == 1 ? true : false;
  // let fAmount = `$${ConvertPrice((data[guestIndex]?.total_price - data[guestIndex]?.total_discount ) + data[guestIndex]?.total_tax + data[guestIndex]?.paymentList[0]?.tip_amount )}`
  return (
    <RightDrawer data-qaid='createcomment_centermodal' open={open} padding={1}>
      <>
        <Grid
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
                marginTop: 20,
              }}>
              <Grid
                style={{
                  display: "flex",
                  width: "60%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}>
                {guestDropDownData.length > 1 ? (
                  <>
                    <SingleLeftVector onClick={() => decreaseSeatIndex()} />
                    <AvenirBlackLightH2
                      label={`Bill ${guestIndex + 1} of ${
                        guestDropDownData.length
                      }`}
                      style={{ marginLeft: 21, marginRight: 21 }}
                    />
                    <RightArrow onClick={() => increaseSeatIndex()} />
                  </>
                ) : null}
              </Grid>
              <Grid
                data-qaid='createcategory_close_grid'
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "40%",
                  alignItems: "center",
                }}>
                <CircleClose
                  data-qaid='createcategory_closeicon'
                  size={20}
                  onClick={onClose}
                  style={{ marginRight: 40, cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Grid
              ref={printJobRef}
              data-qaid='transactions_billdetails_main_grid'
              container
              direction='column'
              style={{
                marginTop: 20,
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                width: 500,
              }}>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}>
                <MonariesLogo
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <H2
                  label={selectedRestaurant?.name}
                  style={{ marginTop: 20 }}
                />
              </Grid>
              {responseData?.order_channel_name === "Third Party" ||
              responseData?.order_channel_name === "Kiosk" ? (
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginTop: 16,
                    }}>
                    <Grid
                      style={{
                        display: "flex",
                        width: "50%",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}>
                      <H5
                        label={moment
                          .unix(data[guestIndex]?.ts)
                          .format("MM/DD/YYYY hh:mm a")}
                      />
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        width: "50%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}>
                      <H5 label={responseData.otype_name} />
                    </Grid>
                  </Grid>
                  {responseData?.delivery_mode === "Delivery" && (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 5,
                      }}>
                      <Grid
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}>
                        <H5 label={responseData.delivery.customer_name} />
                      </Grid>

                      <Grid
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}>
                        <H5
                          label={`${Context.langData.order} #${data[guestIndex]?.bill_no}`}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {responseData?.delivery_mode === "Takeaway" && (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 5,
                      }}>
                      <Grid
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}>
                        <H5 label={responseData?.customers[0]?.customer_name} />
                      </Grid>

                      <Grid
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}>
                        <H5
                          label={`${Context.langData.order} #${data[guestIndex]?.bill_no}`}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {responseData?.delivery_mode === "EatIn" && (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 5,
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}>
                      <H5
                        label={`${Context.langData.order} #${data[guestIndex]?.bill_no}`}
                      />
                    </Grid>
                  )}

                  {responseData?.delivery_mode === "Delivery" && (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 5,
                      }}>
                      <Grid
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}>
                        <H5 label={responseData.delivery.phone_number} />
                      </Grid>

                      <Grid
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}>
                        <H5 label={row.channel_order_no} />
                      </Grid>
                    </Grid>
                  )}
                  {responseData?.delivery_mode === "Takeaway" && (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 5,
                      }}>
                      <Grid
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}>
                        <H5 label={responseData?.customers[0]?.phone_number} />
                      </Grid>

                      <Grid
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}>
                        <H5 label={row.channel_order_no} />
                      </Grid>
                    </Grid>
                  )}
                  {responseData?.delivery_mode === "EatIn" && (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 5,
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}>
                      <H5 label={row.channel_order_no} />
                    </Grid>
                  )}

                  {responseData?.delivery_mode === "Delivery" && (
                    <>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          marginTop: 5,
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}>
                        <H5 label={responseData?.delivery?.address} />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          marginTop: 5,
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}>
                        <H5
                          label={`${Context.langData.delivery_notes} ${responseData?.delivery?.delivery_instructions}`}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginTop: 16,
                    }}>
                    <Grid
                      style={{
                        display: "flex",
                        width: "50%",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}>
                      <H5
                        label={moment
                          .unix(data[guestIndex]?.ts)
                          .format("MM/DD/YYYY hh:mm a")}
                      />
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        width: "50%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}>
                      {guestDropDownData.length > 1 ? (
                        <H5 label={getSeatIndexValue(guestIndex)} />
                      ) : (
                        <>
                          <H5
                            label={
                              data[guestIndex]?.takeaway_order_type != null
                                ? data[guestIndex]?.otype_name
                                : Context.langData.all_seats
                            }
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginTop: 5,
                    }}>
                    <Grid
                      style={{
                        display: "flex",
                        width: "50%",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}>
                      <H5 label={`${row?.server_name || "-"}`} />
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        width: "50%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}>
                      <H5
                        label={
                          data[guestIndex]?.takeaway_order_type != null
                            ? convertNormalCase(
                                data[guestIndex]?.takeaway_order_type
                              )
                            : `${data[guestIndex]?.table?.table_name || "-"}`
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginTop: 5,
                    }}>
                    <Grid
                      style={{
                        display: "flex",
                        width: "50%",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}>
                      <H5
                        label={`${Context.langData.order} #${data[guestIndex]?.bill_no}`}
                      />
                    </Grid>
                    {!(data[guestIndex]?.takeaway_order_type != null) && (
                      <Grid
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}>
                        <H5
                          label={`${Context.langData.total_guest}${guestDropDownData?.length}`}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid
                style={{
                  display: "flex",
                  borderBottom: "1px solid #000000",
                  marginTop: 16,
                }}
              />
              {data?.length > guestIndex &&
                data[guestIndex]?.items &&
                data[guestIndex]?.items.length > 0 &&
                data[guestIndex].items.map((item, index) => {
                  return (
                    // each item
                    <Grid
                      key={index}
                      style={{ display: "flex", flexDirection: "column" }}>
                      <Grid
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "row",
                          marginTop: 16,
                        }}>
                        {/* item quantity */}
                        <Grid
                          style={{
                            display: "flex",
                            width: "20%",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            textDecorationLine: voidedValue
                              ? "line-through"
                              : null,
                          }}>
                          <H4 label={item.quantity_faction} />
                        </Grid>
                        {/* item name */}
                        <Grid
                          style={{
                            display: "flex",
                            width: "60%",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            textDecorationLine: voidedValue
                              ? "line-through"
                              : null,
                          }}>
                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}>
                            <H4
                              label={item.item_name}
                              style={{ wordBreak: "break-all" }}
                            />
                          </Grid>
                        </Grid>
                        {/* price */}
                        <Grid
                          style={{
                            display: "flex",
                            width: "20%",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            textDecorationLine: voidedValue
                              ? "line-through"
                              : null,
                          }}>
                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}>
                            <H4 label={`$${ConvertPrice(item.total_price)}`} />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* addons */}
                      {item.addons?.length > 0 &&
                        item.addons.map((addonItem, AddonIndex) => {
                          return (
                            <Grid
                              key={AddonIndex}
                              style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                marginTop: 16,
                              }}>
                              <Grid
                                style={{
                                  display: "flex",
                                  width: "20%",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}></Grid>
                              <Grid
                                style={{
                                  display: "flex",
                                  width: "60%",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  textDecorationLine: voidedValue
                                    ? "line-through"
                                    : null,
                                  marginLeft: 16,
                                }}>
                                <H4
                                  label={`${addonItem.name} (${addonItem.quantity})`}
                                  style={{ wordBreak: "break-all" }}
                                />
                              </Grid>
                              <Grid
                                style={{
                                  display: "flex",
                                  width: "20%",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  textDecorationLine: voidedValue
                                    ? "line-through"
                                    : null,
                                }}>
                                {addonItem.price > 0 && (
                                  <H4
                                    label={`$${ConvertPrice(
                                      addonItem.price *
                                        addonItem.quantity *
                                        item.quantity
                                    )}`}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          );
                        })}
                      {item.combo?.length > 0 &&
                        item.combo.map((comboItem, ComboIndex) => {
                          return (
                            <Grid>
                              <Grid
                                key={ComboIndex}
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  flexDirection: "row",
                                  marginTop: 16,
                                }}>
                                <Grid
                                  style={{
                                    display: "flex",
                                    width: "20%",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}></Grid>
                                <Grid
                                  style={{
                                    display: "flex",
                                    width: "60%",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    textDecorationLine: voidedValue
                                      ? "line-through"
                                      : null,
                                    marginLeft: 16,
                                  }}>
                                  <H4
                                    label={`${comboItem.item_name}`}
                                    style={{ wordBreak: "break-all" }}
                                  />
                                </Grid>
                                <Grid
                                  style={{
                                    display: "flex",
                                    width: "20%",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    textDecorationLine: voidedValue
                                      ? "line-through"
                                      : null,
                                  }}>
                                  {comboItem.price > 0 && (
                                    <H4
                                      label={`$${ConvertPrice(
                                        comboItem.price * comboItem.quantity
                                      )}`}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                              {comboItem.addons?.length > 0 &&
                                comboItem.addons.map(
                                  (comboAddonItem, comboAddonIndex) => {
                                    return (
                                      <Grid
                                        key={comboAddonIndex}
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          flexDirection: "row",
                                          marginTop: 16,
                                        }}>
                                        <Grid
                                          style={{
                                            display: "flex",
                                            width: "20%",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                          }}></Grid>
                                        <Grid
                                          style={{
                                            display: "flex",
                                            width: "60%",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            textDecorationLine: voidedValue
                                              ? "line-through"
                                              : null,
                                            marginLeft: 32,
                                          }}>
                                          <H4
                                            label={`${comboAddonItem.name} (${comboAddonItem.quantity})`}
                                            style={{ wordBreak: "break-all" }}
                                          />
                                        </Grid>
                                        <Grid
                                          style={{
                                            display: "flex",
                                            width: "20%",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                            textDecorationLine: voidedValue
                                              ? "line-through"
                                              : null,
                                          }}>
                                          {comboAddonItem.price > 0 && (
                                            <H4
                                              label={`$${ConvertPrice(
                                                comboAddonItem.price *
                                                  comboAddonItem.quantity *
                                                  comboItem.quantity
                                              )}`}
                                            />
                                          )}
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                )}
                            </Grid>
                          );
                        })}
                      {/* Item Discount */}
                      {item.discount && (
                        <Grid
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            marginTop: 16,
                          }}>
                          <Grid
                            style={{
                              display: "flex",
                              width: "20%",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              textDecorationLine: voidedValue
                                ? "line-through"
                                : null,
                            }}></Grid>
                          {/* discount name */}
                          <Grid
                            style={{
                              display: "flex",
                              width: "60%",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              textDecorationLine: voidedValue
                                ? "line-through"
                                : null,
                            }}>
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}>
                              <H4
                                label={`${item.discount.promotion_name}`}
                                style={{ wordBreak: "break-all" }}
                              />
                            </Grid>
                          </Grid>
                          {/* discount price */}
                          <Grid
                            style={{
                              display: "flex",
                              width: "20%",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              textDecorationLine: voidedValue
                                ? "line-through"
                                : null,
                            }}>
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}>
                              <H4
                                label={`-$${ConvertPrice(
                                  item.discount.amount
                                )}`}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}

              <Grid
                style={{
                  display: "flex",
                  borderBottom: "1px solid #000000",
                  marginTop: 16,
                }}
              />
              {data[guestIndex]?.delivery_charges > 0 && (
                <Grid
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    marginTop: 16,
                  }}>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '40%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}></Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '40%',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}>
                    <H4 label={Context.langData.delivery_charges} />
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      width: '20%',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      textDecorationLine: voidedValue ? 'line-through' : null,
                    }}>
                    <H4
                      label={`$${ConvertPrice(data[guestIndex]?.delivery_charges)}`}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  marginTop: 16,
                }}>
                <Grid
                  style={{
                    display: "flex",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}></Grid>
                <Grid
                  style={{
                    display: "flex",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}>
                  <H4 label={Context.langData.subtotal} />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    width: "20%",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    textDecorationLine: voidedValue ? "line-through" : null,
                  }}>
                  <H4 label={`$${ConvertPrice(data[guestIndex]?.sub_total)}`} />
                </Grid>
              </Grid>
              {data[guestIndex]?.discounts?.length > 0 &&
                data[guestIndex]?.discounts.map((discountItem) => (
                  <Grid
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      marginTop: 16,
                    }}>
                    <Grid
                      style={{
                        display: "flex",
                        width: "40%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}></Grid>
                    <Grid
                      style={{
                        display: "flex",
                        width: "40%",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}>
                      <H4 label={discountItem.promotion_name} />
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        width: "20%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        textDecorationLine: voidedValue ? "line-through" : null,
                      }}>
                      <H4 label={`-$${ConvertPrice(discountItem.amount)}`} />
                    </Grid>
                  </Grid>
                ))}
              {data[guestIndex]?.total_tax > 0 && (
                <Grid
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    marginTop: 16,
                  }}>
                  <Grid
                    style={{
                      display: "flex",
                      width: "40%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}></Grid>
                  <Grid
                    style={{
                      display: "flex",
                      width: "40%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}>
                    <H4 label={Context.langData.tax} />
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      width: "20%",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      textDecorationLine: voidedValue ? "line-through" : null,
                    }}>
                    <H4
                      label={`$${ConvertPrice(data[guestIndex]?.total_tax)}`}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}>
                <Grid
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    paddingTop: 16,
                    borderTop: "1px solid #EEEEEE",
                    paddingBottom: 16,
                  }}>
                  <Grid
                    style={{
                      display: "flex",
                      width: "40%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}></Grid>
                  <Grid
                    style={{
                      display: "flex",
                      width: "40%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}>
                    <AvenirBlackH4 label={Context.langData.grand_total} />
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      width: "20%",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      textDecorationLine: voidedValue ? "line-through" : null,
                    }}>
                    <AvenirBlackH4
                      label={`$${ConvertPrice(data[guestIndex]?.final_price)}`}
                    />
                  </Grid>
                </Grid>
                {/* Tips */}
                {data[guestIndex]?.tip_amount > 0 &&
                  data[guestIndex]?.paymentList.map((pItem) => {
                    return (
                      <>
                        {data[guestIndex]?.paymentList[0]?.tip_amount !== 0 &&
                          pItem.ptype != "Cash" && (
                            <Grid
                              style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                borderBottom: "1px solid #EEEEEE",
                                paddingBottom: 16,
                              }}>
                              <Grid
                                style={{
                                  display: "flex",
                                  width: "40%",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}></Grid>
                              <Grid
                                style={{
                                  display: "flex",
                                  width: "40%",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}>
                                <H4 label={Context.langData.tip} />
                              </Grid>
                              <Grid
                                style={{
                                  display: "flex",
                                  width: "20%",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  textDecorationLine: voidedValue
                                    ? "line-through"
                                    : null,
                                }}>
                                <H4
                                  label={`$${ConvertPrice(
                                    data[guestIndex]?.paymentList[0]?.tip_amount
                                  )}`}
                                />
                              </Grid>
                            </Grid>
                          )}
                      </>
                    );
                  })}

                {data[guestIndex]?.paymentList &&
                  data[guestIndex]?.paymentList.length > 0 &&
                  data[guestIndex]?.paymentList.map((paymetItem) => {
                    return (
                      <Grid
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "row",
                          paddingTop: 4,
                          paddingBottom: 16,
                        }}>
                        <Grid
                          style={{
                            display: "flex",
                            width: "45%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}></Grid>
                        <Grid
                          style={{
                            display: "flex",
                            width: "35%",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}>
                          {paymentTypeView(paymetItem)}
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            width: "20%",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}>
                          <T
                            label={
                              paymetItem.ptype != "Cash"
                                ? `$${ConvertPrice(
                                    paymetItem.amount +
                                      data[guestIndex]?.paymentList[0]
                                        ?.tip_amount
                                  )}`
                                : `$${ConvertPrice(paymetItem.amount)}`
                            }
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
              {data[0]?.void == 1 && (
                <Grid
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}>
                  <Grid
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}>
                    <H1
                      label={`Reason: ${
                        data[0]?.void_type == "void"
                          ? "Voided order"
                          : "Closed without payment"
                      }`}
                    />
                  </Grid>
                </Grid>
              )}
              {data[guestIndex]?.refund?.length > 0 && (
                <Grid style={{ display: "flex", flexDirection: "column" }}>
                  <Grid
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      paddingTop: 16,
                      borderTop: "1px solid #EEEEEE",
                    }}>
                    <Grid
                      style={{
                        display: "flex",
                        width: "40%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}></Grid>
                    <Grid
                      style={{
                        display: "flex",
                        width: "40%",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}>
                      <AvenirBlackH4 label={Context.langData.refund_applied} />
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        width: "20%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}>
                      <AvenirBlackH4
                        label={`-$ ${ConvertPrice(
                          data[guestIndex]?.refund.reduce(
                            (a, b) => a + b.refund_amount,
                            0
                          )
                        )}`}
                      />
                    </Grid>
                  </Grid>
                  {data[guestIndex]?.refund.map((refundItemObj) => {
                    return (
                      <>
                        <Grid
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                          }}>
                          <Grid
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                            }}>
                            <Grid
                              style={{
                                display: "flex",
                                width: "40%",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}></Grid>
                            <Grid
                              style={{
                                display: "flex",
                                width: "40%",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}>
                              <H5
                                label={`(${moment
                                  .unix(refundItemObj.ts)
                                  .format("DD/MM/YYYY")})`}
                                style={{ color: "#54575A", marginTop: 8 }}
                              />
                            </Grid>
                            <Grid
                              style={{
                                display: "flex",
                                width: "20%",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}></Grid>
                          </Grid>
                          <Grid
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                            }}>
                            <Grid
                              style={{
                                display: "flex",
                                width: "40%",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}></Grid>
                            <Grid
                              style={{
                                display: "flex",
                                width: "40%",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}>
                              <H5
                                label={`By${refundItemObj.employee_name}`}
                                style={{ color: "#54575A", marginTop: 8 }}
                              />
                            </Grid>
                            <Grid
                              style={{
                                display: "flex",
                                width: "20%",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}></Grid>
                          </Grid>
                          <Grid
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                            }}>
                            <Grid
                              style={{
                                display: "flex",
                                width: "40%",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}></Grid>
                            <Grid
                              style={{
                                display: "flex",
                                width: "40%",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}>
                              <H5
                                label={`${Context.langData.reason}:${refundItemObj.refund_reason}`}
                                style={{ color: "#54575A", marginTop: 8 }}
                              />
                            </Grid>
                            <Grid
                              style={{
                                display: "flex",
                                width: "20%",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}></Grid>
                          </Grid>
                        </Grid>
                        {refundItemObj?.refund_payment_details?.length > 0
                          ? refundItemObj.refund_payment_details.map((item) => {
                              return (
                                <Grid
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                  }}>
                                  <Grid
                                    style={{
                                      display: "flex",
                                      width: "45%",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}></Grid>
                                  <Grid
                                    style={{
                                      display: "flex",
                                      width: "35%",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                    }}>
                                    {paymentTypeView(item)}
                                  </Grid>
                                  <Grid
                                    style={{
                                      display: "flex",
                                      width: "20%",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}>
                                    <T
                                      label={`- $${ConvertPrice(
                                        item.refund_amount
                                      )}`}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            })
                          : null}
                      </>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='transactions_billdetails_action_grid'
              container
              style={{ backgroundColor: "#FAFAFA" }}>
              <Grid
                data-qaid='transactions_billdetails_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                {isEnableRefund && !isDisableRefund && (
                  <Button
                    className={style.buttonClass}
                    data-qaid='transactions_billdetails_cancel_button'
                    onClick={() => {
                      handlerefundClick();
                    }}
                    variant='outlined'
                    style={{
                      backgroundColor: "#FFFFFF",
                      marginRight: 16,
                      border: "2px solid #707272",
                      width: 154,
                    }}>
                    <AvenirBlackH4
                      data-qaid='transactions_billdetails_cancel_text'
                      label={Context.langData.process_refund}
                      style={{ color: "#004C60" }}
                    />
                  </Button>
                )}

                <ReactToPrint
                  trigger={() => (
                    <Button
                      className={style.buttonClass}
                      data-qaid='transactions_billdetails_save_btn'
                      variant='contained'
                      style={{
                        backgroundColor: "#FFB600",
                        marginRight: 32,
                        height: 40,
                      }}>
                      <AvenirBlackH4
                        data-qaid='transactions_billdetails_save_text'
                        label={Context.langData.print_bill}
                      />
                    </Button>
                  )}
                  content={() => printJobRef.current}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {snackbar && (
          <SnackBar
            data-qaid='categories_snackbar'
            open={true}
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
      </>
    </RightDrawer>
  );
};

export default BillDetails;
