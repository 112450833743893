import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core'
import BackIcon from '../../../components/SVG/Back'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useNavigate } from 'react-router-dom';
import { AvenirBlackH7, H6, AvenirBlackH4 } from '../../../utils/text'
import CloseCircle from '../../../components/SVG/CloseCircle'

const steps = [
  "Step 1 - Let’s get started",
  "Step 2 - Connect to your Moneris account",
  "Step 3 - Finalize your site"
]
const Header = ({ step = 0, onSave, onNext }) => {
  const navigate = useNavigate();
  const onExit = () => {
    navigate('/home/builder')
  }
  const onBack = () => {
    if (step > 0) {
      navigate(`/builder/${step}`)
    }
  }
  return (
    <div style={{ position: 'relative' }}>
      <Grid container style={{ backgroundColor: 'white', width: '100%', padding: 20, top: 60, flex: 1 }}>
        <Grid item xs={6} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ marginRight: 10, flexDirection: 'row', alignItems: 'center', display: 'flex' }} onClick={onBack}>
            {step > 0 && <BackIcon />}
            <span style={{ fontWeight: 900, fontSize: 24, paddingLeft: 20 }}>
              <H6 label={steps[step]} />
            </span>
          </span>
        </Grid>

        <Grid item xs={6} style={{ paddingRight: 20 }}>
          <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Grid style={{ paddingRight: 10 }}>
              <Button
                onClick={onExit}
                startIcon={<CloseCircle />}>
                <AvenirBlackH4 label='Exit' />
              </Button>
            </Grid>
            {/* <Grid item xs={4}>
                  <Button
                    onClick={() => onSave(false)}
                    style={{ backgroundColor: '#00B6C9' }}
                    variant="contained">
                    Save
                  </Button>
                </Grid> */}
            <Grid>
              <Button
                onClick={onNext}
                style={{ backgroundColor: '#FFB600', marginTop: 2 }}
                variant="contained">
                <AvenirBlackH4 label={step == 2 ? 'Complete' : 'Save and next'} />
              </Button>
            </Grid>
          </Grid>


        </Grid>
      </Grid>
    </div>
  )
}

export default Header;
