import * as React from "react"

function RefreshIcon(props) {
    return (
        <svg
            width={25}
            height={25}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.911 14.999v4.5h-4M5.911 19.5a9.5 9.5 0 112.47 1.639"
                stroke="#004C60"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default RefreshIcon
