
import moment from 'moment'
import {
  getWookBook,
  downloadAs,
  setHeaderStyle,
  setNormalRowStyle
} from './workbook'
import { ConvertPrice } from '../util'



const downloadEodReport = (report, report_title, report_subtitle, file_name) => {
  console.log(report)

  if (!report) {
    return 
  }

  const workbook = getWookBook()
  const ws = workbook.addWorksheet("Report Data");
    
  var row = ws.addRow( [report_title] )
  row.font = {bold: true, size: 14}
  var row = ws.addRow( [report_subtitle] )
  row.font = {bold: true, size: 12}
  ws.addRow( [] )

  report?.ReportData?.data?.map((item) => {
    ws.getColumn(1).width = 50;
    ws.getColumn(2).width = 20;
    ws.getColumn(3).width = 20;

    var row = ws.addRow( [item.sectionName] )
    row.font = {bold: true, size: 12}

    var hasQuantity = false
    if (item?.sectionData.some((obj) =>
        obj.hasOwnProperty('quantity') ) ){
            var sRow = ws.addRow( [" ", "Quantity", "Amount"] )
            sRow.font = {bold: true, italic: true}
            //setHeaderStyle(ws, 1)
            hasQuantity = true
      }

    item.sectionData?.map((subItem, i) => {
        var amt = subItem.value.amount / 100.00;
        if ( hasQuantity) {
            var dRow = ws.addRow([
                subItem.name,
                subItem.quantity,
                amt,] )
            dRow.getCell(3).numFmt = "$#,##0.00"
            if (subItem.name.includes('Total')) {
              dRow.font = {bold: true}
            }
        } else {
          var dRow = ws.addRow([
                subItem.name,
                "",
                amt,] )
          dRow.getCell(3).numFmt = "$#,##0.00"
          if (subItem.name.includes('Total')) {
            dRow.font = {bold: true}
          }
        }
        //setNormalRowStyle(ws, i + 2)
    } )
    ws.addRow( [""] )
  })

  downloadAs(workbook, file_name)
}


export {
  downloadEodReport
}