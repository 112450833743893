import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPettyCashSession, fetchPettyCashCategories } from '../../../redux/actions';
import ExpensesList from './expensesList';

const Expenses = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPettyCashCategories());
        dispatch(fetchPettyCashSession());
    }, [])
    return (
        <div data-qaid="expenses_index_div">
            <ExpensesList data-qaid="expenses_index_expensesList">
            </ExpensesList>
        </div>
    );
};

export default Expenses;
