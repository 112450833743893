import React, { useEffect, useState, useContext } from 'react';
import {
    Grid,
    makeStyles,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import TextInputComponent from '../../../components/TextInputComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchEmployee, editEmploye, resetEmail, fetchRoles, fetchEmployeeCheck } from './../../../redux/actions'
import ProvinceData from '../customers/province';
import InputMaskComponent from '../../../components/TextInputMask';
import moment from 'moment';
import InfoOutlinedIcon from '../../../components/SVG/InfoOutlined';
import MyContext from '../../../Provider/MyContext';
import { AvenirBlackH4, AvenirBlackH7, AvenirBlackH2, H5, AvenirBlackH3 } from '../../../utils/text';
import Back from '../../../components/SVG/Back';
import SingleSelect from '../../../components/SingleSelect';
import styles from '../../../styles/index';
import SnackBar from '../../../components/SnackBar';
import SingleDate from '../../../components/SingleDate'
import WarningIcon from '../../../components/SVG/WarningIcon';
import { validateText, validateAlphaNumeric, validateNumber } from '../../../utils/util';
const useStyles = makeStyles((theme) => ({
    newCustomerHeadingView: {
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '20px'
    },
    newCustomerText: {
        fontWeight: 'bold',
        fontSize: 24
    },
    saveBtn: {
        marginRight: '20px',
        backgroundColor: '#FFB600',
        color: 'white',
        height: '40px',
        width: '103px',
        '&:hover': {
            backgroundColor: '#FFB600',
        }
    },
    cancelBtn: {
        marginLeft: '20px',
        backgroundColor: '#707272',
        color: 'white',
        fontWeight: 'bold'
    },
    contactInfoMainView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '1%',
        marginLeft: '1%',
        marginRight: '1%'
    },
    hrStyles: {
        marginTop: '1%',
        marginLeft: ' 1%',
        marginRight: '1%',
        border: '1px solid #EEE'
    },
    checkboxMainView: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '10px'
    },
    additionalInfoCardView: {
        marginTop: '20px',
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '20px'
    },
    additionalInfoText: {
        display: 'flex',
        marginTop: '1%',
        marginLeft: '1%',
        marginRight: '1%'
    },
    root: {
    },
    title: {
        fontSize: '18px',
    },
    input: {
        color: 'black !important',
        fontSize: 14,
        backgroundColor: '#FFF',
        borderRadius: 4,
        // height: '24px'
    },
    notchedOutline: {
        border: "1px solid #707272 !important",
    },
    labelRoot: {
        // fontSize:6
    },
    formControl: {
        minWidth: '100%',
        backgroundColor: '#FFF !important',
        border: '#707272',
        borderRadius: 4
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    number: {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        }
    },
    customWidth: {
        maxWidth: 700,
    },
}));

const EditEmployee = () => {
    const style = styles();
    const state = useLocation();
    const testData = JSON.parse(state.state.id);
    const [isEditable, setEditable] = useState(true)
    const Roles = useSelector(state => state.profileReducers.roles);
    const { selectedRestaurant } = useSelector(state => state.loginReducer);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [status, setStatus] = useState(1);
    const [role, setRole] = useState('');
    const [roleId, setRoleId] = useState('0');
    const [selectedRoleType, setSelectedRoleType] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [province, setProvince] = useState('');
    const navigate = useNavigate();
    // const [isEditPin, setEditPin] = useState(false)
    const [employeeData, setEmployeeData] = useState({});
    const [uNameErr, setuNameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [currentDate, setCurrentDate] = useState('')
    const Context = useContext(MyContext);

    const [indexValue, setIndexValue] = useState(0)
    const [snackbar, setSnackbar] = useState(null);
    const [emailExists, setEmailExists] = useState(false);
    const [lang, setLang] = useState(0);
    const languageType = [
        { name: "English", value: 0 },
        { name: "French", value: 1 },
    ]

    const WarningText = <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', paddingTop: 5 }}>
        <WarningIcon color='#AB1212' height={16} width={16} />
        <H5 label={'Employee email already exists'} style={{ color: '#AB1212', paddingLeft: 5 }} />
    </Grid>

    useEffect(() => {
        setCurrentDate(moment().format("YYYY-MM-DD"))
        dispatch(fetchRoles({}, afterRolesRecive))
    }, [])
    const getLang = (val) => {
        const l = languageType.find(e => e.value == val);
        return l.name
    }
    const afterRolesRecive = () => {
        if (state.state && state.state.id) {
            dispatch(fetchEmployee({ 'employee_id': testData.employee_id }, onFetchRoleCallback));
        }
    }
    const onFetchRoleCallback = (response) => {
        setEmployeeData(response);
        if (response.is_super_admin == 1) {
            if (selectedRestaurant.is_super_admin == 0) {
                setEditable(false)
            }
        }
        // if (selectedRestaurant.is_super_admin == 1) {
        //     setEditPin(true)
        // }
        if (response.role_id) {
            setRoleId(response.role_id)
            var selected = Roles.roles.filter((role) => role.id === response.role_id);
            console.log("employee role", selected)
            var roleLength = [];
            var role = null;
            if (selected.length) {
                role = selected[0];
                var roleApplications = role.role_application.split(',');
                if (roleApplications && roleApplications.length > 0) {
                    roleApplications.map(namespace => {
                        if (namespace == "Portal") {
                            roleLength.push("portal");
                        }
                        else {
                            if (roleLength.indexOf("app") == -1) {
                                roleLength.push("app");
                            }
                        }
                    })
                }
            }
            var selectedRoleType = 0;
            if (roleLength.indexOf("app") !== -1 && roleLength.indexOf("portal") !== -1) {
                selectedRoleType = 1
            }
            else if (roleLength.indexOf("app") !== -1) {
                selectedRoleType = 3
            }
            else if (roleLength.indexOf("portal") !== -1) {
                selectedRoleType = 2
            }
            setRole(response.role_id);
            setRoleId(response.role_id)
            setSelectedRoleType(selectedRoleType);
            setSelectedRole(role);
            setStatus(response.status)

            if (response.province && response.province != '') {
                setProvince(response.province)
            } else {
                setProvince('')
            }
            if (response.language === "French") {
                setLang(1)
            } else {
                setLang(0)
            }
        }

    }

    const handleRoleChange = (val) => {
        var selected = Roles.roles.filter((role) => role.id === val.target.value);
        var roleLength = [];
        var role = null;
        if (selected.length) {
            role = selected[0];
            if (role.role_application == "Portal") {
                roleLength.push("portal");
            }
            else {
                if (roleLength.indexOf("app") == -1) {
                    roleLength.push("app");
                }
            }
        }
        var selectedRoleType = 0;
        if (roleLength.indexOf("app") !== -1 && roleLength.indexOf("portal") !== -1) {
            selectedRoleType = 1
        }
        else if (roleLength.indexOf("app") !== -1) {
            selectedRoleType = 3
        }
        else if (roleLength.indexOf("portal") !== -1) {
            selectedRoleType = 2
        }
        setRole(val.target.value);
        setRoleId(val.target.value)
        setSelectedRoleType(selectedRoleType);
        setSelectedRole(role);
    };
    const handleCancel = () => {
        navigate('/home/profile', { state: { tab: 0, emp: true } })
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const validateAddress = (address) => {
        if ((selectedRoleType == 3) && address.values.userName == '') {
            setuNameErr(true)
            setEmailErr(false)
            return "null"
        } else if (selectedRoleType == 1) {
            if (address.values.email == '') {
                setEmailErr(true)
                return "null"
            } else if (address.values.userName == '') {
                setuNameErr(true)
                return "null"
            }
        } else if (selectedRoleType == 2 && address.values.email == '') {
            setEmailErr(true)
            return "null"
        }
        else {
            setuNameErr(false)
            setEmailErr(false)
        }

        var arr = ["streetAddress", "city", "postalCode"];
        var temp = [];
        arr.map(key => {
            if (address.values[key] == "" || address.values[key] == null) {
                temp.push(key)
            }
        });
        if (temp.length === arr.length || temp.length === 0) {
            return null
        }
        else {
            return "Address Incomplete";
        }
    };

    function reset(emailValue) {
        dispatch(resetEmail({ email: emailValue }, onResetCompleted))
    }
    function onResetCompleted(response, data) {
        setIndexValue(1)
    }
    const setPhoneNumber = (phone) => {
        var value = ""
        if (phone) {
            value = phone.substring(0, 1) + "-" + phone.substring(1, 4) + "-" + phone.substring(4, 7) + "-" + phone.substring(7)
        }
        return value;
    }
    const setPostalCode = (postalCode) => {
        var value = ""
        if (postalCode) {
            value = postalCode.substring(0, 3) + " " + postalCode.substring(3)
        }
        return value;
    }

    const statusData = [
        { name: 'Inactive', value: 0 },
        { name: 'Active', value: 1 }
    ]

    const onBlurEmail = (e, setFieldValue) => {
        if (e.target.value !== '') {
            setEmailErr(false)
            dispatch(fetchEmployeeCheck({ email: e.target.value }, (status, data) => {
                if (status) {
                    if (data.exist) {
                        setEmailExists(true)
                    } else {
                        setEmailExists(false)
                    }
                } else {
                    console.log("email alreay exists")
                }
            }))
            setFieldValue('contactEmail', e.target.value)
        } else {
            setEmailErr(false)
        }
    }

    const onChangeUName = (e) => {
        if (e.target.value !== '') {
            setuNameErr(false)
        }
    }

    const onChangeEmail = (e) => {
        if (e.target.value !== '') {
            setEmailErr(false)
        }
    }

    console.log(employeeData, "employeeData", isEditable)
    let disableProps = {};
    let disablePin = {}
    if (!isEditable) {
        disableProps["disabled"] = true
    }
    // if (!isEditPin) {
    //     disablePin["disabled"] = true
    // }
    // console.log("isEditpin", isEditPin, disableProps, disablePin)
    return (
        <>
            < Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: validateText(employeeData.first_name),
                    lastName: validateText(employeeData.last_name),
                    email: employeeData.email == null ? '' : employeeData.email,
                    userName: employeeData.username,
                    tempPin: employeeData.temp_pin,
                    status: employeeData.status,
                    phoneNumber: setPhoneNumber(employeeData.phone_number),
                    contactEmail: employeeData.contact_email == null ? '' : employeeData.contact_email,
                    employeeId: employeeData.employee_id,
                    birthday: employeeData.birthday ? employeeData.birthday : null,
                    assignedEmployeeId: employeeData.assigned_employee_id,
                    streetAddress: employeeData.street_address,
                    unitNumber: employeeData.unit_number,
                    city: employeeData.city,
                    province: employeeData.province,
                    postalCode: setPostalCode(employeeData.postal_code),
                    roleId: employeeData.role_id,
                    notes: employeeData.notes,
                    language: employeeData.language,
                    // status: employeeData.status
                }}
                validationSchema={
                    Yup.object().shape({
                        firstName: Yup.string()
                        .max(255)
                        .matches(/^[a-zA-Z\s\u00C0-\u00FF]+$/, "Please enter valid text")
                        .required(Context.langData.first_name_req_error),
                        lastName: Yup.string()
                        .max(255)
                        .matches(/^[a-zA-Z\s\u00C0-\u00FF]+$/, "Please enter valid text")
                        .required(Context.langData.last_name_req_error),
                         email: Yup.string()
                        .email(Context.langData.email_valid_error),
                        assignedEmployeeId: Yup.string()
                        .max(255)
                        .matches(/^[a-zA-Z0-9\u00C0-\u00FF]*$/, "Please enter valid text"),
                         streetAddress: Yup.string()
                        .max(255)
                        .matches(/^[a-zA-Z0-9\s\u00C0-\u00FF]*$/, "Please enter valid text"),
                        city: Yup.string()
                        .max(255)
                        .matches(/^[a-zA-Z\s\u00C0-\u00FF]*$/, "Please enter valid text"),
                    })
                }
                onSubmit={(values) => {
                    var valid = validateAddress({ values });
                    if (valid) {
                        if (valid !== "null")
                            setSnackbar({ message: valid, severity: 'error' });
                    } else {
                        dispatch(editEmploye({
                            employee_id: testData.employee_id,
                            first_name: values.firstName,
                            last_name: values.lastName,
                            email: values.email,
                            phone_number: values.phoneNumber == null ? null : values.phoneNumber.replace(/-/g, ''),
                            contact_email: values.contactEmail,
                            enable_marketing: 0,
                            assigned_employee_id: values.assignedEmployeeId,
                            birthday: values.birthday == null ? null : values.birthday,
                            street_address: values.streetAddress,
                            unit_number: values.unitNumber,
                            city: values.city,
                            province: province == 'Select' ? '' : province,
                            postal_code: values.postalCode == null ? null : values.postalCode.replace(/ /g, ''),
                            notes: values.notes,
                            temp_pin: values.tempPin == employeeData.temp_pin ? null : values.tempPin,
                            username: values.userName,
                            role_id: roleId,
                            status: status,
                            language: lang == 0 ? 'English' : 'French'
                        }, (status, data) => {
                            if (status) {
                                navigate('/home/profile', { state: { tab: 0, emp: true, createCustomer: status, message: Context.langData.employee_edit_successfully, ts: moment().unix() } })
                            } else {
                                roleId == 0 ?
                                    setSnackbar({ message: 'Please select role', severity: 'error' })
                                    :
                                    setSnackbar({ message: data.message, severity: 'error' })
                            }
                        }));
                    }
                }
                }
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setFieldError,
                    isSubmitting,
                    touched,
                    values,
                    setErrors,
                    isValid,
                    dirty,
                }) => (
                    <form data-qaid="editEmployee_mainDiv" onSubmit={handleSubmit}>
                        {console.log("isDirty", dirty, "isValid", isValid, values)}
                        <Grid container data-qaid="editEmployee_mainGrid" style={{ backgroundColor: '#EEE' }}>
                            <Grid container data-qaid="editEmployee_topMainContainer" spacing={2} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#FFF', paddingTop: '25px', paddingBottom: '25px' }}>
                                <Grid item data-qaid="editEmployee_backView" style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginLeft: 30 }}>
                                    <Back data-qaid="editEmployee_backIcon" onClick={() => handleCancel()} style={{ cursor: 'pointer' }} />
                                    <AvenirBlackH7 data-qaid="editEmployee_editEmployee_label" label={Context.langData.edit_employee} style={{ fontWeight: 'bold', paddingLeft: 20 }} />
                                </Grid>
                                <Grid item data-qaid="editEmployee_save_cancel_view">
                                    <Button data-qaid="editEmployee_cancelButton" className={style.buttonClass} style={{ backgroundColor: "#FFF", color: "white", marginRight: 10, width: 103, height: 40, border: '2px solid #051D33' }} variant="contained" id="cancel" onClick={() => handleCancel()}  >
                                        <AvenirBlackH4 data-qaid="editEmployee_cancelLabel" label={Context.langData.cancel} />
                                    </Button>
                                    <Button className={[style.buttonClass, classes.saveBtn]} data-qaid="editEmployee_saveButton" variant="contained" id="savebtn" type='submit' disabled={!(dirty && isValid)} >
                                        <AvenirBlackH4 data-qaid="editEmployee_saveLabel" label={Context.langData.save} />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item data-qaid="editEmployee_formGrid" lg={12} md={12} sm={12} style={{ overflow: 'scroll', marginTop: 30 }}>
                                <div data-qaid="editEmployee_formDiv" style={{ marginLeft: '20px', marginRight: '20px' }}>
                                    <Card data-qaid="editEmployee_card" className={classes.root}>
                                        <CardContent>
                                            <Grid data-qaid="editEmployee_acc_informationView" className={classes.contactInfoMainView}>
                                                <AvenirBlackH2 data-qaid="editEmployee_acc_informationLabel" label={Context.langData.acc_information} />
                                            </Grid>
                                            <hr data-qaid="editEmployee_hrView" className={classes.hrStyles} />
                                            <Grid container data-qaid="editEmployee_fName_lName_Grid" spacing={4} style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid item data-qaid="editEmployee_fName_Grid" xs={12} sm={6} md={6} lg={5}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_fName_label" label={Context.langData.first_name} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.firstName}
                                                        onBlur={handleBlur}
                                                        onChange={(val) => setFieldValue('firstName', validateText(val.target.value))}
                                                        fullWidth
                                                        error={Boolean(touched.firstName && errors.firstName)}
                                                        helperText={touched.firstName && errors.firstName}
                                                        {...disableProps}
                                                        id="firstName"
                                                        maxLength={128}
                                                        name="firstName"
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item data-qaid="editEmployee_lName_Grid" xs={12} sm={6} md={6} lg={5}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_lName_label" label={Context.langData.last_name} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.lastName}
                                                        onBlur={handleBlur}
                                                        onChange={(val) => setFieldValue('lastName', validateText(val.target.value))}
                                                        fullWidth
                                                        maxLength={128}
                                                        error={Boolean(touched.lastName && errors.lastName)}
                                                        helperText={touched.lastName && errors.lastName}
                                                        id="lastName"
                                                        {...disableProps}
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container data-qaid="editEmployee_role_status_Grid" spacing={4} style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid item data-qaid="editEmployee_role_singleSelect" xs={12} sm={6} md={6} lg={5}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_roleLabel" label={Context.langData.role} style={{ marginBottom: 5 }} />
                                                    <SingleSelect
                                                        list={Roles.roles}
                                                        valueKey="id"
                                                        displayKey="name"
                                                        value={roleId}
                                                        disabled={!isEditable}
                                                        handleChange={(e) => { handleRoleChange(e); setFieldValue('roleId', e.target.value); console.log("roleId", e.target.value) }}
                                                        border={false}
                                                        onBlur={handleBlur}
                                                        placeholder='Select'
                                                        height={54}
                                                        FormWidth={'100%'}
                                                    />
                                                </Grid>
                                                <Grid item data-qaid="editEmployee_status_Grid" xs={12} sm={6} md={6} lg={5} style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                                                    <Grid style={{ width: '60%' }}>
                                                        <AvenirBlackH4 data-qaid="editEmployee_statusLabel" label={Context.langData.status} style={{ marginBottom: 5 }} />
                                                        <SingleSelect
                                                            list={statusData}
                                                            valueKey="value"
                                                            displayKey="name"
                                                            value={status}
                                                            disabled={!isEditable}
                                                            handleChange={(e) => { setStatus(e.target.value); setFieldValue('status', e.target.value) }}
                                                            border={false}
                                                            height={54}
                                                            FormWidth={'100%'}
                                                        />
                                                    </Grid>
                                                    <Grid style={{ width: '35%' }}>
                                                        <AvenirBlackH4 data-qaid="newCustomers_provinceLabel" label={Context.langData.language + '*'} style={{ marginBottom: 5 }} />
                                                        <SingleSelect
                                                            list={languageType}
                                                            valueKey="value"
                                                            displayKey="name"
                                                            value={lang}
                                                            disabled={!isEditable}
                                                            handleChange={
                                                                (language) => {
                                                                    setLang(language.target.value)
                                                                    setFieldValue('language', getLang(language.target.value))
                                                                }}
                                                            border={false}
                                                            height={54}
                                                            FormWidth={'100%'}
                                                            borderStyle={'1px solid #707272'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {
                                                (selectedRoleType == 1 || selectedRoleType == 2) &&
                                                <Grid container data-qaid="editEmployee_email_resetPassword_Grid" spacing={4} style={{ paddingTop: '20px', paddingLeft: '10px', display: 'flex', alignItems: 'center' }}>
                                                    <Grid item data-qaid="editEmployee_email_Grid" xs={12} sm={12} md={9} lg={8}>
                                                        <AvenirBlackH3 data-qaid="editEmployee_portal_credentials_label" label={Context.langData.portal_credentials} style={{ marginBottom: 20 }} />
                                                        <AvenirBlackH4 data-qaid="editEmployee_email_header_star_label" label={Context.langData.email_header_star} style={{ marginBottom: 5 }} />
                                                        <TextInputComponent
                                                            value={values.email}
                                                            maxLength={64}
                                                            onBlur={(e) => {
                                                                handleBlur(e);
                                                                onBlurEmail(e, setFieldValue)
                                                            }}
                                                            {...disableProps}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                onChangeEmail(e, setFieldValue)
                                                            }}
                                                            // setFieldValue('email', val.target.value.trim().replace(/\s/g, ''));
                                                            fullWidth
                                                            error={Boolean(touched.email && errors.email)}
                                                            helperText={touched.email && errors.email}
                                                            id="email"
                                                            variant="outlined"
                                                            errorBorder={(emailExists && !errors.email) || emailErr ? '1px solid #AB1212' : null}
                                                            style={{ width: '100%' }}
                                                            className={classes.sample}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.labelRoot,
                                                                    notchedOutline: classes.notchedOutline,
                                                                    input: classes.input
                                                                },
                                                            }}
                                                        />
                                                        {emailErr ? <H5 label='Email is required' style={{ color: '#AB1212', paddingLeft: 10 }} /> : ''}
                                                        {emailExists &&
                                                            <H5 label={emailExists ? WarningText : ''} style={{ color: '#AB1212', paddingTop: 5 }} />
                                                        }
                                                        <Grid item data-qaid="editEmployee_infoIcon_view" style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                                                            <InfoOutlinedIcon data-qaid="editEmployee_infoIcon" style={{ height: 16, width: 16, marginRight: 5 }} />
                                                            <H5 data-qaid="editEmployee_emailAddressRequired_label" label={Context.langData.an_email_address_is_required_for_roles} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item data-qaid="editEmployee_resetPassword_view" xs={12} sm={12} md={3} lg={4} style={{ alignItems: 'center' }}>
                                                        {indexValue == 0
                                                            ?
                                                            <Button
                                                                disabled={!isEditable}
                                                                data-qaid="editEmployee_resetPassword_button"
                                                                className={style.buttonClass} variant="contained" style={{ marginTop: 20, backgroundColor: '#00B6C9', color: '#051D33', height: '40px', width: '147px', marginTop: '20px' }} onClick={() => reset(values.email)}>
                                                                Reset Password
                                                            </Button>
                                                            :
                                                            <AvenirBlackH4 data-qaid="editEmployee_emailSentLabel" label={'Email sent'} style={{ marginTop: 20, marginLeft: 20 }} />
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }
                                            {
                                                (selectedRoleType == 1 || selectedRoleType == 3) &&
                                                <>
                                                    <AvenirBlackH3 data-qaid="editEmployee_appCredentials_label" label={Context.langData.app_credentials} style={{ paddingTop: 20, paddingLeft: 10 }} />
                                                    <Grid container data-qaid="editEmployee_userName_tempPin_view" spacing={4} style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                        <Grid item data-qaid="editEmployee_userName_view" xs={12} sm={6} md={6} lg={5}>
                                                            <AvenirBlackH4 data-qaid="editEmployee_userNameLabel" label={Context.langData.user_name} style={{ marginBottom: 5 }} />
                                                            <TextInputComponent
                                                                value={values.userName}
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeUName(e, setFieldValue('userName', validateAlphaNumeric(e.target.value)))
                                                                }}
                                                                fullWidth
                                                                maxLength={16}
                                                                {...disableProps}
                                                                errorBorder={uNameErr ? '1px solid #AB1212' : null}
                                                                id="userName"
                                                                name="userName"
                                                                variant="outlined"
                                                                style={{ width: '100%' }}
                                                                className={classes.sample}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.labelRoot,
                                                                        notchedOutline: classes.notchedOutline,
                                                                        input: classes.input
                                                                    },
                                                                }}
                                                            />
                                                            {uNameErr ? <H5 label='Username is required' style={{ color: '#AB1212', paddingLeft: 10 }} /> : ''}
                                                            <Grid item data-qaid="editEmployee_infoIcon_view1" style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                                                                <InfoOutlinedIcon data-qaid="editEmployee_infoIcon_label1" style={{ height: 16, width: 16, marginRight: 5 }} />
                                                                <H5 data-qaid="editEmployee_userNameRequiredLabel1" label={Context.langData.a_username_is_required_for_roles} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item data-qaid="editEmployee_tempPin_Grid" xs={12} sm={6} md={6} lg={5}>
                                                            <AvenirBlackH4 data-qaid="editEmployee_temppinLabel" label={Context.langData.pin + '*'} style={{ marginBottom: 5 }} />
                                                            <InputMaskComponent
                                                                value={values.tempPin}
                                                                regex='pin'
                                                                maxLength={6}
                                                                isFormik={true}
                                                                {...disableProps}
                                                                {...disablePin}
                                                                handleChange={(val) => setFieldValue('tempPin', val)}
                                                                error={errors.tempPin}
                                                                onBlur={(value) => {
                                                                    if (value) {
                                                                        setFieldError('tempPin', value)
                                                                    } else {
                                                                        let err = { ...errors }
                                                                        delete err.tempPin
                                                                        setErrors(err)
                                                                    }

                                                                }}
                                                                id="tempPin"
                                                                type={showPassword ? 'text' : 'password'}
                                                                placeholder="XXXXXX"
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <Grid item data-qaid="editEmployee_infoIcon_view2" style={{ marginTop: 5, display: 'flex', flexDirection: 'row' }}>
                                                                <InfoOutlinedIcon data-qaid="editEmployee_infoIcon2" style={{ height: 16, width: 16, marginRight: 5 }} />
                                                                <H5 data-qaid="editEmployee_infoIcon_label2" label={Context.langData.a_unique_6_digit_identifier_to_sign_in_to_the_app} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                        </CardContent>
                                    </Card>
                                </div>

                                <div data-qaid="editEmployee_additionalInfo_div" className={classes.additionalInfoCardView}>
                                    <Card data-qaid="editEmployee_additionalInfo_card" className={classes.root}>
                                        <CardContent>
                                            <AvenirBlackH2 data-qaid="editEmployee_additionalInfo_label" label={Context.langData.additional_info} style={{ paddingLeft: 10, paddingTop: 10 }} />
                                            <hr data-qaid="editEmployee_hr_div" className={classes.hrStyles} />
                                            <Grid container data-qaid="editEmployee_employeeId_phoneNumber_Grid" spacing={2} style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid item data-qaid="editEmployee_employeeId_Grid" xs={12} sm={4} md={4} lg={4}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_employeeId_label" label={Context.langData.emp_id} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.assignedEmployeeId}
                                                        onBlur={handleBlur}
                                                        onChange={(val) => setFieldValue('assignedEmployeeId', validateAlphaNumeric(val.target.value))}
                                                        {...disableProps}
                                                        maxLength={32}
                                                        id="assignedEmployeeId"
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item data-qaid="editEmployee_birthday_Grid" xs={12} sm={4} md={4} lg={3}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_birthday_label" label={Context.langData.birthday} style={{ marginBottom: 5 }} />
                                                    <Grid item data-qaid="editEmployee_DOBPicker_Grid">
                                                        {/* <DOBPicker
                                                            value={values.birthday}
                                                            height={54}
                                                            width={276}
                                                            border={false}
                                                            callback={(val) => setFieldValue('birthday', val)}
                                                        /> */}
                                                        <SingleDate
                                                            value={values.birthday}
                                                            height={54}
                                                            disabled={!isEditable}
                                                            maxDate={new Date()}
                                                            border={false}
                                                            callback={(val) => { setFieldValue('birthday', val); console.log("birthday change", val) }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item data-qaid="editEmployee_phoneNumber_Grid" xs={12} sm={4} md={4} lg={3}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_phoneNumber_label" label={Context.langData.phone_number} style={{ marginBottom: 5 }} />
                                                    <InputMaskComponent
                                                        value={values.phoneNumber}
                                                        regex='phone'
                                                        {...disableProps}
                                                        isFormik={true}
                                                        handleChange={(val) => setFieldValue('phoneNumber', val)}
                                                        error={errors.phoneNumber}
                                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                                        id="phoneNumber"
                                                        onBlur={(value) => {
                                                            if (value) {
                                                                setFieldError('phoneNumber', value)
                                                            } else {
                                                                let err = { ...errors }
                                                                delete err.phoneNumber
                                                                setErrors(err)
                                                            }
                                                        }}
                                                    // placeholder="X-XXX-XXX-XXXX"
                                                    />
                                                </Grid>
                                                <Grid item data-qaid="editEmployee_contactEmail_Grid" xs={12} sm={4} md={4} lg={4}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_contactEmail_label" label={Context.langData.contact_email} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.contactEmail}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        {...disableProps}
                                                        maxLength={32}
                                                        disabled={selectedRoleType == 3 ? false : true}
                                                        id="contactEmail"
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container data-qaid="editEmployee_unitNumber_streetAddress_Grid" spacing={2} style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid item data-qaid="editEmployee_streetAddress_Grid" xs={12} sm={9} md={9} lg={7}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_streetAddress_label" label={Context.langData.street_address} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.streetAddress}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        {...disableProps}
                                                        id="streetAddress"
                                                        maxLength={64}
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item data-qaid="editEmployee_unitNumber_Grid" xs={12} sm={3} md={3} lg={3}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_unitNumber_label" label={Context.langData.unit_number} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.unitNumber}
                                                        onBlur={handleBlur}
                                                        {...disableProps}
                                                        onChange={(val) => setFieldValue('unitNumber', validateNumber(val.target.value))}
                                                        id="unitNumber"
                                                        maxLength={32}
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid container data-qaid="editEmployee_city_province_notes_Grid" spacing={2} style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                                <Grid item data-qaid="editEmployee_city_Grid" xs={12} sm={3} md={3} lg={4}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_city_label" label={Context.langData.city} style={{ marginBottom: 5 }} />
                                                    <TextInputComponent
                                                        value={values.city}
                                                        onBlur={handleBlur}
                                                        onChange={(val) => setFieldValue('city', validateText(val.target.value))}
                                                        {...disableProps}
                                                        id="city"
                                                        maxLength={32}
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        className={classes.sample}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                                notchedOutline: classes.notchedOutline,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item data-qaid="editEmployee_province_Grid" xs={12} sm={3} md={3} lg={4}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_province_label" label={Context.langData.province} style={{ marginBottom: 5 }} />
                                                    <SingleSelect
                                                        list={ProvinceData}
                                                        valueKey="value"
                                                        displayKey="name"
                                                        value={province}
                                                        placeholder="Select"
                                                        disabled={!isEditable}
                                                        handleChange={
                                                            (province) => {
                                                                setProvince(province.target.value)
                                                                setFieldValue('province', province.target.value)
                                                            }
                                                        }
                                                        border={false}
                                                        height={54}
                                                        FormWidth={'100%'}
                                                    />
                                                </Grid>
                                                <Grid item data-qaid="editEmployee_postalCode_Grid" xs={12} sm={3} md={3} lg={2}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_postalCode_label" label={Context.langData.postal_code} style={{ marginBottom: 5 }} />
                                                    <InputMaskComponent
                                                        value={values.postalCode}
                                                        regex='postal'
                                                        isFormik={true}
                                                        {...disableProps}
                                                        handleChange={(val) => setFieldValue('postalCode', val)}
                                                        error={errors.postalCode}
                                                        onBlur={(value) => {
                                                            if (value) {
                                                                setFieldError('postalCode', value)
                                                            } else {
                                                                let err = { ...errors }
                                                                delete err.postalCode
                                                                setErrors(err)
                                                            }
                                                        }}
                                                        id="postalCode"
                                                        placeholder="X1X 1X1"
                                                    />
                                                </Grid>
                                                <Grid item data-qaid="editEmployee_notes_Grid" xs={12} sm={12} md={12} lg={10}>
                                                    <AvenirBlackH4 data-qaid="editEmployee_notes_label" label={Context.langData.notes} style={{ marginBottom: 5 }} />
                                                    <TextField
                                                        value={values.notes}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        id="notes"
                                                        {...disableProps}
                                                        multiline={true}
                                                        rows={5}
                                                        InputProps={{ disableUnderline: true }}
                                                        inputProps={{ maxLength: 500 }}
                                                        style={{ width: '100%', height: 128, backgroundColor: '#FFF', outline: 'none', fontSize: 14, borderRadius: 4, fontFamily: 'AvenirLT-Medium', color: '#051D33', padding: 10, border: '1px solid #707272' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={10} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex', padding: 10, paddingTop: 5 }}>
                                                <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                                                    <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                                    <H5 label={Context.langData.do_not_add_any_sensitive_info} style={{ paddingLeft: 5, paddingTop: 5 }} />
                                                </Grid>
                                                <Grid style={{ justifyContent: 'flex-end' }}>
                                                    <H5 style={{ color: '#707272' }} label={(values?.notes?.length == undefined ? '0' : values?.notes?.length) + Context.langData._500_characters_count} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </div>
                                {
                                    snackbar &&
                                    <SnackBar
                                        data-qaid="editEmployee_snackbar"
                                        open={true}
                                        setOpen={() => setSnackbar(null)}
                                        severity={snackbar.severity}
                                        message={snackbar.message}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default EditEmployee;