import instance from './axiosInstance';
import urls from './urls';
const login = (cred, callback) => {
  return (dispatch) => {
    instance.post(urls.login, cred).then((response) => {
      if (response.status === 200) {
        callback(true)
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
      }
    }).catch(err => {
      callback(false, err)
      dispatch({ type: '' })
    })
  };
}
const fetchBranchSales = (params) => {
  return (dispatch) => {
    instance.get(urls.branchSales, { params }).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_BRANCH_SALES', payload: response.data })
    })
  }
}
const fetchUserDetails = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.user).then((response) => {
      if (response.status === 200 || response.status === 304)
        callback(response.data)
    })
  }
}

const refresh = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.refresh, params).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_OAUTH_TOKEN', payload: response.data })
      if (callback) {
        callback()
      }
    })
  }
}

const saveUserDetails = (cred, callback) => {
  console.log("cred=====>cred", cred)
  return (dispatch) => {
    instance.post(urls.user, cred).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true)
      }
    }).catch(err => {
      console.log("err=====>", err)
       callback(false, err)
    })
  };
}
const logoutUser = (params, callback) => {
  return (dispatch) => {
    instance.delete(urls.logout, { data: params }).then((response) => {
      dispatch({ type: 'SET_CUSTOMERS_OFFSET', payload: 0 })
      if (response.status === 200) {
        dispatch({ type: 'LOGOUT' })
        dispatch({ type: 'CLEAR_PROFILE_DATA' })
        callback()
      }
    }).catch(err => {
      dispatch({ type: 'LOGOUT' })
    });
  };
}
const forgotEmail = (cred, callback) => {
  return (dispatch) => {
    instance.post(urls.resetemail, cred).then((response) => {
      if (response.status === 200) {
        callback(true)
      }
    }).catch(err => {
      callback(false)
      dispatch({ type: '' })
    })
  };
}
const resetPassword = (cred, callback) => {
  return (dispatch) => {
    instance.post(urls.reset, cred).then((response) => {
      callback(true, 'Password successfully created')
    }).catch(err => {
      callback(false, err.message)
    })
  };
}
const checkEmailExist = (cred, callback) => {
  return (dispatch) => {
    instance.post(urls.activation.checkEmail, cred).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true, response.data)
      }
    }).catch(err => {
      console.log(" data login", err)
      const { data } = err.response;
      callback(false, data)
      dispatch({ type: '' })
    })
  };
}
const saveHelpSupport = (cred, callback) => {
  return (dispatch) => {
    instance.post(urls.help_support, cred).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true)
      }
    }).catch(err => {
      callback(false)
    })
  };
}
const mfaLoginVerification = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.login_mfa_verfiation, params).then((response) => {
      callback(true)
      console.log("response==>response.data", response.data)
      localStorage.setItem("session_token", response?.data?.session_token);
    }).catch(err => {
      console.log(err)
      callback(false, err.status)
    });
  };
}
const mfaLoginResend = (obj,callback) => {
  return (dispatch) => {
    instance.post(urls.login_mfa_resend,obj).then((response) => {
      callback(true)
    }).catch(err => {
      console.log(err)
      callback(false, err.status)
    });
  };
}
export {
  login,
  fetchBranchSales,
  refresh,
  fetchUserDetails,
  saveUserDetails,
  logoutUser,
  forgotEmail,
  resetPassword,
  checkEmailExist,
  saveHelpSupport,
  mfaLoginVerification,
  mfaLoginResend
}
