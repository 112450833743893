import React from 'react';
import { Grid } from '@material-ui/core';
import { T, AvenirBlackH4 } from '../utils/text'
const CustomButton = ({
  height=40,
  backgroundColor = "#ffb600",
  label = "",
  width = "100%",
  disabled = false,
  TextColor = '#004C60',
  style={},
  onClick }) => {
  const color = disabled ? "#c3c3c3" : backgroundColor
  const click = () => {
    if (!disabled) {
      onClick()
    }
  }
  const newStyle = {
    backgroundColor: color,
    border: `2px solid ${color}`,
    padding: '7.5px 0px',
    borderRadius: 5,
    display: 'flex',
    width: width,
    minWidth: 90,
    height:height,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: disabled ? 'none' : 'pointer',
    ...style
  }
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={newStyle}
      onClick={click}
    >
      <AvenirBlackH4 label={label} style={{ color: TextColor }} />
    </Grid>
  )
}

export default CustomButton;
