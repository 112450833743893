import {
	Paper,
	Grid,
	createStyles,
	withStyles
} from "@material-ui/core";
import { differenceInCalendarMonths, setMonth, setDate, setYear, addMonths } from "date-fns";
import Month from "./components/CalMonth";
import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { MARKERS } from "..";

const styles = (theme) =>
	createStyles({
		header: {
			padding: "20px 70px"
		},
		headerItem: {
			flex: 1,
			textAlign: "center"
		},
		divider: {
			borderLeft: `1px solid ${theme.palette.action.hover}`,
			marginBottom: 20
		}
	});



const Calendar = props => {
	const {
		classes,
		selecteddate,
		minDate,
		maxDate,

		onSetDate,
		width = 290,
	} = props;


	const commonProps = { minDate, maxDate };
	const [date, setDates] = useState()
	// const [day,setDay] = useState(moment().format("DD"))
	// const [month,setMonth] = useState(moment().format("MM"));
	// const [year,setYear] = useState(moment().format("YYYY"));
	// const [date,setDate] = useState(new Date())
	useEffect(() => {
		setDates(selecteddate)
		// console.log("hello",getMonth(selecteddate),getYear(selecteddate))
	}, [selecteddate])
	const isMinMonth = () => {
		if (minDate) {
			const diff = differenceInCalendarMonths(minDate, date)
			console.log("differenceInCalendarMonths min", diff, minDate)
			return diff < 0
		}
		return true

	}
	const isMaxMonth = () => {
		if (maxDate) {
			const diff = differenceInCalendarMonths(maxDate, date)
			console.log("differenceInCalendarMonths max", diff, maxDate, date)
			return diff > 0
		}
		return true
	}
	const onPreMonth = () => {
		console.log("pre")
		setDates(addMonths(date, -1))
	}
	const onNextMonth = () => {
		setDates(addMonths(date, 1))
	}
	const onYearChange = (year) => {

		setDates(setYear(date, year))
	}
	const onDateClick = (date) => {
		console.log("onDateClick", date)
		onSetDate(date)
	}
	const handlers = {
		onPreMonth, onNextMonth, onYearChange, onDateClick
	}
	return (
		<Paper elevation={0} square style={{ borderBottom: '1px solid #c3c3c3' }}>
			<Grid container direction="row" wrap="nowrap">
				<Grid>

					<Grid container direction="row" justify="center" wrap="nowrap">
						<Month
							{...commonProps}
							value={date}
							width={width}
							selected={selecteddate}
							setValue={setDate}
							navState={[isMinMonth(), isMaxMonth()]}

							handlers={handlers}
						/>

					</Grid>
				</Grid>

			</Grid>
		</Paper>
	);
};

export default withStyles(styles)(Calendar);
