import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchKitchenTicketSettings,
  putKitchenTicketSettings,
} from "../../../../redux/actions";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  FormGroup,
  FormControl,
  Box,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { AvenirBlackH2, AvenirBlackH4 } from "../../../../utils/text";
import MyContext from "../../../../Provider/MyContext";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import styles from "../../../../styles/index";
import SpacingInput from "../../../../components/SpacingInput";
import SnackBar from "../../../../components/SnackBar";

const ReceiptPreview = ({ values }) => {
  const showOrderTypeHeader = values.SHOW_ORDER_TYPE ? "Online Ordering" : "";
 // const printVoid = values.PRINT_VOID ? "Void" : "";
  const showSentTime = values.SHOW_SENT_TIME
    ? `Sent: ${new Date().toLocaleString()}`
    : "";

  const itemNames = ["Item name 1", "Item name 2"];
  const modifierName = "Modifier name";
  const lineHeightOptions = {
    SMALL: "24px",
    MEDIUM: "24px",
    LARGE: "24px",
    EXTRA_LARGE: "24px",
  };

  const fontOptions = {
    SMALL: "12px",
    MEDIUM: "14px",
    LARGE: "16px",
    EXTRA_LARGE: "18px",
  };

  return (
    <Paper
      style={{
        padding: 20,
        margin: "16px 0",
        overflow: "auto",
        maxHeight: "700px",
        border: "1px solid #707272",
        borderRadius: 0,
        minWidth: "350px",
        width: "60%",
      }}>
      <Box
        style={{
          marginTop: values.TOP_SPACING
            ? `${values.TOP_SPACING * 18}px`
            : "0px",
          marginBottom: values.BOTTOM_SPACING
            ? `${values.BOTTOM_SPACING * 18}px`
            : "0px",
        }}>
        {showOrderTypeHeader && (
          <Box>
            <Typography
              style={{
                fontWeight: "500",
                color: "#051D33",
                fontSize: fontOptions[values.FONT_SIZE],
                fontFamily: "AvenirLT-Medium",
                textAlign: "center",
              }}>
              *************************************
            </Typography>
            <Typography
              style={{
                fontWeight: "500",
                color: "#051D33",
                textAlign: "center",
                fontSize: fontOptions[values.FONT_SIZE],
                fontFamily: "AvenirLT-Medium",
              }}>
              {showOrderTypeHeader}
            </Typography>
            <Typography
              style={{
                fontWeight: "500",
                color: "#051D33",
                fontSize: fontOptions[values.FONT_SIZE],
                fontFamily: "AvenirLT-Medium",
                textAlign: "center",
              }}>
              *************************************
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          marginTop={3}>
          {showSentTime && (
            <Typography
              style={{
                color: "#051D33",
                fontSize: fontOptions[values.FONT_SIZE],
                fontFamily: "AvenirLT-Medium",
                lineHeight: lineHeightOptions[values.FONT_SIZE],
                fontWeight: 300,
              }}>
              {showSentTime}
            </Typography>
          )}
          <Typography
            style={{
              color: "#051D33",
              fontSize: fontOptions[values.FONT_SIZE],
              fontFamily: "AvenirLT-Medium",
              lineHeight: lineHeightOptions[values.FONT_SIZE],
              fontWeight: 300,
            }}>
            Order #1234
          </Typography>
          <Typography
            style={{
              color: "#051D33",
              fontSize: fontOptions[values.FONT_SIZE],
              fontFamily: "AvenirLT-Medium",
              lineHeight: lineHeightOptions[values.FONT_SIZE],
              fontWeight: 300,
            }}>
            Table 1
          </Typography>
          <Typography
            style={{
              color: "#051D33",
              fontSize: fontOptions[values.FONT_SIZE],
              fontFamily: "AvenirLT-Medium",
              lineHeight: lineHeightOptions[values.FONT_SIZE],
              fontWeight: 300,
            }}>
            Server: Joe
          </Typography>
          <Typography
            style={{
              color: "#051D33",
              fontSize: fontOptions[values.FONT_SIZE],
              fontFamily: "AvenirLT-Medium",
              lineHeight: lineHeightOptions[values.FONT_SIZE],
              fontWeight: 300,
            }}>
            Notes: Seafood allergy
          </Typography>
        </Box>

        <Divider />
        {/* {printVoid && (
          <Typography
            style={{
              color: "#AB1212",
              paddingTop: "8%",
              paddingBottom: "8%",
              fontSize: fontOptions[values.FONT_SIZE],
              fontFamily: "AvenirLT-Medium",
              fontWeight: 300,
              textAlign: "center",
            }}>
            -----------------------Void------------------------
          </Typography>
        )} */}
        <Box display="flex" justifyContent="space-between" width="70%">
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
            width="25%">
            <Typography
              style={{
                color: "#051D33",
                padding: 2,
                fontSize: fontOptions[values.FONT_SIZE],
                fontFamily: "AvenirLT-Medium",
                fontWeight: 300,
              }}>
              1
            </Typography>
            <Typography
              style={{
                color: "#051D33",
                padding: 2,
                fontSize: fontOptions[values.FONT_SIZE],
                fontFamily: "AvenirLT-Medium",
                fontWeight: 300,
              }}>
              1
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
            width="75%">
            <Typography
              style={{
                color: "#051D33",
                padding: 2,
                fontSize: fontOptions[values.FONT_SIZE],
                fontFamily: "AvenirLT-Medium",
                fontWeight: 300,
              }}>
              Item Name
            </Typography>
            <Typography
              style={{
                color: "#051D33",
                padding: 2,
                fontSize: fontOptions[values.FONT_SIZE],
                fontFamily: "AvenirLT-Medium",
                fontWeight: 300,
              }}>
              Item Name
            </Typography>
            <Typography
              style={{
                color: values.PRINT_MOD_RED ? "#AB1212" : "#051D33",
                padding: 2,
                fontSize: fontOptions[values.FONT_SIZE],
                fontFamily: "AvenirLT-Medium",
                fontWeight: 300,
              }}>
              Modifier Name
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const KitchenTickets = () => {
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const kitchenTicketSettings = useSelector(
    (state) => state.settingsReducer.kitchenTicketSettings
  );
  const style = styles();
  const [initValues, setInitValues] = useState({});
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const [snackbar, setSnackbar] = useState(null);

  useEffect(() => {
    if (kitchenTicketSettings) {
      const initialValuesFromSettings = kitchenTicketSettings?.reduce(
        (values, setting) => {
          switch (setting.settingType) {
            case "boolean":
              values[setting.code] = setting.booleanValue;
              break;
            case "int":
              values[setting.code] = setting.integerValue;
              break;
            case "lookup":
              values[setting.code] = setting.selectedOptions[0];
              break;
            default:
              break;
          }
          return values;
        },
        {}
      );
      setInitValues(initialValuesFromSettings);
    }
  }, [kitchenTicketSettings]);

  useEffect(() => {
    dispatch(fetchKitchenTicketSettings(restId.rest_id));
  }, [restId.rest_id]);

  const handleSubmit = (values) => {
    const payload = kitchenTicketSettings?.map((setting) => {
      switch (setting.settingType) {
        case "boolean":
          return {
            id: setting.id,
            booleanValue: values[setting.code],
          };
        case "int":
          return {
            id: setting.id,
            integerValue: values[setting.code],
          };
        case "lookup":
          return {
            id: setting.id,
            selectedOptions: [values[setting.code]],
          };
        default:
          break;
      }
    });
    dispatch(
      putKitchenTicketSettings(
        restId.rest_id,
        { settings: payload },
        handleToast
      )
    );
  };

  const handleToast = (value) => {
    if (value) {
      setSnackbar({
        message: Context.langData.kitchen_ticket_success,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.kitchen_ticket_failed,
        severity: "error",
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          FONT_SIZE: initValues.FONT_SIZE || "LARGE",
          TOP_SPACING: initValues.TOP_SPACING || 2,
          BOTTOM_SPACING: initValues.BOTTOM_SPACING || 0,
          SHOW_ORDER_TYPE: initValues.SHOW_ORDER_TYPE || false,
          SHOW_SENT_TIME: initValues.SHOW_SENT_TIME || false,
          //PRINT_VOID: initValues.PRINT_VOID || false,
          PRINT_MOD_RED: initValues.PRINT_MOD_RED || false,
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}>
        {({
          values,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleChange,
        }) => {
          return (
            <Box p={3} display="flex">
              <Box width="55%" p={3}>
                <AvenirBlackH2
                  label={Context.langData.configuration}
                  style={{ marginBottom: "6%" }}
                />
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <AvenirBlackH4
                      label={Context.langData.font_size}
                      style={{ marginLeft: "10px" }}
                    />
                    {kitchenTicketSettings?.map((setting) => {
                      switch (setting.settingType) {
                        case "boolean":
                          return (
                            <Box ml="20px">
                              <FormControlLabel
                                control={
                                  <Field
                                    type="checkbox"
                                    checked={values[setting.code]}
                                    name={setting.code}
                                    as={CustomCheckbox}
                                    onChange={handleChange}
                                  />
                                }
                                label={setting.name}
                                key={setting.id}
                              />
                            </Box>
                          );
                        case "int":
                          return (
                            <SpacingInput
                              initialValue={values[setting.code]}
                              title={setting.name}
                              label={setting.name}
                              name={setting.code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              key={setting.id}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              field={{
                                name: setting.code,
                                value: values[setting.code],
                              }}
                              form={{
                                setFieldValue: (name, value) =>
                                  setFieldValue(name, value),
                              }}
                            />
                          );
                        case "lookup":
                          return (
                            <Field name={setting.cod}>
                              {(field) => (
                                <FormControl
                                  component="fieldset"
                                  key={setting.id}>
                                  <RadioGroup
                                    {...field}
                                    aria-label={setting.name}
                                    name={setting.code}
                                    value={values[setting.code]}
                                    onChange={handleChange}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignContent: "center",
                                        flexWrap: "wrap",
                                        marginLeft: "8px",
                                      }}>
                                      {setting.Options?.map((option) => (
                                        <FormControlLabel
                                          value={option.code}
                                          control={<Radio />}
                                          label={option.name}
                                          key={option.code}
                                        />
                                      ))}
                                    </Box>
                                  </RadioGroup>
                                </FormControl>
                              )}
                            </Field>
                          );
                        default:
                          return null;
                      }
                    })}
                  </FormGroup>

                  <Button
                    className={style.buttonClass}
                    data-qaid="reservation_save_btn"
                    variant="contained"
                    style={{
                      backgroundColor: "#FFB600",
                      marginLeft: "10px",
                      height: 40,
                      width: 90,
                      marginTop: "60px",
                    }}
                    onClick={handleSubmit}>
                    <AvenirBlackH4
                      data-qaid="customization_save_text"
                      label={Context.langData.save}
                    />
                  </Button>
                </Form>
              </Box>
              <Box width="45%" p={3} marginBottom={10}>
                <AvenirBlackH2
                  label={Context.langData.kitchen_chit_preview}
                  style={{ marginBottom: "10%" }}
                />
                {values && <ReceiptPreview values={values} />}
              </Box>
            </Box>
          );
        }}
      </Formik>
      {snackbar && (
        <SnackBar
          data-qaid="categories_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
    </>
  );
};

export default KitchenTickets;
