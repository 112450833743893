import instance from './axiosInstance'
import urls from './urls';

const fetchOrderTypes = () => {
  return (dispatch) => {
    instance.get(urls.meta.ordertypes).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_ORDERTYPES', payload: response.data })
    })
  }
}
const fetchPaymentTypes = () => {
  return (dispatch) => {
    instance.get(urls.meta.payment_type,).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_PAYMENTS', payload: response.data })
    })
  }
}

const fetchKitchenName = (params) => {
  return (dispatch) => {
    instance.get(urls.meta.kitchen_name, { params }).then((response) => {
      console.log(response)
      if (response.status === 200 || response.status === 304) {
        dispatch({ type: 'GET_KITCHEN_NAME', payload: response.data });
      }
    });
  };
}

const fetchKitchenPrinterUnassigned = (params) => {
  return (dispatch) => {
    instance.get(urls.meta.kitchen_printer_unassigned, { params }).then((response) => {
      console.log(response)
      if (response.status === 200 || response.status === 304) {
        dispatch({ type: 'GET_KITCHEN_PRINTER_UNASSIGNED', payload: response.data });
      }
    });
  };
}
const createKitchen = (params, callback) => {
  return (dispatch) => {
    instance.put(urls.meta.kitchen_name, params).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
        console.log("false")
      }
    }).catch(err => {
      callback(false);
      console.log(err)
    })
  };
}
const editKitchen = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.meta.kitchen_name, params).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
      }
    }).catch(err => {
      callback(false);
      console.log(err)
    })
  };
}
const deleteKitchens = (cred, callback) => {
  return (dispatch) => {
    instance.delete(urls.meta.kitchen_name, { data: cred }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
      }
    }).catch(err => {
      callback(false);
      console.log(err)
    })
  };
}

const fetchOrderType_ps = () => {
  return (dispatch) => {
    instance.get(urls.meta.orderType_ps).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_ORDERTYPE_PS', payload: response.data })
    })
  }
}

const createOrderTypes = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.meta.ordertypes, params).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
      }
    }).catch(err => {
      callback(false);
      console.log(err)
    })
  };
}
const createItemPosition = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.meta.item_position, params).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
      }
    }).catch(err => {
      callback(false);
      console.log(err)
    })
  };
}
const createCatePosition = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.meta.cat_position, params).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
      }
    }).catch(err => {
      callback(false);
      console.log(err)
    })
  };
}
const editOrderTypes = (params, callback) => {
  return (dispatch) => {
    instance.put(urls.meta.ordertypes, params).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
      }
    }).catch(err => {
      callback(false, err);
      console.log(err)
    })
  };
}
const deleteOrderTypes = (cred, callback) => {
  return (dispatch) => {
    instance.delete(urls.meta.ordertypes, { data: cred }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
      }
    }).catch(err => {
      callback(false, err);
      console.log(err)
    })
  };
}
const fetchOTypePayments = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.meta.oType_payment, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        if (callback) {
          callback(response.data)
        }
      }
    });
  };
}
const fetchAssignKitchen = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.meta.assginKitchen, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        if (callback) {
          callback(response.data)
        }
      }
    });
  };
}
const saveAssignKitchen = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.meta.assginKitchen, params).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
      }
    }).catch(err => {
      callback(false);
      console.log(err)
    })
  };
}

const fetchAdvanceAssignKitchen = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.meta.advanceAssginKitchen, { params }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        if (callback) {
          callback(response.data)
        }
      }
    });
  };
}
const createOTypePayment = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.meta.oType_payment, params).then((response) => {
      console.log(response)
      if (response.status === 200) {
        callback(true);
      }
    }).catch(err => {
      callback(false);
      console.log(err)
    })
  };
}
export {
  fetchOrderTypes,
  fetchPaymentTypes,
  fetchKitchenName,
  fetchKitchenPrinterUnassigned,
  fetchOrderType_ps,
  createOrderTypes,
  editOrderTypes,
  deleteOrderTypes,
  fetchOTypePayments,
  createOTypePayment,
  createItemPosition,
  createCatePosition,
  createKitchen,
  editKitchen,
  deleteKitchens,
  fetchAssignKitchen,
  fetchAdvanceAssignKitchen,
  saveAssignKitchen
}