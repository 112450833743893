import React, { useCallback, useEffect, useState, useContext } from 'react';
import DoughnutChart from '../../../components/DoughnutChart';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import clsx from 'clsx';
import { fetchShiftsSales } from '../../../redux/actions'
import { useSelector, useDispatch } from 'react-redux';
import RefreshButton from '../../../components/RefreshButton';
import { H3, TBold, AvenirBlackH4 } from '../../../utils/text'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MyContext from '../../../Provider/MyContext';
import styles from '../../../styles/index';
const ShiftsChart = ({ classes, dates }) => {
  const style = styles();
  const dispatch = useDispatch();
  const shifts = useSelector(state => state.dashboard.shifts);
  const [type, setType] = useState('sales')
  const [value, setValue] = React.useState(0);
  const Context = useContext(MyContext);
  const [buttonArray, setButtonArray] = useState([Context.langData.sales, Context.langData.transactions]);

  useEffect(() => {
    if (dates)
      dispatch(fetchShiftsSales(dates))
  }, [dates])
  const refresh = useCallback(() => {
    dispatch(fetchShiftsSales(dates))
  }, [dates])
  const handleChange = (item) => {
    setValue(item);
    if (item == 0) {
      setType('sales');
    } else {
      setType('transactions');
    }
  }
  return (
    <Paper data-qaid="shiftChart_main_paper" className={clsx(classes.paper, classes.noSidePadding, classes.relative, classes.chart)}>
      <Grid data-qaid="shiftChart_main_grid" container className={clsx(classes.paddingHorizontal, classes.padding, classes.relative)} justify="space-between" style={{ marginLeft: 24, paddingLeft: 0, paddingBottom: 0 }}>
        <Grid data-qaid="shiftChart_header_grid" item sm={12} lg={8} md={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <H3 data-qaid="shiftChart_header_text" label={Context.langData.shifts}></H3>
        </Grid>
        <RefreshButton data-qaid="shiftChart_refresh_button" refresh={refresh} className={classes.absolute} />

      </Grid>
      <Grid data-qaid="shiftChart_tabs_grid" container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 10, paddingRight: 20 }}>
        <Grid data-qaid="shiftChart_tabs_itemGrid" item sm={12} lg={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 24 }}>
          {
            buttonArray.map((item, i) => {
              return (
                <Button className={style.buttonClass} data-qaid="settings_button" style={{ backgroundColor: value == i ? '#051D33' : '#EEEEEE', marginRight: 1, borderRadius: 0, borderTopLeftRadius: i == 0 ? 5 : 0, borderBottomLeftRadius: i == 0 ? 5 : 0, borderTopRightRadius: buttonArray.length - 1 == i ? 5 : 0, borderBottomRightRadius: buttonArray.length - 1 == i ? 5 : 0 }} onClick={() => handleChange(i)}>
                  <AvenirBlackH4 data-qaid="settings_button_label" label={item} style={{ color: value == i ? '#FFFFFF' : '#051D33', paddingLeft: 8, paddingRight: 8, paddingTop: 10, paddingBottom: 10, lineHeight: '24px' }} />
                </Button>
              )
            }
            )
          }
          {/* <Tabs
            data-qaid="shiftChart_tabs"
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab data-qaid="shiftChart_sales_tab" label={Context.langData.sales} style={{ backgroundColor: value == 0 ? '#FAFAFA' : '#FFFFFF', color: '#000000' }} />
            <Tab data-qaid="shiftChart_transaction_tab" label={Context.langData.transactions} style={{ backgroundColor: value == 1 ? '#FAFAFA' : '#FFFFFF', color: '#000000' }} />
          </Tabs> */}
        </Grid>
      </Grid>
      <Grid containe style={{ display: 'flex', justifyContent: 'center' }}>
        <DoughnutChart data-qaid="shiftChart_doughnutchart" data={shifts.data || []} valueKey={type} labelsKey="type" />
      </Grid>
    </Paper>
  )
}

export default React.memo(ShiftsChart);