import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, MenuItem, Menu } from '@material-ui/core';
import MultiSelect from '../../../components/MultiSelect';
import MyContext from '../../../Provider/MyContext';
import ReportsTable from '../../../components/ReportsTable';
import Daterange from '../../../components/DateRange';
import moment from 'moment';
import { AvenirBlackH2, AvenirBlackH4 } from '../../../utils/text';
import { fetchVendorsData } from '../../../redux/actions';
import CircleClose from '../../../components/SVG/CircleClose';
import DownlodeIcon from '../../../components/SVG/DownlodeIcon';
import {
  ConvertPrice,
  ConvertCurrencyPriceToDollar,
} from '../../../utils/util';
import _ from 'lodash';
import { downloadVendorPdfDocument } from '../../../components/PDFTableGenerator';
import { downloadVendorsReport } from '../../../utils/excel';

const Vendors = () => {
  const Context = useContext(MyContext);
  const [table1, setTable1] = useState([]);
  const vendorsData = useSelector(
    (state) => state.inventoryReportReducer.vendors
  );
  const [date, setDate] = useState({});
  const dispatch = useDispatch();
  const [vendors, setVendors] = useState([]);
  const [resetDate, toggleResetDate] = useState(false);
  const vendorData = useSelector((state) => state.inventory.vendors);
  const [anchorEl, setAnchorEl] = useState(null);
  const [vendorColumns, setVendorColumns] = useState([
    { field: 'stock_name', display: Context.langData.stock_item, size: '40%' },
    {
      field: 'received_qty',
      display: Context.langData.received_qty,
      size: '30%',
    },
    {
      field: 'total_cost',
      display: Context.langData.amount,
      size: '30%',
      isNumber: true,
    },
  ]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleApplyVendorData();
    }, 100);
    return () => clearTimeout(timeout);
  }, [date, vendors]);

  const onDateChange = (d) => {
    if (
      date['from_date'] !== d.startDate ||
      date['to_date'] !== d.endDate
    ) {
      setDate({
        from_date: d.startDate,
        to_date: d.endDate,
      });
    }
  };

  const handleApplyVendorData = () => {
    var obj = { ...date };
    if (vendors.length > 0) {
      obj = {
        ...obj,
        vendor_ids: vendors.join(','),
      };
    } else {
      obj = {
        ...obj,
        vendor_ids: 'all',
      };
    }
    dispatch(fetchVendorsData(obj));
  };
  const handleClearVendorData = () => {
    setVendors([]);
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  useEffect(() => {
    var parsed_data = [];
    if (vendorsData?.data?.length > 0) {
      vendorsData.data.map((obj) => {
        var stock_list = [];
        var result = vendorData?.filter((e) => e.id == obj.vendorId);
        if (result.length != 0) {
          obj['vendor_name'] = result[0].name;
        } else {
          obj['vendor_name'] = 'Deleted Vendor';
        }
        if (obj?.data?.length > 0) {
          obj?.data?.map((stock) => {
            console.log('stockstock==>', stock);
            var received_qty =
              stock.fulfilledQuantity.toString() + ' ' + stock.itemUnitTypeCode;
            stock_list.push({
              stock_name: stock.itemName,
              received_qty: received_qty,
              total_cost: ConvertCurrencyPriceToDollar(stock.fulfilledAmount),
              quantity: stock.fulfilledQuantity,
            });
          });
        }
        obj['balance_amount'] = ConvertCurrencyPriceToDollar(obj.balanceAmount);
        obj['net_amount'] = ConvertCurrencyPriceToDollar(obj.fulfilledAmount);
        obj['amount_paid'] = ConvertCurrencyPriceToDollar(obj.paidAmount);
        obj['total_amount'] = ConvertCurrencyPriceToDollar(obj.totalAmount);
        delete obj.stocks;
        if (stock_list.length != 0) {
          obj['stocks'] = stock_list;
          parsed_data.push(obj);
        }
      });
    }
    console.log('parsed_data==>', parsed_data);
    setTable1(parsed_data);
  }, [vendorsData]);

  const handleSort = (name, desc, index, catIndx) => {
    var columnData = [...vendorColumns];
    columnData[index] = { ...columnData[index], desc };
    setVendorColumns(columnData);
    const catItems = table1[catIndx].stocks;
    let sortItemwiseSales;
    if (name == 'total_cost') {
      sortItemwiseSales = _.orderBy(
        catItems,
        [(catItem) => catItem[name]],
        [desc ? 'desc' : 'asc']
      );
    } else if (name == 'received_qty') {
      sortItemwiseSales = _.orderBy(
        catItems,
        [(catItem) => catItem['quantity']],
        [desc ? 'desc' : 'asc']
      );
    } else {
      sortItemwiseSales = _.orderBy(
        catItems,
        [(catItem) => catItem[name].toLowerCase()],
        [desc ? 'desc' : 'asc']
      );
    }
    table1[catIndx].stocks = sortItemwiseSales;
    setTable1(table1);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const downloadExcel = () => {
    downloadVendorsReport(table1);
    handleCloseMenu();
  };
  const downloadPDF = () => {
    downloadVendorPdfDocument(
      vendorColumns,
      table1,
      Context.langData.vendor_inventory_report,
      date,
      'VendorInventory'
    );
    handleCloseMenu();
  };

  return (
    <Grid
      data-qaid="vendors_mainContainer"
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid
        data-qaid="vendors_subContainer1"
        container
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Grid
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
          }}>
          <Grid data-qaid="vendors_dateGrid" item style={{}}>
            <AvenirBlackH4
              data-qaid="vendors_dateLabel"
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid="vendors_dateRange"
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>

          <Grid
            data-qaid="vendors_selectOrderView"
            item
            style={{ marginLeft: 10 }}>
            <AvenirBlackH4
              data-qaid="vendors_selectOrderLabel"
              label={Context.langData.select_vendor}
            />
            <MultiSelect
              data-qaid="vendors_setVendorsGrid"
              list={vendorData}
              value="vendor_id"
              name="name"
              placeholder={Context.langData.all_vendors}
              selectedValue={vendors}
              handleChange={(e) => setVendors(e)}
              border={false}
              height={56}
              width={298}
            />
          </Grid>
        </Grid>

        <Grid
          item
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 20,
          }}>
          <Grid
            data-qaid="vendors_clearContainer"
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 20,
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <CircleClose
              data-qaid="vendors_closeIcon"
              onClick={() => handleClearVendorData()}></CircleClose>
            <AvenirBlackH4
              data-qaid="vendors_clearLabel"
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid="consolidated_index_h1_download"
            style={{
              backgroundColor: '#FFB600',
              marginRight: 0,
              height: 40,
              width: '149px',
            }}
            variant="contained"
            id="cancel">
            <DownlodeIcon
              data-qaid="consolidated_index_h1_downlodeicon"
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid="consolidated_create_label"
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id="simple-menu-download"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {table1?.length > 0 ? (
        table1.map((vendor, index) => {
          if (vendor.stocks.length > 0) {
            return (
              <>
                <Grid
                  key={index}
                  data-qaid="vendors_subContainer2"
                  container
                  style={{
                    display: 'flex',
                    marginTop: 20,
                    marginBottom: 20,
                    alignItems: 'center',
                  }}>
                  <Grid container lg={4}>
                    <AvenirBlackH2
                      data-qaid="vendors_supplyNameLabel"
                      label={vendor.vendor_name}
                      style={{ fontWeight: 'bold' }}
                    />
                  </Grid>
                  <Grid
                    container
                    lg={8}
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Grid
                      style={{
                        flexDirection: 'row',
                        dispaly: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <AvenirBlackH2
                        data-qaid="vendors_balanceAmountLabel1"
                        label={`$${ConvertPrice(vendor.balance_amount)}`}
                        style={{ fontWeight: 'bold' }}
                      />
                      <AvenirBlackH4
                        data-qaid="vendors_balanceAmountLabel2"
                        label={Context.langData.balance_amount}
                        style={{ color: '#707272' }}
                      />
                    </Grid>
                    <Grid
                      style={{
                        flexDirection: 'row',
                        dispaly: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <AvenirBlackH2
                        data-qaid="vendors_paidAmountLabel1"
                        label={`$${ConvertPrice(vendor.amount_paid)}`}
                        style={{ fontWeight: 'bold' }}
                      />
                      <AvenirBlackH4
                        data-qaid="vendors_paidAmountLabel2"
                        label={Context.langData.paid_amount}
                        style={{ color: '#707272' }}
                      />
                    </Grid>
                    <Grid
                      style={{
                        flexDirection: 'row',
                        dispaly: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <AvenirBlackH2
                        data-qaid="vendors_purchasesAmountLabel1"
                        label={`$${ConvertPrice(vendor.total_amount)}`}
                        style={{ fontWeight: 'bold' }}
                      />
                      <AvenirBlackH4
                        data-qaid="vendors_purchasesLabel2"
                        label={Context.langData.total_purchases}
                      />
                    </Grid>
                    <Grid
                      style={{
                        flexDirection: 'row',
                        dispaly: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <AvenirBlackH2
                        data-qaid="vendors_totalWithoutTextLabel1"
                        label={`$${ConvertPrice(vendor.net_amount)}`}
                        style={{ fontWeight: 'bold' }}
                      />
                      <AvenirBlackH4
                        data-qaid="vendors_totalWithoutTextLabel2"
                        label={Context.langData.total_without_tax}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  data-qaid="vendors_reportsTableView"
                  item
                  xs={12}
                  lg={12}
                  style={{ marginTop: 20 }}>
                  <ReportsTable
                    data-qaid="vendors_reportsTable"
                    columns={vendorColumns}
                    rows={vendor.stocks}
                    handleSort={(name, desc, colIndex) =>
                      handleSort(name, desc, colIndex, index)
                    }
                  />
                </Grid>
              </>
            );
          } else {
          }
        })
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default Vendors;
