import * as React from "react"

function ContextMenu({ color = '#004C60', ...props }) {
    return (
        <svg

            width={5}
            height={22}
            viewBox="0 0 5 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.444 18.333a1.222 1.222 0 100 2.445 1.222 1.222 0 000-2.445zM0 19.556a2.444 2.444 0 114.889 0 2.444 2.444 0 01-4.889 0zM2.444 1.222a1.222 1.222 0 100 2.445 1.222 1.222 0 000-2.445zM0 2.444a2.444 2.444 0 114.889 0 2.444 2.444 0 01-4.889 0zM2.444 9.778a1.222 1.222 0 100 2.444 1.222 1.222 0 000-2.444zM0 11a2.444 2.444 0 114.889 0A2.444 2.444 0 010 11z"
                fill={color}
            />
        </svg>
    )
}

export default ContextMenu
