import React, { useState, useEffect, useContext } from 'react';
import RightDrawer from '../../../../components/RightDrawer';
import {
    Grid,
    Button,
    TextField,
    Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SingleSelect from '../../../../components/SingleSelect';
import {
    fetchAllGroupsData,
    fetchAllVendors,
    saveIssue,
    fetchAllIndents,
    fetchAllStocks
} from '../../../../redux/actions';
import AutoCompliteDropDown from '../../../../components/AutoComplete';
import Delete from '../../../../components/SVG/Delete';
import MyContext from '../../../../Provider/MyContext';
import moment from 'moment'
import styles from '../../../../styles/index';
const IssueIndent = ({ open, onClose }) => {
    const style = styles();
    const dispatch = useDispatch();
    const groupsData = useSelector(state => state.inventory.groupsData);
    const getStocks = useSelector((state) => state.inventory.stocks);
    const indentViewData = useSelector(state => state.inventory.indentViewdata);
    const [allGroups, setAllGroups] = useState([]);
    const vendorData = useSelector(state => state.inventory.vendors);
    const [vendorName, setVendorName] = useState([]);
    const [itemsList, setItemsList] = useState([]);
    const [dateValue, setDateValue] = useState('')
    const [currentDate, setCurrentDate] = useState('');
    const Context = useContext(MyContext);
    useEffect(() => {
        setCurrentDate(moment().format("YYYY-MM-DD"));
        setDateValue(moment().format("YYYY-MM-DD"))
        dispatch(fetchAllGroupsData());
        dispatch(fetchAllStocks())
        dispatch(fetchAllVendors());
    }, []);
    useEffect(() => {
        if (indentViewData && indentViewData.items && indentViewData.items.length > 0) {
            console.log("indentViewData", indentViewData)
            var itemListArray = [];
            indentViewData.items.map((itemObj) => {
                var obj = { stock_name: itemObj.stock_name, amount: itemObj.amount, units: itemObj.units, stock_id: itemObj.stock_id, unit_price: 0 }
                itemListArray.push(obj);
            })
            setItemsList(itemListArray)
        }
        else {

        }
    }, [indentViewData])
    //this is not working. maybe no needed.
    const handleSave = () => {
        var vendorId = vendorName;
        console.log("vendorId", vendorName)
        var VName = '';
        var items = []
        if (vendorData && vendorData.length > 0) {
            var vendorItem = vendorData.filter((e) => e.vendor_id == vendorName)
            if (vendorItem.length > 0) {
                VName = vendorItem[0].name;
            }

        }
        if (itemsList && itemsList.length > 0) {
            itemsList.map((Sitem) => {
                if (Sitem.amount > 0) {
                    items.push(Sitem)
                }
            })
        }

        dispatch(saveIssue({ vendor_id: vendorId, vendor_name: VName, items: items, date: dateValue, issue: 1 }, onClose));
    }
    useEffect(() => {
        if (vendorData && vendorData.length) {
            setVendorName(vendorData[0].vendor_id)
        }
    }, [vendorData])

    function callback() {
        dispatch(fetchAllIndents({ status: 'all', vendor_id: 'all' }));
    }
    const selectedStock = (value) => {
        var itemListArray = [...itemsList];
        var item = itemListArray.filter((e) => e.name == value)
        if (item.length > 0) {
        } else {
            if (getStocks && getStocks.length > 0) {
                var stock = getStocks.filter((e) => e.name = value)
                if (stock.length > 0) {
                    var obj = { stock_name: stock[0].name, amount: 0, units: stock[0].units, stock_id: stock[0].stock_id, unit_price: 0 }
                    itemListArray.push(obj);
                }
            }
        }
        setItemsList(itemListArray);
        console.log("select value", value, "itemList", itemListArray)
    }
    const updateQtyValue = (index, value) => {
        var item = [...itemsList];
        item[index] = { ...item[index], amount: parseInt(value) }
        setItemsList(item);
    }
    const updateunitPrice = (index, value) => {
        var item = [...itemsList];
        item[index] = { ...item[index], unit_price: parseInt(value) }
        setItemsList(item);
    }
    const removeStock = (index) => {
        var item = [...itemsList];
        item.splice(index, 1)
        setItemsList(item)
    }
    const selectCatDropDown = (value) => {
        var itemArray = []
        if (groupsData && groupsData.length > 0) {
            var catItem = groupsData.filter((e) => e.scat_id == value)
            if (catItem.length > 0) {
                if (catItem[0].stock_ids && catItem[0].stock_ids.length > 0) {
                    catItem[0].stock_ids.map((item) => {
                        var StockItem = getStocks.filter((stocke) => stocke.stock_id == item)
                        if (StockItem.length > 0) {
                            var obj = { stock_name: StockItem[0].name, amount: 0, units: StockItem[0].units, stock_id: StockItem[0].stock_id, unit_price: 0 }
                            itemArray.push(obj)
                        }
                    })
                }
            }
        }
        setAllGroups(value);
        setItemsList(itemArray);
    }
    const handleSelectStock = (value) => {
        selectedStock(value)
    }
    return (
        <RightDrawer open={open}>
            <Grid data-qaid="issueIndent_subcontainer1" container style={{ display: 'flex', flexDirection: 'row', width: 500, marginLeft: 10 }} >
                <Grid item >
                    <Typography variant="h3" style={{ display: 'flex', justifyContent: 'center' }}>
                        Send Issue
                    </Typography>
                </Grid>

                <Grid data-qaid="issueIndent_vendorMainContainer" container style={{ marginTop: '30px', justifyContent: 'space-between' }}>
                    <Grid data-qaid="issueIndent_vendorSubContainer1" item style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid data-qaid="issueIndent_vendorSubContainer2" item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                            <Typography variant="h5" style={{ alignItems: 'center' }}>Vendor : </Typography>

                            <Grid data-qaid="issueIndent_setVendorName" item style={{ marginLeft: '10px' }}>
                                <SingleSelect
                                    list={vendorData}
                                    valueKey="vendor_id"
                                    displayKey="name"
                                    placeholder="Select"
                                    value={vendorName}
                                    handleChange={(e) => setVendorName(e.target.value)}
                                    border={true}
                                // style={{height:'10px', marginTop:'10px'}}
                                />
                            </Grid>
                        </Grid>
                        <Grid data-qaid="issueIndent_selectedStockMainContainer" item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography variant="h5" >Search : </Typography>
                            <Grid data-qaid="issueIndent_selectedStockSubContainer" item style={{ width: 200, marginTop: '10px', marginLeft: '10px' }}>
                                <AutoCompliteDropDown
                                    options={getStocks}
                                    onChange={handleSelectStock}
                                    labelKey={'name'}
                                    key={itemsList}
                                    placeholder={Context.langData.serch_stock_items}

                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid data-qaid="issueIndent_setDateValueGrid" item style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h5">Raise Date :</Typography>
                        <TextField
                            id="birthday"
                            type="date"
                            value={dateValue}
                            variant="outlined"
                            placeholder='DD/MM/YYYY'
                            onChange={(e) => {
                                setDateValue(e.target.value)
                            }}
                            size="small"
                            style={{ width: '100%' }}
                            inputProps={{ min: "1900-01-01", max: currentDate }}
                        />
                        <Grid data-qaid="issueIndent_selectCatDropDownGrid" item style={{ marginTop: '30px' }}>
                            <SingleSelect
                                list={groupsData}
                                valueKey="scat_id"
                                displayKey="scat_name"
                                placeholder="Select category"
                                value={allGroups}
                                handleChange={(e) => selectCatDropDown(e.target.value)}
                                border={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid data-qaid="issueIndent_itemsGrid" item style={{ marginTop: '20px' }}>
                    <Grid data-qaid="issueIndent_itemsMainContainer" item style={{ marginLeft: 20, marginTop: '20px', display: 'flex', flexDirection: 'row', backgroundColor: '#f4f4f5' }}>
                        <Grid data-qaid="issueIndent_items" item xs={12} sm={12} md={3} lg={3} style={{ marginRight: 30 }}>
                            <Typography variant="h5">
                                items
                            </Typography>
                        </Grid>
                        <Grid data-qaid="issueIndent_storeBalance" item xs={12} sm={12} md={3} lg={3} style={{ marginRight: 30 }}>
                            <Typography variant="h5">
                                Store Balance
                            </Typography>
                        </Grid>
                        <Grid data-qaid="issueIndent_dispatch" item xs={12} sm={12} md={3} lg={3} style={{ marginRight: 30 }}>
                            <Typography variant="h5">
                                Dispatch Qty
                            </Typography>
                        </Grid>
                        <Grid data-qaid="issueIndent_price" item xs={12} sm={12} md={3} lg={3} style={{ marginLeft: 30, marginLeft: 100 }}>
                            <Typography variant="h5">
                                Price
                            </Typography>
                        </Grid>

                    </Grid>
                    {itemsList ?
                        itemsList.map((itemName, index) => {
                            return (
                                <Grid data-qaid="issueIndent_stockNameGrid" key={itemName.stock_name} item style={{ display: 'flex', flexDirection: 'row', marginLeft: 20, marginTop: 10 }}>
                                    <Grid data-qaid="issueIndent_stockName" item xs={12} sm={12} md={3} lg={3} style={{ marginRight: 30 }}>
                                        <Typography variant="h5">
                                            {itemName.stock_name}
                                        </Typography>
                                    </Grid>
                                    <Grid data-qaid="issueIndent_amount" item xs={12} sm={12} md={3} lg={3} style={{ marginRight: 30 }}>
                                        <Typography variant="h5">
                                            {itemName.amount}&nbsp;&nbsp;{itemName.units}
                                        </Typography>
                                    </Grid>
                                    <Grid data-qaid="issueIndent_updateQuantityValue" item xs={12} sm={12} md={3} lg={3} style={{ marginLeft: 30, display: 'flex', flexDirection: 'row' }}>
                                        <TextField
                                            value={itemName.amount}
                                            maxLength={8}
                                            onChange={(e) => { updateQtyValue(index, e.target.value) }}
                                        >
                                        </TextField>
                                        <Typography variant="h5">
                                            {itemName.units}
                                        </Typography>

                                        <TextField
                                            maxLength={8}
                                            value={itemName.unit_price}
                                            onChange={(e) => { updateunitPrice(index, e.target.value) }}
                                        >
                                        </TextField>
                                        <Typography variant="h5">
                                            {itemName.units}
                                        </Typography>
                                        <Delete width={24} height={24} onClick={() => { removeStock(index) }} ></Delete>
                                    </Grid>
                                </Grid>
                            )
                        }) : null
                    }
                </Grid>

            </Grid>
            <Grid data-qaid="issueIndent_buttonView" style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                <Button className={style.buttonClass} data-qaid="issueIndent_cancelButton" size="large" variant="outlined" type="submit" style={{ color: 'black', backgroundColor: '#f4f4f5', cursor: 'pointer' }} onClick={onClose}>
                    <Typography variant="h5" >Cancel</Typography></Button>
                <Button className={style.buttonClass} data-qaid="issueIndent_saveButton" size="large" variant="outlined" type="submit" style={{ color: 'white', backgroundColor: '#f0585f', marginLeft: '10px', cursor: 'pointer' }} onClick={handleSave}>  <Typography variant="h5" style={{ color: 'white' }}>Save</Typography></Button>
            </Grid>
        </RightDrawer>
    )
}

export default IssueIndent;